/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StripeRecurring } from '../models/StripeRecurring';
import { HttpFile } from '../http/http';

export class StripePrice {
    'currency': string;
    'id': string;
    'product': string;
    'unitAmount': number;
    'unitAmountDecimal': string;
    'active': boolean;
    'customUnitAmount': string;
    'recurring'?: StripeRecurring;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "currency",
            "baseName": "currency",
            "type": "string",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "product",
            "baseName": "product",
            "type": "string",
            "format": ""
        },
        {
            "name": "unitAmount",
            "baseName": "unitAmount",
            "type": "number",
            "format": ""
        },
        {
            "name": "unitAmountDecimal",
            "baseName": "unitAmountDecimal",
            "type": "string",
            "format": ""
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "customUnitAmount",
            "baseName": "customUnitAmount",
            "type": "string",
            "format": ""
        },
        {
            "name": "recurring",
            "baseName": "recurring",
            "type": "StripeRecurring",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StripePrice.attributeTypeMap;
    }

    public constructor() {
    }
}

