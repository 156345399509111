/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class TranscodedFile {
    'file': URI;
    'width': number;
    'height': number;
    'frameRate': number;
    'encoding': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "file",
            "baseName": "file",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "width",
            "baseName": "width",
            "type": "number",
            "format": ""
        },
        {
            "name": "height",
            "baseName": "height",
            "type": "number",
            "format": ""
        },
        {
            "name": "frameRate",
            "baseName": "frameRate",
            "type": "number",
            "format": "double"
        },
        {
            "name": "encoding",
            "baseName": "encoding",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TranscodedFile.attributeTypeMap;
    }

    public constructor() {
    }
}

