import { Group } from "@/openapi";
import { devPrint } from "@/utils/devUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGroupsState {
  groups: Group[] | undefined;
  selectedGroup: Group | undefined;
  fetchingGroups: boolean;
}

export const initialGroupsState: IGroupsState = {
  groups: undefined,
  selectedGroup: undefined,
  fetchingGroups: false,
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState: initialGroupsState,
  reducers: {
    setSelectedGroup: (state, action: PayloadAction<Group | undefined>) => {
      state.selectedGroup = action.payload;
    },
    setFetchingGroups: (state, action: PayloadAction<boolean>) => {
      state.fetchingGroups = action.payload;
    },
    setGroups: (state, action: PayloadAction<Group[] | undefined>) => {
      state.groups = action.payload;
    },
  },
});

export const { setGroups, setSelectedGroup, setFetchingGroups } =
  groupsSlice.actions;

export default groupsSlice.reducer;
