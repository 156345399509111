/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HealthStatusEnum } from '../models/HealthStatusEnum';
import { HorseProfilePhoto } from '../models/HorseProfilePhoto';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { HttpFile } from '../http/http';

export class WFOffspring {
    'faedingarnumer': string;
    'name': string;
    'sexEnum'?: SexEnumEnum;
    'healthStatus'?: HealthStatusEnum;
    'sportfengurId'?: number;
    'avatar'?: HorseProfilePhoto;
    'colorCode'?: string;
    'sireName'?: string;
    'damName'?: string;
    'sex'?: string;
    'isAlive'?: boolean;
    'birthDate': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "faedingarnumer",
            "baseName": "faedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "sexEnum",
            "baseName": "sexEnum",
            "type": "SexEnumEnum",
            "format": ""
        },
        {
            "name": "healthStatus",
            "baseName": "healthStatus",
            "type": "HealthStatusEnum",
            "format": ""
        },
        {
            "name": "sportfengurId",
            "baseName": "sportfengurId",
            "type": "number",
            "format": ""
        },
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "HorseProfilePhoto",
            "format": ""
        },
        {
            "name": "colorCode",
            "baseName": "colorCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "sireName",
            "baseName": "sireName",
            "type": "string",
            "format": ""
        },
        {
            "name": "damName",
            "baseName": "damName",
            "type": "string",
            "format": ""
        },
        {
            "name": "sex",
            "baseName": "sex",
            "type": "string",
            "format": ""
        },
        {
            "name": "isAlive",
            "baseName": "isAlive",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "birthDate",
            "baseName": "birthDate",
            "type": "string",
            "format": "date"
        }    ];

    static getAttributeTypeMap() {
        return WFOffspring.attributeTypeMap;
    }

    public constructor() {
    }
}



