import { Actor, PublicUser, SimpleUser, User } from "@/openapi";
import store from "@/redux/store";
import { trackEvent } from "./analyticsFunctions";

export const isPremium = (user?: User | SimpleUser | PublicUser) => {
  if (!user) {
    user = store.getState().userState.user;
  }

  if (!user) {
    return false;
  }
  if (user.subscriptionType === "CUSTOM") {
    return true;
  }

  return user.entitlement === "PRO";
};

export const publicUserToSimpleUser = (
  user?: PublicUser | User
): SimpleUser => {
  const u = new SimpleUser();
  if (!user) {
    u.name = "";
    u.id = -1;
    return u;
  }
  u.name = user.name;
  u.id = user.id;
  u.uid = user.uid;
  u.aThumbnail = user.aThumbnail;
  u.avatar = user.avatar;
  u.entitlement = user.entitlement ?? "NONE";
  u.subscriptionType = user.subscriptionType ?? "BASIC";
  u.role = user.role;

  return u;
};

export const showTestFeature = (): boolean => {
  const user = store.getState().userState.user;

  if (user && (user.isStaff || user.featureFlagged)) {
    return true;
  }
  return false;
};

export const userToActor = (user: User | undefined) => {
  if (!user) {
    return undefined;
  }
  const a = new Actor();
  a.name = user.name;
  a.id = user.id;
  a.uid = user.uid;
  a.avatar = user.avatar;
  a.aThumbnail = user.aThumbnail;
  a.gender = user.gender;
  a.subscriptionType = user.subscriptionType;
  return a;
};

// not a good way of doing this
export const hasLikedActivity = (activityId: number): boolean => {
  const reactions = store.getState().userState.activityReactions;
  if (!reactions) {
    return false;
  }
  for (var item of reactions) {
    if (item.activity === activityId) {
      return true;
    }
  }
  return false;
};

export const getIPCountry = async () => {
  var res = await fetch("http://ip-api.com/json");
  var data = await res.json();
  trackEvent("user_country");
  return data["country"] ?? undefined;
};
