import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import { Configuration} from '../configuration'

import { AcceptRequest } from '../models/AcceptRequest';
import { ActivityMedia } from '../models/ActivityMedia';
import { ActivityReaction } from '../models/ActivityReaction';
import { ActivityReactionRequest } from '../models/ActivityReactionRequest';
import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { ActivityVideoThumbnailRequest } from '../models/ActivityVideoThumbnailRequest';
import { Actor } from '../models/Actor';
import { AnswerUserSurveyRequest } from '../models/AnswerUserSurveyRequest';
import { AppMeta } from '../models/AppMeta';
import { AssessmentOverview } from '../models/AssessmentOverview';
import { ChatMessage } from '../models/ChatMessage';
import { ChatMessageRequest } from '../models/ChatMessageRequest';
import { ChatRoom } from '../models/ChatRoom';
import { ChatRoomRequest } from '../models/ChatRoomRequest';
import { ChatUser } from '../models/ChatUser';
import { ChatUserRequest } from '../models/ChatUserRequest';
import { Comment } from '../models/Comment';
import { CommentReaction } from '../models/CommentReaction';
import { CommentReactionRequest } from '../models/CommentReactionRequest';
import { CommentReactionSummary } from '../models/CommentReactionSummary';
import { CommentRequest } from '../models/CommentRequest';
import { CountryYears } from '../models/CountryYears';
import { CreateGroupDiscussionRequest } from '../models/CreateGroupDiscussionRequest';
import { CreateGroupRequest } from '../models/CreateGroupRequest';
import { CreateGroupUserInviteRequest } from '../models/CreateGroupUserInviteRequest';
import { CreateHorseAccessInviteRequest } from '../models/CreateHorseAccessInviteRequest';
import { CreateHorseAccessReqRequest } from '../models/CreateHorseAccessReqRequest';
import { CreateHorseAccessRequest } from '../models/CreateHorseAccessRequest';
import { CreateHorseRequest } from '../models/CreateHorseRequest';
import { CustomTrainingSession } from '../models/CustomTrainingSession';
import { CustomTrainingSessionCreate } from '../models/CustomTrainingSessionCreate';
import { CustomTrainingSessionCreateRequest } from '../models/CustomTrainingSessionCreateRequest';
import { DetailedAssessmentOverview } from '../models/DetailedAssessmentOverview';
import { DownloadPurposeEnum } from '../models/DownloadPurposeEnum';
import { DurationEnum } from '../models/DurationEnum';
import { EntitlementEnum } from '../models/EntitlementEnum';
import { EventPoints } from '../models/EventPoints';
import { FaedingarnumerSerilizerRequest } from '../models/FaedingarnumerSerilizerRequest';
import { Feeding } from '../models/Feeding';
import { FeedingRequest } from '../models/FeedingRequest';
import { FlokkurEnum } from '../models/FlokkurEnum';
import { FlokkurKeppni } from '../models/FlokkurKeppni';
import { FollowEventFlokkur } from '../models/FollowEventFlokkur';
import { FollowEventFlokkurRequest } from '../models/FollowEventFlokkurRequest';
import { FollowRequest } from '../models/FollowRequest';
import { FollowingFollowers } from '../models/FollowingFollowers';
import { GaitEnum } from '../models/GaitEnum';
import { GaitPredictions } from '../models/GaitPredictions';
import { GaitPredictionsRequest } from '../models/GaitPredictionsRequest';
import { GaitRating } from '../models/GaitRating';
import { GaitRatingRequest } from '../models/GaitRatingRequest';
import { GaitSensorRequest } from '../models/GaitSensorRequest';
import { GoalRequest } from '../models/GoalRequest';
import { Group } from '../models/Group';
import { GroupDiscussion } from '../models/GroupDiscussion';
import { GroupDiscussionComment } from '../models/GroupDiscussionComment';
import { GroupDiscussionCommentReaction } from '../models/GroupDiscussionCommentReaction';
import { GroupDiscussionCommentReactionRequest } from '../models/GroupDiscussionCommentReactionRequest';
import { GroupDiscussionCommentRequest } from '../models/GroupDiscussionCommentRequest';
import { GroupDiscussionPhoto } from '../models/GroupDiscussionPhoto';
import { GroupDiscussionPhotoRequest } from '../models/GroupDiscussionPhotoRequest';
import { GroupDiscussionReaction } from '../models/GroupDiscussionReaction';
import { GroupDiscussionReactionRequest } from '../models/GroupDiscussionReactionRequest';
import { GroupInvite } from '../models/GroupInvite';
import { GroupMare } from '../models/GroupMare';
import { GroupMareRequest } from '../models/GroupMareRequest';
import { GroupProfile } from '../models/GroupProfile';
import { GroupRequest } from '../models/GroupRequest';
import { GroupSettings } from '../models/GroupSettings';
import { GroupSettingsRequest } from '../models/GroupSettingsRequest';
import { GroupThumbnailRequest } from '../models/GroupThumbnailRequest';
import { GroupTypeEnum } from '../models/GroupTypeEnum';
import { GroupUserRoleEnum } from '../models/GroupUserRoleEnum';
import { GroupUserRoleRequest } from '../models/GroupUserRoleRequest';
import { HealthStatusEnum } from '../models/HealthStatusEnum';
import { Horse } from '../models/Horse';
import { HorseAccess } from '../models/HorseAccess';
import { HorseAccessHistory } from '../models/HorseAccessHistory';
import { HorseAccessInvite } from '../models/HorseAccessInvite';
import { HorseAccessInviteRequest } from '../models/HorseAccessInviteRequest';
import { HorseAccessReq } from '../models/HorseAccessReq';
import { HorseAccessRequest } from '../models/HorseAccessRequest';
import { HorseActivities } from '../models/HorseActivities';
import { HorseActivity } from '../models/HorseActivity';
import { HorseActivityCreate } from '../models/HorseActivityCreate';
import { HorseActivityCreateRequest } from '../models/HorseActivityCreateRequest';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { HorseActivityMultiCreateRequest } from '../models/HorseActivityMultiCreateRequest';
import { HorseActivityPhoto } from '../models/HorseActivityPhoto';
import { HorseActivityPhotoRequest } from '../models/HorseActivityPhotoRequest';
import { HorseActivityReactionSummary } from '../models/HorseActivityReactionSummary';
import { HorseActivityUpdate } from '../models/HorseActivityUpdate';
import { HorseActivityUpdateRequest } from '../models/HorseActivityUpdateRequest';
import { HorseActivityVideo } from '../models/HorseActivityVideo';
import { HorseActivityVideoRequest } from '../models/HorseActivityVideoRequest';
import { HorseAssessment } from '../models/HorseAssessment';
import { HorseAssessmentRequest } from '../models/HorseAssessmentRequest';
import { HorseBreedEnum } from '../models/HorseBreedEnum';
import { HorseDayMessage } from '../models/HorseDayMessage';
import { HorseDayRanking } from '../models/HorseDayRanking';
import { HorseDetails } from '../models/HorseDetails';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { HorseDetailsRequest } from '../models/HorseDetailsRequest';
import { HorseForSale } from '../models/HorseForSale';
import { HorseForSaleRequest } from '../models/HorseForSaleRequest';
import { HorseIdRequest } from '../models/HorseIdRequest';
import { HorseMedicalDetails } from '../models/HorseMedicalDetails';
import { HorseMedicalDetailsRequest } from '../models/HorseMedicalDetailsRequest';
import { HorsePhoto } from '../models/HorsePhoto';
import { HorsePhotoRequest } from '../models/HorsePhotoRequest';
import { HorseProfilePhoto } from '../models/HorseProfilePhoto';
import { HorseProfilePhotoRequest } from '../models/HorseProfilePhotoRequest';
import { HorseQueryMetadata } from '../models/HorseQueryMetadata';
import { HorseSettings } from '../models/HorseSettings';
import { HorseSettingsRequest } from '../models/HorseSettingsRequest';
import { HorseShoeEnum } from '../models/HorseShoeEnum';
import { HorseTypeEnum } from '../models/HorseTypeEnum';
import { HorseUserRoleEnum } from '../models/HorseUserRoleEnum';
import { HorseVaccination } from '../models/HorseVaccination';
import { HorseVaccinationRequest } from '../models/HorseVaccinationRequest';
import { HorseVideo } from '../models/HorseVideo';
import { HorseVideoRequest } from '../models/HorseVideoRequest';
import { HorseVideoThumbnailRequest } from '../models/HorseVideoThumbnailRequest';
import { IdRequest } from '../models/IdRequest';
import { InRoomRequest } from '../models/InRoomRequest';
import { InfoSummary } from '../models/InfoSummary';
import { IntervalTypeEnum } from '../models/IntervalTypeEnum';
import { LandsmotJudgeOverview } from '../models/LandsmotJudgeOverview';
import { Leaderboard } from '../models/Leaderboard';
import { LeaderboardRequest } from '../models/LeaderboardRequest';
import { LoginRequest } from '../models/LoginRequest';
import { MeasurmentSystemEnum } from '../models/MeasurmentSystemEnum';
import { Measurments } from '../models/Measurments';
import { MeasurmentsRequest } from '../models/MeasurmentsRequest';
import { Medical } from '../models/Medical';
import { MedicalRequest } from '../models/MedicalRequest';
import { MessageTypeEnum } from '../models/MessageTypeEnum';
import { MetricEnum } from '../models/MetricEnum';
import { MonthlyTrainingSummary } from '../models/MonthlyTrainingSummary';
import { Notification } from '../models/Notification';
import { NotificationTypeEnum } from '../models/NotificationTypeEnum';
import { NotificationTypeRequest } from '../models/NotificationTypeRequest';
import { Number } from '../models/Number';
import { NumberRequest } from '../models/NumberRequest';
import { Owner } from '../models/Owner';
import { OwnerRequest } from '../models/OwnerRequest';
import { PaginatedActivity } from '../models/PaginatedActivity';
import { PaginatedActivityMediaList } from '../models/PaginatedActivityMediaList';
import { PaginatedActivityReactionList } from '../models/PaginatedActivityReactionList';
import { PaginatedChatMessageList } from '../models/PaginatedChatMessageList';
import { PaginatedChatRoomList } from '../models/PaginatedChatRoomList';
import { PaginatedCommentList } from '../models/PaginatedCommentList';
import { PaginatedCommentReactionList } from '../models/PaginatedCommentReactionList';
import { PaginatedGroupDiscussionCommentList } from '../models/PaginatedGroupDiscussionCommentList';
import { PaginatedGroupDiscussionList } from '../models/PaginatedGroupDiscussionList';
import { PaginatedHorseActivityList } from '../models/PaginatedHorseActivityList';
import { PaginatedHorseActivityPhotoList } from '../models/PaginatedHorseActivityPhotoList';
import { PaginatedHorseActivityVideoList } from '../models/PaginatedHorseActivityVideoList';
import { PaginatedHorseDayRankingList } from '../models/PaginatedHorseDayRankingList';
import { PaginatedHorseList } from '../models/PaginatedHorseList';
import { PaginatedHorsePhotoList } from '../models/PaginatedHorsePhotoList';
import { PaginatedHorsePhotos } from '../models/PaginatedHorsePhotos';
import { PaginatedHorseVideoList } from '../models/PaginatedHorseVideoList';
import { PaginatedHorseVideos } from '../models/PaginatedHorseVideos';
import { PaginatedHorses } from '../models/PaginatedHorses';
import { PaginatedLeaderboardList } from '../models/PaginatedLeaderboardList';
import { PaginatedNotificationList } from '../models/PaginatedNotificationList';
import { PaginatedPaginatedHorsePhotosList } from '../models/PaginatedPaginatedHorsePhotosList';
import { PaginatedPaginatedHorseVideosList } from '../models/PaginatedPaginatedHorseVideosList';
import { PaginatedTrainingSessionList } from '../models/PaginatedTrainingSessionList';
import { PaginatedUsers } from '../models/PaginatedUsers';
import { PartialSurvey } from '../models/PartialSurvey';
import { PartialSurveyQuestion } from '../models/PartialSurveyQuestion';
import { PartialUserSurvey } from '../models/PartialUserSurvey';
import { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import { PasswordResetConfirmRequest } from '../models/PasswordResetConfirmRequest';
import { PasswordResetRequest } from '../models/PasswordResetRequest';
import { PatchedActivityReactionRequest } from '../models/PatchedActivityReactionRequest';
import { PatchedCommentReactionRequest } from '../models/PatchedCommentReactionRequest';
import { PatchedCommentRequest } from '../models/PatchedCommentRequest';
import { PatchedFeedingRequest } from '../models/PatchedFeedingRequest';
import { PatchedGroupDiscussionCommentRequest } from '../models/PatchedGroupDiscussionCommentRequest';
import { PatchedGroupDiscussionRequest } from '../models/PatchedGroupDiscussionRequest';
import { PatchedGroupRequest } from '../models/PatchedGroupRequest';
import { PatchedHorseAccessInviteRequest } from '../models/PatchedHorseAccessInviteRequest';
import { PatchedHorseAccessRequest } from '../models/PatchedHorseAccessRequest';
import { PatchedHorseActivityPhotoRequest } from '../models/PatchedHorseActivityPhotoRequest';
import { PatchedHorseActivityUpdateRequest } from '../models/PatchedHorseActivityUpdateRequest';
import { PatchedHorseActivityVideoRequest } from '../models/PatchedHorseActivityVideoRequest';
import { PatchedHorseDetailsRequest } from '../models/PatchedHorseDetailsRequest';
import { PatchedHorseMedicalDetailsRequest } from '../models/PatchedHorseMedicalDetailsRequest';
import { PatchedHorsePhotoRequest } from '../models/PatchedHorsePhotoRequest';
import { PatchedHorseRequest } from '../models/PatchedHorseRequest';
import { PatchedHorseVaccinationRequest } from '../models/PatchedHorseVaccinationRequest';
import { PatchedHorseVideoRequest } from '../models/PatchedHorseVideoRequest';
import { PatchedLeaderboardRequest } from '../models/PatchedLeaderboardRequest';
import { PatchedNotificationTypeRequest } from '../models/PatchedNotificationTypeRequest';
import { PatchedTrainingSessionRequest } from '../models/PatchedTrainingSessionRequest';
import { PatchedUserRequest } from '../models/PatchedUserRequest';
import { PatchedUserSettingsRequest } from '../models/PatchedUserSettingsRequest';
import { PatchedUserUpdateRequest } from '../models/PatchedUserUpdateRequest';
import { PlatformEnum } from '../models/PlatformEnum';
import { Point } from '../models/Point';
import { PriceCategoryEnum } from '../models/PriceCategoryEnum';
import { PromoCode } from '../models/PromoCode';
import { PromoCodeRequest } from '../models/PromoCodeRequest';
import { ProviderEnum } from '../models/ProviderEnum';
import { PublicUser } from '../models/PublicUser';
import { ReactionTypeEnum } from '../models/ReactionTypeEnum';
import { RegisterRequest } from '../models/RegisterRequest';
import { RequestStatus } from '../models/RequestStatus';
import { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import { RestAuthDetail } from '../models/RestAuthDetail';
import { RevenucatEntitlement } from '../models/RevenucatEntitlement';
import { RevenucatSubscription } from '../models/RevenucatSubscription';
import { RevenuecatSubscriptions } from '../models/RevenuecatSubscriptions';
import { SafetyTrackingMyInfo } from '../models/SafetyTrackingMyInfo';
import { SafetyTrackingTeam } from '../models/SafetyTrackingTeam';
import { SafetyTrackingTeamInvite } from '../models/SafetyTrackingTeamInvite';
import { SafetyTrackingTeamInviteCreateRequest } from '../models/SafetyTrackingTeamInviteCreateRequest';
import { SafetyTrackingViewer } from '../models/SafetyTrackingViewer';
import { SearchHorse } from '../models/SearchHorse';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { Shoeing } from '../models/Shoeing';
import { ShoeingRequest } from '../models/ShoeingRequest';
import { SimpleBooleanRequest } from '../models/SimpleBooleanRequest';
import { SimpleGroup } from '../models/SimpleGroup';
import { SimpleGroupHorse } from '../models/SimpleGroupHorse';
import { SimpleGroupHorseRequest } from '../models/SimpleGroupHorseRequest';
import { SimpleGroupUser } from '../models/SimpleGroupUser';
import { SimpleGroupUserRequest } from '../models/SimpleGroupUserRequest';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleHorseActivities } from '../models/SimpleHorseActivities';
import { SimpleHorseActivity } from '../models/SimpleHorseActivity';
import { SimpleHorseRequest } from '../models/SimpleHorseRequest';
import { SimpleMessage } from '../models/SimpleMessage';
import { SimpleMonthlyActivitySummary } from '../models/SimpleMonthlyActivitySummary';
import { SimpleNote } from '../models/SimpleNote';
import { SimpleRequest } from '../models/SimpleRequest';
import { SimpleTrackingPoint } from '../models/SimpleTrackingPoint';
import { SimpleUser } from '../models/SimpleUser';
import { SimpleUserActivity } from '../models/SimpleUserActivity';
import { SimpleUserInfo } from '../models/SimpleUserInfo';
import { SimpleUserInfoRequest } from '../models/SimpleUserInfoRequest';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { SocialAccount } from '../models/SocialAccount';
import { SocialConnect } from '../models/SocialConnect';
import { SocialConnectRequest } from '../models/SocialConnectRequest';
import { SocialLoginRequest } from '../models/SocialLoginRequest';
import { SportFengurEvent } from '../models/SportFengurEvent';
import { SportFengurEventFollowRequest } from '../models/SportFengurEventFollowRequest';
import { SportFengurEventRequest } from '../models/SportFengurEventRequest';
import { StallionGroup } from '../models/StallionGroup';
import { StallionGroupRequest } from '../models/StallionGroupRequest';
import { StartEndRequest } from '../models/StartEndRequest';
import { StripeCheckoutSession } from '../models/StripeCheckoutSession';
import { StripeCheckoutSessionCreateRequest } from '../models/StripeCheckoutSessionCreateRequest';
import { StripeCoupon } from '../models/StripeCoupon';
import { StripePrice } from '../models/StripePrice';
import { StripePricesAndProducts } from '../models/StripePricesAndProducts';
import { StripeProduct } from '../models/StripeProduct';
import { StripeProductMetadata } from '../models/StripeProductMetadata';
import { StripeRecurring } from '../models/StripeRecurring';
import { Subscription } from '../models/Subscription';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { Suggested } from '../models/Suggested';
import { Summary } from '../models/Summary';
import { TeamInfo } from '../models/TeamInfo';
import { Token } from '../models/Token';
import { TokenRequest } from '../models/TokenRequest';
import { TrackingPointCorrectionRequest } from '../models/TrackingPointCorrectionRequest';
import { TrainingSession } from '../models/TrainingSession';
import { TrainingSessionCreate } from '../models/TrainingSessionCreate';
import { TrainingSessionCreateRequest } from '../models/TrainingSessionCreateRequest';
import { TrainingSessionFeedbackRequest } from '../models/TrainingSessionFeedbackRequest';
import { TrainingSessionRequest } from '../models/TrainingSessionRequest';
import { TrainingSessionTrackingPoint } from '../models/TrainingSessionTrackingPoint';
import { TrainingSessionTrackingPointRequest } from '../models/TrainingSessionTrackingPointRequest';
import { TrainingSummary } from '../models/TrainingSummary';
import { TrainingSummaryRequest } from '../models/TrainingSummaryRequest';
import { TranscodedFile } from '../models/TranscodedFile';
import { TranscodedFileRequest } from '../models/TranscodedFileRequest';
import { TranscodedFiles } from '../models/TranscodedFiles';
import { TranscodedFilesRequest } from '../models/TranscodedFilesRequest';
import { TranscoderCallbackRequestRequest } from '../models/TranscoderCallbackRequestRequest';
import { TranscoderData } from '../models/TranscoderData';
import { TranscoderDataRequest } from '../models/TranscoderDataRequest';
import { TypesEnumEnum } from '../models/TypesEnumEnum';
import { UpdateGroupDiscussionRequest } from '../models/UpdateGroupDiscussionRequest';
import { UpdateGroupHorseRequest } from '../models/UpdateGroupHorseRequest';
import { UpdateGroupMareRequest } from '../models/UpdateGroupMareRequest';
import { UpdateGroupRequest } from '../models/UpdateGroupRequest';
import { UpdateStallionGroupRequest } from '../models/UpdateStallionGroupRequest';
import { User } from '../models/User';
import { UserExtradata } from '../models/UserExtradata';
import { UserExtradataRequest } from '../models/UserExtradataRequest';
import { UserFollowRequest } from '../models/UserFollowRequest';
import { UserFollowRequestResponseRequest } from '../models/UserFollowRequestResponseRequest';
import { UserLocale } from '../models/UserLocale';
import { UserLocaleRequest } from '../models/UserLocaleRequest';
import { UserMonthlyTrainingSummary } from '../models/UserMonthlyTrainingSummary';
import { UserQuestionareRequest } from '../models/UserQuestionareRequest';
import { UserReaction } from '../models/UserReaction';
import { UserRequest } from '../models/UserRequest';
import { UserSettings } from '../models/UserSettings';
import { UserSettingsRequest } from '../models/UserSettingsRequest';
import { UserUidRequest } from '../models/UserUidRequest';
import { UserWeeklyTrainingSummary } from '../models/UserWeeklyTrainingSummary';
import { VerifyEmailRequest } from '../models/VerifyEmailRequest';
import { VerySimpleHorseActivity } from '../models/VerySimpleHorseActivity';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { WFLoginRequest } from '../models/WFLoginRequest';
import { WFOffspring } from '../models/WFOffspring';
import { WFWebookHorseRemoveRequest } from '../models/WFWebookHorseRemoveRequest';
import { WFWebookHorseUpdateRequest } from '../models/WFWebookHorseUpdateRequest';
import { WFWebookNewJudgementRequest } from '../models/WFWebookNewJudgementRequest';
import { WFWebookOffspringUpdateRequest } from '../models/WFWebookOffspringUpdateRequest';
import { Weather } from '../models/Weather';
import { WeatherRequest } from '../models/WeatherRequest';
import { WebhookDomarRequest } from '../models/WebhookDomarRequest';
import { WebhookEventParticipantRequest } from '../models/WebhookEventParticipantRequest';
import { WebhookEventProgramRequest } from '../models/WebhookEventProgramRequest';
import { WebhookEventRequest } from '../models/WebhookEventRequest';
import { WebhookRaslistiRequest } from '../models/WebhookRaslistiRequest';
import { WeeklyTrainingSummary } from '../models/WeeklyTrainingSummary';
import { WorldCupHorse } from '../models/WorldCupHorse';
import { WrappedSerialzer } from '../models/WrappedSerialzer';
import { ObservableAccountsApi } from './ObservableAPI';

import { AccountsApiRequestFactory, AccountsApiResponseProcessor} from "../apis/AccountsApi";
export class PromiseAccountsApi {
    private api: ObservableAccountsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AccountsApiRequestFactory,
        responseProcessor?: AccountsApiResponseProcessor
    ) {
        this.api = new ObservableAccountsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = 'localhost:8000' -------------
     * @param socialLoginRequest 
     */
    public accountsAppleCreate(socialLoginRequest?: SocialLoginRequest, _options?: Configuration): Promise<Token> {
        const result = this.api.accountsAppleCreate(socialLoginRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userUidRequest 
     */
    public accountsBlockUnblockCreate(userUidRequest: UserUidRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.accountsBlockUnblockCreate(userUidRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userExtradataRequest 
     */
    public accountsExtradataCreate(userExtradataRequest?: UserExtradataRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.accountsExtradataCreate(userExtradataRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public accountsGetAllBlockedList(_options?: Configuration): Promise<Array<SimpleUser>> {
        const result = this.api.accountsGetAllBlockedList(_options);
        return result.toPromise();
    }

    /**
     * @param sportfengurIds 
     */
    public accountsGetUsersBySportfengurIdList(sportfengurIds?: string, _options?: Configuration): Promise<Array<SimpleUser>> {
        const result = this.api.accountsGetUsersBySportfengurIdList(sportfengurIds, _options);
        return result.toPromise();
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = 'localhost:8000' -------------
     * @param socialLoginRequest 
     */
    public accountsGoogleCreate(socialLoginRequest?: SocialLoginRequest, _options?: Configuration): Promise<Token> {
        const result = this.api.accountsGoogleCreate(socialLoginRequest, _options);
        return result.toPromise();
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
     * @param loginRequest 
     */
    public accountsLoginCreate(loginRequest: LoginRequest, _options?: Configuration): Promise<Token> {
        const result = this.api.accountsLoginCreate(loginRequest, _options);
        return result.toPromise();
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    public accountsLogoutCreate(_options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsLogoutCreate(_options);
        return result.toPromise();
    }

    /**
     * @param userExtradataRequest 
     */
    public accountsMetadataCreate(userExtradataRequest?: UserExtradataRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.accountsMetadataCreate(userExtradataRequest, _options);
        return result.toPromise();
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param notificationTypeRequest 
     */
    public accountsNotificationsCreate(notificationTypeRequest: NotificationTypeRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsNotificationsCreate(notificationTypeRequest, _options);
        return result.toPromise();
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param patchedNotificationTypeRequest 
     */
    public accountsNotificationsPartialUpdate(patchedNotificationTypeRequest?: PatchedNotificationTypeRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsNotificationsPartialUpdate(patchedNotificationTypeRequest, _options);
        return result.toPromise();
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param notificationTypeRequest 
     */
    public accountsNotificationsUpdate(notificationTypeRequest: NotificationTypeRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsNotificationsUpdate(notificationTypeRequest, _options);
        return result.toPromise();
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param passwordChangeRequest 
     */
    public accountsPasswordChangeCreate(passwordChangeRequest: PasswordChangeRequest, _options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsPasswordChangeCreate(passwordChangeRequest, _options);
        return result.toPromise();
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param passwordResetConfirmRequest 
     */
    public accountsPasswordResetConfirmCreate(passwordResetConfirmRequest: PasswordResetConfirmRequest, _options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsPasswordResetConfirmCreate(passwordResetConfirmRequest, _options);
        return result.toPromise();
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param passwordResetRequest 
     */
    public accountsPasswordResetCreate(passwordResetRequest: PasswordResetRequest, _options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsPasswordResetCreate(passwordResetRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userQuestionareRequest 
     */
    public accountsQuestionareCreate(userQuestionareRequest?: UserQuestionareRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.accountsQuestionareCreate(userQuestionareRequest, _options);
        return result.toPromise();
    }

    /**
     * @param registerRequest 
     */
    public accountsRegistrationCreate(registerRequest: RegisterRequest, _options?: Configuration): Promise<Token> {
        const result = this.api.accountsRegistrationCreate(registerRequest, _options);
        return result.toPromise();
    }

    /**
     * @param resendEmailVerificationRequest 
     */
    public accountsRegistrationResendEmailCreate(resendEmailVerificationRequest: ResendEmailVerificationRequest, _options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsRegistrationResendEmailCreate(resendEmailVerificationRequest, _options);
        return result.toPromise();
    }

    /**
     * @param verifyEmailRequest 
     */
    public accountsRegistrationVerifyEmailCreate(verifyEmailRequest: VerifyEmailRequest, _options?: Configuration): Promise<RestAuthDetail> {
        const result = this.api.accountsRegistrationVerifyEmailCreate(verifyEmailRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userSettingsRequest 
     */
    public accountsSettingsCreate(userSettingsRequest?: UserSettingsRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsSettingsCreate(userSettingsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param patchedUserSettingsRequest 
     */
    public accountsSettingsPartialUpdate(patchedUserSettingsRequest?: PatchedUserSettingsRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsSettingsPartialUpdate(patchedUserSettingsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userSettingsRequest 
     */
    public accountsSettingsUpdate(userSettingsRequest?: UserSettingsRequest, _options?: Configuration): Promise<UserSettings> {
        const result = this.api.accountsSettingsUpdate(userSettingsRequest, _options);
        return result.toPromise();
    }

    /**
     * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
     * @param id 
     * @param socialConnectRequest 
     */
    public accountsSocialaccountsConnectCreate(id: string, socialConnectRequest?: SocialConnectRequest, _options?: Configuration): Promise<SocialConnect> {
        const result = this.api.accountsSocialaccountsConnectCreate(id, socialConnectRequest, _options);
        return result.toPromise();
    }

    /**
     * Disconnect SocialAccount from remote service for the currently logged in user
     * @param id 
     * @param socialConnectRequest 
     */
    public accountsSocialaccountsDisconnectCreate(id: string, socialConnectRequest?: SocialConnectRequest, _options?: Configuration): Promise<SocialConnect> {
        const result = this.api.accountsSocialaccountsDisconnectCreate(id, socialConnectRequest, _options);
        return result.toPromise();
    }

    /**
     * List SocialAccounts for the currently logged in user
     */
    public accountsSocialaccountsList(_options?: Configuration): Promise<Array<SocialAccount>> {
        const result = this.api.accountsSocialaccountsList(_options);
        return result.toPromise();
    }

    /**
     */
    public accountsSubscriptionRestorePurchasesCreate(_options?: Configuration): Promise<Subscription> {
        const result = this.api.accountsSubscriptionRestorePurchasesCreate(_options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersDistanceList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserMonthlyTrainingSummary>> {
        const result = this.api.accountsTopRidersDistanceList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersDistanceWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserWeeklyTrainingSummary>> {
        const result = this.api.accountsTopRidersDistanceWeekList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersSessionsList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserMonthlyTrainingSummary>> {
        const result = this.api.accountsTopRidersSessionsList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersSessionsWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserWeeklyTrainingSummary>> {
        const result = this.api.accountsTopRidersSessionsWeekList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersTimeList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserMonthlyTrainingSummary>> {
        const result = this.api.accountsTopRidersTimeList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersTimeWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Promise<Array<UserWeeklyTrainingSummary>> {
        const result = this.api.accountsTopRidersTimeWeekList(cutoff, last, social, _options);
        return result.toPromise();
    }

    /**
     */
    public accountsUpdateEntitlementCreate(_options?: Configuration): Promise<User> {
        const result = this.api.accountsUpdateEntitlementCreate(_options);
        return result.toPromise();
    }

    /**
     */
    public accountsUserDeleteDestroy(_options?: Configuration): Promise<void> {
        const result = this.api.accountsUserDeleteDestroy(_options);
        return result.toPromise();
    }

    /**
     * GET or POST the locale of the logged in user --- response: - locale: 'is', 'en' or 'de'
     */
    public accountsUserGetLocaleRetrieve(_options?: Configuration): Promise<UserLocale> {
        const result = this.api.accountsUserGetLocaleRetrieve(_options);
        return result.toPromise();
    }

    /**
     * Get monthly summaries for user based on - months: list of weeks (default [this month]) - year: (default this year)
     * @param month 
     * @param year 
     */
    public accountsUserMonthlyActivitySummaryList(month?: number, year?: number, _options?: Configuration): Promise<Array<SimpleUserActivity>> {
        const result = this.api.accountsUserMonthlyActivitySummaryList(month, year, _options);
        return result.toPromise();
    }

    /**
     * @param goalRequest 
     */
    public accountsUserMonthlyGoalCreate(goalRequest?: GoalRequest, _options?: Configuration): Promise<UserMonthlyTrainingSummary> {
        const result = this.api.accountsUserMonthlyGoalCreate(goalRequest, _options);
        return result.toPromise();
    }

    /**
     * Get monthly summaries for user based on - months: list of months (default [this month]) - year: (default this year) - user_id
     * @param months 
     * @param userUid 
     * @param year 
     */
    public accountsUserMonthsTrainingSummaryList(months?: string, userUid?: string, year?: number, _options?: Configuration): Promise<Array<UserMonthlyTrainingSummary>> {
        const result = this.api.accountsUserMonthsTrainingSummaryList(months, userUid, year, _options);
        return result.toPromise();
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param patchedUserRequest 
     */
    public accountsUserPartialUpdate(patchedUserRequest?: PatchedUserRequest, _options?: Configuration): Promise<User> {
        const result = this.api.accountsUserPartialUpdate(patchedUserRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public accountsUserProfileRetrieve(uid: string, _options?: Configuration): Promise<PublicUser> {
        const result = this.api.accountsUserProfileRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     */
    public accountsUserRetrieve(_options?: Configuration): Promise<User> {
        const result = this.api.accountsUserRetrieve(_options);
        return result.toPromise();
    }

    /**
     * Searching for users to follow, add as trainers, etc.. --- parameters: - name: query -  description: to query for users in the database -  required: true -  type: string -  paramType: form
     * @param query 
     */
    public accountsUserSearchList(query: string, _options?: Configuration): Promise<Array<PublicUser>> {
        const result = this.api.accountsUserSearchList(query, _options);
        return result.toPromise();
    }

    /**
     * Searching for users to follow, add as trainers, etc..  --- parameters: - name: query -  description: to query for users in the database -  required: true -  type: string -  paramType: form
     * @param query 
     * @param next 
     * @param pageSize 
     */
    public accountsUserSearchPaginatedRetrieve(query: string, next?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedUsers> {
        const result = this.api.accountsUserSearchPaginatedRetrieve(query, next, pageSize, _options);
        return result.toPromise();
    }

    /**
     * Change the locale of a user --- parameters: - locale: 'is', 'en' or 'de' response: - locale set
     * @param userLocaleRequest 
     */
    public accountsUserSetLocaleCreate(userLocaleRequest: UserLocaleRequest, _options?: Configuration): Promise<User> {
        const result = this.api.accountsUserSetLocaleCreate(userLocaleRequest, _options);
        return result.toPromise();
    }

    /**
     * @param isMonthly 
     * @param month 
     * @param uid 
     * @param week 
     * @param year 
     */
    public accountsUserTrainingSummaryRetrieve(isMonthly?: boolean, month?: number, uid?: string, week?: number, year?: number, _options?: Configuration): Promise<Summary> {
        const result = this.api.accountsUserTrainingSummaryRetrieve(isMonthly, month, uid, week, year, _options);
        return result.toPromise();
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param userRequest 
     */
    public accountsUserUpdate(userRequest?: UserRequest, _options?: Configuration): Promise<User> {
        const result = this.api.accountsUserUpdate(userRequest, _options);
        return result.toPromise();
    }

    /**
     * @param goalRequest 
     */
    public accountsUserWeeklyGoalCreate(goalRequest?: GoalRequest, _options?: Configuration): Promise<UserWeeklyTrainingSummary> {
        const result = this.api.accountsUserWeeklyGoalCreate(goalRequest, _options);
        return result.toPromise();
    }

    /**
     * Get weekly summaries for user based on - weeks: list of weeks (default [this week]) - year: (default this year) - user_id
     * @param userUid 
     * @param weeks 
     * @param year 
     */
    public accountsUserWeeksTrainingSummaryList(userUid?: string, weeks?: string, year?: number, _options?: Configuration): Promise<Array<UserWeeklyTrainingSummary>> {
        const result = this.api.accountsUserWeeksTrainingSummaryList(userUid, weeks, year, _options);
        return result.toPromise();
    }


}



import { ObservableActivitiesApi } from './ObservableAPI';

import { ActivitiesApiRequestFactory, ActivitiesApiResponseProcessor} from "../apis/ActivitiesApi";
export class PromiseActivitiesApi {
    private api: ObservableActivitiesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivitiesApiRequestFactory,
        responseProcessor?: ActivitiesApiResponseProcessor
    ) {
        this.api = new ObservableActivitiesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public activitiesActivitySummaryMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Promise<Array<HorseActivities>> {
        const result = this.api.activitiesActivitySummaryMultiHorseActivitiesPeriodList(faedingarnumer, _from, to, _options);
        return result.toPromise();
    }

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Promise<Array<SimpleHorseActivities>> {
        const result = this.api.activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(faedingarnumer, _from, to, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCommentsList(id: number, _options?: Configuration): Promise<Array<Comment>> {
        const result = this.api.activitiesCommentsList(id, _options);
        return result.toPromise();
    }

    /**
     * @param horseActivityCreateRequest 
     */
    public activitiesCreate(horseActivityCreateRequest: HorseActivityCreateRequest, _options?: Configuration): Promise<HorseActivityCreate> {
        const result = this.api.activitiesCreate(horseActivityCreateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCreateMapTaskCreate(id: number, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.activitiesCreateMapTaskCreate(id, _options);
        return result.toPromise();
    }

    /**
     * waits for map to be generated.
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCreateMapWithoutTaskCreate(id: number, _options?: Configuration): Promise<HorseActivity> {
        const result = this.api.activitiesCreateMapWithoutTaskCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.activitiesDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * Cloud task, should not be called directly
     * @param id A unique integer value identifying this horse activity.
     * @param isPremium if to generate premium map
     */
    public activitiesGenerateMapWithTaskCreate(id: number, isPremium: boolean, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.activitiesGenerateMapWithTaskCreate(id, isPremium, _options);
        return result.toPromise();
    }

    /**
     * get all activities on day
     * @param date date of activties
     * @param horse If horse is null, then will get all activities for user
     * @param useDrafts include activities marked as drafts
     */
    public activitiesGetActivitiesOnDayList(date: string, horse?: string, useDrafts?: boolean, _options?: Configuration): Promise<Array<HorseActivity>> {
        const result = this.api.activitiesGetActivitiesOnDayList(date, horse, useDrafts, _options);
        return result.toPromise();
    }

    /**
     */
    public activitiesGetAllPlannedActivitiesList(_options?: Configuration): Promise<Array<HorseActivity>> {
        const result = this.api.activitiesGetAllPlannedActivitiesList(_options);
        return result.toPromise();
    }

    /**
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param excludeDrafts 
     * @param excludeFuture 
     * @param horse 
     * @param pageSize Number of results to return per page.
     */
    public activitiesGetLatestTracksList(activityId?: number, actor?: number, cursor?: string, excludeDrafts?: boolean, excludeFuture?: boolean, horse?: string, pageSize?: number, _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.activitiesGetLatestTracksList(activityId, actor, cursor, excludeDrafts, excludeFuture, horse, pageSize, _options);
        return result.toPromise();
    }

    /**
     */
    public activitiesGetPastPlannedActivitiesRetrieve(_options?: Configuration): Promise<PaginatedActivity> {
        const result = this.api.activitiesGetPastPlannedActivitiesRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param date date of activties
     */
    public activitiesGetPlannedActivitiesTodayList(date?: string, _options?: Configuration): Promise<Array<HorseActivity>> {
        const result = this.api.activitiesGetPlannedActivitiesTodayList(date, _options);
        return result.toPromise();
    }

    /**
     * @param activities 
     * @param actor 
     * @param creator 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param excludePlanned Set to true to exclude planned activities
     * @param hasFinishedPlanned 
     * @param horse 
     * @param ids Multiple values may be separated by commas.
     * @param isDraft 
     * @param isPlanned 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public activitiesList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, actor?: number, creator?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, excludePlanned?: boolean, hasFinishedPlanned?: boolean, horse?: string, ids?: Array<number>, isDraft?: boolean, isPlanned?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.activitiesList(activities, actor, creator, cursor, dateAfter, dateBefore, dateRangeAfter, dateRangeBefore, excludePlanned, hasFinishedPlanned, horse, ids, isDraft, isPlanned, order, pageSize, type, visibility, _options);
        return result.toPromise();
    }

    /**
     * Get weekly summary for all horses the user has access to - month: (default this month) - year: (default this year)
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public activitiesMonthlyTrainingSummaryAllHorsesList(isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Promise<Array<MonthlyTrainingSummary>> {
        const result = this.api.activitiesMonthlyTrainingSummaryAllHorsesList(isTaggedSummary, month, year, _options);
        return result.toPromise();
    }

    /**
     * Get monthly summary for horse based on - month: (default this month) - year: (default this year) - tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public activitiesMonthlyTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Promise<MonthlyTrainingSummary> {
        const result = this.api.activitiesMonthlyTrainingSummarySingleHorseRetrieve(faedingarnumer, isTaggedSummary, month, year, _options);
        return result.toPromise();
    }

    /**
     * @param horseActivityMultiCreateRequest 
     */
    public activitiesMultiCreateCreate(horseActivityMultiCreateRequest: HorseActivityMultiCreateRequest, _options?: Configuration): Promise<Array<HorseActivity>> {
        const result = this.api.activitiesMultiCreateCreate(horseActivityMultiCreateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param patchedHorseActivityUpdateRequest 
     */
    public activitiesPartialUpdate(id: number, patchedHorseActivityUpdateRequest?: PatchedHorseActivityUpdateRequest, _options?: Configuration): Promise<HorseActivityUpdate> {
        const result = this.api.activitiesPartialUpdate(id, patchedHorseActivityUpdateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesReactionsList(id: number, _options?: Configuration): Promise<Array<UserReaction>> {
        const result = this.api.activitiesReactionsList(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesRetrieve(id: number, _options?: Configuration): Promise<HorseActivity> {
        const result = this.api.activitiesRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * Get monthly summary for all horses user has access to based on - month: (default this month) - year: (default this year)
     * @param month 
     * @param year 
     */
    public activitiesSummaryAllHorsesList(month?: number, year?: number, _options?: Configuration): Promise<Array<SimpleMonthlyActivitySummary>> {
        const result = this.api.activitiesSummaryAllHorsesList(month, year, _options);
        return result.toPromise();
    }

    /**
     * Get monthly activity summary for horse based on - month: (default this month) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param month 
     * @param year 
     */
    public activitiesSummarySingleHorseRetrieve(faedingarnumer?: string, month?: number, year?: number, _options?: Configuration): Promise<SimpleMonthlyActivitySummary> {
        const result = this.api.activitiesSummarySingleHorseRetrieve(faedingarnumer, month, year, _options);
        return result.toPromise();
    }

    /**
     * Get weekly summary for all horses the user has access to - week: (default this week) - year: (default this year)
     * @param week 
     * @param year 
     */
    public activitiesTrainingSummaryAllHorsesList(week?: number, year?: number, _options?: Configuration): Promise<Array<WeeklyTrainingSummary>> {
        const result = this.api.activitiesTrainingSummaryAllHorsesList(week, year, _options);
        return result.toPromise();
    }

    /**
     * Get weekly summary for horse based on - week: (default this week) - year: (default this year) - is_tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param week 
     * @param year 
     */
    public activitiesTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, week?: number, year?: number, _options?: Configuration): Promise<WeeklyTrainingSummary> {
        const result = this.api.activitiesTrainingSummarySingleHorseRetrieve(faedingarnumer, isTaggedSummary, week, year, _options);
        return result.toPromise();
    }

    /**
     * Get weekly summary for horse based on - weeks: list of weeks (default [this week]) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param weeks 
     * @param year 
     */
    public activitiesTrainingSummarySingleHorseWeeksList(faedingarnumer: string, weeks?: string, year?: number, _options?: Configuration): Promise<Array<WeeklyTrainingSummary>> {
        const result = this.api.activitiesTrainingSummarySingleHorseWeeksList(faedingarnumer, weeks, year, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param horseActivityUpdateRequest 
     */
    public activitiesUpdate(id: number, horseActivityUpdateRequest?: HorseActivityUpdateRequest, _options?: Configuration): Promise<HorseActivityUpdate> {
        const result = this.api.activitiesUpdate(id, horseActivityUpdateRequest, _options);
        return result.toPromise();
    }


}



import { ObservableActivityMediaApi } from './ObservableAPI';

import { ActivityMediaApiRequestFactory, ActivityMediaApiResponseProcessor} from "../apis/ActivityMediaApi";
export class PromiseActivityMediaApi {
    private api: ObservableActivityMediaApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityMediaApiRequestFactory,
        responseProcessor?: ActivityMediaApiResponseProcessor
    ) {
        this.api = new ObservableActivityMediaApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param group 
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public activityMediaList(actor?: number, cursor?: string, group?: string, horse?: string, order?: Array<'-_' | '-d' | '-e' | '-f' | '-m' | '-o' | '-p' | '-r' | '-t' | '_' | 'd' | 'e' | 'f' | 'm' | 'o' | 'p' | 'r' | 't'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Promise<PaginatedActivityMediaList> {
        const result = this.api.activityMediaList(actor, cursor, group, horse, order, pageSize, type, _options);
        return result.toPromise();
    }

    /**
     * @param actor 
     * @param horse 
     */
    public activityMediaNumerOfMediaRetrieve(actor?: number, horse?: string, _options?: Configuration): Promise<Number> {
        const result = this.api.activityMediaNumerOfMediaRetrieve(actor, horse, _options);
        return result.toPromise();
    }


}



import { ObservableActivityPhotosApi } from './ObservableAPI';

import { ActivityPhotosApiRequestFactory, ActivityPhotosApiResponseProcessor} from "../apis/ActivityPhotosApi";
export class PromiseActivityPhotosApi {
    private api: ObservableActivityPhotosApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityPhotosApiRequestFactory,
        responseProcessor?: ActivityPhotosApiResponseProcessor
    ) {
        this.api = new ObservableActivityPhotosApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosCreate(activity: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Promise<HorseActivityPhoto> {
        const result = this.api.activityPhotosCreate(activity, name, image, isMap, isPremiumMap, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     */
    public activityPhotosDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.activityPhotosDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param isMap 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public activityPhotosList(activity?: number, isMap?: boolean, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedHorseActivityPhotoList> {
        const result = this.api.activityPhotosList(activity, isMap, order, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosPartialUpdate(id: number, activity?: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Promise<HorseActivityPhoto> {
        const result = this.api.activityPhotosPartialUpdate(id, activity, name, image, isMap, isPremiumMap, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     */
    public activityPhotosRetrieve(id: number, _options?: Configuration): Promise<HorseActivityPhoto> {
        const result = this.api.activityPhotosRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param b 
     */
    public activityPhotosToggleShowOnProfileCreate(id: number, b: boolean, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.activityPhotosToggleShowOnProfileCreate(id, b, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosUpdate(id: number, activity: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Promise<HorseActivityPhoto> {
        const result = this.api.activityPhotosUpdate(id, activity, name, image, isMap, isPremiumMap, _options);
        return result.toPromise();
    }


}



import { ObservableActivityVideosApi } from './ObservableAPI';

import { ActivityVideosApiRequestFactory, ActivityVideosApiResponseProcessor} from "../apis/ActivityVideosApi";
export class PromiseActivityVideosApi {
    private api: ObservableActivityVideosApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityVideosApiRequestFactory,
        responseProcessor?: ActivityVideosApiResponseProcessor
    ) {
        this.api = new ObservableActivityVideosApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosCreate(activity: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosCreate(activity, name, video, transcoded, thumbnail, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.activityVideosDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosFinishUploadCreate(id: number, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosFinishUploadCreate(id, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public activityVideosList(activity?: number, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedHorseActivityVideoList> {
        const result = this.api.activityVideosList(activity, order, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosPartialUpdate(id: number, activity?: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosPartialUpdate(id, activity, name, video, transcoded, thumbnail, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosRetrieve(id: number, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param thumbnail 
     */
    public activityVideosThumbnailCreate(id: number, thumbnail?: HttpFile, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosThumbnailCreate(id, thumbnail, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param b 
     */
    public activityVideosToggleShowOnProfileCreate(id: number, b: boolean, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.activityVideosToggleShowOnProfileCreate(id, b, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosUpdate(id: number, activity: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Promise<HorseActivityVideo> {
        const result = this.api.activityVideosUpdate(id, activity, name, video, transcoded, thumbnail, _options);
        return result.toPromise();
    }


}



import { ObservableCallbacksApi } from './ObservableAPI';

import { CallbacksApiRequestFactory, CallbacksApiResponseProcessor} from "../apis/CallbacksApi";
export class PromiseCallbacksApi {
    private api: ObservableCallbacksApi

    public constructor(
        configuration: Configuration,
        requestFactory?: CallbacksApiRequestFactory,
        responseProcessor?: CallbacksApiResponseProcessor
    ) {
        this.api = new ObservableCallbacksApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param transcoderCallbackRequestRequest 
     */
    public callbacksTranscoderCreate(transcoderCallbackRequestRequest: TranscoderCallbackRequestRequest, _options?: Configuration): Promise<void> {
        const result = this.api.callbacksTranscoderCreate(transcoderCallbackRequestRequest, _options);
        return result.toPromise();
    }


}



import { ObservableChatApi } from './ObservableAPI';

import { ChatApiRequestFactory, ChatApiResponseProcessor} from "../apis/ChatApi";
export class PromiseChatApi {
    private api: ObservableChatApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ChatApiRequestFactory,
        responseProcessor?: ChatApiResponseProcessor
    ) {
        this.api = new ObservableChatApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param followRequest 
     */
    public chatAddToRoomCreate(id: number, followRequest: FollowRequest, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatAddToRoomCreate(id, followRequest, _options);
        return result.toPromise();
    }

    /**
     * @param chatRoomRequest 
     */
    public chatCreate(chatRoomRequest: ChatRoomRequest, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatCreate(chatRoomRequest, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public chatList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_latest_message' | 'dt_latest_message'>, pageSize?: number, _options?: Configuration): Promise<PaginatedChatRoomList> {
        const result = this.api.chatList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, _options);
        return result.toPromise();
    }

    /**
     */
    public chatNumberUnseenRetrieve(_options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.chatNumberUnseenRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this chat room.
     */
    public chatRetrieve(id: number, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * Get room info from list of user_uid if no room exists then create it
     * @param users 
     * @param faedingarnumer 
     * @param isSaleChat 
     * @param name 
     */
    public chatRoomInfoFromUsersRetrieve(users: string, faedingarnumer?: string, isSaleChat?: boolean, name?: string, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatRoomInfoFromUsersRetrieve(users, faedingarnumer, isSaleChat, name, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer 
     */
    public chatSaleChatsList(faedingarnumer?: string, _options?: Configuration): Promise<Array<ChatRoom>> {
        const result = this.api.chatSaleChatsList(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param inRoomRequest 
     */
    public chatSetInRoomCreate(id: number, inRoomRequest: InRoomRequest, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatSetInRoomCreate(id, inRoomRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param simpleRequest 
     */
    public chatUpdateNameCreate(id: number, simpleRequest?: SimpleRequest, _options?: Configuration): Promise<ChatRoom> {
        const result = this.api.chatUpdateNameCreate(id, simpleRequest, _options);
        return result.toPromise();
    }


}



import { ObservableChatMessagesApi } from './ObservableAPI';

import { ChatMessagesApiRequestFactory, ChatMessagesApiResponseProcessor} from "../apis/ChatMessagesApi";
export class PromiseChatMessagesApi {
    private api: ObservableChatMessagesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: ChatMessagesApiRequestFactory,
        responseProcessor?: ChatMessagesApiResponseProcessor
    ) {
        this.api = new ObservableChatMessagesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param chatMessageRequest 
     */
    public chatMessagesCreate(chatMessageRequest: ChatMessageRequest, _options?: Configuration): Promise<ChatMessage> {
        const result = this.api.chatMessagesCreate(chatMessageRequest, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param room 
     */
    public chatMessagesList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_created' | 'dt_created'>, pageSize?: number, room?: number, _options?: Configuration): Promise<PaginatedChatMessageList> {
        const result = this.api.chatMessagesList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, room, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this chat message.
     */
    public chatMessagesRetrieve(id: number, _options?: Configuration): Promise<ChatMessage> {
        const result = this.api.chatMessagesRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this chat message.
     * @param chatMessageRequest 
     */
    public chatMessagesSendMessageCreate(id: number, chatMessageRequest: ChatMessageRequest, _options?: Configuration): Promise<any> {
        const result = this.api.chatMessagesSendMessageCreate(id, chatMessageRequest, _options);
        return result.toPromise();
    }


}



import { ObservableCommentsApi } from './ObservableAPI';

import { CommentsApiRequestFactory, CommentsApiResponseProcessor} from "../apis/CommentsApi";
export class PromiseCommentsApi {
    private api: ObservableCommentsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: CommentsApiRequestFactory,
        responseProcessor?: CommentsApiResponseProcessor
    ) {
        this.api = new ObservableCommentsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param commentRequest 
     */
    public commentsCreate(commentRequest: CommentRequest, _options?: Configuration): Promise<Comment> {
        const result = this.api.commentsCreate(commentRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this comment.
     */
    public commentsDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.commentsDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param activity 
     * @param cursor The pagination cursor value.
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param thread 
     * @param user 
     */
    public commentsList(activity?: number, cursor?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, pageSize?: number, thread?: number, user?: number, _options?: Configuration): Promise<PaginatedCommentList> {
        const result = this.api.commentsList(activity, cursor, order, pageSize, thread, user, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this comment.
     * @param patchedCommentRequest 
     */
    public commentsPartialUpdate(id: number, patchedCommentRequest?: PatchedCommentRequest, _options?: Configuration): Promise<Comment> {
        const result = this.api.commentsPartialUpdate(id, patchedCommentRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this comment.
     */
    public commentsRetrieve(id: number, _options?: Configuration): Promise<Comment> {
        const result = this.api.commentsRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this comment.
     * @param commentRequest 
     */
    public commentsUpdate(id: number, commentRequest: CommentRequest, _options?: Configuration): Promise<Comment> {
        const result = this.api.commentsUpdate(id, commentRequest, _options);
        return result.toPromise();
    }


}



import { ObservableFeedApi } from './ObservableAPI';

import { FeedApiRequestFactory, FeedApiResponseProcessor} from "../apis/FeedApi";
export class PromiseFeedApi {
    private api: ObservableFeedApi

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedApiRequestFactory,
        responseProcessor?: FeedApiResponseProcessor
    ) {
        this.api = new ObservableFeedApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedGetSimpleTrackingPointsList(id: number, _options?: Configuration): Promise<Array<SimpleTrackingPoint>> {
        const result = this.api.feedGetSimpleTrackingPointsList(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedGetTrackingPointsList(id: number, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.feedGetTrackingPointsList(id, _options);
        return result.toPromise();
    }

    /**
     * @param activities 
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public feedList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, activityId?: number, actor?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, horse?: string, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.feedList(activities, activityId, actor, cursor, dateAfter, dateBefore, dateRangeAfter, dateRangeBefore, horse, order, pageSize, type, visibility, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedRetrieve(id: number, _options?: Configuration): Promise<HorseActivity> {
        const result = this.api.feedRetrieve(id, _options);
        return result.toPromise();
    }


}



import { ObservableFeedbackApi } from './ObservableAPI';

import { FeedbackApiRequestFactory, FeedbackApiResponseProcessor} from "../apis/FeedbackApi";
export class PromiseFeedbackApi {
    private api: ObservableFeedbackApi

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedbackApiRequestFactory,
        responseProcessor?: FeedbackApiResponseProcessor
    ) {
        this.api = new ObservableFeedbackApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param trainingSessionFeedbackRequest 
     */
    public feedbackCreate(trainingSessionFeedbackRequest: TrainingSessionFeedbackRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.feedbackCreate(trainingSessionFeedbackRequest, _options);
        return result.toPromise();
    }


}



import { ObservableFeedingsApi } from './ObservableAPI';

import { FeedingsApiRequestFactory, FeedingsApiResponseProcessor} from "../apis/FeedingsApi";
export class PromiseFeedingsApi {
    private api: ObservableFeedingsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedingsApiRequestFactory,
        responseProcessor?: FeedingsApiResponseProcessor
    ) {
        this.api = new ObservableFeedingsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param feedingRequest 
     */
    public feedingsCreate(feedingRequest: FeedingRequest, _options?: Configuration): Promise<Feeding> {
        const result = this.api.feedingsCreate(feedingRequest, _options);
        return result.toPromise();
    }

    /**
     * @param horse 
     */
    public feedingsCurrentFeedingForHorseRetrieve(horse: string, _options?: Configuration): Promise<Feeding> {
        const result = this.api.feedingsCurrentFeedingForHorseRetrieve(horse, _options);
        return result.toPromise();
    }

    /**
     * @param horses Comma separated list of horse IDs
     */
    public feedingsCurrentFeedingsForHorsesList(horses: string, _options?: Configuration): Promise<Array<Feeding>> {
        const result = this.api.feedingsCurrentFeedingsForHorsesList(horses, _options);
        return result.toPromise();
    }

    /**
     * @param horse 
     */
    public feedingsFeedingsForHorseList(horse: string, _options?: Configuration): Promise<Array<Feeding>> {
        const result = this.api.feedingsFeedingsForHorseList(horse, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this feeding.
     * @param patchedFeedingRequest 
     */
    public feedingsPartialUpdate(id: number, patchedFeedingRequest?: PatchedFeedingRequest, _options?: Configuration): Promise<Feeding> {
        const result = this.api.feedingsPartialUpdate(id, patchedFeedingRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this feeding.
     */
    public feedingsRetrieve(id: number, _options?: Configuration): Promise<Feeding> {
        const result = this.api.feedingsRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this feeding.
     * @param feedingRequest 
     */
    public feedingsUpdate(id: number, feedingRequest: FeedingRequest, _options?: Configuration): Promise<Feeding> {
        const result = this.api.feedingsUpdate(id, feedingRequest, _options);
        return result.toPromise();
    }


}



import { ObservableGroupsApi } from './ObservableAPI';

import { GroupsApiRequestFactory, GroupsApiResponseProcessor} from "../apis/GroupsApi";
export class PromiseGroupsApi {
    private api: ObservableGroupsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: GroupsApiRequestFactory,
        responseProcessor?: GroupsApiResponseProcessor
    ) {
        this.api = new ObservableGroupsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * get all group invites for horses that are pending
     * @param uid 
     */
    public groupsAccessHorseInvitesList(uid: string, _options?: Configuration): Promise<Array<GroupInvite>> {
        const result = this.api.groupsAccessHorseInvitesList(uid, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param createGroupUserInviteRequest 
     */
    public groupsAccessInviteCreate(uid: string, createGroupUserInviteRequest?: CreateGroupUserInviteRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAccessInviteCreate(uid, createGroupUserInviteRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param idRequest 
     */
    public groupsAccessInviteDeleteCreate(uid: string, idRequest: IdRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAccessInviteDeleteCreate(uid, idRequest, _options);
        return result.toPromise();
    }

    /**
     * @param acceptRequest 
     */
    public groupsAccessInviteRespondCreate(acceptRequest: AcceptRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAccessInviteRespondCreate(acceptRequest, _options);
        return result.toPromise();
    }

    /**
     * get all invites for this user
     */
    public groupsAccessInvitesList(_options?: Configuration): Promise<Array<GroupInvite>> {
        const result = this.api.groupsAccessInvitesList(_options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param idRequest 
     */
    public groupsAccessRemoveUsersCreate(uid: string, idRequest: Array<IdRequest>, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsAccessRemoveUsersCreate(uid, idRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public groupsAccessRequestCreate(uid: string, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAccessRequestCreate(uid, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param acceptRequest 
     */
    public groupsAccessRequestRespondCreate(uid: string, acceptRequest: AcceptRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAccessRequestRespondCreate(uid, acceptRequest, _options);
        return result.toPromise();
    }

    /**
     * get all group invites for users that are pending
     * @param uid 
     */
    public groupsAccessUserInvitesList(uid: string, _options?: Configuration): Promise<Array<GroupInvite>> {
        const result = this.api.groupsAccessUserInvitesList(uid, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param month 
     * @param year 
     */
    public groupsActivitySummaryList(uid: string, month?: number, year?: number, _options?: Configuration): Promise<Array<SimpleMonthlyActivitySummary>> {
        const result = this.api.groupsActivitySummaryList(uid, month, year, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsAddHorsesCreate(uid: string, faedingarnumerSerilizerRequest: Array<FaedingarnumerSerilizerRequest>, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.groupsAddHorsesCreate(uid, faedingarnumerSerilizerRequest, _options);
        return result.toPromise();
    }

    /**
     * @param createGroupRequest 
     */
    public groupsCreate(createGroupRequest: CreateGroupRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsCreate(createGroupRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public groupsDestroy(uid: string, _options?: Configuration): Promise<void> {
        const result = this.api.groupsDestroy(uid, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param groupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsCreate(discussionUid: string, groupUid: string, groupDiscussionCommentRequest: GroupDiscussionCommentRequest, _options?: Configuration): Promise<GroupDiscussionComment> {
        const result = this.api.groupsDiscussionsCommentsCreate(discussionUid, groupUid, groupDiscussionCommentRequest, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     */
    public groupsDiscussionsCommentsDestroy(discussionUid: string, groupUid: string, id: number, _options?: Configuration): Promise<void> {
        const result = this.api.groupsDiscussionsCommentsDestroy(discussionUid, groupUid, id, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param discussion 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public groupsDiscussionsCommentsList(discussionUid: string, groupUid: string, discussion?: string, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedGroupDiscussionCommentList> {
        const result = this.api.groupsDiscussionsCommentsList(discussionUid, groupUid, discussion, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param patchedGroupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsPartialUpdate(discussionUid: string, groupUid: string, id: number, patchedGroupDiscussionCommentRequest?: PatchedGroupDiscussionCommentRequest, _options?: Configuration): Promise<GroupDiscussionComment> {
        const result = this.api.groupsDiscussionsCommentsPartialUpdate(discussionUid, groupUid, id, patchedGroupDiscussionCommentRequest, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param groupDiscussionCommentReactionRequest 
     */
    public groupsDiscussionsCommentsReactionCreate(discussionUid: string, groupUid: string, id: number, groupDiscussionCommentReactionRequest: GroupDiscussionCommentReactionRequest, _options?: Configuration): Promise<GroupDiscussionCommentReaction> {
        const result = this.api.groupsDiscussionsCommentsReactionCreate(discussionUid, groupUid, id, groupDiscussionCommentReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param discussion 
     */
    public groupsDiscussionsCommentsReactionsList(discussionUid: string, groupUid: string, id: number, discussion?: string, _options?: Configuration): Promise<Array<GroupDiscussionCommentReaction>> {
        const result = this.api.groupsDiscussionsCommentsReactionsList(discussionUid, groupUid, id, discussion, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     */
    public groupsDiscussionsCommentsRetrieve(discussionUid: string, groupUid: string, id: number, _options?: Configuration): Promise<GroupDiscussionComment> {
        const result = this.api.groupsDiscussionsCommentsRetrieve(discussionUid, groupUid, id, _options);
        return result.toPromise();
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param groupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsUpdate(discussionUid: string, groupUid: string, id: number, groupDiscussionCommentRequest: GroupDiscussionCommentRequest, _options?: Configuration): Promise<GroupDiscussionComment> {
        const result = this.api.groupsDiscussionsCommentsUpdate(discussionUid, groupUid, id, groupDiscussionCommentRequest, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param createGroupDiscussionRequest 
     */
    public groupsDiscussionsCreate(groupUid: string, createGroupDiscussionRequest: CreateGroupDiscussionRequest, _options?: Configuration): Promise<GroupDiscussion> {
        const result = this.api.groupsDiscussionsCreate(groupUid, createGroupDiscussionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsDestroy(groupUid: string, uid: string, _options?: Configuration): Promise<void> {
        const result = this.api.groupsDiscussionsDestroy(groupUid, uid, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param group 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public groupsDiscussionsList(groupUid: string, group?: string, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedGroupDiscussionList> {
        const result = this.api.groupsDiscussionsList(groupUid, group, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param patchedGroupDiscussionRequest 
     */
    public groupsDiscussionsPartialUpdate(groupUid: string, uid: string, patchedGroupDiscussionRequest?: PatchedGroupDiscussionRequest, _options?: Configuration): Promise<GroupDiscussion> {
        const result = this.api.groupsDiscussionsPartialUpdate(groupUid, uid, patchedGroupDiscussionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     * @param groupDiscussionPhotoRequest 
     */
    public groupsDiscussionsPhotosCreate(groupUid: string, uid: string, group?: string, groupDiscussionPhotoRequest?: GroupDiscussionPhotoRequest, _options?: Configuration): Promise<Array<GroupDiscussionPhoto>> {
        const result = this.api.groupsDiscussionsPhotosCreate(groupUid, uid, group, groupDiscussionPhotoRequest, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     */
    public groupsDiscussionsPhotosList(groupUid: string, uid: string, group?: string, _options?: Configuration): Promise<Array<GroupDiscussionPhoto>> {
        const result = this.api.groupsDiscussionsPhotosList(groupUid, uid, group, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param groupDiscussionReactionRequest 
     */
    public groupsDiscussionsReactionCreate(groupUid: string, uid: string, groupDiscussionReactionRequest: GroupDiscussionReactionRequest, _options?: Configuration): Promise<GroupDiscussionReaction> {
        const result = this.api.groupsDiscussionsReactionCreate(groupUid, uid, groupDiscussionReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsReactionDestroy(groupUid: string, uid: string, _options?: Configuration): Promise<void> {
        const result = this.api.groupsDiscussionsReactionDestroy(groupUid, uid, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     */
    public groupsDiscussionsReactionsList(groupUid: string, uid: string, group?: string, _options?: Configuration): Promise<Array<GroupDiscussionReaction>> {
        const result = this.api.groupsDiscussionsReactionsList(groupUid, uid, group, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsRetrieve(groupUid: string, uid: string, _options?: Configuration): Promise<GroupDiscussion> {
        const result = this.api.groupsDiscussionsRetrieve(groupUid, uid, _options);
        return result.toPromise();
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param updateGroupDiscussionRequest 
     */
    public groupsDiscussionsUpdate(groupUid: string, uid: string, updateGroupDiscussionRequest?: UpdateGroupDiscussionRequest, _options?: Configuration): Promise<GroupDiscussion> {
        const result = this.api.groupsDiscussionsUpdate(groupUid, uid, updateGroupDiscussionRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public groupsList(_options?: Configuration): Promise<Array<Group>> {
        const result = this.api.groupsList(_options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param patchedGroupRequest 
     */
    public groupsPartialUpdate(uid: string, patchedGroupRequest?: PatchedGroupRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsPartialUpdate(uid, patchedGroupRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public groupsProfileRetrieve(uid: string, _options?: Configuration): Promise<GroupProfile> {
        const result = this.api.groupsProfileRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsRemoveHorsesCreate(uid: string, faedingarnumerSerilizerRequest: Array<FaedingarnumerSerilizerRequest>, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsRemoveHorsesCreate(uid, faedingarnumerSerilizerRequest, _options);
        return result.toPromise();
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsRemoveMareCreate(uid: string, faedingarnumerSerilizerRequest: FaedingarnumerSerilizerRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsRemoveMareCreate(uid, faedingarnumerSerilizerRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public groupsRetrieve(uid: string, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param groupSettingsRequest 
     */
    public groupsSettingsUpdate(uid: string, groupSettingsRequest?: GroupSettingsRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsSettingsUpdate(uid, groupSettingsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param groupThumbnailRequest 
     */
    public groupsThumbnailCreate(uid: string, groupThumbnailRequest?: GroupThumbnailRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsThumbnailCreate(uid, groupThumbnailRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param weeks 
     * @param year 
     */
    public groupsTrainingSummaryList(uid: string, weeks?: string, year?: number, _options?: Configuration): Promise<Array<WeeklyTrainingSummary>> {
        const result = this.api.groupsTrainingSummaryList(uid, weeks, year, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param updateGroupRequest 
     */
    public groupsUpdate(uid: string, updateGroupRequest?: UpdateGroupRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsUpdate(uid, updateGroupRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param updateGroupHorseRequest 
     */
    public groupsUpdateHorseUpdate(uid: string, updateGroupHorseRequest: UpdateGroupHorseRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsUpdateHorseUpdate(uid, updateGroupHorseRequest, _options);
        return result.toPromise();
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param updateGroupMareRequest 
     */
    public groupsUpdateMareCreate(uid: string, updateGroupMareRequest?: UpdateGroupMareRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsUpdateMareCreate(uid, updateGroupMareRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     * @param groupUserRoleRequest 
     */
    public groupsUpdateRoleCreate(uid: string, groupUserRoleRequest: GroupUserRoleRequest, _options?: Configuration): Promise<Group> {
        const result = this.api.groupsUpdateRoleCreate(uid, groupUserRoleRequest, _options);
        return result.toPromise();
    }


}



import { ObservableHorseAccessApi } from './ObservableAPI';

import { HorseAccessApiRequestFactory, HorseAccessApiResponseProcessor} from "../apis/HorseAccessApi";
export class PromiseHorseAccessApi {
    private api: ObservableHorseAccessApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessApiRequestFactory,
        responseProcessor?: HorseAccessApiResponseProcessor
    ) {
        this.api = new ObservableHorseAccessApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param faedingarnumer 
     */
    public horseAccessAccessHistoryList(faedingarnumer?: string, _options?: Configuration): Promise<Array<HorseAccessHistory>> {
        const result = this.api.horseAccessAccessHistoryList(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * Change health status of horse
     * @param id A unique integer value identifying this horse access.
     * @param status 
     */
    public horseAccessChangeHealthStatusCreate(id: number, status?: string, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessChangeHealthStatusCreate(id, status, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param stable 
     */
    public horseAccessChangeStableStatusCreate(id: number, stable: boolean, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessChangeStableStatusCreate(id, stable, _options);
        return result.toPromise();
    }

    /**
     * @param createHorseAccessRequest 
     */
    public horseAccessCreate(createHorseAccessRequest: CreateHorseAccessRequest, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessCreate(createHorseAccessRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     */
    public horseAccessDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseAccessDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param horse 
     * @param user 
     */
    public horseAccessList(horse?: string, user?: number, _options?: Configuration): Promise<Array<HorseAccess>> {
        const result = this.api.horseAccessList(horse, user, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param patchedHorseAccessRequest 
     */
    public horseAccessPartialUpdate(id: number, patchedHorseAccessRequest?: PatchedHorseAccessRequest, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessPartialUpdate(id, patchedHorseAccessRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     */
    public horseAccessRetrieve(id: number, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer 
     */
    public horseAccessTeamInfoRetrieve(faedingarnumer: string, _options?: Configuration): Promise<TeamInfo> {
        const result = this.api.horseAccessTeamInfoRetrieve(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param horseAccessRequest 
     */
    public horseAccessUpdate(id: number, horseAccessRequest: HorseAccessRequest, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessUpdate(id, horseAccessRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param role 
     */
    public horseAccessUpdateAccessCreate(id: number, role: number, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessUpdateAccessCreate(id, role, _options);
        return result.toPromise();
    }


}



import { ObservableHorseAccessInvitesApi } from './ObservableAPI';

import { HorseAccessInvitesApiRequestFactory, HorseAccessInvitesApiResponseProcessor} from "../apis/HorseAccessInvitesApi";
export class PromiseHorseAccessInvitesApi {
    private api: ObservableHorseAccessInvitesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessInvitesApiRequestFactory,
        responseProcessor?: HorseAccessInvitesApiResponseProcessor
    ) {
        this.api = new ObservableHorseAccessInvitesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesAcceptCreate(id: number, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessInvitesAcceptCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param createHorseAccessInviteRequest 
     */
    public horseAccessInvitesCreate(createHorseAccessInviteRequest: CreateHorseAccessInviteRequest, _options?: Configuration): Promise<HorseAccessInvite> {
        const result = this.api.horseAccessInvitesCreate(createHorseAccessInviteRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseAccessInvitesDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param dtProcessed 
     * @param dtRequested 
     * @param horse 
     * @param inviter 
     * @param status 
     * @param user 
     */
    public horseAccessInvitesList(dtProcessed?: Date, dtRequested?: Date, horse?: string, inviter?: number, status?: 'ACCEPTED' | 'PENDING' | 'REJECTED', user?: number, _options?: Configuration): Promise<Array<HorseAccessInvite>> {
        const result = this.api.horseAccessInvitesList(dtProcessed, dtRequested, horse, inviter, status, user, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     * @param patchedHorseAccessInviteRequest 
     */
    public horseAccessInvitesPartialUpdate(id: number, patchedHorseAccessInviteRequest?: PatchedHorseAccessInviteRequest, _options?: Configuration): Promise<HorseAccessInvite> {
        const result = this.api.horseAccessInvitesPartialUpdate(id, patchedHorseAccessInviteRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesRejectCreate(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseAccessInvitesRejectCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesRetrieve(id: number, _options?: Configuration): Promise<HorseAccessInvite> {
        const result = this.api.horseAccessInvitesRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     * @param horseAccessInviteRequest 
     */
    public horseAccessInvitesUpdate(id: number, horseAccessInviteRequest: HorseAccessInviteRequest, _options?: Configuration): Promise<HorseAccessInvite> {
        const result = this.api.horseAccessInvitesUpdate(id, horseAccessInviteRequest, _options);
        return result.toPromise();
    }


}



import { ObservableHorseAccessRequestsApi } from './ObservableAPI';

import { HorseAccessRequestsApiRequestFactory, HorseAccessRequestsApiResponseProcessor} from "../apis/HorseAccessRequestsApi";
export class PromiseHorseAccessRequestsApi {
    private api: ObservableHorseAccessRequestsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessRequestsApiRequestFactory,
        responseProcessor?: HorseAccessRequestsApiResponseProcessor
    ) {
        this.api = new ObservableHorseAccessRequestsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsAcceptCreate(id: number, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horseAccessRequestsAcceptCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param createHorseAccessReqRequest 
     */
    public horseAccessRequestsCreate(createHorseAccessReqRequest: CreateHorseAccessReqRequest, _options?: Configuration): Promise<HorseAccessReq> {
        const result = this.api.horseAccessRequestsCreate(createHorseAccessReqRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseAccessRequestsDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param dtProcessed 
     * @param dtRequested 
     * @param horse 
     * @param status 
     * @param user 
     */
    public horseAccessRequestsList(dtProcessed?: Date, dtRequested?: Date, horse?: string, status?: 'ACCEPTED' | 'PENDING' | 'REJECTED', user?: number, _options?: Configuration): Promise<Array<HorseAccessReq>> {
        const result = this.api.horseAccessRequestsList(dtProcessed, dtRequested, horse, status, user, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsRejectCreate(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseAccessRequestsRejectCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsRetrieve(id: number, _options?: Configuration): Promise<HorseAccessReq> {
        const result = this.api.horseAccessRequestsRetrieve(id, _options);
        return result.toPromise();
    }


}



import { ObservableHorsePhotosApi } from './ObservableAPI';

import { HorsePhotosApiRequestFactory, HorsePhotosApiResponseProcessor} from "../apis/HorsePhotosApi";
export class PromiseHorsePhotosApi {
    private api: ObservableHorsePhotosApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsePhotosApiRequestFactory,
        responseProcessor?: HorsePhotosApiResponseProcessor
    ) {
        this.api = new ObservableHorsePhotosApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosCreate(horse: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Promise<HorsePhoto> {
        const result = this.api.horsePhotosCreate(horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horsePhotosDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * Fetches the profile photo for a horse identified by its FEIF number. If no profile photo is set, returns the first available photo. Returns null if no photos are available.
     * @param horse faedingarnumer
     */
    public horsePhotosGetProfilePhotoRetrieve(horse: string, _options?: Configuration): Promise<HorsePhoto> {
        const result = this.api.horsePhotosGetProfilePhotoRetrieve(horse, _options);
        return result.toPromise();
    }

    /**
     * @param horse 
     * @param inProfile 
     * @param isHidden 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public horsePhotosList(horse?: string, inProfile?: boolean, isHidden?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-profile_order' | 'dt_created' | 'dt_modified' | 'profile_order'>, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedHorsePhotoList> {
        const result = this.api.horsePhotosList(horse, inProfile, isHidden, order, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param horse 
     * @param inProfile 
     * @param isHidden 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public horsePhotosPaginatedList(cursor?: string, horse?: string, inProfile?: boolean, isHidden?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-profile_order' | 'dt_created' | 'dt_modified' | 'profile_order'>, pageSize?: number, _options?: Configuration): Promise<PaginatedPaginatedHorsePhotosList> {
        const result = this.api.horsePhotosPaginatedList(cursor, horse, inProfile, isHidden, order, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosPartialUpdate(id: number, horse?: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Promise<HorsePhoto> {
        const result = this.api.horsePhotosPartialUpdate(id, horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosRetrieve(id: number, _options?: Configuration): Promise<HorsePhoto> {
        const result = this.api.horsePhotosRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosSetInProfileCreate(id: number, _options?: Configuration): Promise<Array<HorsePhoto>> {
        const result = this.api.horsePhotosSetInProfileCreate(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosUpdate(id: number, horse: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Promise<HorsePhoto> {
        const result = this.api.horsePhotosUpdate(id, horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);
        return result.toPromise();
    }


}



import { ObservableHorseSocialsApi } from './ObservableAPI';

import { HorseSocialsApiRequestFactory, HorseSocialsApiResponseProcessor} from "../apis/HorseSocialsApi";
export class PromiseHorseSocialsApi {
    private api: ObservableHorseSocialsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseSocialsApiRequestFactory,
        responseProcessor?: HorseSocialsApiResponseProcessor
    ) {
        this.api = new ObservableHorseSocialsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * follow user
     * @param horseIdRequest 
     */
    public horseSocialsFollowUnfollowCreate(horseIdRequest: HorseIdRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.horseSocialsFollowUnfollowCreate(horseIdRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer 
     */
    public horseSocialsFollowersList(faedingarnumer: string, _options?: Configuration): Promise<Array<SimpleUser>> {
        const result = this.api.horseSocialsFollowersList(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * Get the horses that the user follows
     */
    public horseSocialsGetFollowedHorsesList(_options?: Configuration): Promise<Array<SimpleHorse>> {
        const result = this.api.horseSocialsGetFollowedHorsesList(_options);
        return result.toPromise();
    }

    /**
     */
    public horseSocialsGetMostFollowedHorsesList(_options?: Configuration): Promise<Array<SimpleHorse>> {
        const result = this.api.horseSocialsGetMostFollowedHorsesList(_options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public horseSocialsList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.horseSocialsList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, type, _options);
        return result.toPromise();
    }


}



import { ObservableHorseVideosApi } from './ObservableAPI';

import { HorseVideosApiRequestFactory, HorseVideosApiResponseProcessor} from "../apis/HorseVideosApi";
export class PromiseHorseVideosApi {
    private api: ObservableHorseVideosApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseVideosApiRequestFactory,
        responseProcessor?: HorseVideosApiResponseProcessor
    ) {
        this.api = new ObservableHorseVideosApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param horse 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosCreate(horse: string, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Promise<HorseVideo> {
        const result = this.api.horseVideosCreate(horse, name, video, transcoded, thumbnail, user, isSaleVideo, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse video.
     */
    public horseVideosDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.horseVideosDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param horse 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public horseVideosList(horse?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedHorseVideoList> {
        const result = this.api.horseVideosList(horse, order, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public horseVideosPaginatedList(cursor?: string, horse?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, pageSize?: number, _options?: Configuration): Promise<PaginatedPaginatedHorseVideosList> {
        const result = this.api.horseVideosPaginatedList(cursor, horse, order, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param name 
     * @param horse 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosPartialUpdate(id: number, name?: string, horse?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Promise<HorseVideo> {
        const result = this.api.horseVideosPartialUpdate(id, name, horse, video, transcoded, thumbnail, user, isSaleVideo, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse video.
     */
    public horseVideosRetrieve(id: number, _options?: Configuration): Promise<HorseVideo> {
        const result = this.api.horseVideosRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param thumbnail 
     */
    public horseVideosThumbnailCreate(id: number, thumbnail?: HttpFile, _options?: Configuration): Promise<HorseVideo> {
        const result = this.api.horseVideosThumbnailCreate(id, thumbnail, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param horse 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosUpdate(id: number, horse: string, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Promise<HorseVideo> {
        const result = this.api.horseVideosUpdate(id, horse, name, video, transcoded, thumbnail, user, isSaleVideo, _options);
        return result.toPromise();
    }


}



import { ObservableHorsedayRankingApi } from './ObservableAPI';

import { HorsedayRankingApiRequestFactory, HorsedayRankingApiResponseProcessor} from "../apis/HorsedayRankingApi";
export class PromiseHorsedayRankingApi {
    private api: ObservableHorsedayRankingApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsedayRankingApiRequestFactory,
        responseProcessor?: HorsedayRankingApiResponseProcessor
    ) {
        this.api = new ObservableHorsedayRankingApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public horsedayRankingCountriesYearsList(_options?: Configuration): Promise<Array<CountryYears>> {
        const result = this.api.horsedayRankingCountriesYearsList(_options);
        return result.toPromise();
    }

    /**
     * @param land 
     * @param year 
     */
    public horsedayRankingFlokkurKeppnirList(land: string, year: number, _options?: Configuration): Promise<Array<FlokkurKeppni>> {
        const result = this.api.horsedayRankingFlokkurKeppnirList(land, year, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param flokkur 
     * @param keppnisgrein 
     * @param land 
     * @param pageSize Number of results to return per page.
     * @param year 
     */
    public horsedayRankingList(cursor?: string, flokkur?: string, keppnisgrein?: string, land?: string, pageSize?: number, year?: number, _options?: Configuration): Promise<PaginatedHorseDayRankingList> {
        const result = this.api.horsedayRankingList(cursor, flokkur, keppnisgrein, land, pageSize, year, _options);
        return result.toPromise();
    }

    /**
     * @param keppandiNumer A unique value identifying this horse day ranking.
     */
    public horsedayRankingRetrieve(keppandiNumer: number, _options?: Configuration): Promise<HorseDayRanking> {
        const result = this.api.horsedayRankingRetrieve(keppandiNumer, _options);
        return result.toPromise();
    }


}



import { ObservableHorsedayWrappedApi } from './ObservableAPI';

import { HorsedayWrappedApiRequestFactory, HorsedayWrappedApiResponseProcessor} from "../apis/HorsedayWrappedApi";
export class PromiseHorsedayWrappedApi {
    private api: ObservableHorsedayWrappedApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsedayWrappedApiRequestFactory,
        responseProcessor?: HorsedayWrappedApiResponseProcessor
    ) {
        this.api = new ObservableHorsedayWrappedApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public horsedayWrappedRetrieve(_options?: Configuration): Promise<WrappedSerialzer> {
        const result = this.api.horsedayWrappedRetrieve(_options);
        return result.toPromise();
    }


}



import { ObservableHorsesApi } from './ObservableAPI';

import { HorsesApiRequestFactory, HorsesApiResponseProcessor} from "../apis/HorsesApi";
export class PromiseHorsesApi {
    private api: ObservableHorsesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsesApiRequestFactory,
        responseProcessor?: HorsesApiResponseProcessor
    ) {
        this.api = new ObservableHorsesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public horsesAllMyHorsesList(_options?: Configuration): Promise<Array<Horse>> {
        const result = this.api.horsesAllMyHorsesList(_options);
        return result.toPromise();
    }

    /**
     */
    public horsesAllMySimpleHorsesList(_options?: Configuration): Promise<Array<SimpleHorse>> {
        const result = this.api.horsesAllMySimpleHorsesList(_options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param dam 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesAssessmentOverviewList(faedingarnumer: string, dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Promise<Array<AssessmentOverview>> {
        const result = this.api.horsesAssessmentOverviewList(faedingarnumer, dam, order, sire, userRole, _options);
        return result.toPromise();
    }

    /**
     * Change health status of horse
     * @param faedingarnumer A unique value identifying this horse.
     * @param status 
     */
    public horsesChangeHealthStatusCreate(faedingarnumer: string, status?: string, _options?: Configuration): Promise<SimpleHorse> {
        const result = this.api.horsesChangeHealthStatusCreate(faedingarnumer, status, _options);
        return result.toPromise();
    }

    /**
     * @param createHorseRequest 
     */
    public horsesCreateHorseManualCreate(createHorseRequest: CreateHorseRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesCreateHorseManualCreate(createHorseRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseVaccinationRequest 
     */
    public horsesDeleteVaccinationCreate(faedingarnumer: string, horseVaccinationRequest?: HorseVaccinationRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.horsesDeleteVaccinationCreate(faedingarnumer, horseVaccinationRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesDestroy(faedingarnumer: string, _options?: Configuration): Promise<void> {
        const result = this.api.horsesDestroy(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param domurId 
     */
    public horsesDetailedAssessmentOverviewRetrieve(domurId: string, _options?: Configuration): Promise<DetailedAssessmentOverview> {
        const result = this.api.horsesDetailedAssessmentOverviewRetrieve(domurId, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseDetailsRequest 
     */
    public horsesDetailsCreate(faedingarnumer: string, horseDetailsRequest?: HorseDetailsRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesDetailsCreate(faedingarnumer, horseDetailsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseDetailsRequest 
     */
    public horsesDetailsPartialUpdate(faedingarnumer: string, patchedHorseDetailsRequest?: PatchedHorseDetailsRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesDetailsPartialUpdate(faedingarnumer, patchedHorseDetailsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesFollowCreate(faedingarnumer: string, _options?: Configuration): Promise<HorseAccess> {
        const result = this.api.horsesFollowCreate(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseForSaleRequest 
     */
    public horsesForSaleCreate(faedingarnumer: string, horseForSaleRequest: HorseForSaleRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesForSaleCreate(faedingarnumer, horseForSaleRequest, _options);
        return result.toPromise();
    }

    /**
     * Get the feeding history of a horse
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesGetHorseFeedingHistoryList(faedingarnumer: string, _options?: Configuration): Promise<Array<Feeding>> {
        const result = this.api.horsesGetHorseFeedingHistoryList(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * listi af strengjum
     * @param faedingarnumers 
     */
    public horsesGetHorsesByFaedingarnumerList(faedingarnumers?: string, _options?: Configuration): Promise<Array<SimpleHorse>> {
        const result = this.api.horsesGetHorsesByFaedingarnumerList(faedingarnumers, _options);
        return result.toPromise();
    }

    /**
     * @param sportfengurIds 
     */
    public horsesGetHorsesBySportfengurIdsList(sportfengurIds?: string, _options?: Configuration): Promise<Array<SimpleHorse>> {
        const result = this.api.horsesGetHorsesBySportfengurIdsList(sportfengurIds, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesGetVaccinationsList(faedingarnumer: string, _options?: Configuration): Promise<Array<HorseVaccination>> {
        const result = this.api.horsesGetVaccinationsList(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesHorseInfoSummaryRetrieve(faedingarnumer: string, _options?: Configuration): Promise<InfoSummary> {
        const result = this.api.horsesHorseInfoSummaryRetrieve(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param isMonthly 
     * @param month 
     * @param week 
     * @param year 
     */
    public horsesHorseTrainingSummaryRetrieve(faedingarnumer: string, isMonthly?: boolean, month?: number, week?: number, year?: number, _options?: Configuration): Promise<Summary> {
        const result = this.api.horsesHorseTrainingSummaryRetrieve(faedingarnumer, isMonthly, month, week, year, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer 
     * @param faedingarnumerContains 
     * @param name 
     * @param nameContains 
     */
    public horsesHorsedaySearchList(faedingarnumer?: string, faedingarnumerContains?: string, name?: string, nameContains?: string, _options?: Configuration): Promise<Array<SearchHorse>> {
        const result = this.api.horsesHorsedaySearchList(faedingarnumer, faedingarnumerContains, name, nameContains, _options);
        return result.toPromise();
    }

    /**
     * @param dam 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param sire 
     * @param userRole 
     */
    public horsesList(dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, page?: number, pageSize?: number, sire?: string, userRole?: string, _options?: Configuration): Promise<PaginatedHorseList> {
        const result = this.api.horsesList(dam, order, page, pageSize, sire, userRole, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param measurmentsRequest 
     */
    public horsesMeasurementsCreate(faedingarnumer: string, measurmentsRequest: MeasurmentsRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesMeasurementsCreate(faedingarnumer, measurmentsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseMedicalDetailsRequest 
     */
    public horsesMedicalDetailsCreate(faedingarnumer: string, horseMedicalDetailsRequest?: HorseMedicalDetailsRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesMedicalDetailsCreate(faedingarnumer, horseMedicalDetailsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseMedicalDetailsRequest 
     */
    public horsesMedicalDetailsPartialUpdate(faedingarnumer: string, patchedHorseMedicalDetailsRequest?: PatchedHorseMedicalDetailsRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesMedicalDetailsPartialUpdate(faedingarnumer, patchedHorseMedicalDetailsRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param goalRequest 
     */
    public horsesMonthlyGoalCreate(faedingarnumer: string, goalRequest?: GoalRequest, _options?: Configuration): Promise<MonthlyTrainingSummary> {
        const result = this.api.horsesMonthlyGoalCreate(faedingarnumer, goalRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param dam 
     * @param faedingarnumer2 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesOffspringList(faedingarnumer: string, dam?: string, faedingarnumer2?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Promise<Array<WFOffspring>> {
        const result = this.api.horsesOffspringList(faedingarnumer, dam, faedingarnumer2, order, sire, userRole, _options);
        return result.toPromise();
    }

    /**
     * @param birthDateGt age greater than
     * @param birthDateLt age less than
     * @param country Origin country, country code 2 letters
     * @param horse 
     * @param next 
     * @param onlyAlive 
     * @param orderBy 
     * @param pageSize 
     * @param place 
     * @param sexEnum Sex enum
     */
    public horsesPaginatedHorsedaySearchRetrieve(birthDateGt?: string, birthDateLt?: string, country?: string, horse?: string, next?: number, onlyAlive?: boolean, orderBy?: string, pageSize?: number, place?: string, sexEnum?: string, _options?: Configuration): Promise<PaginatedHorses> {
        const result = this.api.horsesPaginatedHorsedaySearchRetrieve(birthDateGt, birthDateLt, country, horse, next, onlyAlive, orderBy, pageSize, place, sexEnum, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseRequest 
     */
    public horsesPartialUpdate(faedingarnumer: string, patchedHorseRequest?: PatchedHorseRequest, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesPartialUpdate(faedingarnumer, patchedHorseRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesRetrieve(faedingarnumer: string, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesRetrieve(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseSettingsRequest 
     */
    public horsesSettingsCreate(faedingarnumer: string, horseSettingsRequest?: HorseSettingsRequest, _options?: Configuration): Promise<HorseSettings> {
        const result = this.api.horsesSettingsCreate(faedingarnumer, horseSettingsRequest, _options);
        return result.toPromise();
    }

    /**
     * Uses wf to search for horse.
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesSingleHorseRetrieve(faedingarnumer: string, _options?: Configuration): Promise<Horse> {
        const result = this.api.horsesSingleHorseRetrieve(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     */
    public horsesTopHorsesList(_options?: Configuration): Promise<Array<Horse>> {
        const result = this.api.horsesTopHorsesList(_options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesUnauthedHorseRetrieveRetrieve(faedingarnumer: string, _options?: Configuration): Promise<SearchHorse> {
        const result = this.api.horsesUnauthedHorseRetrieveRetrieve(faedingarnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseAccessRequest 
     */
    public horsesUpdateAccessCreate(faedingarnumer: string, horseAccessRequest: HorseAccessRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.horsesUpdateAccessCreate(faedingarnumer, horseAccessRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseVaccinationRequest 
     */
    public horsesVaccinationCreate(faedingarnumer: string, horseVaccinationRequest?: HorseVaccinationRequest, _options?: Configuration): Promise<HorseVaccination> {
        const result = this.api.horsesVaccinationCreate(faedingarnumer, horseVaccinationRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseVaccinationRequest 
     */
    public horsesVaccinationPartialUpdate(faedingarnumer: string, patchedHorseVaccinationRequest?: PatchedHorseVaccinationRequest, _options?: Configuration): Promise<HorseVaccination> {
        const result = this.api.horsesVaccinationPartialUpdate(faedingarnumer, patchedHorseVaccinationRequest, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param goalRequest 
     */
    public horsesWeeklyGoalCreate(faedingarnumer: string, goalRequest?: GoalRequest, _options?: Configuration): Promise<WeeklyTrainingSummary> {
        const result = this.api.horsesWeeklyGoalCreate(faedingarnumer, goalRequest, _options);
        return result.toPromise();
    }

    /**
     * @param wFLoginRequest 
     */
    public horsesWfAccountSyncCreate(wFLoginRequest: WFLoginRequest, _options?: Configuration): Promise<Array<Horse>> {
        const result = this.api.horsesWfAccountSyncCreate(wFLoginRequest, _options);
        return result.toPromise();
    }

    /**
     * @param dam 
     * @param faedingarnumer 
     * @param name 
     * @param order Ordering
     * @param origin 
     * @param owner 
     * @param rfid 
     * @param sire 
     * @param userRole 
     */
    public horsesWfSearchList(dam?: string, faedingarnumer?: string, name?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, origin?: string, owner?: string, rfid?: string, sire?: string, userRole?: string, _options?: Configuration): Promise<Array<SearchHorse>> {
        const result = this.api.horsesWfSearchList(dam, faedingarnumer, name, order, origin, owner, rfid, sire, userRole, _options);
        return result.toPromise();
    }

    /**
     * @param dam 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesWorldChampionshipList(dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Promise<Array<WorldCupHorse>> {
        const result = this.api.horsesWorldChampionshipList(dam, order, sire, userRole, _options);
        return result.toPromise();
    }


}



import { ObservableHorsesQueriesApi } from './ObservableAPI';

import { HorsesQueriesApiRequestFactory, HorsesQueriesApiResponseProcessor} from "../apis/HorsesQueriesApi";
export class PromiseHorsesQueriesApi {
    private api: ObservableHorsesQueriesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsesQueriesApiRequestFactory,
        responseProcessor?: HorsesQueriesApiResponseProcessor
    ) {
        this.api = new ObservableHorsesQueriesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param adaleinkunn 
     * @param assessment 
     * @param availableForBreeding 
     * @param birthDate 
     * @param birthDateFiilterAfter 
     * @param birthDateFiilterBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param forSale 
     * @param haestaAdaleinkunn 
     * @param horseType 
     * @param name 
     * @param noPriceSet 
     * @param order Ordering
     * @param originCountry 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param priceCategory 
     * @param priceRangeGreaterThan 
     * @param priceRangeLessThan 
     * @param sexEnum 
     * @param ssName 
     * @param ssPlace 
     */
    public horsesQueriesList(adaleinkunn?: number, assessment?: number, availableForBreeding?: boolean, birthDate?: string, birthDateFiilterAfter?: string, birthDateFiilterBefore?: string, dateRangeAfter?: string, dateRangeBefore?: string, forSale?: boolean, haestaAdaleinkunn?: number, horseType?: Array<'COMPETITION' | 'FAMILY' | 'OTHER' | 'PACE' | 'RIDE' | 'UNTAMED'>, name?: string, noPriceSet?: boolean, order?: Array<'-adaleinkunn' | '-dt_set_for_sale' | '-for_sale__price' | '-haestaAdaleinkunn' | '-name' | 'adaleinkunn' | 'dt_set_for_sale' | 'for_sale__price' | 'haestaAdaleinkunn' | 'name'>, originCountry?: string, page?: number, pageSize?: number, priceCategory?: Array<'ONE' | 'THREE' | 'TWO' | 'UNDEFINED'>, priceRangeGreaterThan?: number, priceRangeLessThan?: number, sexEnum?: Array<'GELDING' | 'MARE' | 'STALLION'>, ssName?: string, ssPlace?: string, _options?: Configuration): Promise<PaginatedHorseList> {
        const result = this.api.horsesQueriesList(adaleinkunn, assessment, availableForBreeding, birthDate, birthDateFiilterAfter, birthDateFiilterBefore, dateRangeAfter, dateRangeBefore, forSale, haestaAdaleinkunn, horseType, name, noPriceSet, order, originCountry, page, pageSize, priceCategory, priceRangeGreaterThan, priceRangeLessThan, sexEnum, ssName, ssPlace, _options);
        return result.toPromise();
    }

    /**
     */
    public horsesQueriesMetadataRetrieve(_options?: Configuration): Promise<HorseQueryMetadata> {
        const result = this.api.horsesQueriesMetadataRetrieve(_options);
        return result.toPromise();
    }

    /**
     * will this be quicker
     * @param number 
     */
    public horsesQueriesNewestForSaleList(number?: number, _options?: Configuration): Promise<Array<Horse>> {
        const result = this.api.horsesQueriesNewestForSaleList(number, _options);
        return result.toPromise();
    }


}



import { ObservableLeaderboardApi } from './ObservableAPI';

import { LeaderboardApiRequestFactory, LeaderboardApiResponseProcessor} from "../apis/LeaderboardApi";
export class PromiseLeaderboardApi {
    private api: ObservableLeaderboardApi

    public constructor(
        configuration: Configuration,
        requestFactory?: LeaderboardApiRequestFactory,
        responseProcessor?: LeaderboardApiResponseProcessor
    ) {
        this.api = new ObservableLeaderboardApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param leaderboardRequest 
     */
    public leaderboardCreate(leaderboardRequest: LeaderboardRequest, _options?: Configuration): Promise<Leaderboard> {
        const result = this.api.leaderboardCreate(leaderboardRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this leader board.
     */
    public leaderboardDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.leaderboardDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardList(intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Promise<PaginatedLeaderboardList> {
        const result = this.api.leaderboardList(intervalType, intervalValue, metric, page, pageSize, user, year, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this leader board.
     * @param patchedLeaderboardRequest 
     */
    public leaderboardPartialUpdate(id: number, patchedLeaderboardRequest?: PatchedLeaderboardRequest, _options?: Configuration): Promise<Leaderboard> {
        const result = this.api.leaderboardPartialUpdate(id, patchedLeaderboardRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this leader board.
     */
    public leaderboardRetrieve(id: number, _options?: Configuration): Promise<Leaderboard> {
        const result = this.api.leaderboardRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetLeaderboardLast30DaysCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Promise<PaginatedLeaderboardList> {
        const result = this.api.leaderboardSetLeaderboardLast30DaysCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);
        return result.toPromise();
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetMonthlyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Promise<PaginatedLeaderboardList> {
        const result = this.api.leaderboardSetMonthlyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);
        return result.toPromise();
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetWeeklyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Promise<PaginatedLeaderboardList> {
        const result = this.api.leaderboardSetWeeklyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);
        return result.toPromise();
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetYearlyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Promise<PaginatedLeaderboardList> {
        const result = this.api.leaderboardSetYearlyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);
        return result.toPromise();
    }

    /**
     * @param intervalType Type of the interval
     * @param intervalValue 
     * @param metric Metric type
     * @param user 
     * @param year 
     */
    public leaderboardUserLeaderboardList(intervalType?: 'DISTANCE' | 'SESSIONS' | 'TIME', intervalValue?: number, metric?: 'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR', user?: number, year?: number, _options?: Configuration): Promise<Array<Leaderboard>> {
        const result = this.api.leaderboardUserLeaderboardList(intervalType, intervalValue, metric, user, year, _options);
        return result.toPromise();
    }


}



import { ObservableMessageTokensApi } from './ObservableAPI';

import { MessageTokensApiRequestFactory, MessageTokensApiResponseProcessor} from "../apis/MessageTokensApi";
export class PromiseMessageTokensApi {
    private api: ObservableMessageTokensApi

    public constructor(
        configuration: Configuration,
        requestFactory?: MessageTokensApiRequestFactory,
        responseProcessor?: MessageTokensApiResponseProcessor
    ) {
        this.api = new ObservableMessageTokensApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param tokenRequest 
     */
    public messageTokensCreate(tokenRequest: TokenRequest, _options?: Configuration): Promise<void> {
        const result = this.api.messageTokensCreate(tokenRequest, _options);
        return result.toPromise();
    }

    /**
     * @param token A unique value identifying this messaging token.
     */
    public messageTokensDestroy(token: string, _options?: Configuration): Promise<void> {
        const result = this.api.messageTokensDestroy(token, _options);
        return result.toPromise();
    }


}



import { ObservableMetaApi } from './ObservableAPI';

import { MetaApiRequestFactory, MetaApiResponseProcessor} from "../apis/MetaApi";
export class PromiseMetaApi {
    private api: ObservableMetaApi

    public constructor(
        configuration: Configuration,
        requestFactory?: MetaApiRequestFactory,
        responseProcessor?: MetaApiResponseProcessor
    ) {
        this.api = new ObservableMetaApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public metaAppMetaRetrieve(_options?: Configuration): Promise<AppMeta> {
        const result = this.api.metaAppMetaRetrieve(_options);
        return result.toPromise();
    }

    /**
     */
    public metaHorsedayMessageRetrieve(_options?: Configuration): Promise<HorseDayMessage> {
        const result = this.api.metaHorsedayMessageRetrieve(_options);
        return result.toPromise();
    }


}



import { ObservableNotificationsApi } from './ObservableAPI';

import { NotificationsApiRequestFactory, NotificationsApiResponseProcessor} from "../apis/NotificationsApi";
export class PromiseNotificationsApi {
    private api: ObservableNotificationsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: NotificationsApiRequestFactory,
        responseProcessor?: NotificationsApiResponseProcessor
    ) {
        this.api = new ObservableNotificationsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique value identifying this notification.
     */
    public notificationsDestroy(id: string, _options?: Configuration): Promise<void> {
        const result = this.api.notificationsDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param receiver 
     * @param seen 
     */
    public notificationsList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, receiver?: number, seen?: boolean, _options?: Configuration): Promise<PaginatedNotificationList> {
        const result = this.api.notificationsList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, receiver, seen, _options);
        return result.toPromise();
    }

    /**
     */
    public notificationsNumberUnseenRetrieve(_options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.notificationsNumberUnseenRetrieve(_options);
        return result.toPromise();
    }

    /**
     */
    public notificationsUnseenList(_options?: Configuration): Promise<Array<Notification>> {
        const result = this.api.notificationsUnseenList(_options);
        return result.toPromise();
    }


}



import { ObservablePromoApi } from './ObservableAPI';

import { PromoApiRequestFactory, PromoApiResponseProcessor} from "../apis/PromoApi";
export class PromisePromoApi {
    private api: ObservablePromoApi

    public constructor(
        configuration: Configuration,
        requestFactory?: PromoApiRequestFactory,
        responseProcessor?: PromoApiResponseProcessor
    ) {
        this.api = new ObservablePromoApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param platform 
     */
    public promoCodeRetrieve(platform?: string, _options?: Configuration): Promise<PromoCode> {
        const result = this.api.promoCodeRetrieve(platform, _options);
        return result.toPromise();
    }

    /**
     * @param promoCodeRequest 
     */
    public promoUseCodeCreate(promoCodeRequest?: PromoCodeRequest, _options?: Configuration): Promise<PromoCode> {
        const result = this.api.promoUseCodeCreate(promoCodeRequest, _options);
        return result.toPromise();
    }


}



import { ObservableRedeemCodeApi } from './ObservableAPI';

import { RedeemCodeApiRequestFactory, RedeemCodeApiResponseProcessor} from "../apis/RedeemCodeApi";
export class PromiseRedeemCodeApi {
    private api: ObservableRedeemCodeApi

    public constructor(
        configuration: Configuration,
        requestFactory?: RedeemCodeApiRequestFactory,
        responseProcessor?: RedeemCodeApiResponseProcessor
    ) {
        this.api = new ObservableRedeemCodeApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param code 
     */
    public redeemCodeCreate(code?: string, _options?: Configuration): Promise<User> {
        const result = this.api.redeemCodeCreate(code, _options);
        return result.toPromise();
    }


}



import { ObservableSafetyTrackingApi } from './ObservableAPI';

import { SafetyTrackingApiRequestFactory, SafetyTrackingApiResponseProcessor} from "../apis/SafetyTrackingApi";
export class PromiseSafetyTrackingApi {
    private api: ObservableSafetyTrackingApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SafetyTrackingApiRequestFactory,
        responseProcessor?: SafetyTrackingApiResponseProcessor
    ) {
        this.api = new ObservableSafetyTrackingApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param safetyTrackingTeamInviteCreateRequest 
     */
    public safetyTrackingAccessCreateInviteCreate(safetyTrackingTeamInviteCreateRequest: SafetyTrackingTeamInviteCreateRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingAccessCreateInviteCreate(safetyTrackingTeamInviteCreateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param idRequest 
     */
    public safetyTrackingAccessInviteDeleteCreate(idRequest: IdRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingAccessInviteDeleteCreate(idRequest, _options);
        return result.toPromise();
    }

    /**
     * Teams user has been invited to
     */
    public safetyTrackingAccessInvitedList(_options?: Configuration): Promise<Array<SafetyTrackingTeamInvite>> {
        const result = this.api.safetyTrackingAccessInvitedList(_options);
        return result.toPromise();
    }

    /**
     * All team user has invited others
     */
    public safetyTrackingAccessInviterList(_options?: Configuration): Promise<Array<SafetyTrackingTeamInvite>> {
        const result = this.api.safetyTrackingAccessInviterList(_options);
        return result.toPromise();
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingAccessRemoveCreate(userUidRequest: UserUidRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingAccessRemoveCreate(userUidRequest, _options);
        return result.toPromise();
    }

    /**
     * @param acceptRequest 
     */
    public safetyTrackingAccessRespondInviteCreate(acceptRequest: AcceptRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingAccessRespondInviteCreate(acceptRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public safetyTrackingActiveTrackingRetrieve(uid: string, _options?: Configuration): Promise<HorseActivity | SimpleMessage> {
        const result = this.api.safetyTrackingActiveTrackingRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingAddSafetyTeamViewerCreate(userUidRequest: UserUidRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingAddSafetyTeamViewerCreate(userUidRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public safetyTrackingDismissedSafetyTrackCreate(_options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingDismissedSafetyTrackCreate(_options);
        return result.toPromise();
    }

    /**
     * @param token 
     */
    public safetyTrackingGetTeamWithTokenRetrieve(token: string, _options?: Configuration): Promise<SafetyTrackingTeam> {
        const result = this.api.safetyTrackingGetTeamWithTokenRetrieve(token, _options);
        return result.toPromise();
    }

    /**
     */
    public safetyTrackingMyInfoRetrieve(_options?: Configuration): Promise<SafetyTrackingMyInfo> {
        const result = this.api.safetyTrackingMyInfoRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingRemoveSelfAsViewerCreate(userUidRequest: UserUidRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.safetyTrackingRemoveSelfAsViewerCreate(userUidRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public safetyTrackingTeamRetrieve(uid?: string, _options?: Configuration): Promise<SafetyTrackingTeam> {
        const result = this.api.safetyTrackingTeamRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     */
    public safetyTrackingTeamsWhereViewerList(_options?: Configuration): Promise<Array<SafetyTrackingTeam>> {
        const result = this.api.safetyTrackingTeamsWhereViewerList(_options);
        return result.toPromise();
    }

    /**
     */
    public safetyTrackingToggleSafetyTrackingCreate(_options?: Configuration): Promise<SafetyTrackingTeam> {
        const result = this.api.safetyTrackingToggleSafetyTrackingCreate(_options);
        return result.toPromise();
    }

    /**
     * @param activityId 
     */
    public safetyTrackingTrackingPointsList(activityId: number, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.safetyTrackingTrackingPointsList(activityId, _options);
        return result.toPromise();
    }

    /**
     * @param token 
     */
    public safetyTrackingTrackingPointsWithTokenList(token: string, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.safetyTrackingTrackingPointsWithTokenList(token, _options);
        return result.toPromise();
    }


}



import { ObservableSchedulesApi } from './ObservableAPI';

import { SchedulesApiRequestFactory, SchedulesApiResponseProcessor} from "../apis/SchedulesApi";
export class PromiseSchedulesApi {
    private api: ObservableSchedulesApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SchedulesApiRequestFactory,
        responseProcessor?: SchedulesApiResponseProcessor
    ) {
        this.api = new ObservableSchedulesApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public schedulesMonthlyTrainingSummaryRetrieve(_options?: Configuration): Promise<void> {
        const result = this.api.schedulesMonthlyTrainingSummaryRetrieve(_options);
        return result.toPromise();
    }

    /**
     */
    public schedulesTrainingSessionValidationRetrieve(_options?: Configuration): Promise<void> {
        const result = this.api.schedulesTrainingSessionValidationRetrieve(_options);
        return result.toPromise();
    }

    /**
     * Virkur HDnotandi er skilgreindur sem notandi með einkvæmt númer hjá HD sem ekki er með persónulegan aðgang að WF tengdan og hefur framkvæmt meira en 5 leitir í WF frá því þeir hófu notkun á HD appinu.
     */
    public schedulesWorldFengurCountRetrieve(_options?: Configuration): Promise<void> {
        const result = this.api.schedulesWorldFengurCountRetrieve(_options);
        return result.toPromise();
    }


}



import { ObservableSchemaApi } from './ObservableAPI';

import { SchemaApiRequestFactory, SchemaApiResponseProcessor} from "../apis/SchemaApi";
export class PromiseSchemaApi {
    private api: ObservableSchemaApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SchemaApiRequestFactory,
        responseProcessor?: SchemaApiResponseProcessor
    ) {
        this.api = new ObservableSchemaApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param format 
     * @param lang 
     */
    public schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'ug' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Promise<{ [key: string]: any; }> {
        const result = this.api.schemaRetrieve(format, lang, _options);
        return result.toPromise();
    }


}



import { ObservableSocialsApi } from './ObservableAPI';

import { SocialsApiRequestFactory, SocialsApiResponseProcessor} from "../apis/SocialsApi";
export class PromiseSocialsApi {
    private api: ObservableSocialsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SocialsApiRequestFactory,
        responseProcessor?: SocialsApiResponseProcessor
    ) {
        this.api = new ObservableSocialsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * follow user if response.number == 2 then it was a follow request if response.number == 1 then it was a follow
     * @param followRequest 
     */
    public socialsCancelFollowRequestCreate(followRequest: FollowRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.socialsCancelFollowRequestCreate(followRequest, _options);
        return result.toPromise();
    }

    /**
     * follow user if response.number == 2 then it was a follow request if response.number == 1 then it was a follow
     * @param followRequest 
     */
    public socialsFollowCreate(followRequest: FollowRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.socialsFollowCreate(followRequest, _options);
        return result.toPromise();
    }

    /**
     * @param userFollowRequestResponseRequest 
     */
    public socialsFollowRequestResponseCreate(userFollowRequestResponseRequest: UserFollowRequestResponseRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.socialsFollowRequestResponseCreate(userFollowRequestResponseRequest, _options);
        return result.toPromise();
    }

    /**
     * @param uid 
     */
    public socialsGetFollowRequestRetrieve(uid?: string, _options?: Configuration): Promise<UserFollowRequest | any> {
        const result = this.api.socialsGetFollowRequestRetrieve(uid, _options);
        return result.toPromise();
    }

    /**
     * Hide user from suggested follow list
     * @param followRequest 
     */
    public socialsHideSuggestedCreate(followRequest: FollowRequest, _options?: Configuration): Promise<any> {
        const result = this.api.socialsHideSuggestedCreate(followRequest, _options);
        return result.toPromise();
    }

    /**
     * @param activityId 
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public socialsList(activityId?: number, cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.socialsList(activityId, cursor, dateRangeAfter, dateRangeBefore, order, pageSize, type, _options);
        return result.toPromise();
    }

    /**
     */
    public socialsPendingFollowRequestsList(_options?: Configuration): Promise<Array<UserFollowRequest>> {
        const result = this.api.socialsPendingFollowRequestsList(_options);
        return result.toPromise();
    }

    /**
     * @param followRequest 
     */
    public socialsRemoveFromFollowersCreate(followRequest: FollowRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.socialsRemoveFromFollowersCreate(followRequest, _options);
        return result.toPromise();
    }

    /**
     * Get followers and following and requests of user_uid
     * @param userUid 
     */
    public socialsSocialInfoRetrieve(userUid: string, _options?: Configuration): Promise<FollowingFollowers> {
        const result = this.api.socialsSocialInfoRetrieve(userUid, _options);
        return result.toPromise();
    }

    /**
     * @param all 
     */
    public socialsSuggestedFollowRetrieve(all?: boolean, _options?: Configuration): Promise<Suggested> {
        const result = this.api.socialsSuggestedFollowRetrieve(all, _options);
        return result.toPromise();
    }

    /**
     * unfollow user
     * @param followRequest 
     */
    public socialsUnfollowCreate(followRequest: FollowRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.socialsUnfollowCreate(followRequest, _options);
        return result.toPromise();
    }


}



import { ObservableSportfengurApi } from './ObservableAPI';

import { SportfengurApiRequestFactory, SportfengurApiResponseProcessor} from "../apis/SportfengurApi";
export class PromiseSportfengurApi {
    private api: ObservableSportfengurApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SportfengurApiRequestFactory,
        responseProcessor?: SportfengurApiResponseProcessor
    ) {
        this.api = new ObservableSportfengurApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * if not following, return 404 else return
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurEventFollowInfoRetrieve(motsnumer: string, _options?: Configuration): Promise<FollowEventFlokkur> {
        const result = this.api.sportfengurEventFollowInfoRetrieve(motsnumer, _options);
        return result.toPromise();
    }

    /**
     * @param sportFengurEventFollowRequest 
     */
    public sportfengurFollowEventCreate(sportFengurEventFollowRequest: SportFengurEventFollowRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.sportfengurFollowEventCreate(sportFengurEventFollowRequest, _options);
        return result.toPromise();
    }

    /**
     *  flokkar_numer in category flokkar_numer is unique to flokkur/g if None then follow whole event.
     * @param followEventFlokkurRequest 
     */
    public sportfengurFollowEventFlokkurCreate(followEventFlokkurRequest: FollowEventFlokkurRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.sportfengurFollowEventFlokkurCreate(followEventFlokkurRequest, _options);
        return result.toPromise();
    }

    /**
     * @param onlyFuture Only fetch future events
     * @param year Only fetch future events
     */
    public sportfengurFollowedEventsList(onlyFuture?: boolean, year?: number, _options?: Configuration): Promise<Array<SportFengurEvent>> {
        const result = this.api.sportfengurFollowedEventsList(onlyFuture, year, _options);
        return result.toPromise();
    }

    /**
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurGetFollowersForEventList(motsnumer: string, _options?: Configuration): Promise<Array<SimpleUser>> {
        const result = this.api.sportfengurGetFollowersForEventList(motsnumer, _options);
        return result.toPromise();
    }

    /**
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurRetrieve(motsnumer: string, _options?: Configuration): Promise<SportFengurEvent> {
        const result = this.api.sportfengurRetrieve(motsnumer, _options);
        return result.toPromise();
    }

    /**
     * @param faedingarnumers 
     * @param n 
     * @param order 
     */
    public sportfengurTopHorsesList(faedingarnumers?: string, n?: number, order?: string, _options?: Configuration): Promise<Array<Horse>> {
        const result = this.api.sportfengurTopHorsesList(faedingarnumers, n, order, _options);
        return result.toPromise();
    }

    /**
     * number is flokkar numer if number is None, then unfollow whole event
     * @param motsnumer A unique value identifying this sport fengur event.
     * @param numberRequest 
     */
    public sportfengurUnfollowEventCreate(motsnumer: string, numberRequest?: NumberRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.sportfengurUnfollowEventCreate(motsnumer, numberRequest, _options);
        return result.toPromise();
    }


}



import { ObservableSportfengurWebhooksApi } from './ObservableAPI';

import { SportfengurWebhooksApiRequestFactory, SportfengurWebhooksApiResponseProcessor} from "../apis/SportfengurWebhooksApi";
export class PromiseSportfengurWebhooksApi {
    private api: ObservableSportfengurWebhooksApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SportfengurWebhooksApiRequestFactory,
        responseProcessor?: SportfengurWebhooksApiResponseProcessor
    ) {
        this.api = new ObservableSportfengurWebhooksApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * When an event gets new results
     * @param webhookDomarRequest 
     */
    public sportfengurWebhooksDomarCreate(webhookDomarRequest: WebhookDomarRequest, _options?: Configuration): Promise<string> {
        const result = this.api.sportfengurWebhooksDomarCreate(webhookDomarRequest, _options);
        return result.toPromise();
    }

    /**
     * When an event details is updated or when an event is created
     * @param webhookEventRequest 
     */
    public sportfengurWebhooksEventCreate(webhookEventRequest: WebhookEventRequest, _options?: Configuration): Promise<string> {
        const result = this.api.sportfengurWebhooksEventCreate(webhookEventRequest, _options);
        return result.toPromise();
    }

    /**
     * When event participants have been published
     * @param webhookEventParticipantRequest 
     */
    public sportfengurWebhooksParticipantsCreate(webhookEventParticipantRequest: WebhookEventParticipantRequest, _options?: Configuration): Promise<string> {
        const result = this.api.sportfengurWebhooksParticipantsCreate(webhookEventParticipantRequest, _options);
        return result.toPromise();
    }

    /**
     * When the event program have been published
     * @param webhookEventProgramRequest 
     */
    public sportfengurWebhooksProgramCreate(webhookEventProgramRequest: WebhookEventProgramRequest, _options?: Configuration): Promise<string> {
        const result = this.api.sportfengurWebhooksProgramCreate(webhookEventProgramRequest, _options);
        return result.toPromise();
    }

    /**
     * When the startinglinup of and event has been published
     * @param webhookRaslistiRequest 
     */
    public sportfengurWebhooksRaslistiCreate(webhookRaslistiRequest: WebhookRaslistiRequest, _options?: Configuration): Promise<string> {
        const result = this.api.sportfengurWebhooksRaslistiCreate(webhookRaslistiRequest, _options);
        return result.toPromise();
    }


}



import { ObservableStripeApi } from './ObservableAPI';

import { StripeApiRequestFactory, StripeApiResponseProcessor} from "../apis/StripeApi";
export class PromiseStripeApi {
    private api: ObservableStripeApi

    public constructor(
        configuration: Configuration,
        requestFactory?: StripeApiRequestFactory,
        responseProcessor?: StripeApiResponseProcessor
    ) {
        this.api = new ObservableStripeApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Two scenarios - buying for self - buying a giftcard
     * @param stripeCheckoutSessionCreateRequest 
     */
    public stripeCheckoutSessionCreate(stripeCheckoutSessionCreateRequest: StripeCheckoutSessionCreateRequest, _options?: Configuration): Promise<StripeCheckoutSession> {
        const result = this.api.stripeCheckoutSessionCreate(stripeCheckoutSessionCreateRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public stripeGetRevenuecatSubscriptionRetrieve(_options?: Configuration): Promise<RevenucatSubscription> {
        const result = this.api.stripeGetRevenuecatSubscriptionRetrieve(_options);
        return result.toPromise();
    }

    /**
     */
    public stripeMyBoughtGiftcardsList(_options?: Configuration): Promise<Array<StripeCoupon>> {
        const result = this.api.stripeMyBoughtGiftcardsList(_options);
        return result.toPromise();
    }

    /**
     * @param currency 
     */
    public stripePricesAndProductsRetrieve(currency?: string, _options?: Configuration): Promise<StripePricesAndProducts> {
        const result = this.api.stripePricesAndProductsRetrieve(currency, _options);
        return result.toPromise();
    }

    /**
     */
    public stripePricesRetrieve(_options?: Configuration): Promise<StripePrice> {
        const result = this.api.stripePricesRetrieve(_options);
        return result.toPromise();
    }

    /**
     */
    public stripeProductsRetrieve(_options?: Configuration): Promise<StripeProduct> {
        const result = this.api.stripeProductsRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param stripeCheckoutSessionCreateRequest 
     */
    public stripeRedeemGiftcardCreate(stripeCheckoutSessionCreateRequest: StripeCheckoutSessionCreateRequest, _options?: Configuration): Promise<StripeCheckoutSession> {
        const result = this.api.stripeRedeemGiftcardCreate(stripeCheckoutSessionCreateRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public stripeSentToMeGiftcardsList(_options?: Configuration): Promise<Array<StripeCoupon>> {
        const result = this.api.stripeSentToMeGiftcardsList(_options);
        return result.toPromise();
    }

    /**
     */
    public stripeUnsubscribeCreate(_options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.stripeUnsubscribeCreate(_options);
        return result.toPromise();
    }


}



import { ObservableSurveyApi } from './ObservableAPI';

import { SurveyApiRequestFactory, SurveyApiResponseProcessor} from "../apis/SurveyApi";
export class PromiseSurveyApi {
    private api: ObservableSurveyApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SurveyApiRequestFactory,
        responseProcessor?: SurveyApiResponseProcessor
    ) {
        this.api = new ObservableSurveyApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param id A unique integer value identifying this user survey.
     * @param answerUserSurveyRequest 
     * @param surveyFinished if last question
     */
    public surveyAnswerSurveyCreate(id: number, answerUserSurveyRequest: AnswerUserSurveyRequest, surveyFinished?: boolean, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.surveyAnswerSurveyCreate(id, answerUserSurveyRequest, surveyFinished, _options);
        return result.toPromise();
    }

    /**
     */
    public surveyUnansweredSurveyRetrieve(_options?: Configuration): Promise<PartialUserSurvey> {
        const result = this.api.surveyUnansweredSurveyRetrieve(_options);
        return result.toPromise();
    }


}



import { ObservableTracksApi } from './ObservableAPI';

import { TracksApiRequestFactory, TracksApiResponseProcessor} from "../apis/TracksApi";
export class PromiseTracksApi {
    private api: ObservableTracksApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TracksApiRequestFactory,
        responseProcessor?: TracksApiResponseProcessor
    ) {
        this.api = new ObservableTracksApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param userUid 
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public tracksList(userUid: string, cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_performed' | 'dt_performed'>, pageSize?: number, _options?: Configuration): Promise<PaginatedHorseActivityList> {
        const result = this.api.tracksList(userUid, cursor, dateRangeAfter, dateRangeBefore, order, pageSize, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public tracksTrackingPointsList(id: number, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.tracksTrackingPointsList(id, _options);
        return result.toPromise();
    }


}



import { ObservableTrainingSessionsApi } from './ObservableAPI';

import { TrainingSessionsApiRequestFactory, TrainingSessionsApiResponseProcessor} from "../apis/TrainingSessionsApi";
export class PromiseTrainingSessionsApi {
    private api: ObservableTrainingSessionsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainingSessionsApiRequestFactory,
        responseProcessor?: TrainingSessionsApiResponseProcessor
    ) {
        this.api = new ObservableTrainingSessionsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trackingPointCorrectionRequest 
     */
    public trainingSessionsCorrectGaitCreate(activity: number, trackingPointCorrectionRequest: TrackingPointCorrectionRequest, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.trainingSessionsCorrectGaitCreate(activity, trackingPointCorrectionRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitRatingRequest 
     */
    public trainingSessionsGaitRatingCreate(activity: number, gaitRatingRequest?: GaitRatingRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.trainingSessionsGaitRatingCreate(activity, gaitRatingRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitSensorRequest 
     */
    public trainingSessionsGaitSensorsCreate(activity: number, gaitSensorRequest: Array<GaitSensorRequest>, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.trainingSessionsGaitSensorsCreate(activity, gaitSensorRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsGetGaitRatingRetrieve(activity: number, _options?: Configuration): Promise<GaitRating> {
        const result = this.api.trainingSessionsGetGaitRatingRetrieve(activity, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsGetTrackingPointsList(activity: number, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.trainingSessionsGetTrackingPointsList(activity, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activityHorse 
     * @param isIndoors 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public trainingSessionsList(activityHorse?: string, isIndoors?: boolean, page?: number, pageSize?: number, _options?: Configuration): Promise<PaginatedTrainingSessionList> {
        const result = this.api.trainingSessionsList(activityHorse, isIndoors, page, pageSize, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param patchedTrainingSessionRequest 
     */
    public trainingSessionsPartialUpdate(activity: number, patchedTrainingSessionRequest?: PatchedTrainingSessionRequest, _options?: Configuration): Promise<TrainingSession> {
        const result = this.api.trainingSessionsPartialUpdate(activity, patchedTrainingSessionRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsRetrieve(activity: number, _options?: Configuration): Promise<TrainingSession> {
        const result = this.api.trainingSessionsRetrieve(activity, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionTrackingPointRequest 
     * @param requestId 
     * @param totalNumberOfPoints 
     */
    public trainingSessionsSaveCurrentTrackingWithTaskCreate(activity: number, trainingSessionTrackingPointRequest: Array<TrainingSessionTrackingPointRequest>, requestId?: string, totalNumberOfPoints?: number, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.trainingSessionsSaveCurrentTrackingWithTaskCreate(activity, trainingSessionTrackingPointRequest, requestId, totalNumberOfPoints, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param startEndRequest 
     */
    public trainingSessionsSetStartEndOfTrackingCreate(activity: number, startEndRequest: StartEndRequest, _options?: Configuration): Promise<HorseActivity> {
        const result = this.api.trainingSessionsSetStartEndOfTrackingCreate(activity, startEndRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param faedingarnumerSerilizerRequest 
     */
    public trainingSessionsStartTrackingCreate(faedingarnumerSerilizerRequest: FaedingarnumerSerilizerRequest, _options?: Configuration): Promise<HorseActivity> {
        const result = this.api.trainingSessionsStartTrackingCreate(faedingarnumerSerilizerRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param simpleBooleanRequest 
     */
    public trainingSessionsToggleSafetyTrackingCreate(simpleBooleanRequest: SimpleBooleanRequest, _options?: Configuration): Promise<SimpleMessage> {
        const result = this.api.trainingSessionsToggleSafetyTrackingCreate(simpleBooleanRequest, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param activityHorse 
     * @param isIndoors 
     */
    public trainingSessionsTrackingList(activity: number, activityHorse?: string, isIndoors?: boolean, _options?: Configuration): Promise<Array<TrainingSessionTrackingPoint>> {
        const result = this.api.trainingSessionsTrackingList(activity, activityHorse, isIndoors, _options);
        return result.toPromise();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionRequest 
     */
    public trainingSessionsUpdate(activity: number, trainingSessionRequest?: TrainingSessionRequest, _options?: Configuration): Promise<TrainingSession> {
        const result = this.api.trainingSessionsUpdate(activity, trainingSessionRequest, _options);
        return result.toPromise();
    }


}



import { ObservableUserApi } from './ObservableAPI';

import { UserApiRequestFactory, UserApiResponseProcessor} from "../apis/UserApi";
export class PromiseUserApi {
    private api: ObservableUserApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UserApiRequestFactory,
        responseProcessor?: UserApiResponseProcessor
    ) {
        this.api = new ObservableUserApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public userDestroy(_options?: Configuration): Promise<void> {
        const result = this.api.userDestroy(_options);
        return result.toPromise();
    }

    /**
     * GET or POST the locale of the logged in user --- response: - locale: 'is', 'en' or 'de'
     */
    public userDisconnectWfPartialUpdate(_options?: Configuration): Promise<User> {
        const result = this.api.userDisconnectWfPartialUpdate(_options);
        return result.toPromise();
    }

    /**
     */
    public userMostLikelyStablePositionRetrieve(_options?: Configuration): Promise<Point> {
        const result = this.api.userMostLikelyStablePositionRetrieve(_options);
        return result.toPromise();
    }

    /**
     * @param patchedUserUpdateRequest 
     */
    public userPartialUpdate(patchedUserUpdateRequest?: PatchedUserUpdateRequest, _options?: Configuration): Promise<User> {
        const result = this.api.userPartialUpdate(patchedUserUpdateRequest, _options);
        return result.toPromise();
    }

    /**
     */
    public userRetrieve(_options?: Configuration): Promise<User | void> {
        const result = this.api.userRetrieve(_options);
        return result.toPromise();
    }


}



import { ObservableUserActivityReactionsApi } from './ObservableAPI';

import { UserActivityReactionsApiRequestFactory, UserActivityReactionsApiResponseProcessor} from "../apis/UserActivityReactionsApi";
export class PromiseUserActivityReactionsApi {
    private api: ObservableUserActivityReactionsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UserActivityReactionsApiRequestFactory,
        responseProcessor?: UserActivityReactionsApiResponseProcessor
    ) {
        this.api = new ObservableUserActivityReactionsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param activityReactionRequest 
     */
    public userActivityReactionsCreate(activityReactionRequest: ActivityReactionRequest, _options?: Configuration): Promise<ActivityReaction> {
        const result = this.api.userActivityReactionsCreate(activityReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this reaction.
     */
    public userActivityReactionsDestroy(id: number, _options?: Configuration): Promise<void> {
        const result = this.api.userActivityReactionsDestroy(id, _options);
        return result.toPromise();
    }

    /**
     * @param activity 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param user 
     */
    public userActivityReactionsList(activity?: number, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, user?: number, _options?: Configuration): Promise<PaginatedActivityReactionList> {
        const result = this.api.userActivityReactionsList(activity, order, page, user, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this reaction.
     * @param patchedActivityReactionRequest 
     */
    public userActivityReactionsPartialUpdate(id: number, patchedActivityReactionRequest?: PatchedActivityReactionRequest, _options?: Configuration): Promise<ActivityReaction> {
        const result = this.api.userActivityReactionsPartialUpdate(id, patchedActivityReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this reaction.
     */
    public userActivityReactionsRetrieve(id: number, _options?: Configuration): Promise<ActivityReaction> {
        const result = this.api.userActivityReactionsRetrieve(id, _options);
        return result.toPromise();
    }

    /**
     * @param id A unique integer value identifying this reaction.
     * @param activityReactionRequest 
     */
    public userActivityReactionsUpdate(id: number, activityReactionRequest: ActivityReactionRequest, _options?: Configuration): Promise<ActivityReaction> {
        const result = this.api.userActivityReactionsUpdate(id, activityReactionRequest, _options);
        return result.toPromise();
    }


}



import { ObservableUserCommentReactionsApi } from './ObservableAPI';

import { UserCommentReactionsApiRequestFactory, UserCommentReactionsApiResponseProcessor} from "../apis/UserCommentReactionsApi";
export class PromiseUserCommentReactionsApi {
    private api: ObservableUserCommentReactionsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: UserCommentReactionsApiRequestFactory,
        responseProcessor?: UserCommentReactionsApiResponseProcessor
    ) {
        this.api = new ObservableUserCommentReactionsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param commentReactionRequest 
     */
    public userCommentReactionsCreate(commentReactionRequest: CommentReactionRequest, _options?: Configuration): Promise<CommentReaction> {
        const result = this.api.userCommentReactionsCreate(commentReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param comment 
     */
    public userCommentReactionsDestroy(comment: number, _options?: Configuration): Promise<void> {
        const result = this.api.userCommentReactionsDestroy(comment, _options);
        return result.toPromise();
    }

    /**
     * @param page A page number within the paginated result set.
     */
    public userCommentReactionsList(page?: number, _options?: Configuration): Promise<PaginatedCommentReactionList> {
        const result = this.api.userCommentReactionsList(page, _options);
        return result.toPromise();
    }

    /**
     * @param comment 
     * @param patchedCommentReactionRequest 
     */
    public userCommentReactionsPartialUpdate(comment: number, patchedCommentReactionRequest?: PatchedCommentReactionRequest, _options?: Configuration): Promise<CommentReaction> {
        const result = this.api.userCommentReactionsPartialUpdate(comment, patchedCommentReactionRequest, _options);
        return result.toPromise();
    }

    /**
     * @param comment 
     */
    public userCommentReactionsRetrieve(comment: number, _options?: Configuration): Promise<CommentReaction> {
        const result = this.api.userCommentReactionsRetrieve(comment, _options);
        return result.toPromise();
    }

    /**
     * @param comment 
     * @param commentReactionRequest 
     */
    public userCommentReactionsUpdate(comment: number, commentReactionRequest: CommentReactionRequest, _options?: Configuration): Promise<CommentReaction> {
        const result = this.api.userCommentReactionsUpdate(comment, commentReactionRequest, _options);
        return result.toPromise();
    }


}



import { ObservableWebhookApi } from './ObservableAPI';

import { WebhookApiRequestFactory, WebhookApiResponseProcessor} from "../apis/WebhookApi";
export class PromiseWebhookApi {
    private api: ObservableWebhookApi

    public constructor(
        configuration: Configuration,
        requestFactory?: WebhookApiRequestFactory,
        responseProcessor?: WebhookApiResponseProcessor
    ) {
        this.api = new ObservableWebhookApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public webhookRevenuecatCreate(_options?: Configuration): Promise<void> {
        const result = this.api.webhookRevenuecatCreate(_options);
        return result.toPromise();
    }

    /**
     */
    public webhookStripeCreate(_options?: Configuration): Promise<void> {
        const result = this.api.webhookStripeCreate(_options);
        return result.toPromise();
    }


}



import { ObservableWfApi } from './ObservableAPI';

import { WfApiRequestFactory, WfApiResponseProcessor} from "../apis/WfApi";
export class PromiseWfApi {
    private api: ObservableWfApi

    public constructor(
        configuration: Configuration,
        requestFactory?: WfApiRequestFactory,
        responseProcessor?: WfApiResponseProcessor
    ) {
        this.api = new ObservableWfApi(configuration, requestFactory, responseProcessor);
    }

    /**
     */
    public wfLandsmotYfirlitList(_options?: Configuration): Promise<Array<LandsmotJudgeOverview>> {
        const result = this.api.wfLandsmotYfirlitList(_options);
        return result.toPromise();
    }


}



import { ObservableWorldfengurWebhooksApi } from './ObservableAPI';

import { WorldfengurWebhooksApiRequestFactory, WorldfengurWebhooksApiResponseProcessor} from "../apis/WorldfengurWebhooksApi";
export class PromiseWorldfengurWebhooksApi {
    private api: ObservableWorldfengurWebhooksApi

    public constructor(
        configuration: Configuration,
        requestFactory?: WorldfengurWebhooksApiRequestFactory,
        responseProcessor?: WorldfengurWebhooksApiResponseProcessor
    ) {
        this.api = new ObservableWorldfengurWebhooksApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param wFWebookHorseRemoveRequest 
     */
    public worldfengurWebhooksHorseRemoveCreate(wFWebookHorseRemoveRequest: WFWebookHorseRemoveRequest, _options?: Configuration): Promise<Array<SimpleMessage>> {
        const result = this.api.worldfengurWebhooksHorseRemoveCreate(wFWebookHorseRemoveRequest, _options);
        return result.toPromise();
    }

    /**
     * @param wFWebookHorseUpdateRequest 
     */
    public worldfengurWebhooksHorseUpdateCreate(wFWebookHorseUpdateRequest: WFWebookHorseUpdateRequest, _options?: Configuration): Promise<Array<SimpleMessage>> {
        const result = this.api.worldfengurWebhooksHorseUpdateCreate(wFWebookHorseUpdateRequest, _options);
        return result.toPromise();
    }

    /**
     * @param wFWebookNewJudgementRequest 
     */
    public worldfengurWebhooksJudgementCreate(wFWebookNewJudgementRequest: WFWebookNewJudgementRequest, _options?: Configuration): Promise<Array<SimpleMessage>> {
        const result = this.api.worldfengurWebhooksJudgementCreate(wFWebookNewJudgementRequest, _options);
        return result.toPromise();
    }

    /**
     * @param wFWebookOffspringUpdateRequest 
     */
    public worldfengurWebhooksOffspringUpdateCreate(wFWebookOffspringUpdateRequest: WFWebookOffspringUpdateRequest, _options?: Configuration): Promise<Array<SimpleMessage>> {
        const result = this.api.worldfengurWebhooksOffspringUpdateCreate(wFWebookOffspringUpdateRequest, _options);
        return result.toPromise();
    }


}



