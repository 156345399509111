/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class HorsePhoto {
    'id': number;
    'dtCreated': Date;
    'dtModified': Date;
    'horse': string;
    'name'?: string;
    'image'?: URI;
    'thumbnail': URI;
    'isHidden'?: boolean;
    'inProfile'?: boolean;
    'profileOrder'?: number;
    'user'?: SimpleUser;
    'isSalePhoto'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "isHidden",
            "baseName": "isHidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "inProfile",
            "baseName": "inProfile",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "profileOrder",
            "baseName": "profileOrder",
            "type": "number",
            "format": ""
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "isSalePhoto",
            "baseName": "isSalePhoto",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorsePhoto.attributeTypeMap;
    }

    public constructor() {
    }
}

