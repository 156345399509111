/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class FollowingFollowers {
    'following': Array<SimpleUser>;
    'followers': Array<SimpleUser>;
    'requests': Array<SimpleUser>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "following",
            "baseName": "following",
            "type": "Array<SimpleUser>",
            "format": ""
        },
        {
            "name": "followers",
            "baseName": "followers",
            "type": "Array<SimpleUser>",
            "format": ""
        },
        {
            "name": "requests",
            "baseName": "requests",
            "type": "Array<SimpleUser>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return FollowingFollowers.attributeTypeMap;
    }

    public constructor() {
    }
}

