import { accountsApi } from "./NetworkUtils";
import { captureException } from "./devUtils";
import { setSavedLocale } from "./sharedPrefrences";

export type Locales = "is" | "en" | "de";
let locale: Locales = "en";

interface ITranslationObj {
  is: {} | undefined;
  en: {} | undefined;
  de: {} | undefined;
}

const translationFiles: ITranslationObj = {
  is: undefined,
  de: undefined,
  en: undefined,
};

export const setLocale = async (_locale: Locales) => {
  locale = _locale;
  window.localStorage.setItem("locale", _locale);
  await loadTranslations(locale);
  try {
    var req = { locale: _locale };
    await accountsApi.accountsUserSetLocaleCreate(req);
  } catch (e) {
    //
  }
};

export const getLocale = (): Locales => {
  locale = (window.localStorage.getItem("locale") as Locales) ?? "en";
  return locale;
};

// if no translation in lang, try in english
export const t = (key: string, _locale?: Locales): string => {
  let data: any | undefined = new Map();
  if (translationFiles[_locale ?? locale]) {
    data = translationFiles[_locale ?? locale];
  } else {
    loadTranslations(_locale);
    return key;
  }

  let translated: string | string[] =
    data !== null && data[key] ? data[key] ?? key : key;
  if (translated === key && locale in translationFiles) {
    data = translationFiles[locale];
    if (data && key in data && data[key]) {
      return data[key];
    }
  }
  if (Array.isArray(translated)) {
    if (translated[0].length === 0 && _locale !== "en") {
      return t(key, "en");
    }
    return translated[0].length === 0 ? key : translated[0];
  }
  if (!translated) {
    return key;
  }
  if (typeof translated === "string" && translated.trim().length === 0) {
    return key;
  }
  return typeof translated === "string" ? translated : key;
};

const _isLoadingTrans = {
  is: false,
  en: false,
  de: false,
};
export const loadTranslations = async (userLocale?: Locales) => {
  if (!userLocale) {
    var _savedLocel = window.localStorage.getItem("locale");

    if (_savedLocel) {
      locale = _savedLocel as Locales;
    } else {
      const lang = userLocale ?? navigator.language;
      if (lang.substring(0, 2) === "de" || lang === "is") {
        locale = lang.substring(0, 2) as Locales;
        setSavedLocale(locale);
      } else {
        locale = "en";
        setSavedLocale(locale);
      }
    }
  } else {
    locale = userLocale;
  }
  if (_isLoadingTrans[locale]) {
    return;
  }
  _isLoadingTrans[locale] = true;
  // if already loaded
  if (translationFiles[locale]) {
    _isLoadingTrans[locale] = false;
    return;
  }

  try {
    // var res = await fetch("/translation/" + locale + "/" + locale + ".json");
    // var res = await fetch("/translation/trans_" + locale + ".json");
    var res = await fetch("/translation/" + locale + "/" + locale + ".json");
    let data = await res.json();
    translationFiles[locale] = data["locale_data"]["messages"];
  } catch (e) {
    captureException(e);
  }
  _isLoadingTrans[locale] = false;
};

export const loadEnTranslations = async (): Promise<String> => {
  if (_isLoadingTrans["en"]) {
    return Promise.resolve("already loading en");
  }
  _isLoadingTrans["en"] = true;
  // if already loaded
  if (translationFiles["en"]) {
    _isLoadingTrans["en"] = false;
    return Promise.resolve("Already loaded en");
  }

  try {
    // var res = await fetch("/translation/" + locale + "/" + locale + ".json");
    // var res = await fetch("/translation/trans_" + locale + ".json");
    var res = await fetch("/translation/en/en.json");
    let data = await res.json();
    translationFiles[locale] = data["locale_data"]["messages"];
    _isLoadingTrans[locale] = false;
    return Promise.resolve("success");
  } catch (e) {
    captureException(e);
    _isLoadingTrans[locale] = false;
    return Promise.resolve("not success");
  }
};
