/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupTypeEnum } from '../models/GroupTypeEnum';
import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class SimpleGroup {
    'name'?: string;
    'id': number;
    'uid': string;
    'groupType': GroupTypeEnum;
    'thumbnail'?: URI;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "uid",
            "baseName": "uid",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "groupType",
            "baseName": "groupType",
            "type": "GroupTypeEnum",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "URI",
            "format": "uri"
        }    ];

    static getAttributeTypeMap() {
        return SimpleGroup.attributeTypeMap;
    }

    public constructor() {
    }
}



