/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleHorseRequest } from '../models/SimpleHorseRequest';
import { TrainingSummaryRequest } from '../models/TrainingSummaryRequest';
import { WeatherRequest } from '../models/WeatherRequest';
import { HttpFile } from '../http/http';

export class PatchedTrainingSessionRequest {
    'intensity'?: number;
    'satisfaction'?: number;
    'trainingSummary'?: TrainingSummaryRequest;
    'locationString'?: string;
    'dataIncorrect'?: boolean;
    'weather'?: WeatherRequest;
    'taggedHorses'?: Array<SimpleHorseRequest>;
    'bootsWeight'?: number;
    'startIndex'?: number;
    'endIndex'?: number;
    'hasProcessed'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "intensity",
            "baseName": "intensity",
            "type": "number",
            "format": ""
        },
        {
            "name": "satisfaction",
            "baseName": "satisfaction",
            "type": "number",
            "format": ""
        },
        {
            "name": "trainingSummary",
            "baseName": "trainingSummary",
            "type": "TrainingSummaryRequest",
            "format": ""
        },
        {
            "name": "locationString",
            "baseName": "locationString",
            "type": "string",
            "format": ""
        },
        {
            "name": "dataIncorrect",
            "baseName": "dataIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "weather",
            "baseName": "weather",
            "type": "WeatherRequest",
            "format": ""
        },
        {
            "name": "taggedHorses",
            "baseName": "taggedHorses",
            "type": "Array<SimpleHorseRequest>",
            "format": ""
        },
        {
            "name": "bootsWeight",
            "baseName": "bootsWeight",
            "type": "number",
            "format": ""
        },
        {
            "name": "startIndex",
            "baseName": "startIndex",
            "type": "number",
            "format": ""
        },
        {
            "name": "endIndex",
            "baseName": "endIndex",
            "type": "number",
            "format": ""
        },
        {
            "name": "hasProcessed",
            "baseName": "hasProcessed",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedTrainingSessionRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

