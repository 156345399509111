/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseAssessment } from '../models/HorseAssessment';
import { HttpFile } from '../http/http';

export class DetailedAssessmentOverview {
    'horse'?: string;
    'rider'?: string;
    'trainer'?: string;
    'competition'?: string;
    'date'?: string;
    'icelandFizoFeif'?: string;
    'mainJudge'?: string;
    'judges'?: string;
    'm1'?: number;
    'm2'?: number;
    'm3'?: number;
    'm4'?: number;
    'm5'?: number;
    'm6'?: number;
    'm7'?: number;
    'm8'?: number;
    'm9'?: number;
    'm10'?: number;
    'm11'?: number;
    'vfr'?: number;
    'hfr'?: number;
    'va'?: number;
    'ha'?: number;
    'framhofur'?: number;
    'afturhofur'?: number;
    'assessment': HorseAssessment;
    'hofudText'?: string;
    'halsHerdarBogarText'?: string;
    'bakLendText'?: string;
    'samraemiText'?: string;
    'fotagerdText'?: string;
    'rettleikiText'?: string;
    'hofarText'?: string;
    'prudleikiText'?: string;
    'skopulagText'?: string;
    'toltText'?: string;
    'brokkText'?: string;
    'skeidText'?: string;
    'stokkText'?: string;
    'greittStokkText'?: string;
    'haegtStokkText'?: string;
    'samstarfsviljiText'?: string;
    'fegurdIReidText'?: string;
    'fetText'?: string;
    'haefileikarText'?: string;
    'haegtToltText'?: string;
    'adaleinkunnText'?: string;
    'haefileikarAnSkeidsText'?: string;
    'adaleinkunnAnSkeidsText'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "horse",
            "baseName": "horse",
            "type": "string",
            "format": ""
        },
        {
            "name": "rider",
            "baseName": "rider",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainer",
            "baseName": "trainer",
            "type": "string",
            "format": ""
        },
        {
            "name": "competition",
            "baseName": "competition",
            "type": "string",
            "format": ""
        },
        {
            "name": "date",
            "baseName": "date",
            "type": "string",
            "format": ""
        },
        {
            "name": "icelandFizoFeif",
            "baseName": "icelandFizoFeif",
            "type": "string",
            "format": ""
        },
        {
            "name": "mainJudge",
            "baseName": "mainJudge",
            "type": "string",
            "format": ""
        },
        {
            "name": "judges",
            "baseName": "judges",
            "type": "string",
            "format": ""
        },
        {
            "name": "m1",
            "baseName": "M1",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m2",
            "baseName": "M2",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m3",
            "baseName": "M3",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m4",
            "baseName": "M4",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m5",
            "baseName": "M5",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m6",
            "baseName": "M6",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m7",
            "baseName": "M7",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m8",
            "baseName": "M8",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m9",
            "baseName": "M9",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m10",
            "baseName": "M10",
            "type": "number",
            "format": "double"
        },
        {
            "name": "m11",
            "baseName": "M11",
            "type": "number",
            "format": "double"
        },
        {
            "name": "vfr",
            "baseName": "Vfr",
            "type": "number",
            "format": "double"
        },
        {
            "name": "hfr",
            "baseName": "Hfr",
            "type": "number",
            "format": "double"
        },
        {
            "name": "va",
            "baseName": "Va",
            "type": "number",
            "format": "double"
        },
        {
            "name": "ha",
            "baseName": "Ha",
            "type": "number",
            "format": "double"
        },
        {
            "name": "framhofur",
            "baseName": "framhofur",
            "type": "number",
            "format": "double"
        },
        {
            "name": "afturhofur",
            "baseName": "afturhofur",
            "type": "number",
            "format": "double"
        },
        {
            "name": "assessment",
            "baseName": "assessment",
            "type": "HorseAssessment",
            "format": ""
        },
        {
            "name": "hofudText",
            "baseName": "hofudText",
            "type": "string",
            "format": ""
        },
        {
            "name": "halsHerdarBogarText",
            "baseName": "halsHerdarBogarText",
            "type": "string",
            "format": ""
        },
        {
            "name": "bakLendText",
            "baseName": "bakLendText",
            "type": "string",
            "format": ""
        },
        {
            "name": "samraemiText",
            "baseName": "samraemiText",
            "type": "string",
            "format": ""
        },
        {
            "name": "fotagerdText",
            "baseName": "fotagerdText",
            "type": "string",
            "format": ""
        },
        {
            "name": "rettleikiText",
            "baseName": "rettleikiText",
            "type": "string",
            "format": ""
        },
        {
            "name": "hofarText",
            "baseName": "hofarText",
            "type": "string",
            "format": ""
        },
        {
            "name": "prudleikiText",
            "baseName": "prudleikiText",
            "type": "string",
            "format": ""
        },
        {
            "name": "skopulagText",
            "baseName": "skopulagText",
            "type": "string",
            "format": ""
        },
        {
            "name": "toltText",
            "baseName": "toltText",
            "type": "string",
            "format": ""
        },
        {
            "name": "brokkText",
            "baseName": "brokkText",
            "type": "string",
            "format": ""
        },
        {
            "name": "skeidText",
            "baseName": "skeidText",
            "type": "string",
            "format": ""
        },
        {
            "name": "stokkText",
            "baseName": "stokkText",
            "type": "string",
            "format": ""
        },
        {
            "name": "greittStokkText",
            "baseName": "greittStokkText",
            "type": "string",
            "format": ""
        },
        {
            "name": "haegtStokkText",
            "baseName": "haegtStokkText",
            "type": "string",
            "format": ""
        },
        {
            "name": "samstarfsviljiText",
            "baseName": "samstarfsviljiText",
            "type": "string",
            "format": ""
        },
        {
            "name": "fegurdIReidText",
            "baseName": "fegurdIReidText",
            "type": "string",
            "format": ""
        },
        {
            "name": "fetText",
            "baseName": "fetText",
            "type": "string",
            "format": ""
        },
        {
            "name": "haefileikarText",
            "baseName": "haefileikarText",
            "type": "string",
            "format": ""
        },
        {
            "name": "haegtToltText",
            "baseName": "haegtToltText",
            "type": "string",
            "format": ""
        },
        {
            "name": "adaleinkunnText",
            "baseName": "adaleinkunnText",
            "type": "string",
            "format": ""
        },
        {
            "name": "haefileikarAnSkeidsText",
            "baseName": "haefileikarAnSkeidsText",
            "type": "string",
            "format": ""
        },
        {
            "name": "adaleinkunnAnSkeidsText",
            "baseName": "adaleinkunnAnSkeidsText",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return DetailedAssessmentOverview.attributeTypeMap;
    }

    public constructor() {
    }
}

