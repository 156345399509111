import activityReducer from "./reducers/activityReducer";
import chatReducer from "./reducers/chatReducer";
import groupsReducer from "./reducers/groupsReducer";
import horsesReducer from "./reducers/horsesReducer";
import horsesSearchReducer from "./reducers/horsesSearchReducer";
import paymentReducer from "./reducers/paymentReducer";
import userReducer from "./reducers/userReducer";
import usersSearchReducer from "./reducers/usersSearchReducer";

const rootReducer = {
  userState: userReducer,
  horsesState: horsesReducer,
  horsesSearchState: horsesSearchReducer,
  usersSearchState: usersSearchReducer,
  activityState: activityReducer,
  chatState: chatReducer,
  groupState: groupsReducer,
  paymentState: paymentReducer,
};

export default rootReducer;
