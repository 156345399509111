/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GaitEnum } from '../models/GaitEnum';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { HttpFile } from '../http/http';

/**
* The purpose of this serializer is to compress the size of a GPS track And be faster in serializing as we will use the
*/
export class SimpleTrackingPoint {
    'location': HorseDetailsLocation;
    'datetime': Date;
    'paused'?: boolean;
    'gait'?: GaitEnum;
    'elevation'?: number;
    'speed'?: number;
    'pruned'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseDetailsLocation",
            "format": ""
        },
        {
            "name": "datetime",
            "baseName": "datetime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "paused",
            "baseName": "paused",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "gait",
            "baseName": "gait",
            "type": "GaitEnum",
            "format": ""
        },
        {
            "name": "elevation",
            "baseName": "elevation",
            "type": "number",
            "format": "double"
        },
        {
            "name": "speed",
            "baseName": "speed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "pruned",
            "baseName": "pruned",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SimpleTrackingPoint.attributeTypeMap;
    }

    public constructor() {
    }
}

