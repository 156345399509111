/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UpdateStallionGroupRequest } from '../models/UpdateStallionGroupRequest';
import { HttpFile } from '../http/http';

export class UpdateGroupRequest {
    'name'?: string;
    'description'?: string;
    'stallionGroup'?: UpdateStallionGroupRequest;
    'locationString'?: string;
    'contactInfo'?: string;
    'website'?: string;
    'country'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "stallionGroup",
            "baseName": "stallionGroup",
            "type": "UpdateStallionGroupRequest",
            "format": ""
        },
        {
            "name": "locationString",
            "baseName": "locationString",
            "type": "string",
            "format": ""
        },
        {
            "name": "contactInfo",
            "baseName": "contactInfo",
            "type": "string",
            "format": ""
        },
        {
            "name": "website",
            "baseName": "website",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return UpdateGroupRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

