/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseBreedEnum } from '../models/HorseBreedEnum';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { HttpFile } from '../http/http';

export class CreateHorseRequest {
    'name'?: string;
    'faedingarnumer': string;
    'birthDate': string;
    'sexEnum'?: SexEnumEnum;
    'originCountry'?: string;
    'horseBreed'?: HorseBreedEnum;
    'description'?: string;
    'isVerified'?: boolean;
    'colorCode'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "faedingarnumer",
            "baseName": "faedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthDate",
            "baseName": "birthDate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "sexEnum",
            "baseName": "sexEnum",
            "type": "SexEnumEnum",
            "format": ""
        },
        {
            "name": "originCountry",
            "baseName": "originCountry",
            "type": "string",
            "format": ""
        },
        {
            "name": "horseBreed",
            "baseName": "horseBreed",
            "type": "HorseBreedEnum",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "isVerified",
            "baseName": "isVerified",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "colorCode",
            "baseName": "colorCode",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CreateHorseRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



