// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { HorseActivity } from '../models/HorseActivity';
import { PaginatedHorseActivityList } from '../models/PaginatedHorseActivityList';
import { SimpleTrackingPoint } from '../models/SimpleTrackingPoint';
import { TrainingSessionTrackingPoint } from '../models/TrainingSessionTrackingPoint';

/**
 * no description
 */
export class FeedApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async feedGetSimpleTrackingPointsList(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("FeedApi", "feedGetSimpleTrackingPointsList", "id");
        }


        // Path Params
        const localVarPath = '/api/feed/{id}/get_simple_tracking_points/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async feedGetTrackingPointsList(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("FeedApi", "feedGetTrackingPointsList", "id");
        }


        // Path Params
        const localVarPath = '/api/feed/{id}/get_tracking_points/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param activities 
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public async feedList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, activityId?: number, actor?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, horse?: string, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;














        // Path Params
        const localVarPath = '/api/feed/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (activities !== undefined) {
            requestContext.setQueryParam("activities", ObjectSerializer.serialize(activities, "Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>", ""));
        }

        // Query Params
        if (activityId !== undefined) {
            requestContext.setQueryParam("activity_id", ObjectSerializer.serialize(activityId, "number", ""));
        }

        // Query Params
        if (actor !== undefined) {
            requestContext.setQueryParam("actor", ObjectSerializer.serialize(actor, "number", ""));
        }

        // Query Params
        if (cursor !== undefined) {
            requestContext.setQueryParam("cursor", ObjectSerializer.serialize(cursor, "string", ""));
        }

        // Query Params
        if (dateAfter !== undefined) {
            requestContext.setQueryParam("date_after", ObjectSerializer.serialize(dateAfter, "Date", "date-time"));
        }

        // Query Params
        if (dateBefore !== undefined) {
            requestContext.setQueryParam("date_before", ObjectSerializer.serialize(dateBefore, "Date", "date-time"));
        }

        // Query Params
        if (dateRangeAfter !== undefined) {
            requestContext.setQueryParam("date_range_after", ObjectSerializer.serialize(dateRangeAfter, "string", "date"));
        }

        // Query Params
        if (dateRangeBefore !== undefined) {
            requestContext.setQueryParam("date_range_before", ObjectSerializer.serialize(dateRangeBefore, "string", "date"));
        }

        // Query Params
        if (horse !== undefined) {
            requestContext.setQueryParam("horse", ObjectSerializer.serialize(horse, "string", ""));
        }

        // Query Params
        if (order !== undefined) {
            requestContext.setQueryParam("order", ObjectSerializer.serialize(order, "Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", ""));
        }

        // Query Params
        if (type !== undefined) {
            requestContext.setQueryParam("type", ObjectSerializer.serialize(type, "'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'", ""));
        }

        // Query Params
        if (visibility !== undefined) {
            requestContext.setQueryParam("visibility", ObjectSerializer.serialize(visibility, "'FOLLOWERS' | 'ONLY_ME' | 'TEAM'", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async feedRetrieve(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("FeedApi", "feedRetrieve", "id");
        }


        // Path Params
        const localVarPath = '/api/feed/{id}/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class FeedApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to feedGetSimpleTrackingPointsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async feedGetSimpleTrackingPointsList(response: ResponseContext): Promise<Array<SimpleTrackingPoint> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<SimpleTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleTrackingPoint>", ""
            ) as Array<SimpleTrackingPoint>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<SimpleTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleTrackingPoint>", ""
            ) as Array<SimpleTrackingPoint>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to feedGetTrackingPointsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async feedGetTrackingPointsList(response: ResponseContext): Promise<Array<TrainingSessionTrackingPoint> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to feedList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async feedList(response: ResponseContext): Promise<PaginatedHorseActivityList > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to feedRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async feedRetrieve(response: ResponseContext): Promise<HorseActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
