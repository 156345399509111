/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AssessmentOverview {
    'date'?: Date;
    'show'?: string;
    'rider'?: string;
    'adaleinkunn'?: number;
    'adaleinkunnAnSkeids'?: number;
    'domurId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "date",
            "baseName": "date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "show",
            "baseName": "show",
            "type": "string",
            "format": ""
        },
        {
            "name": "rider",
            "baseName": "rider",
            "type": "string",
            "format": ""
        },
        {
            "name": "adaleinkunn",
            "baseName": "adaleinkunn",
            "type": "number",
            "format": "double"
        },
        {
            "name": "adaleinkunnAnSkeids",
            "baseName": "adaleinkunnAnSkeids",
            "type": "number",
            "format": "double"
        },
        {
            "name": "domurId",
            "baseName": "domurId",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AssessmentOverview.attributeTypeMap;
    }

    public constructor() {
    }
}

