/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PlatformEnum } from '../models/PlatformEnum';
import { HttpFile } from '../http/http';

export class TrainingSessionFeedbackRequest {
    'gaitIncorrect'?: boolean;
    'locationIncorrect'?: boolean;
    'activityId': number;
    'description'?: string;
    'platform'?: PlatformEnum;
    'metadata'?: string;
    'deviceName'?: string;
    'distanceIncorrect'?: boolean;
    'speedIncorrect'?: boolean;
    'timeIncorrect'?: boolean;
    'appVersion'?: string;
    'toltIncorrect'?: boolean;
    'walkIncorrect'?: boolean;
    'standingIncorrect'?: boolean;
    'trotIncorrect'?: boolean;
    'gallopIncorrect'?: boolean;
    'paceIncorrect'?: boolean;
    'locationAccuracyIncorrect'?: boolean;
    'locationStoppedRecording'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "gaitIncorrect",
            "baseName": "gaitIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locationIncorrect",
            "baseName": "locationIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "activityId",
            "baseName": "activityId",
            "type": "number",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "platform",
            "baseName": "platform",
            "type": "PlatformEnum",
            "format": ""
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "string",
            "format": ""
        },
        {
            "name": "deviceName",
            "baseName": "deviceName",
            "type": "string",
            "format": ""
        },
        {
            "name": "distanceIncorrect",
            "baseName": "distanceIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "speedIncorrect",
            "baseName": "speedIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "timeIncorrect",
            "baseName": "timeIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "appVersion",
            "baseName": "appVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "toltIncorrect",
            "baseName": "toltIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "walkIncorrect",
            "baseName": "walkIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "standingIncorrect",
            "baseName": "standingIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "trotIncorrect",
            "baseName": "trotIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "gallopIncorrect",
            "baseName": "gallopIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "paceIncorrect",
            "baseName": "paceIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locationAccuracyIncorrect",
            "baseName": "locationAccuracyIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locationStoppedRecording",
            "baseName": "locationStoppedRecording",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TrainingSessionFeedbackRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

