/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class GroupDiscussionPhoto {
    'image': URI;
    'discussion': number;
    'isMain'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "image",
            "baseName": "image",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "discussion",
            "baseName": "discussion",
            "type": "number",
            "format": ""
        },
        {
            "name": "isMain",
            "baseName": "isMain",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GroupDiscussionPhoto.attributeTypeMap;
    }

    public constructor() {
    }
}

