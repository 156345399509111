/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class GaitPredictions {
    'standing'?: number;
    'walk'?: number;
    'tolt'?: number;
    'trot'?: number;
    'gallop'?: number;
    'pace'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "standing",
            "baseName": "standing",
            "type": "number",
            "format": "double"
        },
        {
            "name": "walk",
            "baseName": "walk",
            "type": "number",
            "format": "double"
        },
        {
            "name": "tolt",
            "baseName": "tolt",
            "type": "number",
            "format": "double"
        },
        {
            "name": "trot",
            "baseName": "trot",
            "type": "number",
            "format": "double"
        },
        {
            "name": "gallop",
            "baseName": "gallop",
            "type": "number",
            "format": "double"
        },
        {
            "name": "pace",
            "baseName": "pace",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return GaitPredictions.attributeTypeMap;
    }

    public constructor() {
    }
}

