/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EntitlementEnum } from '../models/EntitlementEnum';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleUser } from '../models/SimpleUser';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { URI } from '../models/URI';
import { UserSettings } from '../models/UserSettings';
import { HttpFile } from '../http/http';

export class PublicUser {
    'id': number;
    'name'?: string;
    'avatar'?: URI;
    'aThumbnail'?: string;
    'uid': string;
    'role'?: string;
    'dateJoined'?: Date;
    'subscriptionType'?: SubscriptionTypeEnum;
    'entitlement'?: EntitlementEnum;
    'description'?: string;
    'allHorses': Array<SimpleHorse>;
    'phone'?: string;
    'country'?: string;
    'numFollowers'?: number;
    'numFollowing'?: number;
    'settings'?: UserSettings;
    'email'?: string;
    'usersYouBothFollow': Array<SimpleUser>;
    'sportfengurId'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "aThumbnail",
            "baseName": "aThumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "uid",
            "baseName": "uid",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "dateJoined",
            "baseName": "dateJoined",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "subscriptionType",
            "baseName": "subscriptionType",
            "type": "SubscriptionTypeEnum",
            "format": ""
        },
        {
            "name": "entitlement",
            "baseName": "entitlement",
            "type": "EntitlementEnum",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "allHorses",
            "baseName": "allHorses",
            "type": "Array<SimpleHorse>",
            "format": ""
        },
        {
            "name": "phone",
            "baseName": "phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "numFollowers",
            "baseName": "numFollowers",
            "type": "number",
            "format": ""
        },
        {
            "name": "numFollowing",
            "baseName": "numFollowing",
            "type": "number",
            "format": ""
        },
        {
            "name": "settings",
            "baseName": "settings",
            "type": "UserSettings",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": ""
        },
        {
            "name": "usersYouBothFollow",
            "baseName": "usersYouBothFollow",
            "type": "Array<SimpleUser>",
            "format": ""
        },
        {
            "name": "sportfengurId",
            "baseName": "sportfengurId",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PublicUser.attributeTypeMap;
    }

    public constructor() {
    }
}



