import { SearchHorse } from "@/openapi";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IHorsesSearchState {
  query: string | undefined;
  searchedHorse: SearchHorse | undefined;
  results: SearchHorse[] | undefined;
}

export const initialHorsesSearchState: IHorsesSearchState = {
  results: undefined,
  query: undefined,
  searchedHorse: undefined,
};

export const horsesSearchSlice = createSlice({
  name: "horsesSearch",
  initialState: initialHorsesSearchState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setResults: (state, action: PayloadAction<SearchHorse[]>) => {
      state.results = action.payload;
    },
    setSearchedHorse: (state, action: PayloadAction<SearchHorse>) => {
      state.searchedHorse = action.payload;
    },
  },
});

export const { setQuery, setResults, setSearchedHorse } =
  horsesSearchSlice.actions;

export default horsesSearchSlice.reducer;
