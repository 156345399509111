/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupDiscussionPhoto } from '../models/GroupDiscussionPhoto';
import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class GroupDiscussion {
    'uid': string;
    'creator': SimpleUser;
    'group': number;
    'title': string;
    'body': string;
    'dtCreated': Date;
    'dtModified': Date;
    'numberOfLikes': number;
    'commentCount': number;
    'mainPhoto'?: GroupDiscussionPhoto;
    'isDraft'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "uid",
            "baseName": "uid",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "creator",
            "baseName": "creator",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "group",
            "baseName": "group",
            "type": "number",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "body",
            "baseName": "body",
            "type": "string",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "numberOfLikes",
            "baseName": "numberOfLikes",
            "type": "number",
            "format": ""
        },
        {
            "name": "commentCount",
            "baseName": "commentCount",
            "type": "number",
            "format": ""
        },
        {
            "name": "mainPhoto",
            "baseName": "mainPhoto",
            "type": "GroupDiscussionPhoto",
            "format": ""
        },
        {
            "name": "isDraft",
            "baseName": "isDraft",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GroupDiscussion.attributeTypeMap;
    }

    public constructor() {
    }
}

