import { isTestEnv } from "./devUtils";

export const LOKALISE_TOKEN = "765cdcb43bf8e60b9584745a4c113b39a631";
export const LOKALISE_PROJECT = "59621025641acc3164b7f0.30207610";
export const getGoogleClientId = () => {
  return isTestEnv()
    ? "1033110082272-7kf8napb9c146gl06op5kc58p3fckl1j.apps.googleusercontent.com"
    : "743036225316-1jqtm54jhhp39nus5k0uj515apoitv57.apps.googleusercontent.com";
};

export const APPLE_KEY_IDENTIFIER = "VTG3JKMBXV";
export const APPLE_PRIVATE_KEY_STRING =
  "MIGTAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBHkwdwIBAQQgMwtN+933PWAPXBTfIViKaOu7nZ7ZzzK03u7TgcSriCegCgYIKoZIzj0DAQehRANCAARfrC0ELR32Gcqgb+NUd+VCe7yDwkqN40p2D/zUbw0e0+Sra6FCWLpabCPELHf3xXSD7gdMlgGp/AMSNMVJkMWC";

export const HORSE_DAY_APPLE_TEAM_ID = "Z4UH879AY5";

export const getMixpanelProjectToken = () => {
  return isTestEnv()
    ? "1f7a8f985f33739da650903116dc25ae"
    : "184243625aef5848f8b08af4588a47f7";
};
