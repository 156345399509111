/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { Actor } from '../models/Actor';
import { HorseActivityPhoto } from '../models/HorseActivityPhoto';
import { HorseActivityVideo } from '../models/HorseActivityVideo';
import { HttpFile } from '../http/http';

export class ActivityMedia {
    'actor'?: Actor;
    'photos': Array<HorseActivityPhoto>;
    'videos': Array<HorseActivityVideo>;
    'id': number;
    'type': ActivityTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "actor",
            "baseName": "actor",
            "type": "Actor",
            "format": ""
        },
        {
            "name": "photos",
            "baseName": "photos",
            "type": "Array<HorseActivityPhoto>",
            "format": ""
        },
        {
            "name": "videos",
            "baseName": "videos",
            "type": "Array<HorseActivityVideo>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "ActivityTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ActivityMedia.attributeTypeMap;
    }

    public constructor() {
    }
}



