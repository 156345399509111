/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseTypeEnum } from '../models/HorseTypeEnum';
import { PriceCategoryEnum } from '../models/PriceCategoryEnum';
import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class HorseForSale {
    'forSale'?: boolean;
    'price'?: number;
    'contactPhone'?: string;
    'contactPhoneCountryCode'?: string;
    'contactEmail'?: string;
    'contactUser': SimpleUser;
    'dtUpdated': Date;
    'title'?: string;
    'description'?: string;
    'createdBy'?: SimpleUser;
    'currency'?: string;
    'priceEur'?: number;
    'priceCategory'?: PriceCategoryEnum;
    'horseType'?: HorseTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "forSale",
            "baseName": "forSale",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "price",
            "baseName": "price",
            "type": "number",
            "format": ""
        },
        {
            "name": "contactPhone",
            "baseName": "contactPhone",
            "type": "string",
            "format": ""
        },
        {
            "name": "contactPhoneCountryCode",
            "baseName": "contactPhoneCountryCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "contactEmail",
            "baseName": "contactEmail",
            "type": "string",
            "format": "email"
        },
        {
            "name": "contactUser",
            "baseName": "contactUser",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "dtUpdated",
            "baseName": "dtUpdated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdBy",
            "baseName": "createdBy",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "currency",
            "baseName": "currency",
            "type": "string",
            "format": ""
        },
        {
            "name": "priceEur",
            "baseName": "priceEur",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "priceCategory",
            "baseName": "priceCategory",
            "type": "PriceCategoryEnum",
            "format": ""
        },
        {
            "name": "horseType",
            "baseName": "horseType",
            "type": "HorseTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseForSale.attributeTypeMap;
    }

    public constructor() {
    }
}



