/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class HorseAssessmentRequest {
    'hofud'?: string;
    'halsHerdarBogar'?: string;
    'bakLend'?: string;
    'samraemi'?: string;
    'fotagerd'?: string;
    'rettleiki'?: string;
    'hofar'?: string;
    'prudleiki'?: string;
    'skopulag'?: string;
    'tolt'?: string;
    'brokk'?: string;
    'skeid'?: string;
    'stokk'?: string;
    'haegtStokk'?: string;
    'greittStokk'?: string;
    'samstarfsvilji'?: string;
    'viljiGedslag'?: string;
    'fegurdIReid'?: string;
    'fet'?: string;
    'haefileikar'?: string;
    'haegtTolt'?: string;
    'adaleinkunn'?: string;
    'haefileikarAnSkeids'?: string;
    'adaleinkunnAnSkeids'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "hofud",
            "baseName": "hofud",
            "type": "string",
            "format": ""
        },
        {
            "name": "halsHerdarBogar",
            "baseName": "halsHerdarBogar",
            "type": "string",
            "format": ""
        },
        {
            "name": "bakLend",
            "baseName": "bakLend",
            "type": "string",
            "format": ""
        },
        {
            "name": "samraemi",
            "baseName": "samraemi",
            "type": "string",
            "format": ""
        },
        {
            "name": "fotagerd",
            "baseName": "fotagerd",
            "type": "string",
            "format": ""
        },
        {
            "name": "rettleiki",
            "baseName": "rettleiki",
            "type": "string",
            "format": ""
        },
        {
            "name": "hofar",
            "baseName": "hofar",
            "type": "string",
            "format": ""
        },
        {
            "name": "prudleiki",
            "baseName": "prudleiki",
            "type": "string",
            "format": ""
        },
        {
            "name": "skopulag",
            "baseName": "skopulag",
            "type": "string",
            "format": ""
        },
        {
            "name": "tolt",
            "baseName": "tolt",
            "type": "string",
            "format": ""
        },
        {
            "name": "brokk",
            "baseName": "brokk",
            "type": "string",
            "format": ""
        },
        {
            "name": "skeid",
            "baseName": "skeid",
            "type": "string",
            "format": ""
        },
        {
            "name": "stokk",
            "baseName": "stokk",
            "type": "string",
            "format": ""
        },
        {
            "name": "haegtStokk",
            "baseName": "haegtStokk",
            "type": "string",
            "format": ""
        },
        {
            "name": "greittStokk",
            "baseName": "greittStokk",
            "type": "string",
            "format": ""
        },
        {
            "name": "samstarfsvilji",
            "baseName": "samstarfsvilji",
            "type": "string",
            "format": ""
        },
        {
            "name": "viljiGedslag",
            "baseName": "viljiGedslag",
            "type": "string",
            "format": ""
        },
        {
            "name": "fegurdIReid",
            "baseName": "fegurdIReid",
            "type": "string",
            "format": ""
        },
        {
            "name": "fet",
            "baseName": "fet",
            "type": "string",
            "format": ""
        },
        {
            "name": "haefileikar",
            "baseName": "haefileikar",
            "type": "string",
            "format": ""
        },
        {
            "name": "haegtTolt",
            "baseName": "haegtTolt",
            "type": "string",
            "format": ""
        },
        {
            "name": "adaleinkunn",
            "baseName": "adaleinkunn",
            "type": "string",
            "format": ""
        },
        {
            "name": "haefileikarAnSkeids",
            "baseName": "haefileikarAnSkeids",
            "type": "string",
            "format": ""
        },
        {
            "name": "adaleinkunnAnSkeids",
            "baseName": "adaleinkunnAnSkeids",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseAssessmentRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

