/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleHorseRequest } from '../models/SimpleHorseRequest';
import { TypesEnumEnum } from '../models/TypesEnumEnum';
import { HttpFile } from '../http/http';

export class MedicalRequest {
    'types'?: Array<string>;
    'veterinarian'?: string;
    'taggedHorses'?: Array<SimpleHorseRequest>;
    'typesEnum'?: Array<TypesEnumEnum>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "types",
            "baseName": "types",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "veterinarian",
            "baseName": "veterinarian",
            "type": "string",
            "format": ""
        },
        {
            "name": "taggedHorses",
            "baseName": "taggedHorses",
            "type": "Array<SimpleHorseRequest>",
            "format": ""
        },
        {
            "name": "typesEnum",
            "baseName": "typesEnum",
            "type": "Array<TypesEnumEnum>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MedicalRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

