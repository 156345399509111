import { User } from "@/openapi";
import { Key } from "@mui/icons-material";
import { captureException, devPrint } from "./devUtils";

export const saveLocalUser = (user: User) => {
  window.localStorage.setItem("saved-user", JSON.stringify(user));
};

export const clearLocalUser = () => {
  window.localStorage.removeItem("saved-user");
};

export const getLocalUser = (): User | undefined => {
  const s = window.localStorage.getItem("saved-user");
  if (s) {
    try {
      var user = new User();
      const data = JSON.parse(s) as User;
      return data;
    } catch (e: any) {
      captureException(e);
    }
  }

  return undefined;
};

export const getSavedLocale = (): "is" | "en" | "de" | null => {
  var s = window.localStorage.getItem("locale");
  if (s) {
    return s as "is" | "en" | "de";
  }
  return null;
};
export const setSavedLocale = (locale: string) => {
  return window.localStorage.setItem("locale", locale);
};

export const getSavedCustomString = (
  key: string,
  def?: string
): string | undefined => {
  return window.localStorage.getItem(key) ?? def;
};

export const setSavedCustomString = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};
