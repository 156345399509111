import { User } from "@/openapi";
import mixpanel, { Dict } from "mixpanel-browser";
import { devPrint, isTestEnv } from "./devUtils";
import { getMixpanelProjectToken } from "./projectKeys";
import { isPremium } from "./userUtils";

export const initMixpanel = () => {
  mixpanel.init(getMixpanelProjectToken(), {
    debug: isTestEnv(),
    cross_site_cookie: false,
    api_host: "https://api-eu.mixpanel.com",
    track_pageview: true,
  });
  checkIfCookiesAllowed();
};

const checkIfCookiesAllowed = () => {
  const allowedCooks = window.localStorage.getItem("allowed-cookies");
  if (!allowedCooks) {
    return;
  }
  if (!eval(allowedCooks)) {
    setNotTrack();
  }
};

export const setTrackUser = (user: User | undefined) => {
  if (!user) {
    mixpanel.people.unset(["id", "is_premium"]);
    mixpanel.reset();
  } else {
    mixpanel.identify(user.uid);
    mixpanel.people.set({ id: user.id, is_premium: isPremium() });
  }
};

export const setUserGroup = (groupName: string, group: any) => {
  mixpanel.set_group(groupName, group);
};

export const trackEvent = (event: string, parameters?: Dict) => {
  if (event === "") {
    return;
  }
  mixpanel.track(event, parameters);
};

export const setNotTrack = () => {
  mixpanel.set_config({
    disable_cookie: true,
    opt_out_tracking_by_default: true,
  });
};
// export const trackScreen = (screen: string, parameters?: Dict) => {
//     if (screen === "") {
//       return;
//     }
//     devPrint("tracking", screen, parameters);
//     mixpanel.
//     mixpanel.track(screen, parameters);
//   };
