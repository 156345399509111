/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MeasurmentSystemEnum } from '../models/MeasurmentSystemEnum';
import { HttpFile } from '../http/http';

export class PatchedUserSettingsRequest {
    'disallowedNotifications'?: string;
    'lockedProfile'?: boolean;
    'displayEmail'?: boolean;
    'displayPhone'?: boolean;
    'displayActivityMediaOnProfile'?: boolean;
    'measurmentSystem'?: MeasurmentSystemEnum;
    'showInLeaderboard'?: boolean;
    'showNumberHorses'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "disallowedNotifications",
            "baseName": "disallowedNotifications",
            "type": "string",
            "format": ""
        },
        {
            "name": "lockedProfile",
            "baseName": "lockedProfile",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "displayEmail",
            "baseName": "displayEmail",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "displayPhone",
            "baseName": "displayPhone",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "displayActivityMediaOnProfile",
            "baseName": "displayActivityMediaOnProfile",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "measurmentSystem",
            "baseName": "measurmentSystem",
            "type": "MeasurmentSystemEnum",
            "format": ""
        },
        {
            "name": "showInLeaderboard",
            "baseName": "showInLeaderboard",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "showNumberHorses",
            "baseName": "showNumberHorses",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedUserSettingsRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

