/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleUser } from '../models/SimpleUser';
import { TrainingSession } from '../models/TrainingSession';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { HttpFile } from '../http/http';

export class SimpleHorseActivity {
    'id': number;
    'dtPerformed'?: Date;
    'type': ActivityTypeEnum;
    'horse'?: SimpleHorse;
    'actor'?: SimpleUser;
    'subtypes': Array<string>;
    'parentActivity'?: number;
    'visibility'?: VisibilityEnum;
    'isDraft'?: boolean;
    'isPlanned'?: boolean;
    'hasFinishedPlanned'?: boolean;
    'plannedWithTime'?: boolean;
    'trainingSession'?: TrainingSession;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtPerformed",
            "baseName": "dtPerformed",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "ActivityTypeEnum",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "actor",
            "baseName": "actor",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "subtypes",
            "baseName": "subtypes",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "parentActivity",
            "baseName": "parentActivity",
            "type": "number",
            "format": ""
        },
        {
            "name": "visibility",
            "baseName": "visibility",
            "type": "VisibilityEnum",
            "format": ""
        },
        {
            "name": "isDraft",
            "baseName": "isDraft",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isPlanned",
            "baseName": "isPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hasFinishedPlanned",
            "baseName": "hasFinishedPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "plannedWithTime",
            "baseName": "plannedWithTime",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "trainingSession",
            "baseName": "trainingSession",
            "type": "TrainingSession",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SimpleHorseActivity.attributeTypeMap;
    }

    public constructor() {
    }
}



