// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { Comment } from '../models/Comment';
import { HorseActivities } from '../models/HorseActivities';
import { HorseActivity } from '../models/HorseActivity';
import { HorseActivityCreate } from '../models/HorseActivityCreate';
import { HorseActivityCreateRequest } from '../models/HorseActivityCreateRequest';
import { HorseActivityMultiCreateRequest } from '../models/HorseActivityMultiCreateRequest';
import { HorseActivityUpdate } from '../models/HorseActivityUpdate';
import { HorseActivityUpdateRequest } from '../models/HorseActivityUpdateRequest';
import { MonthlyTrainingSummary } from '../models/MonthlyTrainingSummary';
import { PaginatedActivity } from '../models/PaginatedActivity';
import { PaginatedHorseActivityList } from '../models/PaginatedHorseActivityList';
import { PatchedHorseActivityUpdateRequest } from '../models/PatchedHorseActivityUpdateRequest';
import { SimpleHorseActivities } from '../models/SimpleHorseActivities';
import { SimpleMessage } from '../models/SimpleMessage';
import { SimpleMonthlyActivitySummary } from '../models/SimpleMonthlyActivitySummary';
import { UserReaction } from '../models/UserReaction';
import { WeeklyTrainingSummary } from '../models/WeeklyTrainingSummary';

/**
 * no description
 */
export class ActivitiesApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public async activitiesActivitySummaryMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/api/activities/activity-summary/multi-horse-activities-period/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (_from !== undefined) {
            requestContext.setQueryParam("from", ObjectSerializer.serialize(_from, "string", ""));
        }

        // Query Params
        if (to !== undefined) {
            requestContext.setQueryParam("to", ObjectSerializer.serialize(to, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public async activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/api/activities/activity-summary/simple-multi-horse-activities-period/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (_from !== undefined) {
            requestContext.setQueryParam("from", ObjectSerializer.serialize(_from, "string", ""));
        }

        // Query Params
        if (to !== undefined) {
            requestContext.setQueryParam("to", ObjectSerializer.serialize(to, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesCommentsList(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesCommentsList", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/comments/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param horseActivityCreateRequest 
     */
    public async activitiesCreate(horseActivityCreateRequest: HorseActivityCreateRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'horseActivityCreateRequest' is not null or undefined
        if (horseActivityCreateRequest === null || horseActivityCreateRequest === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesCreate", "horseActivityCreateRequest");
        }


        // Path Params
        const localVarPath = '/api/activities/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(horseActivityCreateRequest, "HorseActivityCreateRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesCreateMapTaskCreate(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesCreateMapTaskCreate", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/create_map_task/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * waits for map to be generated.
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesCreateMapWithoutTaskCreate(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesCreateMapWithoutTaskCreate", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/create_map_without_task/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesDestroy(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesDestroy", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Cloud task, should not be called directly
     * @param id A unique integer value identifying this horse activity.
     * @param isPremium if to generate premium map
     */
    public async activitiesGenerateMapWithTaskCreate(id: number, isPremium: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesGenerateMapWithTaskCreate", "id");
        }


        // verify required parameter 'isPremium' is not null or undefined
        if (isPremium === null || isPremium === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesGenerateMapWithTaskCreate", "isPremium");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/generate_map_with_task/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (isPremium !== undefined) {
            requestContext.setQueryParam("is_premium", ObjectSerializer.serialize(isPremium, "boolean", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * get all activities on day
     * @param date date of activties
     * @param horse If horse is null, then will get all activities for user
     * @param useDrafts include activities marked as drafts
     */
    public async activitiesGetActivitiesOnDayList(date: string, horse?: string, useDrafts?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'date' is not null or undefined
        if (date === null || date === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesGetActivitiesOnDayList", "date");
        }




        // Path Params
        const localVarPath = '/api/activities/get_activities_on_day/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (date !== undefined) {
            requestContext.setQueryParam("date", ObjectSerializer.serialize(date, "string", ""));
        }

        // Query Params
        if (horse !== undefined) {
            requestContext.setQueryParam("horse", ObjectSerializer.serialize(horse, "string", ""));
        }

        // Query Params
        if (useDrafts !== undefined) {
            requestContext.setQueryParam("use_drafts", ObjectSerializer.serialize(useDrafts, "boolean", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async activitiesGetAllPlannedActivitiesList(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/activities/get_all_planned_activities/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param excludeDrafts 
     * @param excludeFuture 
     * @param horse 
     * @param pageSize Number of results to return per page.
     */
    public async activitiesGetLatestTracksList(activityId?: number, actor?: number, cursor?: string, excludeDrafts?: boolean, excludeFuture?: boolean, horse?: string, pageSize?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;








        // Path Params
        const localVarPath = '/api/activities/get_latest_tracks/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (activityId !== undefined) {
            requestContext.setQueryParam("activity_id", ObjectSerializer.serialize(activityId, "number", ""));
        }

        // Query Params
        if (actor !== undefined) {
            requestContext.setQueryParam("actor", ObjectSerializer.serialize(actor, "number", ""));
        }

        // Query Params
        if (cursor !== undefined) {
            requestContext.setQueryParam("cursor", ObjectSerializer.serialize(cursor, "string", ""));
        }

        // Query Params
        if (excludeDrafts !== undefined) {
            requestContext.setQueryParam("exclude_drafts", ObjectSerializer.serialize(excludeDrafts, "boolean", ""));
        }

        // Query Params
        if (excludeFuture !== undefined) {
            requestContext.setQueryParam("exclude_future", ObjectSerializer.serialize(excludeFuture, "boolean", ""));
        }

        // Query Params
        if (horse !== undefined) {
            requestContext.setQueryParam("horse", ObjectSerializer.serialize(horse, "string", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async activitiesGetPastPlannedActivitiesRetrieve(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/activities/get_past_planned_activities/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param date date of activties
     */
    public async activitiesGetPlannedActivitiesTodayList(date?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;


        // Path Params
        const localVarPath = '/api/activities/get_planned_activities_today/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (date !== undefined) {
            requestContext.setQueryParam("date", ObjectSerializer.serialize(date, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param activities 
     * @param actor 
     * @param creator 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param excludePlanned Set to true to exclude planned activities
     * @param hasFinishedPlanned 
     * @param horse 
     * @param ids Multiple values may be separated by commas.
     * @param isDraft 
     * @param isPlanned 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public async activitiesList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, actor?: number, creator?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, excludePlanned?: boolean, hasFinishedPlanned?: boolean, horse?: string, ids?: Array<number>, isDraft?: boolean, isPlanned?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;



















        // Path Params
        const localVarPath = '/api/activities/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (activities !== undefined) {
            requestContext.setQueryParam("activities", ObjectSerializer.serialize(activities, "Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>", ""));
        }

        // Query Params
        if (actor !== undefined) {
            requestContext.setQueryParam("actor", ObjectSerializer.serialize(actor, "number", ""));
        }

        // Query Params
        if (creator !== undefined) {
            requestContext.setQueryParam("creator", ObjectSerializer.serialize(creator, "number", ""));
        }

        // Query Params
        if (cursor !== undefined) {
            requestContext.setQueryParam("cursor", ObjectSerializer.serialize(cursor, "string", ""));
        }

        // Query Params
        if (dateAfter !== undefined) {
            requestContext.setQueryParam("date_after", ObjectSerializer.serialize(dateAfter, "Date", "date-time"));
        }

        // Query Params
        if (dateBefore !== undefined) {
            requestContext.setQueryParam("date_before", ObjectSerializer.serialize(dateBefore, "Date", "date-time"));
        }

        // Query Params
        if (dateRangeAfter !== undefined) {
            requestContext.setQueryParam("date_range_after", ObjectSerializer.serialize(dateRangeAfter, "string", "date"));
        }

        // Query Params
        if (dateRangeBefore !== undefined) {
            requestContext.setQueryParam("date_range_before", ObjectSerializer.serialize(dateRangeBefore, "string", "date"));
        }

        // Query Params
        if (excludePlanned !== undefined) {
            requestContext.setQueryParam("exclude_planned", ObjectSerializer.serialize(excludePlanned, "boolean", ""));
        }

        // Query Params
        if (hasFinishedPlanned !== undefined) {
            requestContext.setQueryParam("has_finished_planned", ObjectSerializer.serialize(hasFinishedPlanned, "boolean", ""));
        }

        // Query Params
        if (horse !== undefined) {
            requestContext.setQueryParam("horse", ObjectSerializer.serialize(horse, "string", ""));
        }

        // Query Params
        if (ids !== undefined) {
            requestContext.setQueryParam("ids", ObjectSerializer.serialize(ids, "Array<number>", ""));
        }

        // Query Params
        if (isDraft !== undefined) {
            requestContext.setQueryParam("is_draft", ObjectSerializer.serialize(isDraft, "boolean", ""));
        }

        // Query Params
        if (isPlanned !== undefined) {
            requestContext.setQueryParam("is_planned", ObjectSerializer.serialize(isPlanned, "boolean", ""));
        }

        // Query Params
        if (order !== undefined) {
            requestContext.setQueryParam("order", ObjectSerializer.serialize(order, "Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", ""));
        }

        // Query Params
        if (type !== undefined) {
            requestContext.setQueryParam("type", ObjectSerializer.serialize(type, "'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'", ""));
        }

        // Query Params
        if (visibility !== undefined) {
            requestContext.setQueryParam("visibility", ObjectSerializer.serialize(visibility, "'FOLLOWERS' | 'ONLY_ME' | 'TEAM'", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get weekly summary for all horses the user has access to - month: (default this month) - year: (default this year)
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public async activitiesMonthlyTrainingSummaryAllHorsesList(isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/api/activities/monthly-training-summary/all-horses/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (isTaggedSummary !== undefined) {
            requestContext.setQueryParam("is_tagged_summary", ObjectSerializer.serialize(isTaggedSummary, "boolean", ""));
        }

        // Query Params
        if (month !== undefined) {
            requestContext.setQueryParam("month", ObjectSerializer.serialize(month, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get monthly summary for horse based on - month: (default this month) - year: (default this year) - tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public async activitiesMonthlyTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'faedingarnumer' is not null or undefined
        if (faedingarnumer === null || faedingarnumer === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesMonthlyTrainingSummarySingleHorseRetrieve", "faedingarnumer");
        }





        // Path Params
        const localVarPath = '/api/activities/monthly-training-summary/single-horse/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (isTaggedSummary !== undefined) {
            requestContext.setQueryParam("is_tagged_summary", ObjectSerializer.serialize(isTaggedSummary, "boolean", ""));
        }

        // Query Params
        if (month !== undefined) {
            requestContext.setQueryParam("month", ObjectSerializer.serialize(month, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param horseActivityMultiCreateRequest 
     */
    public async activitiesMultiCreateCreate(horseActivityMultiCreateRequest: HorseActivityMultiCreateRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'horseActivityMultiCreateRequest' is not null or undefined
        if (horseActivityMultiCreateRequest === null || horseActivityMultiCreateRequest === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesMultiCreateCreate", "horseActivityMultiCreateRequest");
        }


        // Path Params
        const localVarPath = '/api/activities/multi_create/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(horseActivityMultiCreateRequest, "HorseActivityMultiCreateRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param patchedHorseActivityUpdateRequest 
     */
    public async activitiesPartialUpdate(id: number, patchedHorseActivityUpdateRequest?: PatchedHorseActivityUpdateRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesPartialUpdate", "id");
        }



        // Path Params
        const localVarPath = '/api/activities/{id}/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(patchedHorseActivityUpdateRequest, "PatchedHorseActivityUpdateRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesReactionsList(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesReactionsList", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/reactions/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public async activitiesRetrieve(id: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesRetrieve", "id");
        }


        // Path Params
        const localVarPath = '/api/activities/{id}/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get monthly summary for all horses user has access to based on - month: (default this month) - year: (default this year)
     * @param month 
     * @param year 
     */
    public async activitiesSummaryAllHorsesList(month?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;



        // Path Params
        const localVarPath = '/api/activities/summary/all-horses/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (month !== undefined) {
            requestContext.setQueryParam("month", ObjectSerializer.serialize(month, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get monthly activity summary for horse based on - month: (default this month) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param month 
     * @param year 
     */
    public async activitiesSummarySingleHorseRetrieve(faedingarnumer?: string, month?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/api/activities/summary/single-horse/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (month !== undefined) {
            requestContext.setQueryParam("month", ObjectSerializer.serialize(month, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get weekly summary for all horses the user has access to - week: (default this week) - year: (default this year)
     * @param week 
     * @param year 
     */
    public async activitiesTrainingSummaryAllHorsesList(week?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;



        // Path Params
        const localVarPath = '/api/activities/training-summary/all-horses/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (week !== undefined) {
            requestContext.setQueryParam("week", ObjectSerializer.serialize(week, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get weekly summary for horse based on - week: (default this week) - year: (default this year) - is_tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param week 
     * @param year 
     */
    public async activitiesTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, week?: number, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'faedingarnumer' is not null or undefined
        if (faedingarnumer === null || faedingarnumer === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesTrainingSummarySingleHorseRetrieve", "faedingarnumer");
        }





        // Path Params
        const localVarPath = '/api/activities/training-summary/single-horse/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (isTaggedSummary !== undefined) {
            requestContext.setQueryParam("is_tagged_summary", ObjectSerializer.serialize(isTaggedSummary, "boolean", ""));
        }

        // Query Params
        if (week !== undefined) {
            requestContext.setQueryParam("week", ObjectSerializer.serialize(week, "number", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get weekly summary for horse based on - weeks: list of weeks (default [this week]) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param weeks 
     * @param year 
     */
    public async activitiesTrainingSummarySingleHorseWeeksList(faedingarnumer: string, weeks?: string, year?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'faedingarnumer' is not null or undefined
        if (faedingarnumer === null || faedingarnumer === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesTrainingSummarySingleHorseWeeksList", "faedingarnumer");
        }




        // Path Params
        const localVarPath = '/api/activities/training-summary/single-horse/weeks/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (faedingarnumer !== undefined) {
            requestContext.setQueryParam("faedingarnumer", ObjectSerializer.serialize(faedingarnumer, "string", ""));
        }

        // Query Params
        if (weeks !== undefined) {
            requestContext.setQueryParam("weeks", ObjectSerializer.serialize(weeks, "string", ""));
        }

        // Query Params
        if (year !== undefined) {
            requestContext.setQueryParam("year", ObjectSerializer.serialize(year, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param horseActivityUpdateRequest 
     */
    public async activitiesUpdate(id: number, horseActivityUpdateRequest?: HorseActivityUpdateRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new RequiredError("ActivitiesApi", "activitiesUpdate", "id");
        }



        // Path Params
        const localVarPath = '/api/activities/{id}/'
            .replace('{' + 'id' + '}', encodeURIComponent(String(id)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PUT);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(horseActivityUpdateRequest, "HorseActivityUpdateRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class ActivitiesApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesActivitySummaryMultiHorseActivitiesPeriodList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesActivitySummaryMultiHorseActivitiesPeriodList(response: ResponseContext): Promise<Array<HorseActivities> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<HorseActivities> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivities>", ""
            ) as Array<HorseActivities>;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<HorseActivities> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivities>", ""
            ) as Array<HorseActivities>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(response: ResponseContext): Promise<Array<SimpleHorseActivities> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<SimpleHorseActivities> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleHorseActivities>", ""
            ) as Array<SimpleHorseActivities>;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<SimpleHorseActivities> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleHorseActivities>", ""
            ) as Array<SimpleHorseActivities>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesCommentsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesCommentsList(response: ResponseContext): Promise<Array<Comment> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<Comment> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Comment>", ""
            ) as Array<Comment>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<Comment> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Comment>", ""
            ) as Array<Comment>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesCreate(response: ResponseContext): Promise<HorseActivityCreate > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("201", response.httpStatusCode)) {
            const body: HorseActivityCreate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityCreate", ""
            ) as HorseActivityCreate;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivityCreate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityCreate", ""
            ) as HorseActivityCreate;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesCreateMapTaskCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesCreateMapTaskCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesCreateMapWithoutTaskCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesCreateMapWithoutTaskCreate(response: ResponseContext): Promise<HorseActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesDestroy
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesDestroy(response: ResponseContext): Promise<void > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("204", response.httpStatusCode)) {
            return;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: void = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "void", ""
            ) as void;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGenerateMapWithTaskCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGenerateMapWithTaskCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }
        if (isCodeInRange("400", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGetActivitiesOnDayList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGetActivitiesOnDayList(response: ResponseContext): Promise<Array<HorseActivity> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGetAllPlannedActivitiesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGetAllPlannedActivitiesList(response: ResponseContext): Promise<Array<HorseActivity> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGetLatestTracksList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGetLatestTracksList(response: ResponseContext): Promise<PaginatedHorseActivityList > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGetPastPlannedActivitiesRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGetPastPlannedActivitiesRetrieve(response: ResponseContext): Promise<PaginatedActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedActivity", ""
            ) as PaginatedActivity;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedActivity", ""
            ) as PaginatedActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesGetPlannedActivitiesTodayList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesGetPlannedActivitiesTodayList(response: ResponseContext): Promise<Array<HorseActivity> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesList(response: ResponseContext): Promise<PaginatedHorseActivityList > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedHorseActivityList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseActivityList", ""
            ) as PaginatedHorseActivityList;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesMonthlyTrainingSummaryAllHorsesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesMonthlyTrainingSummaryAllHorsesList(response: ResponseContext): Promise<Array<MonthlyTrainingSummary> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<MonthlyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<MonthlyTrainingSummary>", ""
            ) as Array<MonthlyTrainingSummary>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<MonthlyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<MonthlyTrainingSummary>", ""
            ) as Array<MonthlyTrainingSummary>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesMonthlyTrainingSummarySingleHorseRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesMonthlyTrainingSummarySingleHorseRetrieve(response: ResponseContext): Promise<MonthlyTrainingSummary > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: MonthlyTrainingSummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MonthlyTrainingSummary", ""
            ) as MonthlyTrainingSummary;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: MonthlyTrainingSummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "MonthlyTrainingSummary", ""
            ) as MonthlyTrainingSummary;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesMultiCreateCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesMultiCreateCreate(response: ResponseContext): Promise<Array<HorseActivity> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<HorseActivity> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<HorseActivity>", ""
            ) as Array<HorseActivity>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesPartialUpdate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesPartialUpdate(response: ResponseContext): Promise<HorseActivityUpdate > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivityUpdate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityUpdate", ""
            ) as HorseActivityUpdate;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivityUpdate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityUpdate", ""
            ) as HorseActivityUpdate;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesReactionsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesReactionsList(response: ResponseContext): Promise<Array<UserReaction> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<UserReaction> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<UserReaction>", ""
            ) as Array<UserReaction>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<UserReaction> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<UserReaction>", ""
            ) as Array<UserReaction>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesRetrieve(response: ResponseContext): Promise<HorseActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesSummaryAllHorsesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesSummaryAllHorsesList(response: ResponseContext): Promise<Array<SimpleMonthlyActivitySummary> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<SimpleMonthlyActivitySummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleMonthlyActivitySummary>", ""
            ) as Array<SimpleMonthlyActivitySummary>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<SimpleMonthlyActivitySummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<SimpleMonthlyActivitySummary>", ""
            ) as Array<SimpleMonthlyActivitySummary>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesSummarySingleHorseRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesSummarySingleHorseRetrieve(response: ResponseContext): Promise<SimpleMonthlyActivitySummary > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMonthlyActivitySummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMonthlyActivitySummary", ""
            ) as SimpleMonthlyActivitySummary;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMonthlyActivitySummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMonthlyActivitySummary", ""
            ) as SimpleMonthlyActivitySummary;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesTrainingSummaryAllHorsesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesTrainingSummaryAllHorsesList(response: ResponseContext): Promise<Array<WeeklyTrainingSummary> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<WeeklyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<WeeklyTrainingSummary>", ""
            ) as Array<WeeklyTrainingSummary>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<WeeklyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<WeeklyTrainingSummary>", ""
            ) as Array<WeeklyTrainingSummary>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesTrainingSummarySingleHorseRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesTrainingSummarySingleHorseRetrieve(response: ResponseContext): Promise<WeeklyTrainingSummary > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: WeeklyTrainingSummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "WeeklyTrainingSummary", ""
            ) as WeeklyTrainingSummary;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: WeeklyTrainingSummary = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "WeeklyTrainingSummary", ""
            ) as WeeklyTrainingSummary;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesTrainingSummarySingleHorseWeeksList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesTrainingSummarySingleHorseWeeksList(response: ResponseContext): Promise<Array<WeeklyTrainingSummary> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<WeeklyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<WeeklyTrainingSummary>", ""
            ) as Array<WeeklyTrainingSummary>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<WeeklyTrainingSummary> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<WeeklyTrainingSummary>", ""
            ) as Array<WeeklyTrainingSummary>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to activitiesUpdate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async activitiesUpdate(response: ResponseContext): Promise<HorseActivityUpdate > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivityUpdate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityUpdate", ""
            ) as HorseActivityUpdate;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivityUpdate = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivityUpdate", ""
            ) as HorseActivityUpdate;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
