/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EventPoints } from '../models/EventPoints';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class HorseDayRanking {
    'keppandiNumer': number;
    'horse'?: SimpleHorse;
    'knapi'?: SimpleUser;
    'events': Array<EventPoints>;
    'year': number;
    'land': string;
    'flokkur': string;
    'keppnisgrein': string;
    'faedingarnumer': string;
    'knapiNumer': number;
    'knapiNafn': string;
    'knapiAdildarfelag': string;
    'ranking'?: number;
    'totalPoints'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "keppandiNumer",
            "baseName": "keppandiNumer",
            "type": "number",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "knapi",
            "baseName": "knapi",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "events",
            "baseName": "events",
            "type": "Array<EventPoints>",
            "format": ""
        },
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": ""
        },
        {
            "name": "land",
            "baseName": "land",
            "type": "string",
            "format": ""
        },
        {
            "name": "flokkur",
            "baseName": "flokkur",
            "type": "string",
            "format": ""
        },
        {
            "name": "keppnisgrein",
            "baseName": "keppnisgrein",
            "type": "string",
            "format": ""
        },
        {
            "name": "faedingarnumer",
            "baseName": "faedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "knapiNumer",
            "baseName": "knapiNumer",
            "type": "number",
            "format": ""
        },
        {
            "name": "knapiNafn",
            "baseName": "knapiNafn",
            "type": "string",
            "format": ""
        },
        {
            "name": "knapiAdildarfelag",
            "baseName": "knapiAdildarfelag",
            "type": "string",
            "format": ""
        },
        {
            "name": "ranking",
            "baseName": "ranking",
            "type": "number",
            "format": ""
        },
        {
            "name": "totalPoints",
            "baseName": "totalPoints",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseDayRanking.attributeTypeMap;
    }

    public constructor() {
    }
}

