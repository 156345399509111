/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SafetyTrackingTeam } from '../models/SafetyTrackingTeam';
import { SafetyTrackingTeamInvite } from '../models/SafetyTrackingTeamInvite';
import { HttpFile } from '../http/http';

export class SafetyTrackingMyInfo {
    'myTeam': SafetyTrackingTeam;
    'asViewer': Array<SafetyTrackingTeam>;
    'invited': Array<SafetyTrackingTeamInvite>;
    'inviter': Array<SafetyTrackingTeamInvite>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "myTeam",
            "baseName": "myTeam",
            "type": "SafetyTrackingTeam",
            "format": ""
        },
        {
            "name": "asViewer",
            "baseName": "asViewer",
            "type": "Array<SafetyTrackingTeam>",
            "format": ""
        },
        {
            "name": "invited",
            "baseName": "invited",
            "type": "Array<SafetyTrackingTeamInvite>",
            "format": ""
        },
        {
            "name": "inviter",
            "baseName": "inviter",
            "type": "Array<SafetyTrackingTeamInvite>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SafetyTrackingMyInfo.attributeTypeMap;
    }

    public constructor() {
    }
}

