/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { EntitlementEnum } from '../models/EntitlementEnum';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { URI } from '../models/URI';
import { UserExtradata } from '../models/UserExtradata';
import { UserSettings } from '../models/UserSettings';
import { HttpFile } from '../http/http';

export class User {
    'id': number;
    'email': string;
    'name'?: string;
    'country'?: string;
    'birthdate'?: string;
    'gender'?: string;
    'phone'?: string;
    'aThumbnail'?: string;
    'phoneCountryCode'?: string;
    'description'?: string;
    'role'?: string;
    'avatar'?: URI;
    'wfUser'?: string;
    'dateJoined': Date;
    /**
    * Designates whether the user can log into this admin site.
    */
    'isStaff': boolean;
    'subscriptionType': SubscriptionTypeEnum;
    'uid': string;
    'numFollowers': number;
    'numFollowing': number;
    'settings'?: UserSettings;
    'userExtradata'?: UserExtradata;
    'entitlement': EntitlementEnum;
    'featureFlagged': boolean;
    'locale'?: string;
    'numWfSearches'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": "email"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthdate",
            "baseName": "birthdate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "gender",
            "baseName": "gender",
            "type": "string",
            "format": ""
        },
        {
            "name": "phone",
            "baseName": "phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "aThumbnail",
            "baseName": "aThumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneCountryCode",
            "baseName": "phoneCountryCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "wfUser",
            "baseName": "wfUser",
            "type": "string",
            "format": ""
        },
        {
            "name": "dateJoined",
            "baseName": "dateJoined",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isStaff",
            "baseName": "isStaff",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "subscriptionType",
            "baseName": "subscriptionType",
            "type": "SubscriptionTypeEnum",
            "format": ""
        },
        {
            "name": "uid",
            "baseName": "uid",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "numFollowers",
            "baseName": "numFollowers",
            "type": "number",
            "format": ""
        },
        {
            "name": "numFollowing",
            "baseName": "numFollowing",
            "type": "number",
            "format": ""
        },
        {
            "name": "settings",
            "baseName": "settings",
            "type": "UserSettings",
            "format": ""
        },
        {
            "name": "userExtradata",
            "baseName": "userExtradata",
            "type": "UserExtradata",
            "format": ""
        },
        {
            "name": "entitlement",
            "baseName": "entitlement",
            "type": "EntitlementEnum",
            "format": ""
        },
        {
            "name": "featureFlagged",
            "baseName": "featureFlagged",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locale",
            "baseName": "locale",
            "type": "string",
            "format": ""
        },
        {
            "name": "numWfSearches",
            "baseName": "numWfSearches",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return User.attributeTypeMap;
    }

    public constructor() {
    }
}

