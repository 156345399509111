/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class HorseMedicalDetails {
    'isInjured'?: boolean;
    'weight'?: number;
    'allergies'?: string;
    'sickness'?: string;
    'dateInfoUpdated': string;
    'lastUpdatedBy': SimpleUser;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "isInjured",
            "baseName": "isInjured",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "weight",
            "baseName": "weight",
            "type": "number",
            "format": "double"
        },
        {
            "name": "allergies",
            "baseName": "allergies",
            "type": "string",
            "format": ""
        },
        {
            "name": "sickness",
            "baseName": "sickness",
            "type": "string",
            "format": ""
        },
        {
            "name": "dateInfoUpdated",
            "baseName": "dateInfoUpdated",
            "type": "string",
            "format": "date"
        },
        {
            "name": "lastUpdatedBy",
            "baseName": "lastUpdatedBy",
            "type": "SimpleUser",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseMedicalDetails.attributeTypeMap;
    }

    public constructor() {
    }
}

