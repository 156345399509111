/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseUserRoleEnum } from '../models/HorseUserRoleEnum';
import { SimpleHorseRequest } from '../models/SimpleHorseRequest';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { HttpFile } from '../http/http';

export class PatchedHorseAccessInviteRequest {
    'horse'?: SimpleHorseRequest;
    'user'?: SimpleUserRequest;
    'inviter'?: SimpleUserRequest;
    'message'?: string;
    'role'?: HorseUserRoleEnum;
    'isTrainer'?: boolean;
    'dtProcessed'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorseRequest",
            "format": ""
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUserRequest",
            "format": ""
        },
        {
            "name": "inviter",
            "baseName": "inviter",
            "type": "SimpleUserRequest",
            "format": ""
        },
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "HorseUserRoleEnum",
            "format": ""
        },
        {
            "name": "isTrainer",
            "baseName": "isTrainer",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dtProcessed",
            "baseName": "dtProcessed",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return PatchedHorseAccessInviteRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

