/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class GroupDiscussionComment {
    'user': SimpleUser;
    'discussion': number;
    'body': string;
    'numberOfLikes': number;
    'dtCreated': Date;
    'id': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "discussion",
            "baseName": "discussion",
            "type": "number",
            "format": ""
        },
        {
            "name": "body",
            "baseName": "body",
            "type": "string",
            "format": ""
        },
        {
            "name": "numberOfLikes",
            "baseName": "numberOfLikes",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GroupDiscussionComment.attributeTypeMap;
    }

    public constructor() {
    }
}

