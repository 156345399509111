/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class SportFengurEvent {
    'motsnumer': string;
    'numberOfFollowers': number;
    'numer'?: number;
    'motsheiti'?: string;
    'dtStart'?: Date;
    'dtEnd'?: Date;
    'dtCreated': Date;
    'motshaldari'?: string;
    'skyrslastadfest'?: boolean;
    'dtSignupStart'?: Date;
    'dtSignupEnd'?: Date;
    'stormot'?: string;
    'worldRanking'?: string;
    'motshaldariSimi'?: string;
    'stadsetning'?: string;
    'motstegund'?: { [key: string]: any; };
    'adildarfelag'?: { [key: string]: any; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "motsnumer",
            "baseName": "motsnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "numberOfFollowers",
            "baseName": "numberOfFollowers",
            "type": "number",
            "format": ""
        },
        {
            "name": "numer",
            "baseName": "numer",
            "type": "number",
            "format": ""
        },
        {
            "name": "motsheiti",
            "baseName": "motsheiti",
            "type": "string",
            "format": ""
        },
        {
            "name": "dtStart",
            "baseName": "dtStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtEnd",
            "baseName": "dtEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "motshaldari",
            "baseName": "motshaldari",
            "type": "string",
            "format": ""
        },
        {
            "name": "skyrslastadfest",
            "baseName": "skyrslastadfest",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dtSignupStart",
            "baseName": "dtSignupStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtSignupEnd",
            "baseName": "dtSignupEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "stormot",
            "baseName": "stormot",
            "type": "string",
            "format": ""
        },
        {
            "name": "worldRanking",
            "baseName": "worldRanking",
            "type": "string",
            "format": ""
        },
        {
            "name": "motshaldariSimi",
            "baseName": "motshaldariSimi",
            "type": "string",
            "format": ""
        },
        {
            "name": "stadsetning",
            "baseName": "stadsetning",
            "type": "string",
            "format": ""
        },
        {
            "name": "motstegund",
            "baseName": "motstegund",
            "type": "{ [key: string]: any; }",
            "format": ""
        },
        {
            "name": "adildarfelag",
            "baseName": "adildarfelag",
            "type": "{ [key: string]: any; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SportFengurEvent.attributeTypeMap;
    }

    public constructor() {
    }
}

