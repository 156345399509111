/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleHorseActivity } from '../models/SimpleHorseActivity';
import { HttpFile } from '../http/http';

export class SimpleMonthlyActivitySummary {
    'horse': SimpleHorse;
    'activities': Array<SimpleHorseActivity>;
    'year': number;
    'month': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "activities",
            "baseName": "activities",
            "type": "Array<SimpleHorseActivity>",
            "format": ""
        },
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": ""
        },
        {
            "name": "month",
            "baseName": "month",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SimpleMonthlyActivitySummary.attributeTypeMap;
    }

    public constructor() {
    }
}

