/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class HorseActivityPhoto {
    'id': number;
    'dtCreated': Date;
    'dtModified': Date;
    'name'?: string;
    'image'?: URI;
    'thumbnail': URI;
    'isMap'?: boolean;
    'isPremiumMap'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "isMap",
            "baseName": "isMap",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isPremiumMap",
            "baseName": "isPremiumMap",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseActivityPhoto.attributeTypeMap;
    }

    public constructor() {
    }
}

