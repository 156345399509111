// https://github.com/jeffroche/nextjs-django-auth-example/blob/master/www/auth.tsx

import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchMyInfo, initDoAllApiCalls } from "./redux/api_methods";
import { setUser } from "./redux/reducers/userReducer";
import store from "./redux/store";
import { setTrackUser, trackEvent } from "./utils/analyticsFunctions";
import { captureException, devPrint, isTestEnv } from "./utils/devUtils";
import { clearLocalUser, getLocalUser } from "./utils/sharedPrefrences";

export const makeUrl = (endpoint: string): string => {
  let API_BASE = "https://api-staging-v3.horseday.is"; // "http://localhost:8000"; //"https://api-staging.horseday.is"; // process.env.NEXT_PUBLIC_API_HOST;
  if (!isTestEnv()) {
    API_BASE = "https://api-v3.horseday.is";
  }

  return API_BASE + endpoint;
};

const fetchToken = (email: string, password: string): Promise<Response> => {
  const url = makeUrl("/api/accounts/login/");

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: requestHeaders,
  });
};

type AuthContextProps = {
  isAuthenticated: boolean;
  loading: boolean;
  login: (username: string, password: string) => Promise<Response>;
  loginWithGoogle: (accessToken: string, idToken: string) => Promise<Response>;
  loginWithApple: (accessToken: string, idToken: string) => Promise<Response>;
  logout: () => void;
  getToken: () => Promise<string>;
  makeAuthGet: (url: string) => Promise<Response>;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

interface AuthProviderProps {
  children: React.ReactNode;
}
let hasInit = false;
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState<string>("");

  const dispatch = useDispatch();

  const setNotAuthenticated = (): void => {
    setIsAuthenticated(false);
    setLoading(false);
  };

  const accessTokenIsValid = (): boolean => {
    let token = window.localStorage.getItem("TOKEN");

    setAccessToken("");
    if (token === null || token === "") {
      return false;
    }
    return true;
  };

  const initAuth = async (): Promise<void> => {
    if (hasInit) {
      return;
    }
    hasInit = true;
    setLoading(true);
    if (!accessTokenIsValid()) {
      setLoading(false);
      setIsAuthenticated(false);
    } else {
      const _u = getLocalUser();
      if (_u) {
        dispatch(setUser(_u));
      }

      var isValid = await fetchMyInfo();
      if (isValid) {
        setIsAuthenticated(true);
        setLoading(false);
        initDoAllApiCalls();
      } else {
        setAccessToken("");

        setLoading(false);
        window.localStorage.removeItem("TOKEN");
        setIsAuthenticated(false);
        dispatch(setUser(undefined));
      }
    }
  };

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setTrackUser(store.getState().userState.user);
    }
  }, [isAuthenticated]);

  const handleNewToken = (data: any): void => {
    var token = data.key;
    window.localStorage.setItem("TOKEN", token);

    setAccessToken(token);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const login = async (
    username: string,
    password: string
  ): Promise<Response> => {
    const resp = await fetchToken(username, password);

    if (resp.ok && resp.status < 299) {
      const tokenData = await resp.json();
      handleNewToken(tokenData);
      await fetchMyInfo();
      devPrint("did fetch my info", getLocalUser());
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      // Let the page handle the error
    }
    return resp;
  };

  const signup = async (
    email: string,
    password: string,
    name: string
  ): Promise<Response> => {
    const url = makeUrl("/api/accounts/registration/");

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        email,
        password1: password,
        password2: password,
        name,
      }),
      headers: requestHeaders,
    });

    if (resp.ok && resp.status < 299) {
      const tokenData = await resp.json();
      handleNewToken(tokenData);
      await fetchMyInfo();
    } else {
      setIsAuthenticated(false);
      setLoading(false);
      // Let the page handle the error
    }
    return resp;
  };

  const loginWithApple = async (
    accessToken: string,
    idToken: string
  ): Promise<Response> => {
    // do some apple stuff
    // need code and id_token
    // const accessToken = "";
    // const idToken = "";
    setLoading(true);
    setIsAuthenticated(false);

    const url = makeUrl("/api/accounts/apple/");
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ code: accessToken, id_token: idToken }),
        headers: requestHeaders,
      });
      if (res.status > 299) {
        setLoading(false);
        setIsAuthenticated(false);

        return res;
      }
      const data = await res.json();
      var token = data.key;

      setAccessToken(token);
      window.localStorage.setItem("TOKEN", token);
      fetchMyInfo();
      setIsAuthenticated(true);
      setLoading(false);
      await fetchMyInfo();
      return res;
    } catch (e) {
      captureException(e);
      setLoading(false);
      return Promise.reject("error logging in with apple" + e);
    }
  };
  const loginWithGoogle = async (
    accessToken: string,
    idToken: string
  ): Promise<Response> => {
    // const accessToken = "";
    // const idToken = "";

    const url = makeUrl("/api/accounts/google/");
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    try {
      setLoading(true);
      setIsAuthenticated(false);

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ access_token: accessToken, id_token: idToken }),
        headers: requestHeaders,
      });

      if (res.status > 299) {
        setLoading(false);
        setIsAuthenticated(false);
        return res;
      }
      const data = await res.json();
      var token = data.key;

      setAccessToken(token);
      window.localStorage.setItem("TOKEN", token);
      setIsAuthenticated(true);
      setLoading(false);
      await fetchMyInfo();
      return res;
    } catch (e) {
      captureException(e);
      setLoading(false);
      return Promise.reject("Error loggin in via google" + e);
      // throw e;
    }
  };

  const makeAuthGet = (url: string): Promise<Response> => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    let token = accessToken;
    if (token === "") {
      token = window.localStorage.getItem("TOKEN") ?? "";
    }
    requestHeaders.set("authorization", "Token " + token);
    return fetch(url, {
      method: "GET",
      // mode: "no-cors",
      headers: requestHeaders,
    });
  };

  const getToken = async (): Promise<string> => {
    // Returns an access token if there's one or refetches a new one
    if (accessTokenIsValid()) {
      return Promise.resolve(accessToken);
    } else if (loading) {
      while (loading) {
        console.log("Getting access token.. waiting for token to be refreshed");
      }
      // Assume this means the token is in the middle of refreshing
      return Promise.resolve(accessToken);
    } else {
      console.log("Getting access token.. getting a new token");
      //   const token = await refreshToken();
      return "-1"; //   return token ?? "-1";
    }
  };

  const logout = async () => {
    trackEvent("logout");
    // const url = makeUrl("/api/accounts/logout/");
    // const requestHeaders: HeadersInit = new Headers();
    // requestHeaders.set("Content-Type", "application/json");
    let token = accessToken;
    if (accessToken === "") {
      token = window.localStorage.getItem("TOKEN") ?? "";
    }
    clearLocalUser();
    // requestHeaders.set("authorization", "Token " + token);
    // var res = await fetch(url, {
    //   method: "POST",
    //   //   credentials: "include",
    //   headers: requestHeaders,
    // });

    // cant figure out how to login a user on mobile and web without them sharing a token
    // so now logging one out, will logout both, just delete the token from localstorage
    if (true) {
      // if (res.status < 300) {
      window.localStorage.removeItem("TOKEN");
      setAccessToken("");
      setNotAuthenticated();
      store.dispatch(setUser(undefined));
    } else {
      console.log(
        "not valid logging out, status code"
        // res.status,
        // res.statusText
      );
    }
    // TODO: call endpoint to delete cookie
  };

  const value = {
    isAuthenticated,
    // user,
    loading,
    login,
    signup,
    logout,
    getToken,
    makeAuthGet,
    loginWithApple,
    loginWithGoogle,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): any => useContext(AuthContext);
