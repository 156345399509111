/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TranscoderData } from '../models/TranscoderData';
import { URI } from '../models/URI';
import { HttpFile } from '../http/http';

export class HorseActivityVideo {
    'id': number;
    'dtCreated': Date;
    'dtModified': Date;
    'name'?: string;
    'video'?: URI;
    'uploadSession': string;
    'transcoded'?: TranscoderData;
    'thumbnail'?: URI;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "video",
            "baseName": "video",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "uploadSession",
            "baseName": "uploadSession",
            "type": "string",
            "format": ""
        },
        {
            "name": "transcoded",
            "baseName": "transcoded",
            "type": "TranscoderData",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "URI",
            "format": "uri"
        }    ];

    static getAttributeTypeMap() {
        return HorseActivityVideo.attributeTypeMap;
    }

    public constructor() {
    }
}

