/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseShoeEnum } from '../models/HorseShoeEnum';
import { SimpleHorse } from '../models/SimpleHorse';
import { HttpFile } from '../http/http';

export class Shoeing {
    'id': number;
    'frontShoes'?: HorseShoeEnum;
    'backShoes'?: HorseShoeEnum;
    'frontShoesList'?: Array<string>;
    'backShoesList'?: Array<string>;
    'comment'?: string;
    'farrier'?: string;
    'frontShoesLength'?: number;
    'backShoesLength'?: number;
    'frontShoesSize'?: string;
    'backShoesSize'?: string;
    'taggedHorses'?: Array<SimpleHorse>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "frontShoes",
            "baseName": "frontShoes",
            "type": "HorseShoeEnum",
            "format": ""
        },
        {
            "name": "backShoes",
            "baseName": "backShoes",
            "type": "HorseShoeEnum",
            "format": ""
        },
        {
            "name": "frontShoesList",
            "baseName": "frontShoesList",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "backShoesList",
            "baseName": "backShoesList",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "comment",
            "baseName": "comment",
            "type": "string",
            "format": ""
        },
        {
            "name": "farrier",
            "baseName": "farrier",
            "type": "string",
            "format": ""
        },
        {
            "name": "frontShoesLength",
            "baseName": "frontShoesLength",
            "type": "number",
            "format": ""
        },
        {
            "name": "backShoesLength",
            "baseName": "backShoesLength",
            "type": "number",
            "format": ""
        },
        {
            "name": "frontShoesSize",
            "baseName": "frontShoesSize",
            "type": "string",
            "format": ""
        },
        {
            "name": "backShoesSize",
            "baseName": "backShoesSize",
            "type": "string",
            "format": ""
        },
        {
            "name": "taggedHorses",
            "baseName": "taggedHorses",
            "type": "Array<SimpleHorse>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Shoeing.attributeTypeMap;
    }

    public constructor() {
    }
}

