/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PlatformEnum } from '../models/PlatformEnum';
import { HttpFile } from '../http/http';

export class PromoCode {
    'code': string;
    'infoTextUntranslated': string;
    'dtExpire': Date;
    'used'?: boolean;
    'platform'?: PlatformEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "infoTextUntranslated",
            "baseName": "infoTextUntranslated",
            "type": "string",
            "format": ""
        },
        {
            "name": "dtExpire",
            "baseName": "dtExpire",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "used",
            "baseName": "used",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "platform",
            "baseName": "platform",
            "type": "PlatformEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PromoCode.attributeTypeMap;
    }

    public constructor() {
    }
}

