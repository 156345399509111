import {
  ActivityTypeEnum,
  CustomTrainingSession,
  CustomTrainingSessionCreateRequest,
  HorseActivity,
  HorseActivityCreate,
  HorseActivityCreateRequest,
  Medical,
  MedicalRequest,
  Shoeing,
  ShoeingRequest,
  SimpleHorse,
  TrainingSession,
  TrainingSessionCreateRequest,
} from "@/openapi";
// import store from "@/redux/store";
import { isFuture } from "date-fns";
import { captureException, devPrint } from "./devUtils";
import { activitiesApi } from "./NetworkUtils";
import { publicUserToSimpleUser, userToActor } from "./userUtils";

export const savableActivityTypes: ActivityTypeEnum[] = [
  "TRAINING_SESSION",
  "CUSTOM_TRAINING_SESSION",
  "MEDICAL",
  "SHOEING",
  "REST_DAY",
  "NOTE",
];

export const createDefaultActivity = (
  date?: Date,
  simpleHorse?: SimpleHorse
): HorseActivity => {
  return {
    id: -1,
    dtCreated: new Date(),
    dtModified: new Date(),
    // creator: publicUserToSimpleUser(state.userState.user!),
    type: "TRAINING_SESSION" as ActivityTypeEnum,
    visibility: "FOLLOWERS",
    videos: [],
    photos: [],
    reactionSummary: {},
    commentCount: 0,
    // actor: userToActor(state.userState.user),
    isPlanned: true,
    hasFinishedPlanned: false,
    dtPerformed: date ?? new Date(),
    // horse: horse,

    medical: {
      id: -1,
      // types: [],
      taggedHorses: [],
      // types: [],
      veterinarian: "",
      typesEnum: [],
    } as Medical,
    shoeing: {
      id: -1,
      farrier: "",
    } as Shoeing,
    trainingSession: {
      id: -1,
      taggedHorses: [] as SimpleHorse[],
    } as TrainingSession,
    customTrainingSession: {
      id: -1,
      trainer: "",
    } as CustomTrainingSession,
  };
  // const state = store.getState();
  // let horse = simpleHorse ?? getLastUsedHorse();
  // if (!horse) {
  //   horse =
  //     state.horsesState.simpleHorses &&
  //     state.horsesState.simpleHorses?.length > 0
  //       ? state.horsesState.simpleHorses![0]
  //       : undefined;
  // }
  // let type = window.localStorage.getItem("last-activity-type");
  // if (!type) {
  //   type = "TRAINING_SESSION";
  // }
  // const a: HorseActivity = {
  //   id: -1,
  //   dtCreated: new Date(),
  //   dtModified: new Date(),
  //   creator: publicUserToSimpleUser(state.userState.user!),
  //   type: type as ActivityTypeEnum,
  //   visibility: "FOLLOWERS",
  //   videos: [],
  //   photos: [],
  //   reactionSummary: {},
  //   commentCount: 0,
  //   actor: userToActor(state.userState.user),
  //   isPlanned: true,
  //   hasFinishedPlanned: false,
  //   dtPerformed: date ?? new Date(),
  //   horse: horse,

  //   medical: {
  //     id: -1,
  //     // types: [],
  //     taggedHorses: [],
  //     // types: [],
  //     veterinarian: "",
  //     typesEnum: [],
  //   } as Medical,
  //   shoeing: {
  //     id: -1,
  //     farrier: "",
  //   } as Shoeing,
  //   trainingSession: {
  //     id: -1,
  //     taggedHorses: [] as SimpleHorse[],
  //   } as TrainingSession,
  //   customTrainingSession: {
  //     id: -1,
  //     trainer: "",
  //   } as CustomTrainingSession,
  // };

  // return a;
};

export const saveActivityToServer = async (
  activity: HorseActivity
): Promise<HorseActivityCreate | undefined> => {
  let req: HorseActivityCreateRequest = {
    title: "",
    type: activity.type,
    isDraft: false,
    isPlanned: isFuture(activity.dtPerformed!),
    hasFinishedPlanned: false,
    dtPerformed: activity.dtPerformed,
    horse: activity.horse?.faedingarnumer,
    actor: activity.actor?.id,
    visibility: activity.visibility,
    text: activity.text,
  };

  if (activity.type === "SHOEING") {
    const shoeingReq: ShoeingRequest = {
      ...activity.shoeing,
    };
    req.shoeing = shoeingReq;
  } else if (activity.type === "MEDICAL") {
    req.medical = {
      ...activity.medical,
    } as MedicalRequest;
  } else if (activity.type === "TRAINING_SESSION") {
    req.trainingSession = {
      ...activity.trainingSession,
    } as TrainingSessionCreateRequest;
  } else if (activity.type === "CUSTOM_TRAINING_SESSION") {
    req.customTrainingSession = {
      ...activity.customTrainingSession,
    } as CustomTrainingSessionCreateRequest;
  } else if (activity.type === "REST_DAY") {
  }

  saveLastHorse(activity.horse);
  window.localStorage.setItem("last-activity-type", activity.type);
  try {
    var res = await activitiesApi.activitiesCreate(req);
    return res;
  } catch (e) {
    captureException(e);
    return undefined;
  }
};

const saveLastHorse = (horse?: SimpleHorse) => {
  if (!horse) {
    return;
  }
  window.localStorage.setItem("last-horse", JSON.stringify(horse));
};

const getLastUsedHorse = (): SimpleHorse | undefined => {
  let s = window.localStorage.getItem("last-horse");
  if (s && s !== "") {
    try {
      let horse = { ...JSON.parse(s) } as SimpleHorse;
      return horse;
    } catch (e) {
      captureException(e);
    }
  }
  return undefined;
};

export interface ObjId {
  id: number;
}

export const mergeObjList = <T>(objs: ObjId[], objs2: ObjId[]): T[] => {
  var l: any[] = [];
  let ids: number[] = [];
  for (var item of objs) {
    if (!ids.includes(item.id)) {
      ids.push(item.id);
      l.push(item);
    }
  }
  for (var item of objs2) {
    if (!ids.includes(item.id)) {
      ids.push(item.id);
      l.push(item);
    }
  }
  return l;
};
