/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupSettingsRequest } from '../models/GroupSettingsRequest';
import { GroupTypeEnum } from '../models/GroupTypeEnum';
import { SimpleGroupHorseRequest } from '../models/SimpleGroupHorseRequest';
import { SimpleGroupUserRequest } from '../models/SimpleGroupUserRequest';
import { StallionGroupRequest } from '../models/StallionGroupRequest';
import { HttpFile } from '../http/http';

export class PatchedGroupRequest {
    'users'?: Array<SimpleGroupUserRequest>;
    'horses'?: Array<SimpleGroupHorseRequest>;
    'stallionGroup'?: StallionGroupRequest;
    'name'?: string;
    'description'?: string;
    'groupType'?: GroupTypeEnum;
    'thumbnail'?: HttpFile;
    'settings'?: GroupSettingsRequest;
    'locationString'?: string;
    'website'?: string;
    'country'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "users",
            "baseName": "users",
            "type": "Array<SimpleGroupUserRequest>",
            "format": ""
        },
        {
            "name": "horses",
            "baseName": "horses",
            "type": "Array<SimpleGroupHorseRequest>",
            "format": ""
        },
        {
            "name": "stallionGroup",
            "baseName": "stallionGroup",
            "type": "StallionGroupRequest",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "groupType",
            "baseName": "groupType",
            "type": "GroupTypeEnum",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "settings",
            "baseName": "settings",
            "type": "GroupSettingsRequest",
            "format": ""
        },
        {
            "name": "locationString",
            "baseName": "locationString",
            "type": "string",
            "format": ""
        },
        {
            "name": "website",
            "baseName": "website",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedGroupRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



