/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class EventPoints {
    'id': number;
    'event': number;
    'points': number;
    'grade': number;
    'position': number;
    'totalParticipants': number;
    'isStormot': boolean;
    'isWorldRanking': boolean;
    'participant': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "event",
            "baseName": "event",
            "type": "number",
            "format": ""
        },
        {
            "name": "points",
            "baseName": "points",
            "type": "number",
            "format": ""
        },
        {
            "name": "grade",
            "baseName": "grade",
            "type": "number",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "number",
            "format": ""
        },
        {
            "name": "totalParticipants",
            "baseName": "totalParticipants",
            "type": "number",
            "format": ""
        },
        {
            "name": "isStormot",
            "baseName": "isStormot",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isWorldRanking",
            "baseName": "isWorldRanking",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "participant",
            "baseName": "participant",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return EventPoints.attributeTypeMap;
    }

    public constructor() {
    }
}

