import { ChatRoom, PaginatedChatRoomList } from "@/openapi";
import { devPrint } from "@/utils/devUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IChatState {
  chatRooms: PaginatedChatRoomList | undefined;
  selectedChat: ChatRoom | undefined;
}

export const initialChatState: IChatState = {
  chatRooms: undefined,
  selectedChat: undefined,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    setChatRooms: (state, action: PayloadAction<PaginatedChatRoomList>) => {
      if (!state.chatRooms) {
        state.chatRooms = action.payload;
        state.chatRooms.results = state.chatRooms.results!.sort((a, b) => {
          return (
            (b.dtLatestMessage?.getTime() ?? 0) -
            (a.dtLatestMessage?.getTime() ?? 0)
          );
        });
      } else {
        state.chatRooms.next = action.payload.next;
        state.chatRooms.results = state.chatRooms.results!.concat(
          action.payload.results ?? []
        );
        state.chatRooms.results = state.chatRooms.results.sort((a, b) => {
          return (
            (b.dtLatestMessage?.getTime() ?? 0) -
            (a.dtLatestMessage?.getTime() ?? 0)
          );
        });
        state.chatRooms = { ...state.chatRooms };
      }
    },
    setSelectedChatRoom: (
      state,
      action: PayloadAction<ChatRoom | undefined>
    ) => {
      state.selectedChat = action.payload;
    },
  },
});

export const { setChatRooms, setSelectedChatRoom } = chatSlice.actions;

export default chatSlice.reducer;
