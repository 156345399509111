/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ProviderEnum } from '../models/ProviderEnum';
import { HttpFile } from '../http/http';

/**
* serialize allauth SocialAccounts for use with a REST API
*/
export class SocialAccount {
    'id': number;
    'provider': ProviderEnum;
    'uid': string;
    'lastLogin': Date;
    'dateJoined': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "provider",
            "baseName": "provider",
            "type": "ProviderEnum",
            "format": ""
        },
        {
            "name": "uid",
            "baseName": "uid",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastLogin",
            "baseName": "lastLogin",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dateJoined",
            "baseName": "dateJoined",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return SocialAccount.attributeTypeMap;
    }

    public constructor() {
    }
}



