/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleHorse } from '../models/SimpleHorse';
import { HttpFile } from '../http/http';

export class WeeklyTrainingSummary {
    'id': number;
    'year'?: number;
    'week': number;
    'horse': SimpleHorse;
    'dtCreated': Date;
    'dtModified': Date;
    'isTaggedSummary'?: boolean;
    'standingDistance'?: number;
    'walkDistance'?: number;
    'trotDistance'?: number;
    'toltDistance'?: number;
    'paceDistance'?: number;
    'gallopDistance'?: number;
    'standingTime'?: number;
    'walkTime'?: number;
    'trotTime'?: number;
    'toltTime'?: number;
    'paceTime'?: number;
    'gallopTime'?: number;
    'distance'?: number;
    'time'?: number;
    'numRides'?: number;
    'distanceGoal'?: number;
    'timeGoal'?: number;
    'customDistance'?: number;
    'customNumRides'?: number;
    'customTime'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": ""
        },
        {
            "name": "week",
            "baseName": "week",
            "type": "number",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isTaggedSummary",
            "baseName": "isTaggedSummary",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "standingDistance",
            "baseName": "standingDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "walkDistance",
            "baseName": "walkDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "trotDistance",
            "baseName": "trotDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "toltDistance",
            "baseName": "toltDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "paceDistance",
            "baseName": "paceDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "gallopDistance",
            "baseName": "gallopDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "standingTime",
            "baseName": "standingTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "walkTime",
            "baseName": "walkTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "trotTime",
            "baseName": "trotTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "toltTime",
            "baseName": "toltTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "paceTime",
            "baseName": "paceTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "gallopTime",
            "baseName": "gallopTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": ""
        },
        {
            "name": "time",
            "baseName": "time",
            "type": "number",
            "format": "double"
        },
        {
            "name": "numRides",
            "baseName": "numRides",
            "type": "number",
            "format": ""
        },
        {
            "name": "distanceGoal",
            "baseName": "distanceGoal",
            "type": "number",
            "format": "double"
        },
        {
            "name": "timeGoal",
            "baseName": "timeGoal",
            "type": "number",
            "format": "double"
        },
        {
            "name": "customDistance",
            "baseName": "customDistance",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "customNumRides",
            "baseName": "customNumRides",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "customTime",
            "baseName": "customTime",
            "type": "number",
            "format": "int64"
        }    ];

    static getAttributeTypeMap() {
        return WeeklyTrainingSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

