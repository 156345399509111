import {
  HorseActivity,
  SimpleMonthlyActivitySummary,
  SimpleHorseActivity,
  SimpleUserActivity,
} from "@/openapi";
import { mergeObjList, ObjId } from "@/utils/activityUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type HorsesActivitiesSummaries = {
  [key: string]: SimpleHorseActivity[];
};
interface IHorseSummary {
  faedingarnumer: string;
  activities: SimpleHorseActivity[];
}

export interface IActivityState {
  activities: HorseActivity[] | undefined;
  fetchingActivities: boolean;
  next: string | undefined;
  userActivitySummary: SimpleUserActivity[] | undefined;
  horsesActivitySummary: HorsesActivitiesSummaries;
  allHorsesActivitySummary: SimpleMonthlyActivitySummary[] | undefined;
  fetchingAllHorsesActivitySummary: boolean;
}

export const initialActivityState: IActivityState = {
  activities: undefined,
  fetchingActivities: false,
  next: undefined,
  userActivitySummary: undefined,
  horsesActivitySummary: {},
  allHorsesActivitySummary: undefined,
  fetchingAllHorsesActivitySummary: false,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState: initialActivityState,
  reducers: {
    setActivities: (state, action: PayloadAction<HorseActivity[]>) => {
      state.activities = action.payload;
    },
    addToActivities: (state, action: PayloadAction<HorseActivity[]>) => {
      state.activities = (state.activities ?? []).concat(action.payload);
    },
    setNextActivities: (state, action: PayloadAction<string>) => {
      state.next = action.payload;
    },
    setFetchingActivities: (state, action: PayloadAction<boolean>) => {
      state.fetchingActivities = action.payload;
    },
    appendUserActivitySummary: (
      state,
      action: PayloadAction<SimpleUserActivity[]>
    ) => {
      const summary = state.userActivitySummary ?? []; //.concat(action.payload);

      const currIds = state.userActivitySummary?.map((e) => e.id);
      for (var item of action.payload) {
        if (!currIds?.includes(item.id)) {
          summary.push(item);
        }
      }

      state.userActivitySummary = summary;
    },
    appendAllHorsesActivitySummary: (
      state,
      action: PayloadAction<SimpleMonthlyActivitySummary[]>
    ) => {
      const summary = [...(state.allHorsesActivitySummary ?? [])]; //.concat(action.payload);
      if (summary.length === 0) {
        state.allHorsesActivitySummary = action.payload;
        return;
      }

      for (var item of action.payload) {
        let found = false;
        for (var item2 of summary) {
          if (
            item2.month === item.month &&
            item2.year === item.year &&
            item.horse.faedingarnumer === item2.horse.faedingarnumer
          ) {
            found = true;
            item2.activities = mergeObjList(
              item.activities as ObjId[],
              item2.activities as ObjId[]
            );
          }
        }
        if (!found) {
          summary.push(item);
        }
      }

      state.allHorsesActivitySummary = summary;
    },
    setFetchingAllHorsesActivitySummary: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.fetchingAllHorsesActivitySummary = action.payload;
    },
    appendHorsesActivitySummary: (
      state,
      action: PayloadAction<IHorseSummary>
    ) => {
      // state.horsesActivitySummary[action.payload.faedingarnumer] = (
      //   state.horsesActivitySummary[action.payload.faedingarnumer] ?? []
      // ).concat(action.payload.activities);

      const summary =
        state.horsesActivitySummary[action.payload.faedingarnumer] ?? []; //.concat(action.payload);

      const currIds = state.horsesActivitySummary[
        action.payload.faedingarnumer
      ]?.map((e) => e.id);
      for (var item of action.payload.activities) {
        if (!currIds?.includes(item.id)) {
          summary.push(item);
        }
      }

      state.horsesActivitySummary[action.payload.faedingarnumer] = summary;
    },
  },
});

export const {
  setActivities,
  setFetchingActivities,
  setNextActivities,
  addToActivities,
  appendUserActivitySummary,
  appendHorsesActivitySummary,
  appendAllHorsesActivitySummary,
  setFetchingAllHorsesActivitySummary,
} = activitySlice.actions;

export default activitySlice.reducer;
