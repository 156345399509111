/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserExtradataRequest } from '../models/UserExtradataRequest';
import { UserSettingsRequest } from '../models/UserSettingsRequest';
import { HttpFile } from '../http/http';

export class UserRequest {
    'name'?: string;
    'country'?: string;
    'birthdate'?: string;
    'gender'?: string;
    'phone'?: string;
    'aThumbnail'?: string;
    'phoneCountryCode'?: string;
    'description'?: string;
    'role'?: string;
    'avatar'?: HttpFile;
    'wfUser'?: string;
    'settings'?: UserSettingsRequest;
    'userExtradata'?: UserExtradataRequest;
    'locale'?: string;
    'numWfSearches'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthdate",
            "baseName": "birthdate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "gender",
            "baseName": "gender",
            "type": "string",
            "format": ""
        },
        {
            "name": "phone",
            "baseName": "phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "aThumbnail",
            "baseName": "aThumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneCountryCode",
            "baseName": "phoneCountryCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "wfUser",
            "baseName": "wfUser",
            "type": "string",
            "format": ""
        },
        {
            "name": "settings",
            "baseName": "settings",
            "type": "UserSettingsRequest",
            "format": ""
        },
        {
            "name": "userExtradata",
            "baseName": "userExtradata",
            "type": "UserExtradataRequest",
            "format": ""
        },
        {
            "name": "locale",
            "baseName": "locale",
            "type": "string",
            "format": ""
        },
        {
            "name": "numWfSearches",
            "baseName": "numWfSearches",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return UserRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

