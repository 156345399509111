import { devPrint, isTestEnv } from "./devUtils";
import {
  APPLE_KEY_IDENTIFIER,
  APPLE_PRIVATE_KEY_STRING,
  HORSE_DAY_APPLE_TEAM_ID,
} from "./projectKeys";

export const getAppleClientId = () => {
  // verður að vera þetta annars kemur ef ég set "is.horseday.app":
  // invalid_request
  // Invalid client id or web redirect url.
  return isTestEnv() ? "is.horseday.web-dev" : "is.horseday.web";
  //  return isTestEnv() ? "is.horseday.app.dev" : "is.horseday.app";
};

export const getAppleLoginRedirectURI = () => {
  // when using these I cannot get passed the apple sign in pop up
  // return isTestEnv()
  //   ? "https://api-staging-v3.horseday.is/user/apple/login/callback"
  //   : "https://api-v3.horseday.is/user/apple/login/callback";

  return isTestEnv()
    ? "https://app-dev.horseday.is/auth/apple/callback"
    : "https://app.horseday.is/auth/apple/callback";
};

export interface AppleResponse {
  accessToken: string;
  idToken: string;
}

// Notaði https://pub.dev/packages/sign_in_with_apple til að komast að því hvernig á að nota þetta...
export const getAppleAccessToken = async (
  code: string,
  state: string
): Promise<AppleResponse | undefined> => {
  const cs = {
    clientID: getAppleClientId(),
    teamID: HORSE_DAY_APPLE_TEAM_ID,
    keyIdentifier: APPLE_KEY_IDENTIFIER,
    privateKey: APPLE_PRIVATE_KEY_STRING,
  };

  let clientSecret = JSON.stringify(cs);

  const options = {
    client_id: getAppleClientId(), // Apple Client ID
    redirectUri: getAppleLoginRedirectURI(), // use the same value which you passed to authorisation URL.
    client_secret: clientSecret,
  };

  try {
    // const tokenResponse = await appleSignin.getAuthorizationToken(
    //   code,
    //   options
    // );
    const res = await fetch("https://appleid.apple.com/auth/token", {
      method: "POST",
      body: JSON.stringify(options),
    });

    const tokenResponse = await res.json();
    // tokenResponse.
    return Promise.resolve({
      accessToken: tokenResponse.access_token,
      idToken: tokenResponse.id_token,
    });
  } catch (err) {
    console.error("err", err);
    return Promise.reject(undefined);
  }
};
