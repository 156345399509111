/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleHorse } from '../models/SimpleHorse';
import { TrainingSummary } from '../models/TrainingSummary';
import { Weather } from '../models/Weather';
import { HttpFile } from '../http/http';

export class TrainingSession {
    'id': number;
    'intensity'?: number;
    'satisfaction'?: number;
    'trainingSummary'?: TrainingSummary;
    'locationString'?: string;
    'dataIncorrect'?: boolean;
    'weather'?: Weather;
    'taggedHorses'?: Array<SimpleHorse>;
    'bootsWeight'?: number;
    'startIndex'?: number;
    'endIndex'?: number;
    'hasProcessed'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "intensity",
            "baseName": "intensity",
            "type": "number",
            "format": ""
        },
        {
            "name": "satisfaction",
            "baseName": "satisfaction",
            "type": "number",
            "format": ""
        },
        {
            "name": "trainingSummary",
            "baseName": "trainingSummary",
            "type": "TrainingSummary",
            "format": ""
        },
        {
            "name": "locationString",
            "baseName": "locationString",
            "type": "string",
            "format": ""
        },
        {
            "name": "dataIncorrect",
            "baseName": "dataIncorrect",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "weather",
            "baseName": "weather",
            "type": "Weather",
            "format": ""
        },
        {
            "name": "taggedHorses",
            "baseName": "taggedHorses",
            "type": "Array<SimpleHorse>",
            "format": ""
        },
        {
            "name": "bootsWeight",
            "baseName": "bootsWeight",
            "type": "number",
            "format": ""
        },
        {
            "name": "startIndex",
            "baseName": "startIndex",
            "type": "number",
            "format": ""
        },
        {
            "name": "endIndex",
            "baseName": "endIndex",
            "type": "number",
            "format": ""
        },
        {
            "name": "hasProcessed",
            "baseName": "hasProcessed",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TrainingSession.attributeTypeMap;
    }

    public constructor() {
    }
}

