/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Summary {
    'distance': number;
    'sessions': number;
    'time': number;
    'month'?: number;
    'week'?: number;
    'noRestDay': number;
    'toltDistance'?: number;
    'walkDistance'?: number;
    'trotDistance'?: number;
    'paceDistance'?: number;
    'gallopDistance'?: number;
    'intensity'?: number;
    'satisfaction'?: number;
    'year'?: number;
    'totalNumActivities': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": ""
        },
        {
            "name": "sessions",
            "baseName": "sessions",
            "type": "number",
            "format": ""
        },
        {
            "name": "time",
            "baseName": "time",
            "type": "number",
            "format": "double"
        },
        {
            "name": "month",
            "baseName": "month",
            "type": "number",
            "format": ""
        },
        {
            "name": "week",
            "baseName": "week",
            "type": "number",
            "format": ""
        },
        {
            "name": "noRestDay",
            "baseName": "noRestDay",
            "type": "number",
            "format": ""
        },
        {
            "name": "toltDistance",
            "baseName": "toltDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "walkDistance",
            "baseName": "walkDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "trotDistance",
            "baseName": "trotDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "paceDistance",
            "baseName": "paceDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "gallopDistance",
            "baseName": "gallopDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "intensity",
            "baseName": "intensity",
            "type": "number",
            "format": "double"
        },
        {
            "name": "satisfaction",
            "baseName": "satisfaction",
            "type": "number",
            "format": "double"
        },
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": ""
        },
        {
            "name": "totalNumActivities",
            "baseName": "totalNumActivities",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Summary.attributeTypeMap;
    }

    public constructor() {
    }
}

