/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class StripeCoupon {
    'id': number;
    'intendedRedeemer': SimpleUser;
    'buyer': SimpleUser;
    'dtCreated': Date;
    'buyerEmail'?: string;
    'entitlement'?: string;
    'duration': string;
    'metadata'?: { [key: string]: any; };
    'couponId': string;
    'name': string;
    'productId': string;
    'priceId': string;
    'valid'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "intendedRedeemer",
            "baseName": "intendedRedeemer",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "buyer",
            "baseName": "buyer",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "buyerEmail",
            "baseName": "buyerEmail",
            "type": "string",
            "format": ""
        },
        {
            "name": "entitlement",
            "baseName": "entitlement",
            "type": "string",
            "format": ""
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "string",
            "format": ""
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "{ [key: string]: any; }",
            "format": ""
        },
        {
            "name": "couponId",
            "baseName": "couponId",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "productId",
            "baseName": "productId",
            "type": "string",
            "format": ""
        },
        {
            "name": "priceId",
            "baseName": "priceId",
            "type": "string",
            "format": ""
        },
        {
            "name": "valid",
            "baseName": "valid",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StripeCoupon.attributeTypeMap;
    }

    public constructor() {
    }
}

