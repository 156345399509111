/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class TrainingSummary {
    'id': number;
    'tolt'?: number;
    'standing'?: number;
    'trot'?: number;
    'walk'?: number;
    'gallop'?: number;
    'pace'?: number;
    'standingDistance'?: number;
    'standingTime'?: number;
    'walkDistance'?: number;
    'walkTime'?: number;
    'toltDistance'?: number;
    'toltTime'?: number;
    'paceDistance'?: number;
    'paceTime'?: number;
    'trotDistance'?: number;
    'trotTime'?: number;
    'gallopDistance'?: number;
    'gallopTime'?: number;
    'distance'?: number;
    'time'?: number;
    'speed'?: number;
    'movingTime'?: number;
    'movingDistance'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "tolt",
            "baseName": "tolt",
            "type": "number",
            "format": "double"
        },
        {
            "name": "standing",
            "baseName": "standing",
            "type": "number",
            "format": "double"
        },
        {
            "name": "trot",
            "baseName": "trot",
            "type": "number",
            "format": "double"
        },
        {
            "name": "walk",
            "baseName": "walk",
            "type": "number",
            "format": "double"
        },
        {
            "name": "gallop",
            "baseName": "gallop",
            "type": "number",
            "format": "double"
        },
        {
            "name": "pace",
            "baseName": "pace",
            "type": "number",
            "format": "double"
        },
        {
            "name": "standingDistance",
            "baseName": "standingDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "standingTime",
            "baseName": "standingTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "walkDistance",
            "baseName": "walkDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "walkTime",
            "baseName": "walkTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "toltDistance",
            "baseName": "toltDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "toltTime",
            "baseName": "toltTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "paceDistance",
            "baseName": "paceDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "paceTime",
            "baseName": "paceTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "trotDistance",
            "baseName": "trotDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "trotTime",
            "baseName": "trotTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "gallopDistance",
            "baseName": "gallopDistance",
            "type": "number",
            "format": ""
        },
        {
            "name": "gallopTime",
            "baseName": "gallopTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": "double"
        },
        {
            "name": "time",
            "baseName": "time",
            "type": "number",
            "format": "double"
        },
        {
            "name": "speed",
            "baseName": "speed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "movingTime",
            "baseName": "movingTime",
            "type": "number",
            "format": "double"
        },
        {
            "name": "movingDistance",
            "baseName": "movingDistance",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return TrainingSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

