/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class CustomTrainingSessionCreate {
    'id': number;
    'intensity'?: number;
    'satisfaction'?: number;
    'duration'?: number;
    'distance'?: number;
    'trainer'?: string;
    'trainingTypes'?: string;
    'taggedHorses'?: Array<string>;
    'bootsWeight'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "intensity",
            "baseName": "intensity",
            "type": "number",
            "format": ""
        },
        {
            "name": "satisfaction",
            "baseName": "satisfaction",
            "type": "number",
            "format": ""
        },
        {
            "name": "duration",
            "baseName": "duration",
            "type": "number",
            "format": ""
        },
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": "double"
        },
        {
            "name": "trainer",
            "baseName": "trainer",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainingTypes",
            "baseName": "trainingTypes",
            "type": "string",
            "format": ""
        },
        {
            "name": "taggedHorses",
            "baseName": "taggedHorses",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "bootsWeight",
            "baseName": "bootsWeight",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CustomTrainingSessionCreate.attributeTypeMap;
    }

    public constructor() {
    }
}

