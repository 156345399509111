// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { FaedingarnumerSerilizerRequest } from '../models/FaedingarnumerSerilizerRequest';
import { GaitRating } from '../models/GaitRating';
import { GaitRatingRequest } from '../models/GaitRatingRequest';
import { GaitSensorRequest } from '../models/GaitSensorRequest';
import { HorseActivity } from '../models/HorseActivity';
import { PaginatedTrainingSessionList } from '../models/PaginatedTrainingSessionList';
import { PatchedTrainingSessionRequest } from '../models/PatchedTrainingSessionRequest';
import { SimpleBooleanRequest } from '../models/SimpleBooleanRequest';
import { SimpleMessage } from '../models/SimpleMessage';
import { StartEndRequest } from '../models/StartEndRequest';
import { TrackingPointCorrectionRequest } from '../models/TrackingPointCorrectionRequest';
import { TrainingSession } from '../models/TrainingSession';
import { TrainingSessionRequest } from '../models/TrainingSessionRequest';
import { TrainingSessionTrackingPoint } from '../models/TrainingSessionTrackingPoint';
import { TrainingSessionTrackingPointRequest } from '../models/TrainingSessionTrackingPointRequest';

/**
 * no description
 */
export class TrainingSessionsApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trackingPointCorrectionRequest 
     */
    public async trainingSessionsCorrectGaitCreate(activity: number, trackingPointCorrectionRequest: TrackingPointCorrectionRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsCorrectGaitCreate", "activity");
        }


        // verify required parameter 'trackingPointCorrectionRequest' is not null or undefined
        if (trackingPointCorrectionRequest === null || trackingPointCorrectionRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsCorrectGaitCreate", "trackingPointCorrectionRequest");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/correct-gait/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(trackingPointCorrectionRequest, "TrackingPointCorrectionRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitRatingRequest 
     */
    public async trainingSessionsGaitRatingCreate(activity: number, gaitRatingRequest?: GaitRatingRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsGaitRatingCreate", "activity");
        }



        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/gait-rating/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(gaitRatingRequest, "GaitRatingRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitSensorRequest 
     */
    public async trainingSessionsGaitSensorsCreate(activity: number, gaitSensorRequest: Array<GaitSensorRequest>, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsGaitSensorsCreate", "activity");
        }


        // verify required parameter 'gaitSensorRequest' is not null or undefined
        if (gaitSensorRequest === null || gaitSensorRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsGaitSensorsCreate", "gaitSensorRequest");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/gait_sensors/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(gaitSensorRequest, "Array<GaitSensorRequest>", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public async trainingSessionsGetGaitRatingRetrieve(activity: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsGetGaitRatingRetrieve", "activity");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/get-gait-rating/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public async trainingSessionsGetTrackingPointsList(activity: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsGetTrackingPointsList", "activity");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/get_tracking_points/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activityHorse 
     * @param isIndoors 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public async trainingSessionsList(activityHorse?: string, isIndoors?: boolean, page?: number, pageSize?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;





        // Path Params
        const localVarPath = '/api/training-sessions/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (activityHorse !== undefined) {
            requestContext.setQueryParam("activity__horse", ObjectSerializer.serialize(activityHorse, "string", ""));
        }

        // Query Params
        if (isIndoors !== undefined) {
            requestContext.setQueryParam("is_indoors", ObjectSerializer.serialize(isIndoors, "boolean", ""));
        }

        // Query Params
        if (page !== undefined) {
            requestContext.setQueryParam("page", ObjectSerializer.serialize(page, "number", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param patchedTrainingSessionRequest 
     */
    public async trainingSessionsPartialUpdate(activity: number, patchedTrainingSessionRequest?: PatchedTrainingSessionRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsPartialUpdate", "activity");
        }



        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(patchedTrainingSessionRequest, "PatchedTrainingSessionRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public async trainingSessionsRetrieve(activity: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsRetrieve", "activity");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionTrackingPointRequest 
     * @param requestId 
     * @param totalNumberOfPoints 
     */
    public async trainingSessionsSaveCurrentTrackingWithTaskCreate(activity: number, trainingSessionTrackingPointRequest: Array<TrainingSessionTrackingPointRequest>, requestId?: string, totalNumberOfPoints?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsSaveCurrentTrackingWithTaskCreate", "activity");
        }


        // verify required parameter 'trainingSessionTrackingPointRequest' is not null or undefined
        if (trainingSessionTrackingPointRequest === null || trainingSessionTrackingPointRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsSaveCurrentTrackingWithTaskCreate", "trainingSessionTrackingPointRequest");
        }




        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/save_current_tracking_with_task/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (requestId !== undefined) {
            requestContext.setQueryParam("request_id", ObjectSerializer.serialize(requestId, "string", ""));
        }

        // Query Params
        if (totalNumberOfPoints !== undefined) {
            requestContext.setQueryParam("total_number_of_points", ObjectSerializer.serialize(totalNumberOfPoints, "number", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(trainingSessionTrackingPointRequest, "Array<TrainingSessionTrackingPointRequest>", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param startEndRequest 
     */
    public async trainingSessionsSetStartEndOfTrackingCreate(activity: number, startEndRequest: StartEndRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsSetStartEndOfTrackingCreate", "activity");
        }


        // verify required parameter 'startEndRequest' is not null or undefined
        if (startEndRequest === null || startEndRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsSetStartEndOfTrackingCreate", "startEndRequest");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/set_start_end_of_tracking/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(startEndRequest, "StartEndRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param faedingarnumerSerilizerRequest 
     */
    public async trainingSessionsStartTrackingCreate(faedingarnumerSerilizerRequest: FaedingarnumerSerilizerRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'faedingarnumerSerilizerRequest' is not null or undefined
        if (faedingarnumerSerilizerRequest === null || faedingarnumerSerilizerRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsStartTrackingCreate", "faedingarnumerSerilizerRequest");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/start_tracking/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(faedingarnumerSerilizerRequest, "FaedingarnumerSerilizerRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param simpleBooleanRequest 
     */
    public async trainingSessionsToggleSafetyTrackingCreate(simpleBooleanRequest: SimpleBooleanRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'simpleBooleanRequest' is not null or undefined
        if (simpleBooleanRequest === null || simpleBooleanRequest === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsToggleSafetyTrackingCreate", "simpleBooleanRequest");
        }


        // Path Params
        const localVarPath = '/api/training-sessions/toggle_safety_tracking/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(simpleBooleanRequest, "SimpleBooleanRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param activityHorse 
     * @param isIndoors 
     */
    public async trainingSessionsTrackingList(activity: number, activityHorse?: string, isIndoors?: boolean, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsTrackingList", "activity");
        }




        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/tracking/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (activityHorse !== undefined) {
            requestContext.setQueryParam("activity__horse", ObjectSerializer.serialize(activityHorse, "string", ""));
        }

        // Query Params
        if (isIndoors !== undefined) {
            requestContext.setQueryParam("is_indoors", ObjectSerializer.serialize(isIndoors, "boolean", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionRequest 
     */
    public async trainingSessionsUpdate(activity: number, trainingSessionRequest?: TrainingSessionRequest, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'activity' is not null or undefined
        if (activity === null || activity === undefined) {
            throw new RequiredError("TrainingSessionsApi", "trainingSessionsUpdate", "activity");
        }



        // Path Params
        const localVarPath = '/api/training-sessions/{activity}/'
            .replace('{' + 'activity' + '}', encodeURIComponent(String(activity)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PUT);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json",
        
            "application/x-www-form-urlencoded",
        
            "multipart/form-data"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(trainingSessionRequest, "TrainingSessionRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class TrainingSessionsApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsCorrectGaitCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsCorrectGaitCreate(response: ResponseContext): Promise<Array<TrainingSessionTrackingPoint> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsGaitRatingCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsGaitRatingCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsGaitSensorsCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsGaitSensorsCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsGetGaitRatingRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsGetGaitRatingRetrieve(response: ResponseContext): Promise<GaitRating > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: GaitRating = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GaitRating", ""
            ) as GaitRating;
            return body;
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            throw new ApiException<SimpleMessage>(response.httpStatusCode, "", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: GaitRating = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GaitRating", ""
            ) as GaitRating;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsGetTrackingPointsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsGetTrackingPointsList(response: ResponseContext): Promise<Array<TrainingSessionTrackingPoint> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsList(response: ResponseContext): Promise<PaginatedTrainingSessionList > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedTrainingSessionList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedTrainingSessionList", ""
            ) as PaginatedTrainingSessionList;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedTrainingSessionList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedTrainingSessionList", ""
            ) as PaginatedTrainingSessionList;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsPartialUpdate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsPartialUpdate(response: ResponseContext): Promise<TrainingSession > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsRetrieve(response: ResponseContext): Promise<TrainingSession > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsSaveCurrentTrackingWithTaskCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsSaveCurrentTrackingWithTaskCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsSetStartEndOfTrackingCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsSetStartEndOfTrackingCreate(response: ResponseContext): Promise<HorseActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsStartTrackingCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsStartTrackingCreate(response: ResponseContext): Promise<HorseActivity > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseActivity = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseActivity", ""
            ) as HorseActivity;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsToggleSafetyTrackingCreate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsToggleSafetyTrackingCreate(response: ResponseContext): Promise<SimpleMessage > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: SimpleMessage = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "SimpleMessage", ""
            ) as SimpleMessage;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsTrackingList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsTrackingList(response: ResponseContext): Promise<Array<TrainingSessionTrackingPoint> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<TrainingSessionTrackingPoint> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<TrainingSessionTrackingPoint>", ""
            ) as Array<TrainingSessionTrackingPoint>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to trainingSessionsUpdate
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async trainingSessionsUpdate(response: ResponseContext): Promise<TrainingSession > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: TrainingSession = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "TrainingSession", ""
            ) as TrainingSession;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
