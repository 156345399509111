/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GaitEnum } from '../models/GaitEnum';
import { GaitPredictions } from '../models/GaitPredictions';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { HttpFile } from '../http/http';

export class TrainingSessionTrackingPoint {
    'datetime': Date;
    'location': HorseDetailsLocation;
    'paused'?: boolean;
    'gait'?: GaitEnum;
    'elevation'?: number;
    'speed'?: number;
    'processedLocation'?: HorseActivityLocation;
    'processedSpeed'?: number;
    'processedGait'?: GaitEnum;
    'gaitPredictions'?: GaitPredictions;
    'userCorrectedGait'?: GaitEnum;
    'pruned'?: boolean;
    'accuracy'?: number;
    'speedAccuracy'?: number;
    'softmaxArray'?: Array<number>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "datetime",
            "baseName": "datetime",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseDetailsLocation",
            "format": ""
        },
        {
            "name": "paused",
            "baseName": "paused",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "gait",
            "baseName": "gait",
            "type": "GaitEnum",
            "format": ""
        },
        {
            "name": "elevation",
            "baseName": "elevation",
            "type": "number",
            "format": "double"
        },
        {
            "name": "speed",
            "baseName": "speed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "processedLocation",
            "baseName": "processedLocation",
            "type": "HorseActivityLocation",
            "format": ""
        },
        {
            "name": "processedSpeed",
            "baseName": "processedSpeed",
            "type": "number",
            "format": "double"
        },
        {
            "name": "processedGait",
            "baseName": "processedGait",
            "type": "GaitEnum",
            "format": ""
        },
        {
            "name": "gaitPredictions",
            "baseName": "gaitPredictions",
            "type": "GaitPredictions",
            "format": ""
        },
        {
            "name": "userCorrectedGait",
            "baseName": "userCorrectedGait",
            "type": "GaitEnum",
            "format": ""
        },
        {
            "name": "pruned",
            "baseName": "pruned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "accuracy",
            "baseName": "accuracy",
            "type": "number",
            "format": ""
        },
        {
            "name": "speedAccuracy",
            "baseName": "speedAccuracy",
            "type": "number",
            "format": "double"
        },
        {
            "name": "softmaxArray",
            "baseName": "softmaxArray",
            "type": "Array<number>",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return TrainingSessionTrackingPoint.attributeTypeMap;
    }

    public constructor() {
    }
}

