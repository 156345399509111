/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GaitEnum } from '../models/GaitEnum';
import { IntervalTypeEnum } from '../models/IntervalTypeEnum';
import { MetricEnum } from '../models/MetricEnum';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { HttpFile } from '../http/http';

export class PatchedLeaderboardRequest {
    'user'?: SimpleUserRequest;
    'intervalType'?: IntervalTypeEnum;
    'intervalValue'?: number;
    'metric'?: MetricEnum;
    'metricValue'?: number;
    'year'?: number;
    'gait'?: GaitEnum;
    'rank'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUserRequest",
            "format": ""
        },
        {
            "name": "intervalType",
            "baseName": "intervalType",
            "type": "IntervalTypeEnum",
            "format": ""
        },
        {
            "name": "intervalValue",
            "baseName": "intervalValue",
            "type": "number",
            "format": ""
        },
        {
            "name": "metric",
            "baseName": "metric",
            "type": "MetricEnum",
            "format": ""
        },
        {
            "name": "metricValue",
            "baseName": "metricValue",
            "type": "number",
            "format": ""
        },
        {
            "name": "year",
            "baseName": "year",
            "type": "number",
            "format": ""
        },
        {
            "name": "gait",
            "baseName": "gait",
            "type": "GaitEnum",
            "format": ""
        },
        {
            "name": "rank",
            "baseName": "rank",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedLeaderboardRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

