// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { Horse } from '../models/Horse';
import { HorseQueryMetadata } from '../models/HorseQueryMetadata';
import { PaginatedHorseList } from '../models/PaginatedHorseList';

/**
 * no description
 */
export class HorsesQueriesApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * @param adaleinkunn 
     * @param assessment 
     * @param availableForBreeding 
     * @param birthDate 
     * @param birthDateFiilterAfter 
     * @param birthDateFiilterBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param forSale 
     * @param haestaAdaleinkunn 
     * @param horseType 
     * @param name 
     * @param noPriceSet 
     * @param order Ordering
     * @param originCountry 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param priceCategory 
     * @param priceRangeGreaterThan 
     * @param priceRangeLessThan 
     * @param sexEnum 
     * @param ssName 
     * @param ssPlace 
     */
    public async horsesQueriesList(adaleinkunn?: number, assessment?: number, availableForBreeding?: boolean, birthDate?: string, birthDateFiilterAfter?: string, birthDateFiilterBefore?: string, dateRangeAfter?: string, dateRangeBefore?: string, forSale?: boolean, haestaAdaleinkunn?: number, horseType?: Array<'COMPETITION' | 'FAMILY' | 'OTHER' | 'PACE' | 'RIDE' | 'UNTAMED'>, name?: string, noPriceSet?: boolean, order?: Array<'-adaleinkunn' | '-dt_set_for_sale' | '-for_sale__price' | '-haestaAdaleinkunn' | '-name' | 'adaleinkunn' | 'dt_set_for_sale' | 'for_sale__price' | 'haestaAdaleinkunn' | 'name'>, originCountry?: string, page?: number, pageSize?: number, priceCategory?: Array<'ONE' | 'THREE' | 'TWO' | 'UNDEFINED'>, priceRangeGreaterThan?: number, priceRangeLessThan?: number, sexEnum?: Array<'GELDING' | 'MARE' | 'STALLION'>, ssName?: string, ssPlace?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;
























        // Path Params
        const localVarPath = '/api/horses-queries/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (adaleinkunn !== undefined) {
            requestContext.setQueryParam("adaleinkunn", ObjectSerializer.serialize(adaleinkunn, "number", ""));
        }

        // Query Params
        if (assessment !== undefined) {
            requestContext.setQueryParam("assessment", ObjectSerializer.serialize(assessment, "number", ""));
        }

        // Query Params
        if (availableForBreeding !== undefined) {
            requestContext.setQueryParam("available_for_breeding", ObjectSerializer.serialize(availableForBreeding, "boolean", ""));
        }

        // Query Params
        if (birthDate !== undefined) {
            requestContext.setQueryParam("birth_date", ObjectSerializer.serialize(birthDate, "string", "date"));
        }

        // Query Params
        if (birthDateFiilterAfter !== undefined) {
            requestContext.setQueryParam("birth_date_fiilter_after", ObjectSerializer.serialize(birthDateFiilterAfter, "string", "date"));
        }

        // Query Params
        if (birthDateFiilterBefore !== undefined) {
            requestContext.setQueryParam("birth_date_fiilter_before", ObjectSerializer.serialize(birthDateFiilterBefore, "string", "date"));
        }

        // Query Params
        if (dateRangeAfter !== undefined) {
            requestContext.setQueryParam("date_range_after", ObjectSerializer.serialize(dateRangeAfter, "string", "date"));
        }

        // Query Params
        if (dateRangeBefore !== undefined) {
            requestContext.setQueryParam("date_range_before", ObjectSerializer.serialize(dateRangeBefore, "string", "date"));
        }

        // Query Params
        if (forSale !== undefined) {
            requestContext.setQueryParam("for_sale", ObjectSerializer.serialize(forSale, "boolean", ""));
        }

        // Query Params
        if (haestaAdaleinkunn !== undefined) {
            requestContext.setQueryParam("haestaAdaleinkunn", ObjectSerializer.serialize(haestaAdaleinkunn, "number", "float"));
        }

        // Query Params
        if (horseType !== undefined) {
            requestContext.setQueryParam("horse_type", ObjectSerializer.serialize(horseType, "Array<'COMPETITION' | 'FAMILY' | 'OTHER' | 'PACE' | 'RIDE' | 'UNTAMED'>", ""));
        }

        // Query Params
        if (name !== undefined) {
            requestContext.setQueryParam("name", ObjectSerializer.serialize(name, "string", ""));
        }

        // Query Params
        if (noPriceSet !== undefined) {
            requestContext.setQueryParam("no_price_set", ObjectSerializer.serialize(noPriceSet, "boolean", ""));
        }

        // Query Params
        if (order !== undefined) {
            requestContext.setQueryParam("order", ObjectSerializer.serialize(order, "Array<'-adaleinkunn' | '-dt_set_for_sale' | '-for_sale__price' | '-haestaAdaleinkunn' | '-name' | 'adaleinkunn' | 'dt_set_for_sale' | 'for_sale__price' | 'haestaAdaleinkunn' | 'name'>", ""));
        }

        // Query Params
        if (originCountry !== undefined) {
            requestContext.setQueryParam("origin_country", ObjectSerializer.serialize(originCountry, "string", ""));
        }

        // Query Params
        if (page !== undefined) {
            requestContext.setQueryParam("page", ObjectSerializer.serialize(page, "number", ""));
        }

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", ""));
        }

        // Query Params
        if (priceCategory !== undefined) {
            requestContext.setQueryParam("price_category", ObjectSerializer.serialize(priceCategory, "Array<'ONE' | 'THREE' | 'TWO' | 'UNDEFINED'>", ""));
        }

        // Query Params
        if (priceRangeGreaterThan !== undefined) {
            requestContext.setQueryParam("price_range_greater_than", ObjectSerializer.serialize(priceRangeGreaterThan, "number", ""));
        }

        // Query Params
        if (priceRangeLessThan !== undefined) {
            requestContext.setQueryParam("price_range_less_than", ObjectSerializer.serialize(priceRangeLessThan, "number", ""));
        }

        // Query Params
        if (sexEnum !== undefined) {
            requestContext.setQueryParam("sex_enum", ObjectSerializer.serialize(sexEnum, "Array<'GELDING' | 'MARE' | 'STALLION'>", ""));
        }

        // Query Params
        if (ssName !== undefined) {
            requestContext.setQueryParam("ss_name", ObjectSerializer.serialize(ssName, "string", ""));
        }

        // Query Params
        if (ssPlace !== undefined) {
            requestContext.setQueryParam("ss_place", ObjectSerializer.serialize(ssPlace, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     */
    public async horsesQueriesMetadataRetrieve(_options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // Path Params
        const localVarPath = '/api/horses-queries/metadata/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * will this be quicker
     * @param number 
     */
    public async horsesQueriesNewestForSaleList(number?: number, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;


        // Path Params
        const localVarPath = '/api/horses-queries/newest-for-sale/';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (number !== undefined) {
            requestContext.setQueryParam("number", ObjectSerializer.serialize(number, "number", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["tokenAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        // Apply auth methods
        authMethod = _config.authMethods["cookieAuth"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class HorsesQueriesApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to horsesQueriesList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async horsesQueriesList(response: ResponseContext): Promise<PaginatedHorseList > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: PaginatedHorseList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseList", ""
            ) as PaginatedHorseList;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: PaginatedHorseList = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "PaginatedHorseList", ""
            ) as PaginatedHorseList;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to horsesQueriesMetadataRetrieve
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async horsesQueriesMetadataRetrieve(response: ResponseContext): Promise<HorseQueryMetadata > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: HorseQueryMetadata = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseQueryMetadata", ""
            ) as HorseQueryMetadata;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: HorseQueryMetadata = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HorseQueryMetadata", ""
            ) as HorseQueryMetadata;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to horsesQueriesNewestForSaleList
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async horsesQueriesNewestForSaleList(response: ResponseContext): Promise<Array<Horse> > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: Array<Horse> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Horse>", ""
            ) as Array<Horse>;
            return body;
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: Array<Horse> = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "Array<Horse>", ""
            ) as Array<Horse>;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
