/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupMare } from '../models/GroupMare';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { SimpleHorse } from '../models/SimpleHorse';
import { HttpFile } from '../http/http';

export class StallionGroup {
    'id': number;
    'mares': Array<GroupMare>;
    'stallion': SimpleHorse;
    'locationString'?: string;
    'location'?: HorseDetailsLocation;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "mares",
            "baseName": "mares",
            "type": "Array<GroupMare>",
            "format": ""
        },
        {
            "name": "stallion",
            "baseName": "stallion",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "locationString",
            "baseName": "locationString",
            "type": "string",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseDetailsLocation",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StallionGroup.attributeTypeMap;
    }

    public constructor() {
    }
}

