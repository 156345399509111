import { Horse, PaginatedHorseList, SimpleHorse } from "@/openapi";
import { devPrint } from "@/utils/devUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IHorsesState {
  horses: Horse[] | undefined;
  simpleHorses: SimpleHorse[] | undefined;
  loadingSimpleHorses: boolean;
  salesHorses: PaginatedHorseList | undefined;
  salesHorse: Horse | undefined;

  breedingHorses: PaginatedHorseList | undefined;
  breedingHorse: Horse | undefined;
}

export const initialHorsesState: IHorsesState = {
  horses: undefined,
  simpleHorses: undefined,
  loadingSimpleHorses: false,
  salesHorses: undefined,
  salesHorse: undefined,
  breedingHorses: undefined,
  breedingHorse: undefined,
};

export const horsesSlice = createSlice({
  name: "horses",
  initialState: initialHorsesState,
  reducers: {
    setHorses: (state, action: PayloadAction<Horse[]>) => {
      state.horses = action.payload;
    },
    setSalesHorses: (state, action: PayloadAction<PaginatedHorseList>) => {
      if (!state.salesHorses) {
        state.salesHorses = action.payload;
      } else {
        state.salesHorses.next = action.payload.next;

        state.salesHorses.results = state.salesHorses.results!.concat(
          action.payload.results ?? []
        );
        state.salesHorses = { ...state.salesHorses };
      }
    },
    setSalesHorse: (state, action: PayloadAction<Horse | undefined>) => {
      state.salesHorse = action.payload;
    },

    setBreedingHorses: (state, action: PayloadAction<PaginatedHorseList>) => {
      if (!state.breedingHorses) {
        state.breedingHorses = action.payload;
      } else {
        state.breedingHorses.next = action.payload.next;

        state.breedingHorses.results = state.breedingHorses.results!.concat(
          action.payload.results ?? []
        );
        state.breedingHorses = { ...state.breedingHorses };
      }
    },
    setBreedingHorse: (state, action: PayloadAction<Horse | undefined>) => {
      state.breedingHorse = action.payload;
    },
    setSimpleHorses: (state, action: PayloadAction<SimpleHorse[]>) => {
      state.simpleHorses = action.payload;
    },
    setLoadingSimpleHorses: (state, action: PayloadAction<boolean>) => {
      state.loadingSimpleHorses = action.payload;
    },
  },
});

export const {
  setHorses,
  setSimpleHorses,
  setLoadingSimpleHorses,
  setSalesHorses,
  setSalesHorse,
  setBreedingHorse,
  setBreedingHorses,
} = horsesSlice.actions;

export default horsesSlice.reducer;
