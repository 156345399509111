/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StripePrice } from '../models/StripePrice';
import { StripeProduct } from '../models/StripeProduct';
import { HttpFile } from '../http/http';

export class StripePricesAndProducts {
    'prices': Array<StripePrice>;
    'products': Array<StripeProduct>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "prices",
            "baseName": "prices",
            "type": "Array<StripePrice>",
            "format": ""
        },
        {
            "name": "products",
            "baseName": "products",
            "type": "Array<StripeProduct>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StripePricesAndProducts.attributeTypeMap;
    }

    public constructor() {
    }
}

