export * from '../models/AcceptRequest';
export * from '../models/ActivityMedia';
export * from '../models/ActivityReaction';
export * from '../models/ActivityReactionRequest';
export * from '../models/ActivityTypeEnum';
export * from '../models/ActivityVideoThumbnailRequest';
export * from '../models/Actor';
export * from '../models/AnswerUserSurveyRequest';
export * from '../models/AppMeta';
export * from '../models/AssessmentOverview';
export * from '../models/ChatMessage';
export * from '../models/ChatMessageRequest';
export * from '../models/ChatRoom';
export * from '../models/ChatRoomRequest';
export * from '../models/ChatUser';
export * from '../models/ChatUserRequest';
export * from '../models/Comment';
export * from '../models/CommentReaction';
export * from '../models/CommentReactionRequest';
export * from '../models/CommentReactionSummary';
export * from '../models/CommentRequest';
export * from '../models/CountryYears';
export * from '../models/CreateGroupDiscussionRequest';
export * from '../models/CreateGroupRequest';
export * from '../models/CreateGroupUserInviteRequest';
export * from '../models/CreateHorseAccessInviteRequest';
export * from '../models/CreateHorseAccessReqRequest';
export * from '../models/CreateHorseAccessRequest';
export * from '../models/CreateHorseRequest';
export * from '../models/CustomTrainingSession';
export * from '../models/CustomTrainingSessionCreate';
export * from '../models/CustomTrainingSessionCreateRequest';
export * from '../models/DetailedAssessmentOverview';
export * from '../models/DownloadPurposeEnum';
export * from '../models/DurationEnum';
export * from '../models/EntitlementEnum';
export * from '../models/EventPoints';
export * from '../models/FaedingarnumerSerilizerRequest';
export * from '../models/Feeding';
export * from '../models/FeedingRequest';
export * from '../models/FlokkurEnum';
export * from '../models/FlokkurKeppni';
export * from '../models/FollowEventFlokkur';
export * from '../models/FollowEventFlokkurRequest';
export * from '../models/FollowRequest';
export * from '../models/FollowingFollowers';
export * from '../models/GaitEnum';
export * from '../models/GaitPredictions';
export * from '../models/GaitPredictionsRequest';
export * from '../models/GaitRating';
export * from '../models/GaitRatingRequest';
export * from '../models/GaitSensorRequest';
export * from '../models/GoalRequest';
export * from '../models/Group';
export * from '../models/GroupDiscussion';
export * from '../models/GroupDiscussionComment';
export * from '../models/GroupDiscussionCommentReaction';
export * from '../models/GroupDiscussionCommentReactionRequest';
export * from '../models/GroupDiscussionCommentRequest';
export * from '../models/GroupDiscussionPhoto';
export * from '../models/GroupDiscussionPhotoRequest';
export * from '../models/GroupDiscussionReaction';
export * from '../models/GroupDiscussionReactionRequest';
export * from '../models/GroupInvite';
export * from '../models/GroupMare';
export * from '../models/GroupMareRequest';
export * from '../models/GroupProfile';
export * from '../models/GroupRequest';
export * from '../models/GroupSettings';
export * from '../models/GroupSettingsRequest';
export * from '../models/GroupThumbnailRequest';
export * from '../models/GroupTypeEnum';
export * from '../models/GroupUserRoleEnum';
export * from '../models/GroupUserRoleRequest';
export * from '../models/HealthStatusEnum';
export * from '../models/Horse';
export * from '../models/HorseAccess';
export * from '../models/HorseAccessHistory';
export * from '../models/HorseAccessInvite';
export * from '../models/HorseAccessInviteRequest';
export * from '../models/HorseAccessReq';
export * from '../models/HorseAccessRequest';
export * from '../models/HorseActivities';
export * from '../models/HorseActivity';
export * from '../models/HorseActivityCreate';
export * from '../models/HorseActivityCreateRequest';
export * from '../models/HorseActivityLocation';
export * from '../models/HorseActivityMultiCreateRequest';
export * from '../models/HorseActivityPhoto';
export * from '../models/HorseActivityPhotoRequest';
export * from '../models/HorseActivityReactionSummary';
export * from '../models/HorseActivityUpdate';
export * from '../models/HorseActivityUpdateRequest';
export * from '../models/HorseActivityVideo';
export * from '../models/HorseActivityVideoRequest';
export * from '../models/HorseAssessment';
export * from '../models/HorseAssessmentRequest';
export * from '../models/HorseBreedEnum';
export * from '../models/HorseDayMessage';
export * from '../models/HorseDayRanking';
export * from '../models/HorseDetails';
export * from '../models/HorseDetailsLocation';
export * from '../models/HorseDetailsRequest';
export * from '../models/HorseForSale';
export * from '../models/HorseForSaleRequest';
export * from '../models/HorseIdRequest';
export * from '../models/HorseMedicalDetails';
export * from '../models/HorseMedicalDetailsRequest';
export * from '../models/HorsePhoto';
export * from '../models/HorsePhotoRequest';
export * from '../models/HorseProfilePhoto';
export * from '../models/HorseProfilePhotoRequest';
export * from '../models/HorseQueryMetadata';
export * from '../models/HorseSettings';
export * from '../models/HorseSettingsRequest';
export * from '../models/HorseShoeEnum';
export * from '../models/HorseTypeEnum';
export * from '../models/HorseUserRoleEnum';
export * from '../models/HorseVaccination';
export * from '../models/HorseVaccinationRequest';
export * from '../models/HorseVideo';
export * from '../models/HorseVideoRequest';
export * from '../models/HorseVideoThumbnailRequest';
export * from '../models/IdRequest';
export * from '../models/InRoomRequest';
export * from '../models/InfoSummary';
export * from '../models/IntervalTypeEnum';
export * from '../models/LandsmotJudgeOverview';
export * from '../models/Leaderboard';
export * from '../models/LeaderboardRequest';
export * from '../models/LoginRequest';
export * from '../models/MeasurmentSystemEnum';
export * from '../models/Measurments';
export * from '../models/MeasurmentsRequest';
export * from '../models/Medical';
export * from '../models/MedicalRequest';
export * from '../models/MessageTypeEnum';
export * from '../models/MetricEnum';
export * from '../models/MonthlyTrainingSummary';
export * from '../models/Notification';
export * from '../models/NotificationTypeEnum';
export * from '../models/NotificationTypeRequest';
export * from '../models/Number';
export * from '../models/NumberRequest';
export * from '../models/Owner';
export * from '../models/OwnerRequest';
export * from '../models/PaginatedActivity';
export * from '../models/PaginatedActivityMediaList';
export * from '../models/PaginatedActivityReactionList';
export * from '../models/PaginatedChatMessageList';
export * from '../models/PaginatedChatRoomList';
export * from '../models/PaginatedCommentList';
export * from '../models/PaginatedCommentReactionList';
export * from '../models/PaginatedGroupDiscussionCommentList';
export * from '../models/PaginatedGroupDiscussionList';
export * from '../models/PaginatedHorseActivityList';
export * from '../models/PaginatedHorseActivityPhotoList';
export * from '../models/PaginatedHorseActivityVideoList';
export * from '../models/PaginatedHorseDayRankingList';
export * from '../models/PaginatedHorseList';
export * from '../models/PaginatedHorsePhotoList';
export * from '../models/PaginatedHorsePhotos';
export * from '../models/PaginatedHorseVideoList';
export * from '../models/PaginatedHorseVideos';
export * from '../models/PaginatedHorses';
export * from '../models/PaginatedLeaderboardList';
export * from '../models/PaginatedNotificationList';
export * from '../models/PaginatedPaginatedHorsePhotosList';
export * from '../models/PaginatedPaginatedHorseVideosList';
export * from '../models/PaginatedTrainingSessionList';
export * from '../models/PaginatedUsers';
export * from '../models/PartialSurvey';
export * from '../models/PartialSurveyQuestion';
export * from '../models/PartialUserSurvey';
export * from '../models/PasswordChangeRequest';
export * from '../models/PasswordResetConfirmRequest';
export * from '../models/PasswordResetRequest';
export * from '../models/PatchedActivityReactionRequest';
export * from '../models/PatchedCommentReactionRequest';
export * from '../models/PatchedCommentRequest';
export * from '../models/PatchedFeedingRequest';
export * from '../models/PatchedGroupDiscussionCommentRequest';
export * from '../models/PatchedGroupDiscussionRequest';
export * from '../models/PatchedGroupRequest';
export * from '../models/PatchedHorseAccessInviteRequest';
export * from '../models/PatchedHorseAccessRequest';
export * from '../models/PatchedHorseActivityPhotoRequest';
export * from '../models/PatchedHorseActivityUpdateRequest';
export * from '../models/PatchedHorseActivityVideoRequest';
export * from '../models/PatchedHorseDetailsRequest';
export * from '../models/PatchedHorseMedicalDetailsRequest';
export * from '../models/PatchedHorsePhotoRequest';
export * from '../models/PatchedHorseRequest';
export * from '../models/PatchedHorseVaccinationRequest';
export * from '../models/PatchedHorseVideoRequest';
export * from '../models/PatchedLeaderboardRequest';
export * from '../models/PatchedNotificationTypeRequest';
export * from '../models/PatchedTrainingSessionRequest';
export * from '../models/PatchedUserRequest';
export * from '../models/PatchedUserSettingsRequest';
export * from '../models/PatchedUserUpdateRequest';
export * from '../models/PlatformEnum';
export * from '../models/Point';
export * from '../models/PriceCategoryEnum';
export * from '../models/PromoCode';
export * from '../models/PromoCodeRequest';
export * from '../models/ProviderEnum';
export * from '../models/PublicUser';
export * from '../models/ReactionTypeEnum';
export * from '../models/RegisterRequest';
export * from '../models/RequestStatus';
export * from '../models/ResendEmailVerificationRequest';
export * from '../models/RestAuthDetail';
export * from '../models/RevenucatEntitlement';
export * from '../models/RevenucatSubscription';
export * from '../models/RevenuecatSubscriptions';
export * from '../models/SafetyTrackingMyInfo';
export * from '../models/SafetyTrackingTeam';
export * from '../models/SafetyTrackingTeamInvite';
export * from '../models/SafetyTrackingTeamInviteCreateRequest';
export * from '../models/SafetyTrackingViewer';
export * from '../models/SearchHorse';
export * from '../models/SexEnumEnum';
export * from '../models/Shoeing';
export * from '../models/ShoeingRequest';
export * from '../models/SimpleBooleanRequest';
export * from '../models/SimpleGroup';
export * from '../models/SimpleGroupHorse';
export * from '../models/SimpleGroupHorseRequest';
export * from '../models/SimpleGroupUser';
export * from '../models/SimpleGroupUserRequest';
export * from '../models/SimpleHorse';
export * from '../models/SimpleHorseActivities';
export * from '../models/SimpleHorseActivity';
export * from '../models/SimpleHorseRequest';
export * from '../models/SimpleMessage';
export * from '../models/SimpleMonthlyActivitySummary';
export * from '../models/SimpleNote';
export * from '../models/SimpleRequest';
export * from '../models/SimpleTrackingPoint';
export * from '../models/SimpleUser';
export * from '../models/SimpleUserActivity';
export * from '../models/SimpleUserInfo';
export * from '../models/SimpleUserInfoRequest';
export * from '../models/SimpleUserRequest';
export * from '../models/SocialAccount';
export * from '../models/SocialConnect';
export * from '../models/SocialConnectRequest';
export * from '../models/SocialLoginRequest';
export * from '../models/SportFengurEvent';
export * from '../models/SportFengurEventFollowRequest';
export * from '../models/SportFengurEventRequest';
export * from '../models/StallionGroup';
export * from '../models/StallionGroupRequest';
export * from '../models/StartEndRequest';
export * from '../models/StripeCheckoutSession';
export * from '../models/StripeCheckoutSessionCreateRequest';
export * from '../models/StripeCoupon';
export * from '../models/StripePrice';
export * from '../models/StripePricesAndProducts';
export * from '../models/StripeProduct';
export * from '../models/StripeProductMetadata';
export * from '../models/StripeRecurring';
export * from '../models/Subscription';
export * from '../models/SubscriptionTypeEnum';
export * from '../models/Suggested';
export * from '../models/Summary';
export * from '../models/TeamInfo';
export * from '../models/Token';
export * from '../models/TokenRequest';
export * from '../models/TrackingPointCorrectionRequest';
export * from '../models/TrainingSession';
export * from '../models/TrainingSessionCreate';
export * from '../models/TrainingSessionCreateRequest';
export * from '../models/TrainingSessionFeedbackRequest';
export * from '../models/TrainingSessionRequest';
export * from '../models/TrainingSessionTrackingPoint';
export * from '../models/TrainingSessionTrackingPointRequest';
export * from '../models/TrainingSummary';
export * from '../models/TrainingSummaryRequest';
export * from '../models/TranscodedFile';
export * from '../models/TranscodedFileRequest';
export * from '../models/TranscodedFiles';
export * from '../models/TranscodedFilesRequest';
export * from '../models/TranscoderCallbackRequestRequest';
export * from '../models/TranscoderData';
export * from '../models/TranscoderDataRequest';
export * from '../models/TypesEnumEnum';
export * from '../models/UpdateGroupDiscussionRequest';
export * from '../models/UpdateGroupHorseRequest';
export * from '../models/UpdateGroupMareRequest';
export * from '../models/UpdateGroupRequest';
export * from '../models/UpdateStallionGroupRequest';
export * from '../models/User';
export * from '../models/UserExtradata';
export * from '../models/UserExtradataRequest';
export * from '../models/UserFollowRequest';
export * from '../models/UserFollowRequestResponseRequest';
export * from '../models/UserLocale';
export * from '../models/UserLocaleRequest';
export * from '../models/UserMonthlyTrainingSummary';
export * from '../models/UserQuestionareRequest';
export * from '../models/UserReaction';
export * from '../models/UserRequest';
export * from '../models/UserSettings';
export * from '../models/UserSettingsRequest';
export * from '../models/UserUidRequest';
export * from '../models/UserWeeklyTrainingSummary';
export * from '../models/VerifyEmailRequest';
export * from '../models/VerySimpleHorseActivity';
export * from '../models/VisibilityEnum';
export * from '../models/WFLoginRequest';
export * from '../models/WFOffspring';
export * from '../models/WFWebookHorseRemoveRequest';
export * from '../models/WFWebookHorseUpdateRequest';
export * from '../models/WFWebookNewJudgementRequest';
export * from '../models/WFWebookOffspringUpdateRequest';
export * from '../models/Weather';
export * from '../models/WeatherRequest';
export * from '../models/WebhookDomarRequest';
export * from '../models/WebhookEventParticipantRequest';
export * from '../models/WebhookEventProgramRequest';
export * from '../models/WebhookEventRequest';
export * from '../models/WebhookRaslistiRequest';
export * from '../models/WeeklyTrainingSummary';
export * from '../models/WorldCupHorse';
export * from '../models/WrappedSerialzer';

import { AcceptRequest } from '../models/AcceptRequest';
import { ActivityMedia      } from '../models/ActivityMedia';
import { ActivityReaction     } from '../models/ActivityReaction';
import { ActivityReactionRequest   } from '../models/ActivityReactionRequest';
import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { ActivityVideoThumbnailRequest } from '../models/ActivityVideoThumbnailRequest';
import { Actor } from '../models/Actor';
import { AnswerUserSurveyRequest } from '../models/AnswerUserSurveyRequest';
import { AppMeta } from '../models/AppMeta';
import { AssessmentOverview } from '../models/AssessmentOverview';
import { ChatMessage } from '../models/ChatMessage';
import { ChatMessageRequest } from '../models/ChatMessageRequest';
import { ChatRoom } from '../models/ChatRoom';
import { ChatRoomRequest } from '../models/ChatRoomRequest';
import { ChatUser } from '../models/ChatUser';
import { ChatUserRequest } from '../models/ChatUserRequest';
import { Comment } from '../models/Comment';
import { CommentReaction   } from '../models/CommentReaction';
import { CommentReactionRequest   } from '../models/CommentReactionRequest';
import { CommentReactionSummary } from '../models/CommentReactionSummary';
import { CommentRequest } from '../models/CommentRequest';
import { CountryYears } from '../models/CountryYears';
import { CreateGroupDiscussionRequest } from '../models/CreateGroupDiscussionRequest';
import { CreateGroupRequest    } from '../models/CreateGroupRequest';
import { CreateGroupUserInviteRequest    } from '../models/CreateGroupUserInviteRequest';
import { CreateHorseAccessInviteRequest } from '../models/CreateHorseAccessInviteRequest';
import { CreateHorseAccessReqRequest } from '../models/CreateHorseAccessReqRequest';
import { CreateHorseAccessRequest } from '../models/CreateHorseAccessRequest';
import { CreateHorseRequest          } from '../models/CreateHorseRequest';
import { CustomTrainingSession } from '../models/CustomTrainingSession';
import { CustomTrainingSessionCreate } from '../models/CustomTrainingSessionCreate';
import { CustomTrainingSessionCreateRequest } from '../models/CustomTrainingSessionCreateRequest';
import { DetailedAssessmentOverview } from '../models/DetailedAssessmentOverview';
import { DownloadPurposeEnum } from '../models/DownloadPurposeEnum';
import { DurationEnum } from '../models/DurationEnum';
import { EntitlementEnum } from '../models/EntitlementEnum';
import { EventPoints } from '../models/EventPoints';
import { FaedingarnumerSerilizerRequest } from '../models/FaedingarnumerSerilizerRequest';
import { Feeding } from '../models/Feeding';
import { FeedingRequest } from '../models/FeedingRequest';
import { FlokkurEnum } from '../models/FlokkurEnum';
import { FlokkurKeppni } from '../models/FlokkurKeppni';
import { FollowEventFlokkur } from '../models/FollowEventFlokkur';
import { FollowEventFlokkurRequest } from '../models/FollowEventFlokkurRequest';
import { FollowRequest } from '../models/FollowRequest';
import { FollowingFollowers } from '../models/FollowingFollowers';
import { GaitEnum } from '../models/GaitEnum';
import { GaitPredictions } from '../models/GaitPredictions';
import { GaitPredictionsRequest } from '../models/GaitPredictionsRequest';
import { GaitRating } from '../models/GaitRating';
import { GaitRatingRequest } from '../models/GaitRatingRequest';
import { GaitSensorRequest } from '../models/GaitSensorRequest';
import { GoalRequest } from '../models/GoalRequest';
import { Group               } from '../models/Group';
import { GroupDiscussion } from '../models/GroupDiscussion';
import { GroupDiscussionComment } from '../models/GroupDiscussionComment';
import { GroupDiscussionCommentReaction     } from '../models/GroupDiscussionCommentReaction';
import { GroupDiscussionCommentReactionRequest  } from '../models/GroupDiscussionCommentReactionRequest';
import { GroupDiscussionCommentRequest } from '../models/GroupDiscussionCommentRequest';
import { GroupDiscussionPhoto } from '../models/GroupDiscussionPhoto';
import { GroupDiscussionPhotoRequest } from '../models/GroupDiscussionPhotoRequest';
import { GroupDiscussionReaction     } from '../models/GroupDiscussionReaction';
import { GroupDiscussionReactionRequest  } from '../models/GroupDiscussionReactionRequest';
import { GroupInvite         } from '../models/GroupInvite';
import { GroupMare } from '../models/GroupMare';
import { GroupMareRequest } from '../models/GroupMareRequest';
import { GroupProfile             } from '../models/GroupProfile';
import { GroupRequest      } from '../models/GroupRequest';
import { GroupSettings } from '../models/GroupSettings';
import { GroupSettingsRequest } from '../models/GroupSettingsRequest';
import { GroupThumbnailRequest } from '../models/GroupThumbnailRequest';
import { GroupTypeEnum } from '../models/GroupTypeEnum';
import { GroupUserRoleEnum } from '../models/GroupUserRoleEnum';
import { GroupUserRoleRequest   } from '../models/GroupUserRoleRequest';
import { HealthStatusEnum } from '../models/HealthStatusEnum';
import { Horse                                      } from '../models/Horse';
import { HorseAccess } from '../models/HorseAccess';
import { HorseAccessHistory } from '../models/HorseAccessHistory';
import { HorseAccessInvite } from '../models/HorseAccessInvite';
import { HorseAccessInviteRequest } from '../models/HorseAccessInviteRequest';
import { HorseAccessReq } from '../models/HorseAccessReq';
import { HorseAccessRequest } from '../models/HorseAccessRequest';
import { HorseActivities } from '../models/HorseActivities';
import { HorseActivity                           } from '../models/HorseActivity';
import { HorseActivityCreate                          } from '../models/HorseActivityCreate';
import { HorseActivityCreateRequest                     } from '../models/HorseActivityCreateRequest';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { HorseActivityMultiCreateRequest                      } from '../models/HorseActivityMultiCreateRequest';
import { HorseActivityPhoto } from '../models/HorseActivityPhoto';
import { HorseActivityPhotoRequest } from '../models/HorseActivityPhotoRequest';
import { HorseActivityReactionSummary } from '../models/HorseActivityReactionSummary';
import { HorseActivityUpdate                          } from '../models/HorseActivityUpdate';
import { HorseActivityUpdateRequest                    } from '../models/HorseActivityUpdateRequest';
import { HorseActivityVideo } from '../models/HorseActivityVideo';
import { HorseActivityVideoRequest } from '../models/HorseActivityVideoRequest';
import { HorseAssessment } from '../models/HorseAssessment';
import { HorseAssessmentRequest } from '../models/HorseAssessmentRequest';
import { HorseBreedEnum } from '../models/HorseBreedEnum';
import { HorseDayMessage          } from '../models/HorseDayMessage';
import { HorseDayRanking } from '../models/HorseDayRanking';
import { HorseDetails } from '../models/HorseDetails';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { HorseDetailsRequest } from '../models/HorseDetailsRequest';
import { HorseForSale               } from '../models/HorseForSale';
import { HorseForSaleRequest              } from '../models/HorseForSaleRequest';
import { HorseIdRequest } from '../models/HorseIdRequest';
import { HorseMedicalDetails } from '../models/HorseMedicalDetails';
import { HorseMedicalDetailsRequest } from '../models/HorseMedicalDetailsRequest';
import { HorsePhoto } from '../models/HorsePhoto';
import { HorsePhotoRequest } from '../models/HorsePhotoRequest';
import { HorseProfilePhoto } from '../models/HorseProfilePhoto';
import { HorseProfilePhotoRequest } from '../models/HorseProfilePhotoRequest';
import { HorseQueryMetadata } from '../models/HorseQueryMetadata';
import { HorseSettings } from '../models/HorseSettings';
import { HorseSettingsRequest } from '../models/HorseSettingsRequest';
import { HorseShoeEnum } from '../models/HorseShoeEnum';
import { HorseTypeEnum } from '../models/HorseTypeEnum';
import { HorseUserRoleEnum } from '../models/HorseUserRoleEnum';
import { HorseVaccination } from '../models/HorseVaccination';
import { HorseVaccinationRequest } from '../models/HorseVaccinationRequest';
import { HorseVideo } from '../models/HorseVideo';
import { HorseVideoRequest } from '../models/HorseVideoRequest';
import { HorseVideoThumbnailRequest } from '../models/HorseVideoThumbnailRequest';
import { IdRequest } from '../models/IdRequest';
import { InRoomRequest } from '../models/InRoomRequest';
import { InfoSummary } from '../models/InfoSummary';
import { IntervalTypeEnum } from '../models/IntervalTypeEnum';
import { LandsmotJudgeOverview             } from '../models/LandsmotJudgeOverview';
import { Leaderboard } from '../models/Leaderboard';
import { LeaderboardRequest } from '../models/LeaderboardRequest';
import { LoginRequest } from '../models/LoginRequest';
import { MeasurmentSystemEnum } from '../models/MeasurmentSystemEnum';
import { Measurments } from '../models/Measurments';
import { MeasurmentsRequest } from '../models/MeasurmentsRequest';
import { Medical } from '../models/Medical';
import { MedicalRequest } from '../models/MedicalRequest';
import { MessageTypeEnum } from '../models/MessageTypeEnum';
import { MetricEnum } from '../models/MetricEnum';
import { MonthlyTrainingSummary } from '../models/MonthlyTrainingSummary';
import { Notification                        } from '../models/Notification';
import { NotificationTypeEnum } from '../models/NotificationTypeEnum';
import { NotificationTypeRequest   } from '../models/NotificationTypeRequest';
import { Number } from '../models/Number';
import { NumberRequest } from '../models/NumberRequest';
import { Owner } from '../models/Owner';
import { OwnerRequest } from '../models/OwnerRequest';
import { PaginatedActivity } from '../models/PaginatedActivity';
import { PaginatedActivityMediaList } from '../models/PaginatedActivityMediaList';
import { PaginatedActivityReactionList } from '../models/PaginatedActivityReactionList';
import { PaginatedChatMessageList } from '../models/PaginatedChatMessageList';
import { PaginatedChatRoomList } from '../models/PaginatedChatRoomList';
import { PaginatedCommentList } from '../models/PaginatedCommentList';
import { PaginatedCommentReactionList } from '../models/PaginatedCommentReactionList';
import { PaginatedGroupDiscussionCommentList } from '../models/PaginatedGroupDiscussionCommentList';
import { PaginatedGroupDiscussionList } from '../models/PaginatedGroupDiscussionList';
import { PaginatedHorseActivityList } from '../models/PaginatedHorseActivityList';
import { PaginatedHorseActivityPhotoList } from '../models/PaginatedHorseActivityPhotoList';
import { PaginatedHorseActivityVideoList } from '../models/PaginatedHorseActivityVideoList';
import { PaginatedHorseDayRankingList } from '../models/PaginatedHorseDayRankingList';
import { PaginatedHorseList } from '../models/PaginatedHorseList';
import { PaginatedHorsePhotoList } from '../models/PaginatedHorsePhotoList';
import { PaginatedHorsePhotos } from '../models/PaginatedHorsePhotos';
import { PaginatedHorseVideoList } from '../models/PaginatedHorseVideoList';
import { PaginatedHorseVideos } from '../models/PaginatedHorseVideos';
import { PaginatedHorses } from '../models/PaginatedHorses';
import { PaginatedLeaderboardList } from '../models/PaginatedLeaderboardList';
import { PaginatedNotificationList } from '../models/PaginatedNotificationList';
import { PaginatedPaginatedHorsePhotosList } from '../models/PaginatedPaginatedHorsePhotosList';
import { PaginatedPaginatedHorseVideosList } from '../models/PaginatedPaginatedHorseVideosList';
import { PaginatedTrainingSessionList } from '../models/PaginatedTrainingSessionList';
import { PaginatedUsers } from '../models/PaginatedUsers';
import { PartialSurvey } from '../models/PartialSurvey';
import { PartialSurveyQuestion } from '../models/PartialSurveyQuestion';
import { PartialUserSurvey } from '../models/PartialUserSurvey';
import { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import { PasswordResetConfirmRequest } from '../models/PasswordResetConfirmRequest';
import { PasswordResetRequest } from '../models/PasswordResetRequest';
import { PatchedActivityReactionRequest   } from '../models/PatchedActivityReactionRequest';
import { PatchedCommentReactionRequest   } from '../models/PatchedCommentReactionRequest';
import { PatchedCommentRequest } from '../models/PatchedCommentRequest';
import { PatchedFeedingRequest } from '../models/PatchedFeedingRequest';
import { PatchedGroupDiscussionCommentRequest } from '../models/PatchedGroupDiscussionCommentRequest';
import { PatchedGroupDiscussionRequest } from '../models/PatchedGroupDiscussionRequest';
import { PatchedGroupRequest            } from '../models/PatchedGroupRequest';
import { PatchedHorseAccessInviteRequest } from '../models/PatchedHorseAccessInviteRequest';
import { PatchedHorseAccessRequest } from '../models/PatchedHorseAccessRequest';
import { PatchedHorseActivityPhotoRequest } from '../models/PatchedHorseActivityPhotoRequest';
import { PatchedHorseActivityUpdateRequest                    } from '../models/PatchedHorseActivityUpdateRequest';
import { PatchedHorseActivityVideoRequest } from '../models/PatchedHorseActivityVideoRequest';
import { PatchedHorseDetailsRequest } from '../models/PatchedHorseDetailsRequest';
import { PatchedHorseMedicalDetailsRequest } from '../models/PatchedHorseMedicalDetailsRequest';
import { PatchedHorsePhotoRequest } from '../models/PatchedHorsePhotoRequest';
import { PatchedHorseRequest                                   } from '../models/PatchedHorseRequest';
import { PatchedHorseVaccinationRequest } from '../models/PatchedHorseVaccinationRequest';
import { PatchedHorseVideoRequest } from '../models/PatchedHorseVideoRequest';
import { PatchedLeaderboardRequest } from '../models/PatchedLeaderboardRequest';
import { PatchedNotificationTypeRequest   } from '../models/PatchedNotificationTypeRequest';
import { PatchedTrainingSessionRequest } from '../models/PatchedTrainingSessionRequest';
import { PatchedUserRequest } from '../models/PatchedUserRequest';
import { PatchedUserSettingsRequest } from '../models/PatchedUserSettingsRequest';
import { PatchedUserUpdateRequest } from '../models/PatchedUserUpdateRequest';
import { PlatformEnum } from '../models/PlatformEnum';
import { Point } from '../models/Point';
import { PriceCategoryEnum } from '../models/PriceCategoryEnum';
import { PromoCode } from '../models/PromoCode';
import { PromoCodeRequest } from '../models/PromoCodeRequest';
import { ProviderEnum } from '../models/ProviderEnum';
import { PublicUser                    } from '../models/PublicUser';
import { ReactionTypeEnum } from '../models/ReactionTypeEnum';
import { RegisterRequest } from '../models/RegisterRequest';
import { RequestStatus } from '../models/RequestStatus';
import { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import { RestAuthDetail } from '../models/RestAuthDetail';
import { RevenucatEntitlement } from '../models/RevenucatEntitlement';
import { RevenucatSubscription } from '../models/RevenucatSubscription';
import { RevenuecatSubscriptions } from '../models/RevenuecatSubscriptions';
import { SafetyTrackingMyInfo } from '../models/SafetyTrackingMyInfo';
import { SafetyTrackingTeam } from '../models/SafetyTrackingTeam';
import { SafetyTrackingTeamInvite       } from '../models/SafetyTrackingTeamInvite';
import { SafetyTrackingTeamInviteCreateRequest } from '../models/SafetyTrackingTeamInviteCreateRequest';
import { SafetyTrackingViewer } from '../models/SafetyTrackingViewer';
import { SearchHorse                          } from '../models/SearchHorse';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { Shoeing } from '../models/Shoeing';
import { ShoeingRequest } from '../models/ShoeingRequest';
import { SimpleBooleanRequest } from '../models/SimpleBooleanRequest';
import { SimpleGroup      } from '../models/SimpleGroup';
import { SimpleGroupHorse } from '../models/SimpleGroupHorse';
import { SimpleGroupHorseRequest } from '../models/SimpleGroupHorseRequest';
import { SimpleGroupUser   } from '../models/SimpleGroupUser';
import { SimpleGroupUserRequest   } from '../models/SimpleGroupUserRequest';
import { SimpleHorse        } from '../models/SimpleHorse';
import { SimpleHorseActivities } from '../models/SimpleHorseActivities';
import { SimpleHorseActivity              } from '../models/SimpleHorseActivity';
import { SimpleHorseRequest        } from '../models/SimpleHorseRequest';
import { SimpleMessage } from '../models/SimpleMessage';
import { SimpleMonthlyActivitySummary } from '../models/SimpleMonthlyActivitySummary';
import { SimpleNote } from '../models/SimpleNote';
import { SimpleRequest } from '../models/SimpleRequest';
import { SimpleTrackingPoint } from '../models/SimpleTrackingPoint';
import { SimpleUser } from '../models/SimpleUser';
import { SimpleUserActivity          } from '../models/SimpleUserActivity';
import { SimpleUserInfo } from '../models/SimpleUserInfo';
import { SimpleUserInfoRequest } from '../models/SimpleUserInfoRequest';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { SocialAccount      } from '../models/SocialAccount';
import { SocialConnect } from '../models/SocialConnect';
import { SocialConnectRequest } from '../models/SocialConnectRequest';
import { SocialLoginRequest } from '../models/SocialLoginRequest';
import { SportFengurEvent } from '../models/SportFengurEvent';
import { SportFengurEventFollowRequest } from '../models/SportFengurEventFollowRequest';
import { SportFengurEventRequest } from '../models/SportFengurEventRequest';
import { StallionGroup } from '../models/StallionGroup';
import { StallionGroupRequest } from '../models/StallionGroupRequest';
import { StartEndRequest } from '../models/StartEndRequest';
import { StripeCheckoutSession } from '../models/StripeCheckoutSession';
import { StripeCheckoutSessionCreateRequest } from '../models/StripeCheckoutSessionCreateRequest';
import { StripeCoupon } from '../models/StripeCoupon';
import { StripePrice } from '../models/StripePrice';
import { StripePricesAndProducts } from '../models/StripePricesAndProducts';
import { StripeProduct } from '../models/StripeProduct';
import { StripeProductMetadata   } from '../models/StripeProductMetadata';
import { StripeRecurring } from '../models/StripeRecurring';
import { Subscription      } from '../models/Subscription';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { Suggested } from '../models/Suggested';
import { Summary } from '../models/Summary';
import { TeamInfo } from '../models/TeamInfo';
import { Token } from '../models/Token';
import { TokenRequest } from '../models/TokenRequest';
import { TrackingPointCorrectionRequest   } from '../models/TrackingPointCorrectionRequest';
import { TrainingSession } from '../models/TrainingSession';
import { TrainingSessionCreate } from '../models/TrainingSessionCreate';
import { TrainingSessionCreateRequest } from '../models/TrainingSessionCreateRequest';
import { TrainingSessionFeedbackRequest } from '../models/TrainingSessionFeedbackRequest';
import { TrainingSessionRequest } from '../models/TrainingSessionRequest';
import { TrainingSessionTrackingPoint } from '../models/TrainingSessionTrackingPoint';
import { TrainingSessionTrackingPointRequest } from '../models/TrainingSessionTrackingPointRequest';
import { TrainingSummary } from '../models/TrainingSummary';
import { TrainingSummaryRequest } from '../models/TrainingSummaryRequest';
import { TranscodedFile } from '../models/TranscodedFile';
import { TranscodedFileRequest } from '../models/TranscodedFileRequest';
import { TranscodedFiles } from '../models/TranscodedFiles';
import { TranscodedFilesRequest } from '../models/TranscodedFilesRequest';
import { TranscoderCallbackRequestRequest } from '../models/TranscoderCallbackRequestRequest';
import { TranscoderData } from '../models/TranscoderData';
import { TranscoderDataRequest } from '../models/TranscoderDataRequest';
import { TypesEnumEnum } from '../models/TypesEnumEnum';
import { UpdateGroupDiscussionRequest } from '../models/UpdateGroupDiscussionRequest';
import { UpdateGroupHorseRequest } from '../models/UpdateGroupHorseRequest';
import { UpdateGroupMareRequest } from '../models/UpdateGroupMareRequest';
import { UpdateGroupRequest } from '../models/UpdateGroupRequest';
import { UpdateStallionGroupRequest } from '../models/UpdateStallionGroupRequest';
import { User } from '../models/User';
import { UserExtradata     } from '../models/UserExtradata';
import { UserExtradataRequest     } from '../models/UserExtradataRequest';
import { UserFollowRequest      } from '../models/UserFollowRequest';
import { UserFollowRequestResponseRequest } from '../models/UserFollowRequestResponseRequest';
import { UserLocale } from '../models/UserLocale';
import { UserLocaleRequest } from '../models/UserLocaleRequest';
import { UserMonthlyTrainingSummary } from '../models/UserMonthlyTrainingSummary';
import { UserQuestionareRequest } from '../models/UserQuestionareRequest';
import { UserReaction    } from '../models/UserReaction';
import { UserRequest } from '../models/UserRequest';
import { UserSettings } from '../models/UserSettings';
import { UserSettingsRequest } from '../models/UserSettingsRequest';
import { UserUidRequest } from '../models/UserUidRequest';
import { UserWeeklyTrainingSummary } from '../models/UserWeeklyTrainingSummary';
import { VerifyEmailRequest } from '../models/VerifyEmailRequest';
import { VerySimpleHorseActivity            } from '../models/VerySimpleHorseActivity';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { WFLoginRequest } from '../models/WFLoginRequest';
import { WFOffspring             } from '../models/WFOffspring';
import { WFWebookHorseRemoveRequest } from '../models/WFWebookHorseRemoveRequest';
import { WFWebookHorseUpdateRequest } from '../models/WFWebookHorseUpdateRequest';
import { WFWebookNewJudgementRequest } from '../models/WFWebookNewJudgementRequest';
import { WFWebookOffspringUpdateRequest } from '../models/WFWebookOffspringUpdateRequest';
import { Weather } from '../models/Weather';
import { WeatherRequest } from '../models/WeatherRequest';
import { WebhookDomarRequest } from '../models/WebhookDomarRequest';
import { WebhookEventParticipantRequest } from '../models/WebhookEventParticipantRequest';
import { WebhookEventProgramRequest } from '../models/WebhookEventProgramRequest';
import { WebhookEventRequest } from '../models/WebhookEventRequest';
import { WebhookRaslistiRequest } from '../models/WebhookRaslistiRequest';
import { WeeklyTrainingSummary } from '../models/WeeklyTrainingSummary';
import { WorldCupHorse } from '../models/WorldCupHorse';
import { WrappedSerialzer } from '../models/WrappedSerialzer';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0,
  "application/x-www-form-urlencoded": 0
}


let enumsMap: Set<string> = new Set<string>([
    "ActivityTypeEnum",
    "DownloadPurposeEnum",
    "DurationEnum",
    "EntitlementEnum",
    "FlokkurEnum",
    "GaitEnum",
    "GroupTypeEnum",
    "GroupUserRoleEnum",
    "HealthStatusEnum",
    "HorseBreedEnum",
    "HorseShoeEnum",
    "HorseTypeEnum",
    "HorseUserRoleEnum",
    "IntervalTypeEnum",
    "MeasurmentSystemEnum",
    "MessageTypeEnum",
    "MetricEnum",
    "NotificationTypeEnum",
    "PlatformEnum",
    "PriceCategoryEnum",
    "ProviderEnum",
    "ReactionTypeEnum",
    "RequestStatus",
    "SexEnumEnum",
    "SubscriptionTypeEnum",
    "TypesEnumEnum",
    "VisibilityEnum",
]);

let typeMap: {[index: string]: any} = {
    "AcceptRequest": AcceptRequest,
    "ActivityMedia": ActivityMedia,
    "ActivityReaction": ActivityReaction,
    "ActivityReactionRequest": ActivityReactionRequest,
    "ActivityVideoThumbnailRequest": ActivityVideoThumbnailRequest,
    "Actor": Actor,
    "AnswerUserSurveyRequest": AnswerUserSurveyRequest,
    "AppMeta": AppMeta,
    "AssessmentOverview": AssessmentOverview,
    "ChatMessage": ChatMessage,
    "ChatMessageRequest": ChatMessageRequest,
    "ChatRoom": ChatRoom,
    "ChatRoomRequest": ChatRoomRequest,
    "ChatUser": ChatUser,
    "ChatUserRequest": ChatUserRequest,
    "Comment": Comment,
    "CommentReaction": CommentReaction,
    "CommentReactionRequest": CommentReactionRequest,
    "CommentReactionSummary": CommentReactionSummary,
    "CommentRequest": CommentRequest,
    "CountryYears": CountryYears,
    "CreateGroupDiscussionRequest": CreateGroupDiscussionRequest,
    "CreateGroupRequest": CreateGroupRequest,
    "CreateGroupUserInviteRequest": CreateGroupUserInviteRequest,
    "CreateHorseAccessInviteRequest": CreateHorseAccessInviteRequest,
    "CreateHorseAccessReqRequest": CreateHorseAccessReqRequest,
    "CreateHorseAccessRequest": CreateHorseAccessRequest,
    "CreateHorseRequest": CreateHorseRequest,
    "CustomTrainingSession": CustomTrainingSession,
    "CustomTrainingSessionCreate": CustomTrainingSessionCreate,
    "CustomTrainingSessionCreateRequest": CustomTrainingSessionCreateRequest,
    "DetailedAssessmentOverview": DetailedAssessmentOverview,
    "EventPoints": EventPoints,
    "FaedingarnumerSerilizerRequest": FaedingarnumerSerilizerRequest,
    "Feeding": Feeding,
    "FeedingRequest": FeedingRequest,
    "FlokkurKeppni": FlokkurKeppni,
    "FollowEventFlokkur": FollowEventFlokkur,
    "FollowEventFlokkurRequest": FollowEventFlokkurRequest,
    "FollowRequest": FollowRequest,
    "FollowingFollowers": FollowingFollowers,
    "GaitPredictions": GaitPredictions,
    "GaitPredictionsRequest": GaitPredictionsRequest,
    "GaitRating": GaitRating,
    "GaitRatingRequest": GaitRatingRequest,
    "GaitSensorRequest": GaitSensorRequest,
    "GoalRequest": GoalRequest,
    "Group": Group,
    "GroupDiscussion": GroupDiscussion,
    "GroupDiscussionComment": GroupDiscussionComment,
    "GroupDiscussionCommentReaction": GroupDiscussionCommentReaction,
    "GroupDiscussionCommentReactionRequest": GroupDiscussionCommentReactionRequest,
    "GroupDiscussionCommentRequest": GroupDiscussionCommentRequest,
    "GroupDiscussionPhoto": GroupDiscussionPhoto,
    "GroupDiscussionPhotoRequest": GroupDiscussionPhotoRequest,
    "GroupDiscussionReaction": GroupDiscussionReaction,
    "GroupDiscussionReactionRequest": GroupDiscussionReactionRequest,
    "GroupInvite": GroupInvite,
    "GroupMare": GroupMare,
    "GroupMareRequest": GroupMareRequest,
    "GroupProfile": GroupProfile,
    "GroupRequest": GroupRequest,
    "GroupSettings": GroupSettings,
    "GroupSettingsRequest": GroupSettingsRequest,
    "GroupThumbnailRequest": GroupThumbnailRequest,
    "GroupUserRoleRequest": GroupUserRoleRequest,
    "Horse": Horse,
    "HorseAccess": HorseAccess,
    "HorseAccessHistory": HorseAccessHistory,
    "HorseAccessInvite": HorseAccessInvite,
    "HorseAccessInviteRequest": HorseAccessInviteRequest,
    "HorseAccessReq": HorseAccessReq,
    "HorseAccessRequest": HorseAccessRequest,
    "HorseActivities": HorseActivities,
    "HorseActivity": HorseActivity,
    "HorseActivityCreate": HorseActivityCreate,
    "HorseActivityCreateRequest": HorseActivityCreateRequest,
    "HorseActivityLocation": HorseActivityLocation,
    "HorseActivityMultiCreateRequest": HorseActivityMultiCreateRequest,
    "HorseActivityPhoto": HorseActivityPhoto,
    "HorseActivityPhotoRequest": HorseActivityPhotoRequest,
    "HorseActivityReactionSummary": HorseActivityReactionSummary,
    "HorseActivityUpdate": HorseActivityUpdate,
    "HorseActivityUpdateRequest": HorseActivityUpdateRequest,
    "HorseActivityVideo": HorseActivityVideo,
    "HorseActivityVideoRequest": HorseActivityVideoRequest,
    "HorseAssessment": HorseAssessment,
    "HorseAssessmentRequest": HorseAssessmentRequest,
    "HorseDayMessage": HorseDayMessage,
    "HorseDayRanking": HorseDayRanking,
    "HorseDetails": HorseDetails,
    "HorseDetailsLocation": HorseDetailsLocation,
    "HorseDetailsRequest": HorseDetailsRequest,
    "HorseForSale": HorseForSale,
    "HorseForSaleRequest": HorseForSaleRequest,
    "HorseIdRequest": HorseIdRequest,
    "HorseMedicalDetails": HorseMedicalDetails,
    "HorseMedicalDetailsRequest": HorseMedicalDetailsRequest,
    "HorsePhoto": HorsePhoto,
    "HorsePhotoRequest": HorsePhotoRequest,
    "HorseProfilePhoto": HorseProfilePhoto,
    "HorseProfilePhotoRequest": HorseProfilePhotoRequest,
    "HorseQueryMetadata": HorseQueryMetadata,
    "HorseSettings": HorseSettings,
    "HorseSettingsRequest": HorseSettingsRequest,
    "HorseVaccination": HorseVaccination,
    "HorseVaccinationRequest": HorseVaccinationRequest,
    "HorseVideo": HorseVideo,
    "HorseVideoRequest": HorseVideoRequest,
    "HorseVideoThumbnailRequest": HorseVideoThumbnailRequest,
    "IdRequest": IdRequest,
    "InRoomRequest": InRoomRequest,
    "InfoSummary": InfoSummary,
    "LandsmotJudgeOverview": LandsmotJudgeOverview,
    "Leaderboard": Leaderboard,
    "LeaderboardRequest": LeaderboardRequest,
    "LoginRequest": LoginRequest,
    "Measurments": Measurments,
    "MeasurmentsRequest": MeasurmentsRequest,
    "Medical": Medical,
    "MedicalRequest": MedicalRequest,
    "MonthlyTrainingSummary": MonthlyTrainingSummary,
    "Notification": Notification,
    "NotificationTypeRequest": NotificationTypeRequest,
    "Number": Number,
    "NumberRequest": NumberRequest,
    "Owner": Owner,
    "OwnerRequest": OwnerRequest,
    "PaginatedActivity": PaginatedActivity,
    "PaginatedActivityMediaList": PaginatedActivityMediaList,
    "PaginatedActivityReactionList": PaginatedActivityReactionList,
    "PaginatedChatMessageList": PaginatedChatMessageList,
    "PaginatedChatRoomList": PaginatedChatRoomList,
    "PaginatedCommentList": PaginatedCommentList,
    "PaginatedCommentReactionList": PaginatedCommentReactionList,
    "PaginatedGroupDiscussionCommentList": PaginatedGroupDiscussionCommentList,
    "PaginatedGroupDiscussionList": PaginatedGroupDiscussionList,
    "PaginatedHorseActivityList": PaginatedHorseActivityList,
    "PaginatedHorseActivityPhotoList": PaginatedHorseActivityPhotoList,
    "PaginatedHorseActivityVideoList": PaginatedHorseActivityVideoList,
    "PaginatedHorseDayRankingList": PaginatedHorseDayRankingList,
    "PaginatedHorseList": PaginatedHorseList,
    "PaginatedHorsePhotoList": PaginatedHorsePhotoList,
    "PaginatedHorsePhotos": PaginatedHorsePhotos,
    "PaginatedHorseVideoList": PaginatedHorseVideoList,
    "PaginatedHorseVideos": PaginatedHorseVideos,
    "PaginatedHorses": PaginatedHorses,
    "PaginatedLeaderboardList": PaginatedLeaderboardList,
    "PaginatedNotificationList": PaginatedNotificationList,
    "PaginatedPaginatedHorsePhotosList": PaginatedPaginatedHorsePhotosList,
    "PaginatedPaginatedHorseVideosList": PaginatedPaginatedHorseVideosList,
    "PaginatedTrainingSessionList": PaginatedTrainingSessionList,
    "PaginatedUsers": PaginatedUsers,
    "PartialSurvey": PartialSurvey,
    "PartialSurveyQuestion": PartialSurveyQuestion,
    "PartialUserSurvey": PartialUserSurvey,
    "PasswordChangeRequest": PasswordChangeRequest,
    "PasswordResetConfirmRequest": PasswordResetConfirmRequest,
    "PasswordResetRequest": PasswordResetRequest,
    "PatchedActivityReactionRequest": PatchedActivityReactionRequest,
    "PatchedCommentReactionRequest": PatchedCommentReactionRequest,
    "PatchedCommentRequest": PatchedCommentRequest,
    "PatchedFeedingRequest": PatchedFeedingRequest,
    "PatchedGroupDiscussionCommentRequest": PatchedGroupDiscussionCommentRequest,
    "PatchedGroupDiscussionRequest": PatchedGroupDiscussionRequest,
    "PatchedGroupRequest": PatchedGroupRequest,
    "PatchedHorseAccessInviteRequest": PatchedHorseAccessInviteRequest,
    "PatchedHorseAccessRequest": PatchedHorseAccessRequest,
    "PatchedHorseActivityPhotoRequest": PatchedHorseActivityPhotoRequest,
    "PatchedHorseActivityUpdateRequest": PatchedHorseActivityUpdateRequest,
    "PatchedHorseActivityVideoRequest": PatchedHorseActivityVideoRequest,
    "PatchedHorseDetailsRequest": PatchedHorseDetailsRequest,
    "PatchedHorseMedicalDetailsRequest": PatchedHorseMedicalDetailsRequest,
    "PatchedHorsePhotoRequest": PatchedHorsePhotoRequest,
    "PatchedHorseRequest": PatchedHorseRequest,
    "PatchedHorseVaccinationRequest": PatchedHorseVaccinationRequest,
    "PatchedHorseVideoRequest": PatchedHorseVideoRequest,
    "PatchedLeaderboardRequest": PatchedLeaderboardRequest,
    "PatchedNotificationTypeRequest": PatchedNotificationTypeRequest,
    "PatchedTrainingSessionRequest": PatchedTrainingSessionRequest,
    "PatchedUserRequest": PatchedUserRequest,
    "PatchedUserSettingsRequest": PatchedUserSettingsRequest,
    "PatchedUserUpdateRequest": PatchedUserUpdateRequest,
    "Point": Point,
    "PromoCode": PromoCode,
    "PromoCodeRequest": PromoCodeRequest,
    "PublicUser": PublicUser,
    "RegisterRequest": RegisterRequest,
    "ResendEmailVerificationRequest": ResendEmailVerificationRequest,
    "RestAuthDetail": RestAuthDetail,
    "RevenucatEntitlement": RevenucatEntitlement,
    "RevenucatSubscription": RevenucatSubscription,
    "RevenuecatSubscriptions": RevenuecatSubscriptions,
    "SafetyTrackingMyInfo": SafetyTrackingMyInfo,
    "SafetyTrackingTeam": SafetyTrackingTeam,
    "SafetyTrackingTeamInvite": SafetyTrackingTeamInvite,
    "SafetyTrackingTeamInviteCreateRequest": SafetyTrackingTeamInviteCreateRequest,
    "SafetyTrackingViewer": SafetyTrackingViewer,
    "SearchHorse": SearchHorse,
    "Shoeing": Shoeing,
    "ShoeingRequest": ShoeingRequest,
    "SimpleBooleanRequest": SimpleBooleanRequest,
    "SimpleGroup": SimpleGroup,
    "SimpleGroupHorse": SimpleGroupHorse,
    "SimpleGroupHorseRequest": SimpleGroupHorseRequest,
    "SimpleGroupUser": SimpleGroupUser,
    "SimpleGroupUserRequest": SimpleGroupUserRequest,
    "SimpleHorse": SimpleHorse,
    "SimpleHorseActivities": SimpleHorseActivities,
    "SimpleHorseActivity": SimpleHorseActivity,
    "SimpleHorseRequest": SimpleHorseRequest,
    "SimpleMessage": SimpleMessage,
    "SimpleMonthlyActivitySummary": SimpleMonthlyActivitySummary,
    "SimpleNote": SimpleNote,
    "SimpleRequest": SimpleRequest,
    "SimpleTrackingPoint": SimpleTrackingPoint,
    "SimpleUser": SimpleUser,
    "SimpleUserActivity": SimpleUserActivity,
    "SimpleUserInfo": SimpleUserInfo,
    "SimpleUserInfoRequest": SimpleUserInfoRequest,
    "SimpleUserRequest": SimpleUserRequest,
    "SocialAccount": SocialAccount,
    "SocialConnect": SocialConnect,
    "SocialConnectRequest": SocialConnectRequest,
    "SocialLoginRequest": SocialLoginRequest,
    "SportFengurEvent": SportFengurEvent,
    "SportFengurEventFollowRequest": SportFengurEventFollowRequest,
    "SportFengurEventRequest": SportFengurEventRequest,
    "StallionGroup": StallionGroup,
    "StallionGroupRequest": StallionGroupRequest,
    "StartEndRequest": StartEndRequest,
    "StripeCheckoutSession": StripeCheckoutSession,
    "StripeCheckoutSessionCreateRequest": StripeCheckoutSessionCreateRequest,
    "StripeCoupon": StripeCoupon,
    "StripePrice": StripePrice,
    "StripePricesAndProducts": StripePricesAndProducts,
    "StripeProduct": StripeProduct,
    "StripeProductMetadata": StripeProductMetadata,
    "StripeRecurring": StripeRecurring,
    "Subscription": Subscription,
    "Suggested": Suggested,
    "Summary": Summary,
    "TeamInfo": TeamInfo,
    "Token": Token,
    "TokenRequest": TokenRequest,
    "TrackingPointCorrectionRequest": TrackingPointCorrectionRequest,
    "TrainingSession": TrainingSession,
    "TrainingSessionCreate": TrainingSessionCreate,
    "TrainingSessionCreateRequest": TrainingSessionCreateRequest,
    "TrainingSessionFeedbackRequest": TrainingSessionFeedbackRequest,
    "TrainingSessionRequest": TrainingSessionRequest,
    "TrainingSessionTrackingPoint": TrainingSessionTrackingPoint,
    "TrainingSessionTrackingPointRequest": TrainingSessionTrackingPointRequest,
    "TrainingSummary": TrainingSummary,
    "TrainingSummaryRequest": TrainingSummaryRequest,
    "TranscodedFile": TranscodedFile,
    "TranscodedFileRequest": TranscodedFileRequest,
    "TranscodedFiles": TranscodedFiles,
    "TranscodedFilesRequest": TranscodedFilesRequest,
    "TranscoderCallbackRequestRequest": TranscoderCallbackRequestRequest,
    "TranscoderData": TranscoderData,
    "TranscoderDataRequest": TranscoderDataRequest,
    "UpdateGroupDiscussionRequest": UpdateGroupDiscussionRequest,
    "UpdateGroupHorseRequest": UpdateGroupHorseRequest,
    "UpdateGroupMareRequest": UpdateGroupMareRequest,
    "UpdateGroupRequest": UpdateGroupRequest,
    "UpdateStallionGroupRequest": UpdateStallionGroupRequest,
    "User": User,
    "UserExtradata": UserExtradata,
    "UserExtradataRequest": UserExtradataRequest,
    "UserFollowRequest": UserFollowRequest,
    "UserFollowRequestResponseRequest": UserFollowRequestResponseRequest,
    "UserLocale": UserLocale,
    "UserLocaleRequest": UserLocaleRequest,
    "UserMonthlyTrainingSummary": UserMonthlyTrainingSummary,
    "UserQuestionareRequest": UserQuestionareRequest,
    "UserReaction": UserReaction,
    "UserRequest": UserRequest,
    "UserSettings": UserSettings,
    "UserSettingsRequest": UserSettingsRequest,
    "UserUidRequest": UserUidRequest,
    "UserWeeklyTrainingSummary": UserWeeklyTrainingSummary,
    "VerifyEmailRequest": VerifyEmailRequest,
    "VerySimpleHorseActivity": VerySimpleHorseActivity,
    "WFLoginRequest": WFLoginRequest,
    "WFOffspring": WFOffspring,
    "WFWebookHorseRemoveRequest": WFWebookHorseRemoveRequest,
    "WFWebookHorseUpdateRequest": WFWebookHorseUpdateRequest,
    "WFWebookNewJudgementRequest": WFWebookNewJudgementRequest,
    "WFWebookOffspringUpdateRequest": WFWebookOffspringUpdateRequest,
    "Weather": Weather,
    "WeatherRequest": WeatherRequest,
    "WebhookDomarRequest": WebhookDomarRequest,
    "WebhookEventParticipantRequest": WebhookEventParticipantRequest,
    "WebhookEventProgramRequest": WebhookEventProgramRequest,
    "WebhookEventRequest": WebhookEventRequest,
    "WebhookRaslistiRequest": WebhookRaslistiRequest,
    "WeeklyTrainingSummary": WeeklyTrainingSummary,
    "WorldCupHorse": WorldCupHorse,
    "WrappedSerialzer": WrappedSerialzer,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }

            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                let value = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
                if (value !== undefined) {
                    instance[attributeType.name] = value;
                }
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "text/plain") {
            return String(data);
        }

        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "text/plain") {
            return rawData;
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        if (mediaType === "text/html") {
            return rawData;
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
