/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class HorseActivityReactionSummary {
    'LIKE'?: number;
    'LOVE'?: number;
    'WOW'?: number;
    'HAHA'?: number;
    'SAD'?: number;
    'ANGRY'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "LIKE",
            "baseName": "LIKE",
            "type": "number",
            "format": ""
        },
        {
            "name": "LOVE",
            "baseName": "LOVE",
            "type": "number",
            "format": ""
        },
        {
            "name": "WOW",
            "baseName": "WOW",
            "type": "number",
            "format": ""
        },
        {
            "name": "HAHA",
            "baseName": "HAHA",
            "type": "number",
            "format": ""
        },
        {
            "name": "SAD",
            "baseName": "SAD",
            "type": "number",
            "format": ""
        },
        {
            "name": "ANGRY",
            "baseName": "ANGRY",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseActivityReactionSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

