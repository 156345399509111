import {
  AccountsApi,
  ActivitiesApi,
  BaseServerConfiguration,
  ChatApi,
  ChatMessagesApi,
  CommentsApi,
  Configuration,
  GroupsApi,
  HorsesApi,
  HorsesQueriesApi,
  HttpMethod,
  PromiseHttpLibrary,
  RequestContext,
  ResponseContext,
  SafetyTrackingApi,
  SocialsApi,
  StripeApi,
  UserActivityReactionsApi,
  wrapHttpLibrary,
} from "@/openapi";
import { ResponseBody } from "@/openapi";
import { makeUrl } from "@/auth";

var makeRequestContext = (endpoint: string, httpMethod: HttpMethod) => {
  return new RequestContext(makeUrl(endpoint), httpMethod);
};
var baseServer: BaseServerConfiguration = {
  makeRequestContext,
};

var send = async (request: RequestContext): Promise<ResponseContext> => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  let token = window.localStorage.getItem("TOKEN");
  if (token) {
    requestHeaders.set("authorization", "Token " + token);
  }

  // what is this
  let url = request.getUrl();
  if (!url.endsWith("/") && !url.includes("?")) {
    url += "/";
  }
  var req = await fetch(url, {
    body: request.getBody(),
    method: request.getHttpMethod(),
    headers: requestHeaders,
  });
  if (req.status > 300) {
    var _data = await req.json();
    throw _data;
  }
  var data: Object = await req.json();

  // todo: fix
  // not await and then stringify again
  var resbody: ResponseBody = {
    text: () => Promise.resolve(JSON.stringify(data)),
    binary: () => Promise.resolve(new Blob()),
  };
  var res = new ResponseContext(
    200,
    { mediaType: "application/json", "content-type": "application/json" },
    resbody
  );
  return res;
};

var promiseHttpLibrary: PromiseHttpLibrary = {
  send,
};
var httpApi = wrapHttpLibrary(promiseHttpLibrary);

const configuration: Configuration = {
  baseServer,
  httpApi,
  middleware: [],
  authMethods: {
    default: undefined,
    cookieAuth: undefined,
    tokenAuth: undefined,
  },
};

export const horsesApi = new HorsesApi(configuration);
export const horsesQueryApi = new HorsesQueriesApi(configuration);
export const accountsApi = new AccountsApi(configuration);
export const activitiesApi = new ActivitiesApi(configuration);
export const commentsApi = new CommentsApi(configuration);
export const userActivityReactionsApi = new UserActivityReactionsApi(
  configuration
);
export const socialsApi = new SocialsApi(configuration);
export const safetyTrackingApi = new SafetyTrackingApi(configuration);

export const chatApi = new ChatApi(configuration);
export const chatMessagesApi = new ChatMessagesApi(configuration);
export const groupsApi = new GroupsApi(configuration);
export const stripeApi = new StripeApi(configuration);

export const getStringFromList = (
  item: string | string[] | undefined
): string => {
  if (typeof item === "string") {
    return item;
  } else if (item) {
    return item.join("");
  }
  return "";
};
