/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class GaitSensorRequest {
    'accX': number;
    'accY': number;
    'accZ': number;
    'absOrientX': number;
    'absOrientY': number;
    'absOrientZ': number;
    'absOrientW': number;
    'heading': number;
    'datetime': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "accX",
            "baseName": "accX",
            "type": "number",
            "format": "double"
        },
        {
            "name": "accY",
            "baseName": "accY",
            "type": "number",
            "format": "double"
        },
        {
            "name": "accZ",
            "baseName": "accZ",
            "type": "number",
            "format": "double"
        },
        {
            "name": "absOrientX",
            "baseName": "absOrientX",
            "type": "number",
            "format": "double"
        },
        {
            "name": "absOrientY",
            "baseName": "absOrientY",
            "type": "number",
            "format": "double"
        },
        {
            "name": "absOrientZ",
            "baseName": "absOrientZ",
            "type": "number",
            "format": "double"
        },
        {
            "name": "absOrientW",
            "baseName": "absOrientW",
            "type": "number",
            "format": "double"
        },
        {
            "name": "heading",
            "baseName": "heading",
            "type": "number",
            "format": "double"
        },
        {
            "name": "datetime",
            "baseName": "datetime",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return GaitSensorRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

