import { ActivityReaction } from "@/openapi";
import { IStripeProductPrice } from "@/utils/paymentUtils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaymentState {
  product: IStripeProductPrice | undefined;
}

export const initialPaymentState: IPaymentState = {
  product: undefined,
};

export const PaymentSlice = createSlice({
  name: "Payment",
  initialState: initialPaymentState,
  reducers: {
    setProduct: (
      state,
      action: PayloadAction<IStripeProductPrice | undefined>
    ) => {
      state.product = action.payload;
    },
  },
});

export const { setProduct } = PaymentSlice.actions;

export default PaymentSlice.reducer;
