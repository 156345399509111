import { PublicUser, SimpleUser } from "@/openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsersSearchState {
  query: string | undefined;
  searchedUser: PublicUser | undefined;
  results: SimpleUser[] | undefined;
}

export const initialUsersSearchState: IUsersSearchState = {
  results: undefined,
  query: undefined,
  searchedUser: undefined,
};

export const usersSearchSlice = createSlice({
  name: "usersSearch",
  initialState: initialUsersSearchState,
  reducers: {
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setResults: (state, action: PayloadAction<SimpleUser[]>) => {
      state.results = action.payload;
    },
    setSearchedUser: (state, action: PayloadAction<PublicUser>) => {
      state.searchedUser = action.payload;
    },
  },
});

export const { setQuery, setResults, setSearchedUser } =
  usersSearchSlice.actions;

export default usersSearchSlice.reducer;
