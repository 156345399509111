/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NotificationTypeEnum } from '../models/NotificationTypeEnum';
import { HttpFile } from '../http/http';

export class PatchedNotificationTypeRequest {
    'turnOff'?: boolean;
    'notificationType'?: NotificationTypeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "turnOff",
            "baseName": "turnOff",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "notificationType",
            "baseName": "notificationType",
            "type": "NotificationTypeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedNotificationTypeRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



