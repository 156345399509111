import { ActivityReaction, User } from "@/openapi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  user: User | undefined;
  activityReactions: ActivityReaction[] | undefined;
}

export const initialUserState: IUserState = {
  user: undefined,
  activityReactions: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    setUserReactions: (state, action: PayloadAction<ActivityReaction[]>) => {
      state.activityReactions = action.payload;
    },
  },
});

export const { setUser, setUserReactions } = userSlice.actions;

export default userSlice.reducer;
