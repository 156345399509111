/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseActivity } from '../models/HorseActivity';
import { SimpleHorse } from '../models/SimpleHorse';
import { HttpFile } from '../http/http';

export class WrappedSerialzer {
    'distance': number;
    'time': number;
    'numRides': number;
    'distancePercent': number;
    'timePercent': number;
    'numRidesPercent': number;
    'avgDistance': number;
    'avgTime': number;
    'mostUsedHorse': SimpleHorse;
    'mostUsedHorseActivityCount': number;
    'activityLongestDistance'?: HorseActivity;
    'totalActivities': number;
    'mostUsedActivityType': string;
    'mostUsedActivityTypeCount': number;
    'activityTypes': { [key: string]: any; };
    'horseSummary': { [key: string]: any; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "distance",
            "baseName": "distance",
            "type": "number",
            "format": "double"
        },
        {
            "name": "time",
            "baseName": "time",
            "type": "number",
            "format": "double"
        },
        {
            "name": "numRides",
            "baseName": "numRides",
            "type": "number",
            "format": ""
        },
        {
            "name": "distancePercent",
            "baseName": "distancePercent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "timePercent",
            "baseName": "timePercent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "numRidesPercent",
            "baseName": "numRidesPercent",
            "type": "number",
            "format": "double"
        },
        {
            "name": "avgDistance",
            "baseName": "avgDistance",
            "type": "number",
            "format": "double"
        },
        {
            "name": "avgTime",
            "baseName": "avgTime",
            "type": "number",
            "format": "double"
        },
        {
            "name": "mostUsedHorse",
            "baseName": "mostUsedHorse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "mostUsedHorseActivityCount",
            "baseName": "mostUsedHorseActivityCount",
            "type": "number",
            "format": ""
        },
        {
            "name": "activityLongestDistance",
            "baseName": "activityLongestDistance",
            "type": "HorseActivity",
            "format": ""
        },
        {
            "name": "totalActivities",
            "baseName": "totalActivities",
            "type": "number",
            "format": ""
        },
        {
            "name": "mostUsedActivityType",
            "baseName": "mostUsedActivityType",
            "type": "string",
            "format": ""
        },
        {
            "name": "mostUsedActivityTypeCount",
            "baseName": "mostUsedActivityTypeCount",
            "type": "number",
            "format": ""
        },
        {
            "name": "activityTypes",
            "baseName": "activityTypes",
            "type": "{ [key: string]: any; }",
            "format": ""
        },
        {
            "name": "horseSummary",
            "baseName": "horseSummary",
            "type": "{ [key: string]: any; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return WrappedSerialzer.attributeTypeMap;
    }

    public constructor() {
    }
}

