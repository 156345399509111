/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseProfilePhoto } from '../models/HorseProfilePhoto';
import { Owner } from '../models/Owner';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { HttpFile } from '../http/http';

export class SearchHorse {
    'faedingarnumer': string;
    'name': string;
    'description'?: string;
    'colorCode'?: string;
    'colorName'?: string;
    'birthDate': string;
    'rfid'?: Array<string>;
    'sireFaedingarnumer'?: string;
    'sireName'?: string;
    'damFaedingarnumer'?: string;
    'damName'?: string;
    'sex'?: string;
    'sexEnum'?: SexEnumEnum;
    'isAlive': boolean;
    'originCountry'?: string;
    'noOffspring'?: number;
    'forSale'?: number;
    'owners': Array<Owner>;
    'avatar'?: HorseProfilePhoto;
    'haefileikar'?: number;
    'adaleinkunn'?: number;
    'haefileikarAnSkeids'?: number;
    'adaleinkunnAnSkeids'?: number;
    'haestaAdaleinkunn'?: number;
    'dtSetForSale'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "faedingarnumer",
            "baseName": "faedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorCode",
            "baseName": "colorCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorName",
            "baseName": "colorName",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthDate",
            "baseName": "birthDate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "rfid",
            "baseName": "rfid",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "sireFaedingarnumer",
            "baseName": "sireFaedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "sireName",
            "baseName": "sireName",
            "type": "string",
            "format": ""
        },
        {
            "name": "damFaedingarnumer",
            "baseName": "damFaedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "damName",
            "baseName": "damName",
            "type": "string",
            "format": ""
        },
        {
            "name": "sex",
            "baseName": "sex",
            "type": "string",
            "format": ""
        },
        {
            "name": "sexEnum",
            "baseName": "sexEnum",
            "type": "SexEnumEnum",
            "format": ""
        },
        {
            "name": "isAlive",
            "baseName": "isAlive",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "originCountry",
            "baseName": "originCountry",
            "type": "string",
            "format": ""
        },
        {
            "name": "noOffspring",
            "baseName": "noOffspring",
            "type": "number",
            "format": ""
        },
        {
            "name": "forSale",
            "baseName": "forSale",
            "type": "number",
            "format": ""
        },
        {
            "name": "owners",
            "baseName": "owners",
            "type": "Array<Owner>",
            "format": ""
        },
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "HorseProfilePhoto",
            "format": ""
        },
        {
            "name": "haefileikar",
            "baseName": "haefileikar",
            "type": "number",
            "format": ""
        },
        {
            "name": "adaleinkunn",
            "baseName": "adaleinkunn",
            "type": "number",
            "format": ""
        },
        {
            "name": "haefileikarAnSkeids",
            "baseName": "haefileikarAnSkeids",
            "type": "number",
            "format": ""
        },
        {
            "name": "adaleinkunnAnSkeids",
            "baseName": "adaleinkunnAnSkeids",
            "type": "number",
            "format": ""
        },
        {
            "name": "haestaAdaleinkunn",
            "baseName": "haestaAdaleinkunn",
            "type": "number",
            "format": "double"
        },
        {
            "name": "dtSetForSale",
            "baseName": "dtSetForSale",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return SearchHorse.attributeTypeMap;
    }

    public constructor() {
    }
}



