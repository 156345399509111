/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Measurments {
    'm1': number;
    'm2': number;
    'm3': number;
    'm4': number;
    'm5': number;
    'm6': number;
    'm7': number;
    'm8': number;
    'm9': number;
    'm10': number;
    'm11': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "m1",
            "baseName": "m1",
            "type": "number",
            "format": ""
        },
        {
            "name": "m2",
            "baseName": "m2",
            "type": "number",
            "format": ""
        },
        {
            "name": "m3",
            "baseName": "m3",
            "type": "number",
            "format": ""
        },
        {
            "name": "m4",
            "baseName": "m4",
            "type": "number",
            "format": ""
        },
        {
            "name": "m5",
            "baseName": "m5",
            "type": "number",
            "format": ""
        },
        {
            "name": "m6",
            "baseName": "m6",
            "type": "number",
            "format": ""
        },
        {
            "name": "m7",
            "baseName": "m7",
            "type": "number",
            "format": ""
        },
        {
            "name": "m8",
            "baseName": "m8",
            "type": "number",
            "format": ""
        },
        {
            "name": "m9",
            "baseName": "m9",
            "type": "number",
            "format": ""
        },
        {
            "name": "m10",
            "baseName": "m10",
            "type": "number",
            "format": ""
        },
        {
            "name": "m11",
            "baseName": "m11",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Measurments.attributeTypeMap;
    }

    public constructor() {
    }
}

