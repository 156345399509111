/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupInvite } from '../models/GroupInvite';
import { GroupRequest } from '../models/GroupRequest';
import { HorseAccessInvite } from '../models/HorseAccessInvite';
import { HorseAccessReq } from '../models/HorseAccessReq';
import { NotificationTypeEnum } from '../models/NotificationTypeEnum';
import { SafetyTrackingTeamInvite } from '../models/SafetyTrackingTeamInvite';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleHorseActivity } from '../models/SimpleHorseActivity';
import { SimpleUser } from '../models/SimpleUser';
import { UserFollowRequest } from '../models/UserFollowRequest';
import { HttpFile } from '../http/http';

export class Notification {
    'id': string;
    'dtCreated': Date;
    'dtModified'?: Date;
    'title'?: string;
    'body'?: string;
    'thumbnail'?: string;
    'seen'?: boolean;
    'dtSeen'?: Date;
    'notificationType': NotificationTypeEnum;
    'sender'?: SimpleUser;
    'receiver': SimpleUser;
    'relatedId'?: string;
    'horseActivity'?: SimpleHorseActivity;
    'invite'?: HorseAccessInvite;
    'request'?: HorseAccessReq;
    'followRequest'?: UserFollowRequest;
    'extraText'?: string;
    'translationKey'?: string;
    'horse'?: SimpleHorse;
    'groupInvite'?: GroupInvite;
    'groupRequest'?: GroupRequest;
    'safetyTrackingTeamInvite'?: SafetyTrackingTeamInvite;
    'extraData'?: { [key: string]: any; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "body",
            "baseName": "body",
            "type": "string",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "seen",
            "baseName": "seen",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dtSeen",
            "baseName": "dtSeen",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "notificationType",
            "baseName": "notificationType",
            "type": "NotificationTypeEnum",
            "format": ""
        },
        {
            "name": "sender",
            "baseName": "sender",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "receiver",
            "baseName": "receiver",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "relatedId",
            "baseName": "relatedId",
            "type": "string",
            "format": ""
        },
        {
            "name": "horseActivity",
            "baseName": "horseActivity",
            "type": "SimpleHorseActivity",
            "format": ""
        },
        {
            "name": "invite",
            "baseName": "invite",
            "type": "HorseAccessInvite",
            "format": ""
        },
        {
            "name": "request",
            "baseName": "request",
            "type": "HorseAccessReq",
            "format": ""
        },
        {
            "name": "followRequest",
            "baseName": "followRequest",
            "type": "UserFollowRequest",
            "format": ""
        },
        {
            "name": "extraText",
            "baseName": "extraText",
            "type": "string",
            "format": ""
        },
        {
            "name": "translationKey",
            "baseName": "translationKey",
            "type": "string",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "groupInvite",
            "baseName": "groupInvite",
            "type": "GroupInvite",
            "format": ""
        },
        {
            "name": "groupRequest",
            "baseName": "groupRequest",
            "type": "GroupRequest",
            "format": ""
        },
        {
            "name": "safetyTrackingTeamInvite",
            "baseName": "safetyTrackingTeamInvite",
            "type": "SafetyTrackingTeamInvite",
            "format": ""
        },
        {
            "name": "extraData",
            "baseName": "extraData",
            "type": "{ [key: string]: any; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Notification.attributeTypeMap;
    }

    public constructor() {
    }
}



