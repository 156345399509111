/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { CustomTrainingSessionCreate } from '../models/CustomTrainingSessionCreate';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { HorseActivityReactionSummary } from '../models/HorseActivityReactionSummary';
import { Medical } from '../models/Medical';
import { Shoeing } from '../models/Shoeing';
import { TrainingSessionCreate } from '../models/TrainingSessionCreate';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { HttpFile } from '../http/http';

export class HorseActivityCreate {
    'id': number;
    'dtCreated': Date;
    'dtModified': Date;
    'dtPerformed': Date;
    'isDraft'?: boolean;
    'type': ActivityTypeEnum;
    'horse'?: string;
    'actor'?: number;
    'data'?: { [key: string]: any; };
    'location'?: HorseActivityLocation;
    'title'?: string;
    'text'?: string;
    'trainingSession'?: TrainingSessionCreate;
    'customTrainingSession'?: CustomTrainingSessionCreate;
    'shoeing'?: Shoeing;
    'medical'?: Medical;
    'photos'?: Array<number>;
    'videos'?: Array<number>;
    'reactionSummary': HorseActivityReactionSummary;
    'commentCount': number;
    'visibility'?: VisibilityEnum;
    'isPlanned'?: boolean;
    'hasFinishedPlanned'?: boolean;
    'plannedWithTime'?: boolean;
    'group'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtModified",
            "baseName": "dtModified",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtPerformed",
            "baseName": "dtPerformed",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isDraft",
            "baseName": "isDraft",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "ActivityTypeEnum",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "string",
            "format": ""
        },
        {
            "name": "actor",
            "baseName": "actor",
            "type": "number",
            "format": ""
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "{ [key: string]: any; }",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseActivityLocation",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "text",
            "baseName": "text",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainingSession",
            "baseName": "trainingSession",
            "type": "TrainingSessionCreate",
            "format": ""
        },
        {
            "name": "customTrainingSession",
            "baseName": "customTrainingSession",
            "type": "CustomTrainingSessionCreate",
            "format": ""
        },
        {
            "name": "shoeing",
            "baseName": "shoeing",
            "type": "Shoeing",
            "format": ""
        },
        {
            "name": "medical",
            "baseName": "medical",
            "type": "Medical",
            "format": ""
        },
        {
            "name": "photos",
            "baseName": "photos",
            "type": "Array<number>",
            "format": ""
        },
        {
            "name": "videos",
            "baseName": "videos",
            "type": "Array<number>",
            "format": ""
        },
        {
            "name": "reactionSummary",
            "baseName": "reactionSummary",
            "type": "HorseActivityReactionSummary",
            "format": ""
        },
        {
            "name": "commentCount",
            "baseName": "commentCount",
            "type": "number",
            "format": ""
        },
        {
            "name": "visibility",
            "baseName": "visibility",
            "type": "VisibilityEnum",
            "format": ""
        },
        {
            "name": "isPlanned",
            "baseName": "isPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hasFinishedPlanned",
            "baseName": "hasFinishedPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "plannedWithTime",
            "baseName": "plannedWithTime",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "group",
            "baseName": "group",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseActivityCreate.attributeTypeMap;
    }

    public constructor() {
    }
}



