/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUser } from '../models/SimpleUser';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { HttpFile } from '../http/http';

export class Subscription {
    'subscriptionType'?: SubscriptionTypeEnum;
    'user': SimpleUser;
    'dtPurchase'?: Date;
    'dtExpire'?: Date;
    'autoRenewing'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "subscriptionType",
            "baseName": "subscriptionType",
            "type": "SubscriptionTypeEnum",
            "format": ""
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "dtPurchase",
            "baseName": "dtPurchase",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtExpire",
            "baseName": "dtExpire",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "autoRenewing",
            "baseName": "autoRenewing",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Subscription.attributeTypeMap;
    }

    public constructor() {
    }
}



