/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StripeProductMetadata } from '../models/StripeProductMetadata';
import { HttpFile } from '../http/http';

export class StripeProduct {
    'active': boolean;
    'defaultPrice': string;
    'description': string;
    'id': string;
    'name': string;
    'metadata': StripeProductMetadata;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "defaultPrice",
            "baseName": "defaultPrice",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "StripeProductMetadata",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StripeProduct.attributeTypeMap;
    }

    public constructor() {
    }
}

