import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { AcceptRequest } from '../models/AcceptRequest';
import { ActivityMedia } from '../models/ActivityMedia';
import { ActivityReaction } from '../models/ActivityReaction';
import { ActivityReactionRequest } from '../models/ActivityReactionRequest';
import { ActivityTypeEnum } from '../models/ActivityTypeEnum';
import { ActivityVideoThumbnailRequest } from '../models/ActivityVideoThumbnailRequest';
import { Actor } from '../models/Actor';
import { AnswerUserSurveyRequest } from '../models/AnswerUserSurveyRequest';
import { AppMeta } from '../models/AppMeta';
import { AssessmentOverview } from '../models/AssessmentOverview';
import { ChatMessage } from '../models/ChatMessage';
import { ChatMessageRequest } from '../models/ChatMessageRequest';
import { ChatRoom } from '../models/ChatRoom';
import { ChatRoomRequest } from '../models/ChatRoomRequest';
import { ChatUser } from '../models/ChatUser';
import { ChatUserRequest } from '../models/ChatUserRequest';
import { Comment } from '../models/Comment';
import { CommentReaction } from '../models/CommentReaction';
import { CommentReactionRequest } from '../models/CommentReactionRequest';
import { CommentReactionSummary } from '../models/CommentReactionSummary';
import { CommentRequest } from '../models/CommentRequest';
import { CountryYears } from '../models/CountryYears';
import { CreateGroupDiscussionRequest } from '../models/CreateGroupDiscussionRequest';
import { CreateGroupRequest } from '../models/CreateGroupRequest';
import { CreateGroupUserInviteRequest } from '../models/CreateGroupUserInviteRequest';
import { CreateHorseAccessInviteRequest } from '../models/CreateHorseAccessInviteRequest';
import { CreateHorseAccessReqRequest } from '../models/CreateHorseAccessReqRequest';
import { CreateHorseAccessRequest } from '../models/CreateHorseAccessRequest';
import { CreateHorseRequest } from '../models/CreateHorseRequest';
import { CustomTrainingSession } from '../models/CustomTrainingSession';
import { CustomTrainingSessionCreate } from '../models/CustomTrainingSessionCreate';
import { CustomTrainingSessionCreateRequest } from '../models/CustomTrainingSessionCreateRequest';
import { DetailedAssessmentOverview } from '../models/DetailedAssessmentOverview';
import { DownloadPurposeEnum } from '../models/DownloadPurposeEnum';
import { DurationEnum } from '../models/DurationEnum';
import { EntitlementEnum } from '../models/EntitlementEnum';
import { EventPoints } from '../models/EventPoints';
import { FaedingarnumerSerilizerRequest } from '../models/FaedingarnumerSerilizerRequest';
import { Feeding } from '../models/Feeding';
import { FeedingRequest } from '../models/FeedingRequest';
import { FlokkurEnum } from '../models/FlokkurEnum';
import { FlokkurKeppni } from '../models/FlokkurKeppni';
import { FollowEventFlokkur } from '../models/FollowEventFlokkur';
import { FollowEventFlokkurRequest } from '../models/FollowEventFlokkurRequest';
import { FollowRequest } from '../models/FollowRequest';
import { FollowingFollowers } from '../models/FollowingFollowers';
import { GaitEnum } from '../models/GaitEnum';
import { GaitPredictions } from '../models/GaitPredictions';
import { GaitPredictionsRequest } from '../models/GaitPredictionsRequest';
import { GaitRating } from '../models/GaitRating';
import { GaitRatingRequest } from '../models/GaitRatingRequest';
import { GaitSensorRequest } from '../models/GaitSensorRequest';
import { GoalRequest } from '../models/GoalRequest';
import { Group } from '../models/Group';
import { GroupDiscussion } from '../models/GroupDiscussion';
import { GroupDiscussionComment } from '../models/GroupDiscussionComment';
import { GroupDiscussionCommentReaction } from '../models/GroupDiscussionCommentReaction';
import { GroupDiscussionCommentReactionRequest } from '../models/GroupDiscussionCommentReactionRequest';
import { GroupDiscussionCommentRequest } from '../models/GroupDiscussionCommentRequest';
import { GroupDiscussionPhoto } from '../models/GroupDiscussionPhoto';
import { GroupDiscussionPhotoRequest } from '../models/GroupDiscussionPhotoRequest';
import { GroupDiscussionReaction } from '../models/GroupDiscussionReaction';
import { GroupDiscussionReactionRequest } from '../models/GroupDiscussionReactionRequest';
import { GroupInvite } from '../models/GroupInvite';
import { GroupMare } from '../models/GroupMare';
import { GroupMareRequest } from '../models/GroupMareRequest';
import { GroupProfile } from '../models/GroupProfile';
import { GroupRequest } from '../models/GroupRequest';
import { GroupSettings } from '../models/GroupSettings';
import { GroupSettingsRequest } from '../models/GroupSettingsRequest';
import { GroupThumbnailRequest } from '../models/GroupThumbnailRequest';
import { GroupTypeEnum } from '../models/GroupTypeEnum';
import { GroupUserRoleEnum } from '../models/GroupUserRoleEnum';
import { GroupUserRoleRequest } from '../models/GroupUserRoleRequest';
import { HealthStatusEnum } from '../models/HealthStatusEnum';
import { Horse } from '../models/Horse';
import { HorseAccess } from '../models/HorseAccess';
import { HorseAccessHistory } from '../models/HorseAccessHistory';
import { HorseAccessInvite } from '../models/HorseAccessInvite';
import { HorseAccessInviteRequest } from '../models/HorseAccessInviteRequest';
import { HorseAccessReq } from '../models/HorseAccessReq';
import { HorseAccessRequest } from '../models/HorseAccessRequest';
import { HorseActivities } from '../models/HorseActivities';
import { HorseActivity } from '../models/HorseActivity';
import { HorseActivityCreate } from '../models/HorseActivityCreate';
import { HorseActivityCreateRequest } from '../models/HorseActivityCreateRequest';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { HorseActivityMultiCreateRequest } from '../models/HorseActivityMultiCreateRequest';
import { HorseActivityPhoto } from '../models/HorseActivityPhoto';
import { HorseActivityPhotoRequest } from '../models/HorseActivityPhotoRequest';
import { HorseActivityReactionSummary } from '../models/HorseActivityReactionSummary';
import { HorseActivityUpdate } from '../models/HorseActivityUpdate';
import { HorseActivityUpdateRequest } from '../models/HorseActivityUpdateRequest';
import { HorseActivityVideo } from '../models/HorseActivityVideo';
import { HorseActivityVideoRequest } from '../models/HorseActivityVideoRequest';
import { HorseAssessment } from '../models/HorseAssessment';
import { HorseAssessmentRequest } from '../models/HorseAssessmentRequest';
import { HorseBreedEnum } from '../models/HorseBreedEnum';
import { HorseDayMessage } from '../models/HorseDayMessage';
import { HorseDayRanking } from '../models/HorseDayRanking';
import { HorseDetails } from '../models/HorseDetails';
import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { HorseDetailsRequest } from '../models/HorseDetailsRequest';
import { HorseForSale } from '../models/HorseForSale';
import { HorseForSaleRequest } from '../models/HorseForSaleRequest';
import { HorseIdRequest } from '../models/HorseIdRequest';
import { HorseMedicalDetails } from '../models/HorseMedicalDetails';
import { HorseMedicalDetailsRequest } from '../models/HorseMedicalDetailsRequest';
import { HorsePhoto } from '../models/HorsePhoto';
import { HorsePhotoRequest } from '../models/HorsePhotoRequest';
import { HorseProfilePhoto } from '../models/HorseProfilePhoto';
import { HorseProfilePhotoRequest } from '../models/HorseProfilePhotoRequest';
import { HorseQueryMetadata } from '../models/HorseQueryMetadata';
import { HorseSettings } from '../models/HorseSettings';
import { HorseSettingsRequest } from '../models/HorseSettingsRequest';
import { HorseShoeEnum } from '../models/HorseShoeEnum';
import { HorseTypeEnum } from '../models/HorseTypeEnum';
import { HorseUserRoleEnum } from '../models/HorseUserRoleEnum';
import { HorseVaccination } from '../models/HorseVaccination';
import { HorseVaccinationRequest } from '../models/HorseVaccinationRequest';
import { HorseVideo } from '../models/HorseVideo';
import { HorseVideoRequest } from '../models/HorseVideoRequest';
import { HorseVideoThumbnailRequest } from '../models/HorseVideoThumbnailRequest';
import { IdRequest } from '../models/IdRequest';
import { InRoomRequest } from '../models/InRoomRequest';
import { InfoSummary } from '../models/InfoSummary';
import { IntervalTypeEnum } from '../models/IntervalTypeEnum';
import { LandsmotJudgeOverview } from '../models/LandsmotJudgeOverview';
import { Leaderboard } from '../models/Leaderboard';
import { LeaderboardRequest } from '../models/LeaderboardRequest';
import { LoginRequest } from '../models/LoginRequest';
import { MeasurmentSystemEnum } from '../models/MeasurmentSystemEnum';
import { Measurments } from '../models/Measurments';
import { MeasurmentsRequest } from '../models/MeasurmentsRequest';
import { Medical } from '../models/Medical';
import { MedicalRequest } from '../models/MedicalRequest';
import { MessageTypeEnum } from '../models/MessageTypeEnum';
import { MetricEnum } from '../models/MetricEnum';
import { MonthlyTrainingSummary } from '../models/MonthlyTrainingSummary';
import { Notification } from '../models/Notification';
import { NotificationTypeEnum } from '../models/NotificationTypeEnum';
import { NotificationTypeRequest } from '../models/NotificationTypeRequest';
import { Number } from '../models/Number';
import { NumberRequest } from '../models/NumberRequest';
import { Owner } from '../models/Owner';
import { OwnerRequest } from '../models/OwnerRequest';
import { PaginatedActivity } from '../models/PaginatedActivity';
import { PaginatedActivityMediaList } from '../models/PaginatedActivityMediaList';
import { PaginatedActivityReactionList } from '../models/PaginatedActivityReactionList';
import { PaginatedChatMessageList } from '../models/PaginatedChatMessageList';
import { PaginatedChatRoomList } from '../models/PaginatedChatRoomList';
import { PaginatedCommentList } from '../models/PaginatedCommentList';
import { PaginatedCommentReactionList } from '../models/PaginatedCommentReactionList';
import { PaginatedGroupDiscussionCommentList } from '../models/PaginatedGroupDiscussionCommentList';
import { PaginatedGroupDiscussionList } from '../models/PaginatedGroupDiscussionList';
import { PaginatedHorseActivityList } from '../models/PaginatedHorseActivityList';
import { PaginatedHorseActivityPhotoList } from '../models/PaginatedHorseActivityPhotoList';
import { PaginatedHorseActivityVideoList } from '../models/PaginatedHorseActivityVideoList';
import { PaginatedHorseDayRankingList } from '../models/PaginatedHorseDayRankingList';
import { PaginatedHorseList } from '../models/PaginatedHorseList';
import { PaginatedHorsePhotoList } from '../models/PaginatedHorsePhotoList';
import { PaginatedHorsePhotos } from '../models/PaginatedHorsePhotos';
import { PaginatedHorseVideoList } from '../models/PaginatedHorseVideoList';
import { PaginatedHorseVideos } from '../models/PaginatedHorseVideos';
import { PaginatedHorses } from '../models/PaginatedHorses';
import { PaginatedLeaderboardList } from '../models/PaginatedLeaderboardList';
import { PaginatedNotificationList } from '../models/PaginatedNotificationList';
import { PaginatedPaginatedHorsePhotosList } from '../models/PaginatedPaginatedHorsePhotosList';
import { PaginatedPaginatedHorseVideosList } from '../models/PaginatedPaginatedHorseVideosList';
import { PaginatedTrainingSessionList } from '../models/PaginatedTrainingSessionList';
import { PaginatedUsers } from '../models/PaginatedUsers';
import { PartialSurvey } from '../models/PartialSurvey';
import { PartialSurveyQuestion } from '../models/PartialSurveyQuestion';
import { PartialUserSurvey } from '../models/PartialUserSurvey';
import { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import { PasswordResetConfirmRequest } from '../models/PasswordResetConfirmRequest';
import { PasswordResetRequest } from '../models/PasswordResetRequest';
import { PatchedActivityReactionRequest } from '../models/PatchedActivityReactionRequest';
import { PatchedCommentReactionRequest } from '../models/PatchedCommentReactionRequest';
import { PatchedCommentRequest } from '../models/PatchedCommentRequest';
import { PatchedFeedingRequest } from '../models/PatchedFeedingRequest';
import { PatchedGroupDiscussionCommentRequest } from '../models/PatchedGroupDiscussionCommentRequest';
import { PatchedGroupDiscussionRequest } from '../models/PatchedGroupDiscussionRequest';
import { PatchedGroupRequest } from '../models/PatchedGroupRequest';
import { PatchedHorseAccessInviteRequest } from '../models/PatchedHorseAccessInviteRequest';
import { PatchedHorseAccessRequest } from '../models/PatchedHorseAccessRequest';
import { PatchedHorseActivityPhotoRequest } from '../models/PatchedHorseActivityPhotoRequest';
import { PatchedHorseActivityUpdateRequest } from '../models/PatchedHorseActivityUpdateRequest';
import { PatchedHorseActivityVideoRequest } from '../models/PatchedHorseActivityVideoRequest';
import { PatchedHorseDetailsRequest } from '../models/PatchedHorseDetailsRequest';
import { PatchedHorseMedicalDetailsRequest } from '../models/PatchedHorseMedicalDetailsRequest';
import { PatchedHorsePhotoRequest } from '../models/PatchedHorsePhotoRequest';
import { PatchedHorseRequest } from '../models/PatchedHorseRequest';
import { PatchedHorseVaccinationRequest } from '../models/PatchedHorseVaccinationRequest';
import { PatchedHorseVideoRequest } from '../models/PatchedHorseVideoRequest';
import { PatchedLeaderboardRequest } from '../models/PatchedLeaderboardRequest';
import { PatchedNotificationTypeRequest } from '../models/PatchedNotificationTypeRequest';
import { PatchedTrainingSessionRequest } from '../models/PatchedTrainingSessionRequest';
import { PatchedUserRequest } from '../models/PatchedUserRequest';
import { PatchedUserSettingsRequest } from '../models/PatchedUserSettingsRequest';
import { PatchedUserUpdateRequest } from '../models/PatchedUserUpdateRequest';
import { PlatformEnum } from '../models/PlatformEnum';
import { Point } from '../models/Point';
import { PriceCategoryEnum } from '../models/PriceCategoryEnum';
import { PromoCode } from '../models/PromoCode';
import { PromoCodeRequest } from '../models/PromoCodeRequest';
import { ProviderEnum } from '../models/ProviderEnum';
import { PublicUser } from '../models/PublicUser';
import { ReactionTypeEnum } from '../models/ReactionTypeEnum';
import { RegisterRequest } from '../models/RegisterRequest';
import { RequestStatus } from '../models/RequestStatus';
import { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import { RestAuthDetail } from '../models/RestAuthDetail';
import { RevenucatEntitlement } from '../models/RevenucatEntitlement';
import { RevenucatSubscription } from '../models/RevenucatSubscription';
import { RevenuecatSubscriptions } from '../models/RevenuecatSubscriptions';
import { SafetyTrackingMyInfo } from '../models/SafetyTrackingMyInfo';
import { SafetyTrackingTeam } from '../models/SafetyTrackingTeam';
import { SafetyTrackingTeamInvite } from '../models/SafetyTrackingTeamInvite';
import { SafetyTrackingTeamInviteCreateRequest } from '../models/SafetyTrackingTeamInviteCreateRequest';
import { SafetyTrackingViewer } from '../models/SafetyTrackingViewer';
import { SearchHorse } from '../models/SearchHorse';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { Shoeing } from '../models/Shoeing';
import { ShoeingRequest } from '../models/ShoeingRequest';
import { SimpleBooleanRequest } from '../models/SimpleBooleanRequest';
import { SimpleGroup } from '../models/SimpleGroup';
import { SimpleGroupHorse } from '../models/SimpleGroupHorse';
import { SimpleGroupHorseRequest } from '../models/SimpleGroupHorseRequest';
import { SimpleGroupUser } from '../models/SimpleGroupUser';
import { SimpleGroupUserRequest } from '../models/SimpleGroupUserRequest';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleHorseActivities } from '../models/SimpleHorseActivities';
import { SimpleHorseActivity } from '../models/SimpleHorseActivity';
import { SimpleHorseRequest } from '../models/SimpleHorseRequest';
import { SimpleMessage } from '../models/SimpleMessage';
import { SimpleMonthlyActivitySummary } from '../models/SimpleMonthlyActivitySummary';
import { SimpleNote } from '../models/SimpleNote';
import { SimpleRequest } from '../models/SimpleRequest';
import { SimpleTrackingPoint } from '../models/SimpleTrackingPoint';
import { SimpleUser } from '../models/SimpleUser';
import { SimpleUserActivity } from '../models/SimpleUserActivity';
import { SimpleUserInfo } from '../models/SimpleUserInfo';
import { SimpleUserInfoRequest } from '../models/SimpleUserInfoRequest';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { SocialAccount } from '../models/SocialAccount';
import { SocialConnect } from '../models/SocialConnect';
import { SocialConnectRequest } from '../models/SocialConnectRequest';
import { SocialLoginRequest } from '../models/SocialLoginRequest';
import { SportFengurEvent } from '../models/SportFengurEvent';
import { SportFengurEventFollowRequest } from '../models/SportFengurEventFollowRequest';
import { SportFengurEventRequest } from '../models/SportFengurEventRequest';
import { StallionGroup } from '../models/StallionGroup';
import { StallionGroupRequest } from '../models/StallionGroupRequest';
import { StartEndRequest } from '../models/StartEndRequest';
import { StripeCheckoutSession } from '../models/StripeCheckoutSession';
import { StripeCheckoutSessionCreateRequest } from '../models/StripeCheckoutSessionCreateRequest';
import { StripeCoupon } from '../models/StripeCoupon';
import { StripePrice } from '../models/StripePrice';
import { StripePricesAndProducts } from '../models/StripePricesAndProducts';
import { StripeProduct } from '../models/StripeProduct';
import { StripeProductMetadata } from '../models/StripeProductMetadata';
import { StripeRecurring } from '../models/StripeRecurring';
import { Subscription } from '../models/Subscription';
import { SubscriptionTypeEnum } from '../models/SubscriptionTypeEnum';
import { Suggested } from '../models/Suggested';
import { Summary } from '../models/Summary';
import { TeamInfo } from '../models/TeamInfo';
import { Token } from '../models/Token';
import { TokenRequest } from '../models/TokenRequest';
import { TrackingPointCorrectionRequest } from '../models/TrackingPointCorrectionRequest';
import { TrainingSession } from '../models/TrainingSession';
import { TrainingSessionCreate } from '../models/TrainingSessionCreate';
import { TrainingSessionCreateRequest } from '../models/TrainingSessionCreateRequest';
import { TrainingSessionFeedbackRequest } from '../models/TrainingSessionFeedbackRequest';
import { TrainingSessionRequest } from '../models/TrainingSessionRequest';
import { TrainingSessionTrackingPoint } from '../models/TrainingSessionTrackingPoint';
import { TrainingSessionTrackingPointRequest } from '../models/TrainingSessionTrackingPointRequest';
import { TrainingSummary } from '../models/TrainingSummary';
import { TrainingSummaryRequest } from '../models/TrainingSummaryRequest';
import { TranscodedFile } from '../models/TranscodedFile';
import { TranscodedFileRequest } from '../models/TranscodedFileRequest';
import { TranscodedFiles } from '../models/TranscodedFiles';
import { TranscodedFilesRequest } from '../models/TranscodedFilesRequest';
import { TranscoderCallbackRequestRequest } from '../models/TranscoderCallbackRequestRequest';
import { TranscoderData } from '../models/TranscoderData';
import { TranscoderDataRequest } from '../models/TranscoderDataRequest';
import { TypesEnumEnum } from '../models/TypesEnumEnum';
import { UpdateGroupDiscussionRequest } from '../models/UpdateGroupDiscussionRequest';
import { UpdateGroupHorseRequest } from '../models/UpdateGroupHorseRequest';
import { UpdateGroupMareRequest } from '../models/UpdateGroupMareRequest';
import { UpdateGroupRequest } from '../models/UpdateGroupRequest';
import { UpdateStallionGroupRequest } from '../models/UpdateStallionGroupRequest';
import { User } from '../models/User';
import { UserExtradata } from '../models/UserExtradata';
import { UserExtradataRequest } from '../models/UserExtradataRequest';
import { UserFollowRequest } from '../models/UserFollowRequest';
import { UserFollowRequestResponseRequest } from '../models/UserFollowRequestResponseRequest';
import { UserLocale } from '../models/UserLocale';
import { UserLocaleRequest } from '../models/UserLocaleRequest';
import { UserMonthlyTrainingSummary } from '../models/UserMonthlyTrainingSummary';
import { UserQuestionareRequest } from '../models/UserQuestionareRequest';
import { UserReaction } from '../models/UserReaction';
import { UserRequest } from '../models/UserRequest';
import { UserSettings } from '../models/UserSettings';
import { UserSettingsRequest } from '../models/UserSettingsRequest';
import { UserUidRequest } from '../models/UserUidRequest';
import { UserWeeklyTrainingSummary } from '../models/UserWeeklyTrainingSummary';
import { VerifyEmailRequest } from '../models/VerifyEmailRequest';
import { VerySimpleHorseActivity } from '../models/VerySimpleHorseActivity';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { WFLoginRequest } from '../models/WFLoginRequest';
import { WFOffspring } from '../models/WFOffspring';
import { WFWebookHorseRemoveRequest } from '../models/WFWebookHorseRemoveRequest';
import { WFWebookHorseUpdateRequest } from '../models/WFWebookHorseUpdateRequest';
import { WFWebookNewJudgementRequest } from '../models/WFWebookNewJudgementRequest';
import { WFWebookOffspringUpdateRequest } from '../models/WFWebookOffspringUpdateRequest';
import { Weather } from '../models/Weather';
import { WeatherRequest } from '../models/WeatherRequest';
import { WebhookDomarRequest } from '../models/WebhookDomarRequest';
import { WebhookEventParticipantRequest } from '../models/WebhookEventParticipantRequest';
import { WebhookEventProgramRequest } from '../models/WebhookEventProgramRequest';
import { WebhookEventRequest } from '../models/WebhookEventRequest';
import { WebhookRaslistiRequest } from '../models/WebhookRaslistiRequest';
import { WeeklyTrainingSummary } from '../models/WeeklyTrainingSummary';
import { WorldCupHorse } from '../models/WorldCupHorse';
import { WrappedSerialzer } from '../models/WrappedSerialzer';

import { AccountsApiRequestFactory, AccountsApiResponseProcessor} from "../apis/AccountsApi";
export class ObservableAccountsApi {
    private requestFactory: AccountsApiRequestFactory;
    private responseProcessor: AccountsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AccountsApiRequestFactory,
        responseProcessor?: AccountsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AccountsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AccountsApiResponseProcessor();
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = 'localhost:8000' -------------
     * @param socialLoginRequest 
     */
    public accountsAppleCreate(socialLoginRequest?: SocialLoginRequest, _options?: Configuration): Observable<Token> {
        const requestContextPromise = this.requestFactory.accountsAppleCreate(socialLoginRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsAppleCreate(rsp)));
            }));
    }

    /**
     * @param userUidRequest 
     */
    public accountsBlockUnblockCreate(userUidRequest: UserUidRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.accountsBlockUnblockCreate(userUidRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsBlockUnblockCreate(rsp)));
            }));
    }

    /**
     * @param userExtradataRequest 
     */
    public accountsExtradataCreate(userExtradataRequest?: UserExtradataRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.accountsExtradataCreate(userExtradataRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsExtradataCreate(rsp)));
            }));
    }

    /**
     */
    public accountsGetAllBlockedList(_options?: Configuration): Observable<Array<SimpleUser>> {
        const requestContextPromise = this.requestFactory.accountsGetAllBlockedList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsGetAllBlockedList(rsp)));
            }));
    }

    /**
     * @param sportfengurIds 
     */
    public accountsGetUsersBySportfengurIdList(sportfengurIds?: string, _options?: Configuration): Observable<Array<SimpleUser>> {
        const requestContextPromise = this.requestFactory.accountsGetUsersBySportfengurIdList(sportfengurIds, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsGetUsersBySportfengurIdList(rsp)));
            }));
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = 'localhost:8000' -------------
     * @param socialLoginRequest 
     */
    public accountsGoogleCreate(socialLoginRequest?: SocialLoginRequest, _options?: Configuration): Observable<Token> {
        const requestContextPromise = this.requestFactory.accountsGoogleCreate(socialLoginRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsGoogleCreate(rsp)));
            }));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
     * @param loginRequest 
     */
    public accountsLoginCreate(loginRequest: LoginRequest, _options?: Configuration): Observable<Token> {
        const requestContextPromise = this.requestFactory.accountsLoginCreate(loginRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsLoginCreate(rsp)));
            }));
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     */
    public accountsLogoutCreate(_options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsLogoutCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsLogoutCreate(rsp)));
            }));
    }

    /**
     * @param userExtradataRequest 
     */
    public accountsMetadataCreate(userExtradataRequest?: UserExtradataRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.accountsMetadataCreate(userExtradataRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsMetadataCreate(rsp)));
            }));
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param notificationTypeRequest 
     */
    public accountsNotificationsCreate(notificationTypeRequest: NotificationTypeRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsNotificationsCreate(notificationTypeRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsNotificationsCreate(rsp)));
            }));
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param patchedNotificationTypeRequest 
     */
    public accountsNotificationsPartialUpdate(patchedNotificationTypeRequest?: PatchedNotificationTypeRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsNotificationsPartialUpdate(patchedNotificationTypeRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsNotificationsPartialUpdate(rsp)));
            }));
    }

    /**
     * Only send request to disallow notifications, that is, they are automatically on
     * @param notificationTypeRequest 
     */
    public accountsNotificationsUpdate(notificationTypeRequest: NotificationTypeRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsNotificationsUpdate(notificationTypeRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsNotificationsUpdate(rsp)));
            }));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param passwordChangeRequest 
     */
    public accountsPasswordChangeCreate(passwordChangeRequest: PasswordChangeRequest, _options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsPasswordChangeCreate(passwordChangeRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsPasswordChangeCreate(rsp)));
            }));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user's password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param passwordResetConfirmRequest 
     */
    public accountsPasswordResetConfirmCreate(passwordResetConfirmRequest: PasswordResetConfirmRequest, _options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsPasswordResetConfirmCreate(passwordResetConfirmRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsPasswordResetConfirmCreate(rsp)));
            }));
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param passwordResetRequest 
     */
    public accountsPasswordResetCreate(passwordResetRequest: PasswordResetRequest, _options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsPasswordResetCreate(passwordResetRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsPasswordResetCreate(rsp)));
            }));
    }

    /**
     * @param userQuestionareRequest 
     */
    public accountsQuestionareCreate(userQuestionareRequest?: UserQuestionareRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.accountsQuestionareCreate(userQuestionareRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsQuestionareCreate(rsp)));
            }));
    }

    /**
     * @param registerRequest 
     */
    public accountsRegistrationCreate(registerRequest: RegisterRequest, _options?: Configuration): Observable<Token> {
        const requestContextPromise = this.requestFactory.accountsRegistrationCreate(registerRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsRegistrationCreate(rsp)));
            }));
    }

    /**
     * @param resendEmailVerificationRequest 
     */
    public accountsRegistrationResendEmailCreate(resendEmailVerificationRequest: ResendEmailVerificationRequest, _options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsRegistrationResendEmailCreate(resendEmailVerificationRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsRegistrationResendEmailCreate(rsp)));
            }));
    }

    /**
     * @param verifyEmailRequest 
     */
    public accountsRegistrationVerifyEmailCreate(verifyEmailRequest: VerifyEmailRequest, _options?: Configuration): Observable<RestAuthDetail> {
        const requestContextPromise = this.requestFactory.accountsRegistrationVerifyEmailCreate(verifyEmailRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsRegistrationVerifyEmailCreate(rsp)));
            }));
    }

    /**
     * @param userSettingsRequest 
     */
    public accountsSettingsCreate(userSettingsRequest?: UserSettingsRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsSettingsCreate(userSettingsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSettingsCreate(rsp)));
            }));
    }

    /**
     * @param patchedUserSettingsRequest 
     */
    public accountsSettingsPartialUpdate(patchedUserSettingsRequest?: PatchedUserSettingsRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsSettingsPartialUpdate(patchedUserSettingsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSettingsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param userSettingsRequest 
     */
    public accountsSettingsUpdate(userSettingsRequest?: UserSettingsRequest, _options?: Configuration): Observable<UserSettings> {
        const requestContextPromise = this.requestFactory.accountsSettingsUpdate(userSettingsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSettingsUpdate(rsp)));
            }));
    }

    /**
     * class used for social account linking  example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookConnect(SocialConnectView):     adapter_class = FacebookOAuth2Adapter -------------
     * @param id 
     * @param socialConnectRequest 
     */
    public accountsSocialaccountsConnectCreate(id: string, socialConnectRequest?: SocialConnectRequest, _options?: Configuration): Observable<SocialConnect> {
        const requestContextPromise = this.requestFactory.accountsSocialaccountsConnectCreate(id, socialConnectRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSocialaccountsConnectCreate(rsp)));
            }));
    }

    /**
     * Disconnect SocialAccount from remote service for the currently logged in user
     * @param id 
     * @param socialConnectRequest 
     */
    public accountsSocialaccountsDisconnectCreate(id: string, socialConnectRequest?: SocialConnectRequest, _options?: Configuration): Observable<SocialConnect> {
        const requestContextPromise = this.requestFactory.accountsSocialaccountsDisconnectCreate(id, socialConnectRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSocialaccountsDisconnectCreate(rsp)));
            }));
    }

    /**
     * List SocialAccounts for the currently logged in user
     */
    public accountsSocialaccountsList(_options?: Configuration): Observable<Array<SocialAccount>> {
        const requestContextPromise = this.requestFactory.accountsSocialaccountsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSocialaccountsList(rsp)));
            }));
    }

    /**
     */
    public accountsSubscriptionRestorePurchasesCreate(_options?: Configuration): Observable<Subscription> {
        const requestContextPromise = this.requestFactory.accountsSubscriptionRestorePurchasesCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsSubscriptionRestorePurchasesCreate(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersDistanceList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserMonthlyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersDistanceList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersDistanceList(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersDistanceWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserWeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersDistanceWeekList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersDistanceWeekList(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersSessionsList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserMonthlyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersSessionsList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersSessionsList(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersSessionsWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserWeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersSessionsWeekList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersSessionsWeekList(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersTimeList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserMonthlyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersTimeList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersTimeList(rsp)));
            }));
    }

    /**
     * @param cutoff 
     * @param last 
     * @param social 
     */
    public accountsTopRidersTimeWeekList(cutoff?: number, last?: boolean, social?: boolean, _options?: Configuration): Observable<Array<UserWeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsTopRidersTimeWeekList(cutoff, last, social, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsTopRidersTimeWeekList(rsp)));
            }));
    }

    /**
     */
    public accountsUpdateEntitlementCreate(_options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.accountsUpdateEntitlementCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUpdateEntitlementCreate(rsp)));
            }));
    }

    /**
     */
    public accountsUserDeleteDestroy(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.accountsUserDeleteDestroy(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserDeleteDestroy(rsp)));
            }));
    }

    /**
     * GET or POST the locale of the logged in user --- response: - locale: 'is', 'en' or 'de'
     */
    public accountsUserGetLocaleRetrieve(_options?: Configuration): Observable<UserLocale> {
        const requestContextPromise = this.requestFactory.accountsUserGetLocaleRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserGetLocaleRetrieve(rsp)));
            }));
    }

    /**
     * Get monthly summaries for user based on - months: list of weeks (default [this month]) - year: (default this year)
     * @param month 
     * @param year 
     */
    public accountsUserMonthlyActivitySummaryList(month?: number, year?: number, _options?: Configuration): Observable<Array<SimpleUserActivity>> {
        const requestContextPromise = this.requestFactory.accountsUserMonthlyActivitySummaryList(month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserMonthlyActivitySummaryList(rsp)));
            }));
    }

    /**
     * @param goalRequest 
     */
    public accountsUserMonthlyGoalCreate(goalRequest?: GoalRequest, _options?: Configuration): Observable<UserMonthlyTrainingSummary> {
        const requestContextPromise = this.requestFactory.accountsUserMonthlyGoalCreate(goalRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserMonthlyGoalCreate(rsp)));
            }));
    }

    /**
     * Get monthly summaries for user based on - months: list of months (default [this month]) - year: (default this year) - user_id
     * @param months 
     * @param userUid 
     * @param year 
     */
    public accountsUserMonthsTrainingSummaryList(months?: string, userUid?: string, year?: number, _options?: Configuration): Observable<Array<UserMonthlyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsUserMonthsTrainingSummaryList(months, userUid, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserMonthsTrainingSummaryList(rsp)));
            }));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param patchedUserRequest 
     */
    public accountsUserPartialUpdate(patchedUserRequest?: PatchedUserRequest, _options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.accountsUserPartialUpdate(patchedUserRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserPartialUpdate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public accountsUserProfileRetrieve(uid: string, _options?: Configuration): Observable<PublicUser> {
        const requestContextPromise = this.requestFactory.accountsUserProfileRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserProfileRetrieve(rsp)));
            }));
    }

    /**
     */
    public accountsUserRetrieve(_options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.accountsUserRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserRetrieve(rsp)));
            }));
    }

    /**
     * Searching for users to follow, add as trainers, etc.. --- parameters: - name: query -  description: to query for users in the database -  required: true -  type: string -  paramType: form
     * @param query 
     */
    public accountsUserSearchList(query: string, _options?: Configuration): Observable<Array<PublicUser>> {
        const requestContextPromise = this.requestFactory.accountsUserSearchList(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserSearchList(rsp)));
            }));
    }

    /**
     * Searching for users to follow, add as trainers, etc..  --- parameters: - name: query -  description: to query for users in the database -  required: true -  type: string -  paramType: form
     * @param query 
     * @param next 
     * @param pageSize 
     */
    public accountsUserSearchPaginatedRetrieve(query: string, next?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedUsers> {
        const requestContextPromise = this.requestFactory.accountsUserSearchPaginatedRetrieve(query, next, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserSearchPaginatedRetrieve(rsp)));
            }));
    }

    /**
     * Change the locale of a user --- parameters: - locale: 'is', 'en' or 'de' response: - locale set
     * @param userLocaleRequest 
     */
    public accountsUserSetLocaleCreate(userLocaleRequest: UserLocaleRequest, _options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.accountsUserSetLocaleCreate(userLocaleRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserSetLocaleCreate(rsp)));
            }));
    }

    /**
     * @param isMonthly 
     * @param month 
     * @param uid 
     * @param week 
     * @param year 
     */
    public accountsUserTrainingSummaryRetrieve(isMonthly?: boolean, month?: number, uid?: string, week?: number, year?: number, _options?: Configuration): Observable<Summary> {
        const requestContextPromise = this.requestFactory.accountsUserTrainingSummaryRetrieve(isMonthly, month, uid, week, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserTrainingSummaryRetrieve(rsp)));
            }));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param userRequest 
     */
    public accountsUserUpdate(userRequest?: UserRequest, _options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.accountsUserUpdate(userRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserUpdate(rsp)));
            }));
    }

    /**
     * @param goalRequest 
     */
    public accountsUserWeeklyGoalCreate(goalRequest?: GoalRequest, _options?: Configuration): Observable<UserWeeklyTrainingSummary> {
        const requestContextPromise = this.requestFactory.accountsUserWeeklyGoalCreate(goalRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserWeeklyGoalCreate(rsp)));
            }));
    }

    /**
     * Get weekly summaries for user based on - weeks: list of weeks (default [this week]) - year: (default this year) - user_id
     * @param userUid 
     * @param weeks 
     * @param year 
     */
    public accountsUserWeeksTrainingSummaryList(userUid?: string, weeks?: string, year?: number, _options?: Configuration): Observable<Array<UserWeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.accountsUserWeeksTrainingSummaryList(userUid, weeks, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.accountsUserWeeksTrainingSummaryList(rsp)));
            }));
    }

}

import { ActivitiesApiRequestFactory, ActivitiesApiResponseProcessor} from "../apis/ActivitiesApi";
export class ObservableActivitiesApi {
    private requestFactory: ActivitiesApiRequestFactory;
    private responseProcessor: ActivitiesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivitiesApiRequestFactory,
        responseProcessor?: ActivitiesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ActivitiesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ActivitiesApiResponseProcessor();
    }

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public activitiesActivitySummaryMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Observable<Array<HorseActivities>> {
        const requestContextPromise = this.requestFactory.activitiesActivitySummaryMultiHorseActivitiesPeriodList(faedingarnumer, _from, to, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesActivitySummaryMultiHorseActivitiesPeriodList(rsp)));
            }));
    }

    /**
     * Get activity summary for multiple horses based on - from: start date (default first day of this month) - to: end date (default last day of this month) - faedingarnumer_list: a comma-separated string of horse identifiers
     * @param faedingarnumer 
     * @param _from 
     * @param to 
     */
    public activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(faedingarnumer?: string, _from?: string, to?: string, _options?: Configuration): Observable<Array<SimpleHorseActivities>> {
        const requestContextPromise = this.requestFactory.activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(faedingarnumer, _from, to, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesActivitySummarySimpleMultiHorseActivitiesPeriodList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCommentsList(id: number, _options?: Configuration): Observable<Array<Comment>> {
        const requestContextPromise = this.requestFactory.activitiesCommentsList(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesCommentsList(rsp)));
            }));
    }

    /**
     * @param horseActivityCreateRequest 
     */
    public activitiesCreate(horseActivityCreateRequest: HorseActivityCreateRequest, _options?: Configuration): Observable<HorseActivityCreate> {
        const requestContextPromise = this.requestFactory.activitiesCreate(horseActivityCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCreateMapTaskCreate(id: number, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.activitiesCreateMapTaskCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesCreateMapTaskCreate(rsp)));
            }));
    }

    /**
     * waits for map to be generated.
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesCreateMapWithoutTaskCreate(id: number, _options?: Configuration): Observable<HorseActivity> {
        const requestContextPromise = this.requestFactory.activitiesCreateMapWithoutTaskCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesCreateMapWithoutTaskCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.activitiesDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesDestroy(rsp)));
            }));
    }

    /**
     * Cloud task, should not be called directly
     * @param id A unique integer value identifying this horse activity.
     * @param isPremium if to generate premium map
     */
    public activitiesGenerateMapWithTaskCreate(id: number, isPremium: boolean, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.activitiesGenerateMapWithTaskCreate(id, isPremium, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGenerateMapWithTaskCreate(rsp)));
            }));
    }

    /**
     * get all activities on day
     * @param date date of activties
     * @param horse If horse is null, then will get all activities for user
     * @param useDrafts include activities marked as drafts
     */
    public activitiesGetActivitiesOnDayList(date: string, horse?: string, useDrafts?: boolean, _options?: Configuration): Observable<Array<HorseActivity>> {
        const requestContextPromise = this.requestFactory.activitiesGetActivitiesOnDayList(date, horse, useDrafts, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGetActivitiesOnDayList(rsp)));
            }));
    }

    /**
     */
    public activitiesGetAllPlannedActivitiesList(_options?: Configuration): Observable<Array<HorseActivity>> {
        const requestContextPromise = this.requestFactory.activitiesGetAllPlannedActivitiesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGetAllPlannedActivitiesList(rsp)));
            }));
    }

    /**
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param excludeDrafts 
     * @param excludeFuture 
     * @param horse 
     * @param pageSize Number of results to return per page.
     */
    public activitiesGetLatestTracksList(activityId?: number, actor?: number, cursor?: string, excludeDrafts?: boolean, excludeFuture?: boolean, horse?: string, pageSize?: number, _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.activitiesGetLatestTracksList(activityId, actor, cursor, excludeDrafts, excludeFuture, horse, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGetLatestTracksList(rsp)));
            }));
    }

    /**
     */
    public activitiesGetPastPlannedActivitiesRetrieve(_options?: Configuration): Observable<PaginatedActivity> {
        const requestContextPromise = this.requestFactory.activitiesGetPastPlannedActivitiesRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGetPastPlannedActivitiesRetrieve(rsp)));
            }));
    }

    /**
     * @param date date of activties
     */
    public activitiesGetPlannedActivitiesTodayList(date?: string, _options?: Configuration): Observable<Array<HorseActivity>> {
        const requestContextPromise = this.requestFactory.activitiesGetPlannedActivitiesTodayList(date, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesGetPlannedActivitiesTodayList(rsp)));
            }));
    }

    /**
     * @param activities 
     * @param actor 
     * @param creator 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param excludePlanned Set to true to exclude planned activities
     * @param hasFinishedPlanned 
     * @param horse 
     * @param ids Multiple values may be separated by commas.
     * @param isDraft 
     * @param isPlanned 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public activitiesList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, actor?: number, creator?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, excludePlanned?: boolean, hasFinishedPlanned?: boolean, horse?: string, ids?: Array<number>, isDraft?: boolean, isPlanned?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.activitiesList(activities, actor, creator, cursor, dateAfter, dateBefore, dateRangeAfter, dateRangeBefore, excludePlanned, hasFinishedPlanned, horse, ids, isDraft, isPlanned, order, pageSize, type, visibility, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesList(rsp)));
            }));
    }

    /**
     * Get weekly summary for all horses the user has access to - month: (default this month) - year: (default this year)
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public activitiesMonthlyTrainingSummaryAllHorsesList(isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Observable<Array<MonthlyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.activitiesMonthlyTrainingSummaryAllHorsesList(isTaggedSummary, month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesMonthlyTrainingSummaryAllHorsesList(rsp)));
            }));
    }

    /**
     * Get monthly summary for horse based on - month: (default this month) - year: (default this year) - tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param month 
     * @param year 
     */
    public activitiesMonthlyTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, month?: number, year?: number, _options?: Configuration): Observable<MonthlyTrainingSummary> {
        const requestContextPromise = this.requestFactory.activitiesMonthlyTrainingSummarySingleHorseRetrieve(faedingarnumer, isTaggedSummary, month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesMonthlyTrainingSummarySingleHorseRetrieve(rsp)));
            }));
    }

    /**
     * @param horseActivityMultiCreateRequest 
     */
    public activitiesMultiCreateCreate(horseActivityMultiCreateRequest: HorseActivityMultiCreateRequest, _options?: Configuration): Observable<Array<HorseActivity>> {
        const requestContextPromise = this.requestFactory.activitiesMultiCreateCreate(horseActivityMultiCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesMultiCreateCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param patchedHorseActivityUpdateRequest 
     */
    public activitiesPartialUpdate(id: number, patchedHorseActivityUpdateRequest?: PatchedHorseActivityUpdateRequest, _options?: Configuration): Observable<HorseActivityUpdate> {
        const requestContextPromise = this.requestFactory.activitiesPartialUpdate(id, patchedHorseActivityUpdateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesReactionsList(id: number, _options?: Configuration): Observable<Array<UserReaction>> {
        const requestContextPromise = this.requestFactory.activitiesReactionsList(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesReactionsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public activitiesRetrieve(id: number, _options?: Configuration): Observable<HorseActivity> {
        const requestContextPromise = this.requestFactory.activitiesRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesRetrieve(rsp)));
            }));
    }

    /**
     * Get monthly summary for all horses user has access to based on - month: (default this month) - year: (default this year)
     * @param month 
     * @param year 
     */
    public activitiesSummaryAllHorsesList(month?: number, year?: number, _options?: Configuration): Observable<Array<SimpleMonthlyActivitySummary>> {
        const requestContextPromise = this.requestFactory.activitiesSummaryAllHorsesList(month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesSummaryAllHorsesList(rsp)));
            }));
    }

    /**
     * Get monthly activity summary for horse based on - month: (default this month) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param month 
     * @param year 
     */
    public activitiesSummarySingleHorseRetrieve(faedingarnumer?: string, month?: number, year?: number, _options?: Configuration): Observable<SimpleMonthlyActivitySummary> {
        const requestContextPromise = this.requestFactory.activitiesSummarySingleHorseRetrieve(faedingarnumer, month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesSummarySingleHorseRetrieve(rsp)));
            }));
    }

    /**
     * Get weekly summary for all horses the user has access to - week: (default this week) - year: (default this year)
     * @param week 
     * @param year 
     */
    public activitiesTrainingSummaryAllHorsesList(week?: number, year?: number, _options?: Configuration): Observable<Array<WeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.activitiesTrainingSummaryAllHorsesList(week, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesTrainingSummaryAllHorsesList(rsp)));
            }));
    }

    /**
     * Get weekly summary for horse based on - week: (default this week) - year: (default this year) - is_tagged_summary: if tagged then summary the horse is a tagged horse (default false) - fæðingarnúmer
     * @param faedingarnumer 
     * @param isTaggedSummary 
     * @param week 
     * @param year 
     */
    public activitiesTrainingSummarySingleHorseRetrieve(faedingarnumer: string, isTaggedSummary?: boolean, week?: number, year?: number, _options?: Configuration): Observable<WeeklyTrainingSummary> {
        const requestContextPromise = this.requestFactory.activitiesTrainingSummarySingleHorseRetrieve(faedingarnumer, isTaggedSummary, week, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesTrainingSummarySingleHorseRetrieve(rsp)));
            }));
    }

    /**
     * Get weekly summary for horse based on - weeks: list of weeks (default [this week]) - year: (default this year) - fæðingarnúmer
     * @param faedingarnumer 
     * @param weeks 
     * @param year 
     */
    public activitiesTrainingSummarySingleHorseWeeksList(faedingarnumer: string, weeks?: string, year?: number, _options?: Configuration): Observable<Array<WeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.activitiesTrainingSummarySingleHorseWeeksList(faedingarnumer, weeks, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesTrainingSummarySingleHorseWeeksList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     * @param horseActivityUpdateRequest 
     */
    public activitiesUpdate(id: number, horseActivityUpdateRequest?: HorseActivityUpdateRequest, _options?: Configuration): Observable<HorseActivityUpdate> {
        const requestContextPromise = this.requestFactory.activitiesUpdate(id, horseActivityUpdateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activitiesUpdate(rsp)));
            }));
    }

}

import { ActivityMediaApiRequestFactory, ActivityMediaApiResponseProcessor} from "../apis/ActivityMediaApi";
export class ObservableActivityMediaApi {
    private requestFactory: ActivityMediaApiRequestFactory;
    private responseProcessor: ActivityMediaApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityMediaApiRequestFactory,
        responseProcessor?: ActivityMediaApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ActivityMediaApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ActivityMediaApiResponseProcessor();
    }

    /**
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param group 
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public activityMediaList(actor?: number, cursor?: string, group?: string, horse?: string, order?: Array<'-_' | '-d' | '-e' | '-f' | '-m' | '-o' | '-p' | '-r' | '-t' | '_' | 'd' | 'e' | 'f' | 'm' | 'o' | 'p' | 'r' | 't'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Observable<PaginatedActivityMediaList> {
        const requestContextPromise = this.requestFactory.activityMediaList(actor, cursor, group, horse, order, pageSize, type, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityMediaList(rsp)));
            }));
    }

    /**
     * @param actor 
     * @param horse 
     */
    public activityMediaNumerOfMediaRetrieve(actor?: number, horse?: string, _options?: Configuration): Observable<Number> {
        const requestContextPromise = this.requestFactory.activityMediaNumerOfMediaRetrieve(actor, horse, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityMediaNumerOfMediaRetrieve(rsp)));
            }));
    }

}

import { ActivityPhotosApiRequestFactory, ActivityPhotosApiResponseProcessor} from "../apis/ActivityPhotosApi";
export class ObservableActivityPhotosApi {
    private requestFactory: ActivityPhotosApiRequestFactory;
    private responseProcessor: ActivityPhotosApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityPhotosApiRequestFactory,
        responseProcessor?: ActivityPhotosApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ActivityPhotosApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ActivityPhotosApiResponseProcessor();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosCreate(activity: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Observable<HorseActivityPhoto> {
        const requestContextPromise = this.requestFactory.activityPhotosCreate(activity, name, image, isMap, isPremiumMap, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     */
    public activityPhotosDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.activityPhotosDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosDestroy(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param isMap 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public activityPhotosList(activity?: number, isMap?: boolean, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedHorseActivityPhotoList> {
        const requestContextPromise = this.requestFactory.activityPhotosList(activity, isMap, order, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosList(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosPartialUpdate(id: number, activity?: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Observable<HorseActivityPhoto> {
        const requestContextPromise = this.requestFactory.activityPhotosPartialUpdate(id, activity, name, image, isMap, isPremiumMap, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosPartialUpdate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     */
    public activityPhotosRetrieve(id: number, _options?: Configuration): Observable<HorseActivityPhoto> {
        const requestContextPromise = this.requestFactory.activityPhotosRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosRetrieve(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param b 
     */
    public activityPhotosToggleShowOnProfileCreate(id: number, b: boolean, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.activityPhotosToggleShowOnProfileCreate(id, b, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosToggleShowOnProfileCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity photo.
     * @param activity 
     * @param name 
     * @param image 
     * @param isMap 
     * @param isPremiumMap 
     */
    public activityPhotosUpdate(id: number, activity: number, name?: string, image?: HttpFile, isMap?: boolean, isPremiumMap?: boolean, _options?: Configuration): Observable<HorseActivityPhoto> {
        const requestContextPromise = this.requestFactory.activityPhotosUpdate(id, activity, name, image, isMap, isPremiumMap, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityPhotosUpdate(rsp)));
            }));
    }

}

import { ActivityVideosApiRequestFactory, ActivityVideosApiResponseProcessor} from "../apis/ActivityVideosApi";
export class ObservableActivityVideosApi {
    private requestFactory: ActivityVideosApiRequestFactory;
    private responseProcessor: ActivityVideosApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ActivityVideosApiRequestFactory,
        responseProcessor?: ActivityVideosApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ActivityVideosApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ActivityVideosApiResponseProcessor();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosCreate(activity: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosCreate(activity, name, video, transcoded, thumbnail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.activityVideosDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosDestroy(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosFinishUploadCreate(id: number, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosFinishUploadCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosFinishUploadCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public activityVideosList(activity?: number, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedHorseActivityVideoList> {
        const requestContextPromise = this.requestFactory.activityVideosList(activity, order, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosList(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosPartialUpdate(id: number, activity?: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosPartialUpdate(id, activity, name, video, transcoded, thumbnail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosPartialUpdate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     */
    public activityVideosRetrieve(id: number, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosRetrieve(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param thumbnail 
     */
    public activityVideosThumbnailCreate(id: number, thumbnail?: HttpFile, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosThumbnailCreate(id, thumbnail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosThumbnailCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param b 
     */
    public activityVideosToggleShowOnProfileCreate(id: number, b: boolean, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.activityVideosToggleShowOnProfileCreate(id, b, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosToggleShowOnProfileCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param id A unique integer value identifying this horse activity video.
     * @param activity 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     */
    public activityVideosUpdate(id: number, activity: number, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, _options?: Configuration): Observable<HorseActivityVideo> {
        const requestContextPromise = this.requestFactory.activityVideosUpdate(id, activity, name, video, transcoded, thumbnail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.activityVideosUpdate(rsp)));
            }));
    }

}

import { CallbacksApiRequestFactory, CallbacksApiResponseProcessor} from "../apis/CallbacksApi";
export class ObservableCallbacksApi {
    private requestFactory: CallbacksApiRequestFactory;
    private responseProcessor: CallbacksApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: CallbacksApiRequestFactory,
        responseProcessor?: CallbacksApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new CallbacksApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new CallbacksApiResponseProcessor();
    }

    /**
     * @param transcoderCallbackRequestRequest 
     */
    public callbacksTranscoderCreate(transcoderCallbackRequestRequest: TranscoderCallbackRequestRequest, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.callbacksTranscoderCreate(transcoderCallbackRequestRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.callbacksTranscoderCreate(rsp)));
            }));
    }

}

import { ChatApiRequestFactory, ChatApiResponseProcessor} from "../apis/ChatApi";
export class ObservableChatApi {
    private requestFactory: ChatApiRequestFactory;
    private responseProcessor: ChatApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ChatApiRequestFactory,
        responseProcessor?: ChatApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ChatApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ChatApiResponseProcessor();
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param followRequest 
     */
    public chatAddToRoomCreate(id: number, followRequest: FollowRequest, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatAddToRoomCreate(id, followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatAddToRoomCreate(rsp)));
            }));
    }

    /**
     * @param chatRoomRequest 
     */
    public chatCreate(chatRoomRequest: ChatRoomRequest, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatCreate(chatRoomRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatCreate(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public chatList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_latest_message' | 'dt_latest_message'>, pageSize?: number, _options?: Configuration): Observable<PaginatedChatRoomList> {
        const requestContextPromise = this.requestFactory.chatList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatList(rsp)));
            }));
    }

    /**
     */
    public chatNumberUnseenRetrieve(_options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.chatNumberUnseenRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatNumberUnseenRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this chat room.
     */
    public chatRetrieve(id: number, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatRetrieve(rsp)));
            }));
    }

    /**
     * Get room info from list of user_uid if no room exists then create it
     * @param users 
     * @param faedingarnumer 
     * @param isSaleChat 
     * @param name 
     */
    public chatRoomInfoFromUsersRetrieve(users: string, faedingarnumer?: string, isSaleChat?: boolean, name?: string, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatRoomInfoFromUsersRetrieve(users, faedingarnumer, isSaleChat, name, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatRoomInfoFromUsersRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer 
     */
    public chatSaleChatsList(faedingarnumer?: string, _options?: Configuration): Observable<Array<ChatRoom>> {
        const requestContextPromise = this.requestFactory.chatSaleChatsList(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatSaleChatsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param inRoomRequest 
     */
    public chatSetInRoomCreate(id: number, inRoomRequest: InRoomRequest, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatSetInRoomCreate(id, inRoomRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatSetInRoomCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this chat room.
     * @param simpleRequest 
     */
    public chatUpdateNameCreate(id: number, simpleRequest?: SimpleRequest, _options?: Configuration): Observable<ChatRoom> {
        const requestContextPromise = this.requestFactory.chatUpdateNameCreate(id, simpleRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatUpdateNameCreate(rsp)));
            }));
    }

}

import { ChatMessagesApiRequestFactory, ChatMessagesApiResponseProcessor} from "../apis/ChatMessagesApi";
export class ObservableChatMessagesApi {
    private requestFactory: ChatMessagesApiRequestFactory;
    private responseProcessor: ChatMessagesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: ChatMessagesApiRequestFactory,
        responseProcessor?: ChatMessagesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new ChatMessagesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new ChatMessagesApiResponseProcessor();
    }

    /**
     * @param chatMessageRequest 
     */
    public chatMessagesCreate(chatMessageRequest: ChatMessageRequest, _options?: Configuration): Observable<ChatMessage> {
        const requestContextPromise = this.requestFactory.chatMessagesCreate(chatMessageRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatMessagesCreate(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param room 
     */
    public chatMessagesList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_created' | 'dt_created'>, pageSize?: number, room?: number, _options?: Configuration): Observable<PaginatedChatMessageList> {
        const requestContextPromise = this.requestFactory.chatMessagesList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, room, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatMessagesList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this chat message.
     */
    public chatMessagesRetrieve(id: number, _options?: Configuration): Observable<ChatMessage> {
        const requestContextPromise = this.requestFactory.chatMessagesRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatMessagesRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this chat message.
     * @param chatMessageRequest 
     */
    public chatMessagesSendMessageCreate(id: number, chatMessageRequest: ChatMessageRequest, _options?: Configuration): Observable<any> {
        const requestContextPromise = this.requestFactory.chatMessagesSendMessageCreate(id, chatMessageRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.chatMessagesSendMessageCreate(rsp)));
            }));
    }

}

import { CommentsApiRequestFactory, CommentsApiResponseProcessor} from "../apis/CommentsApi";
export class ObservableCommentsApi {
    private requestFactory: CommentsApiRequestFactory;
    private responseProcessor: CommentsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: CommentsApiRequestFactory,
        responseProcessor?: CommentsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new CommentsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new CommentsApiResponseProcessor();
    }

    /**
     * @param commentRequest 
     */
    public commentsCreate(commentRequest: CommentRequest, _options?: Configuration): Observable<Comment> {
        const requestContextPromise = this.requestFactory.commentsCreate(commentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this comment.
     */
    public commentsDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.commentsDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsDestroy(rsp)));
            }));
    }

    /**
     * @param activity 
     * @param cursor The pagination cursor value.
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param thread 
     * @param user 
     */
    public commentsList(activity?: number, cursor?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, pageSize?: number, thread?: number, user?: number, _options?: Configuration): Observable<PaginatedCommentList> {
        const requestContextPromise = this.requestFactory.commentsList(activity, cursor, order, pageSize, thread, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this comment.
     * @param patchedCommentRequest 
     */
    public commentsPartialUpdate(id: number, patchedCommentRequest?: PatchedCommentRequest, _options?: Configuration): Observable<Comment> {
        const requestContextPromise = this.requestFactory.commentsPartialUpdate(id, patchedCommentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this comment.
     */
    public commentsRetrieve(id: number, _options?: Configuration): Observable<Comment> {
        const requestContextPromise = this.requestFactory.commentsRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this comment.
     * @param commentRequest 
     */
    public commentsUpdate(id: number, commentRequest: CommentRequest, _options?: Configuration): Observable<Comment> {
        const requestContextPromise = this.requestFactory.commentsUpdate(id, commentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.commentsUpdate(rsp)));
            }));
    }

}

import { FeedApiRequestFactory, FeedApiResponseProcessor} from "../apis/FeedApi";
export class ObservableFeedApi {
    private requestFactory: FeedApiRequestFactory;
    private responseProcessor: FeedApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedApiRequestFactory,
        responseProcessor?: FeedApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new FeedApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new FeedApiResponseProcessor();
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedGetSimpleTrackingPointsList(id: number, _options?: Configuration): Observable<Array<SimpleTrackingPoint>> {
        const requestContextPromise = this.requestFactory.feedGetSimpleTrackingPointsList(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedGetSimpleTrackingPointsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedGetTrackingPointsList(id: number, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.feedGetTrackingPointsList(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedGetTrackingPointsList(rsp)));
            }));
    }

    /**
     * @param activities 
     * @param activityId 
     * @param actor 
     * @param cursor The pagination cursor value.
     * @param dateAfter 
     * @param dateBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     * @param visibility 
     */
    public feedList(activities?: Array<'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION'>, activityId?: number, actor?: number, cursor?: string, dateAfter?: Date, dateBefore?: Date, dateRangeAfter?: string, dateRangeBefore?: string, horse?: string, order?: Array<'-dt_created' | '-dt_modified' | '-dt_performed' | 'dt_created' | 'dt_modified' | 'dt_performed'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', visibility?: 'FOLLOWERS' | 'ONLY_ME' | 'TEAM', _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.feedList(activities, activityId, actor, cursor, dateAfter, dateBefore, dateRangeAfter, dateRangeBefore, horse, order, pageSize, type, visibility, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public feedRetrieve(id: number, _options?: Configuration): Observable<HorseActivity> {
        const requestContextPromise = this.requestFactory.feedRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedRetrieve(rsp)));
            }));
    }

}

import { FeedbackApiRequestFactory, FeedbackApiResponseProcessor} from "../apis/FeedbackApi";
export class ObservableFeedbackApi {
    private requestFactory: FeedbackApiRequestFactory;
    private responseProcessor: FeedbackApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedbackApiRequestFactory,
        responseProcessor?: FeedbackApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new FeedbackApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new FeedbackApiResponseProcessor();
    }

    /**
     * @param trainingSessionFeedbackRequest 
     */
    public feedbackCreate(trainingSessionFeedbackRequest: TrainingSessionFeedbackRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.feedbackCreate(trainingSessionFeedbackRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedbackCreate(rsp)));
            }));
    }

}

import { FeedingsApiRequestFactory, FeedingsApiResponseProcessor} from "../apis/FeedingsApi";
export class ObservableFeedingsApi {
    private requestFactory: FeedingsApiRequestFactory;
    private responseProcessor: FeedingsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: FeedingsApiRequestFactory,
        responseProcessor?: FeedingsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new FeedingsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new FeedingsApiResponseProcessor();
    }

    /**
     * @param feedingRequest 
     */
    public feedingsCreate(feedingRequest: FeedingRequest, _options?: Configuration): Observable<Feeding> {
        const requestContextPromise = this.requestFactory.feedingsCreate(feedingRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsCreate(rsp)));
            }));
    }

    /**
     * @param horse 
     */
    public feedingsCurrentFeedingForHorseRetrieve(horse: string, _options?: Configuration): Observable<Feeding> {
        const requestContextPromise = this.requestFactory.feedingsCurrentFeedingForHorseRetrieve(horse, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsCurrentFeedingForHorseRetrieve(rsp)));
            }));
    }

    /**
     * @param horses Comma separated list of horse IDs
     */
    public feedingsCurrentFeedingsForHorsesList(horses: string, _options?: Configuration): Observable<Array<Feeding>> {
        const requestContextPromise = this.requestFactory.feedingsCurrentFeedingsForHorsesList(horses, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsCurrentFeedingsForHorsesList(rsp)));
            }));
    }

    /**
     * @param horse 
     */
    public feedingsFeedingsForHorseList(horse: string, _options?: Configuration): Observable<Array<Feeding>> {
        const requestContextPromise = this.requestFactory.feedingsFeedingsForHorseList(horse, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsFeedingsForHorseList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this feeding.
     * @param patchedFeedingRequest 
     */
    public feedingsPartialUpdate(id: number, patchedFeedingRequest?: PatchedFeedingRequest, _options?: Configuration): Observable<Feeding> {
        const requestContextPromise = this.requestFactory.feedingsPartialUpdate(id, patchedFeedingRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this feeding.
     */
    public feedingsRetrieve(id: number, _options?: Configuration): Observable<Feeding> {
        const requestContextPromise = this.requestFactory.feedingsRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this feeding.
     * @param feedingRequest 
     */
    public feedingsUpdate(id: number, feedingRequest: FeedingRequest, _options?: Configuration): Observable<Feeding> {
        const requestContextPromise = this.requestFactory.feedingsUpdate(id, feedingRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.feedingsUpdate(rsp)));
            }));
    }

}

import { GroupsApiRequestFactory, GroupsApiResponseProcessor} from "../apis/GroupsApi";
export class ObservableGroupsApi {
    private requestFactory: GroupsApiRequestFactory;
    private responseProcessor: GroupsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: GroupsApiRequestFactory,
        responseProcessor?: GroupsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new GroupsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new GroupsApiResponseProcessor();
    }

    /**
     * get all group invites for horses that are pending
     * @param uid 
     */
    public groupsAccessHorseInvitesList(uid: string, _options?: Configuration): Observable<Array<GroupInvite>> {
        const requestContextPromise = this.requestFactory.groupsAccessHorseInvitesList(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessHorseInvitesList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param createGroupUserInviteRequest 
     */
    public groupsAccessInviteCreate(uid: string, createGroupUserInviteRequest?: CreateGroupUserInviteRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAccessInviteCreate(uid, createGroupUserInviteRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessInviteCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param idRequest 
     */
    public groupsAccessInviteDeleteCreate(uid: string, idRequest: IdRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAccessInviteDeleteCreate(uid, idRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessInviteDeleteCreate(rsp)));
            }));
    }

    /**
     * @param acceptRequest 
     */
    public groupsAccessInviteRespondCreate(acceptRequest: AcceptRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAccessInviteRespondCreate(acceptRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessInviteRespondCreate(rsp)));
            }));
    }

    /**
     * get all invites for this user
     */
    public groupsAccessInvitesList(_options?: Configuration): Observable<Array<GroupInvite>> {
        const requestContextPromise = this.requestFactory.groupsAccessInvitesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessInvitesList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param idRequest 
     */
    public groupsAccessRemoveUsersCreate(uid: string, idRequest: Array<IdRequest>, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsAccessRemoveUsersCreate(uid, idRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessRemoveUsersCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public groupsAccessRequestCreate(uid: string, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAccessRequestCreate(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessRequestCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param acceptRequest 
     */
    public groupsAccessRequestRespondCreate(uid: string, acceptRequest: AcceptRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAccessRequestRespondCreate(uid, acceptRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessRequestRespondCreate(rsp)));
            }));
    }

    /**
     * get all group invites for users that are pending
     * @param uid 
     */
    public groupsAccessUserInvitesList(uid: string, _options?: Configuration): Observable<Array<GroupInvite>> {
        const requestContextPromise = this.requestFactory.groupsAccessUserInvitesList(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAccessUserInvitesList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param month 
     * @param year 
     */
    public groupsActivitySummaryList(uid: string, month?: number, year?: number, _options?: Configuration): Observable<Array<SimpleMonthlyActivitySummary>> {
        const requestContextPromise = this.requestFactory.groupsActivitySummaryList(uid, month, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsActivitySummaryList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsAddHorsesCreate(uid: string, faedingarnumerSerilizerRequest: Array<FaedingarnumerSerilizerRequest>, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.groupsAddHorsesCreate(uid, faedingarnumerSerilizerRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsAddHorsesCreate(rsp)));
            }));
    }

    /**
     * @param createGroupRequest 
     */
    public groupsCreate(createGroupRequest: CreateGroupRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsCreate(createGroupRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public groupsDestroy(uid: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.groupsDestroy(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDestroy(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param groupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsCreate(discussionUid: string, groupUid: string, groupDiscussionCommentRequest: GroupDiscussionCommentRequest, _options?: Configuration): Observable<GroupDiscussionComment> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsCreate(discussionUid, groupUid, groupDiscussionCommentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsCreate(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     */
    public groupsDiscussionsCommentsDestroy(discussionUid: string, groupUid: string, id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsDestroy(discussionUid, groupUid, id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsDestroy(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param discussion 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public groupsDiscussionsCommentsList(discussionUid: string, groupUid: string, discussion?: string, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedGroupDiscussionCommentList> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsList(discussionUid, groupUid, discussion, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsList(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param patchedGroupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsPartialUpdate(discussionUid: string, groupUid: string, id: number, patchedGroupDiscussionCommentRequest?: PatchedGroupDiscussionCommentRequest, _options?: Configuration): Observable<GroupDiscussionComment> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsPartialUpdate(discussionUid, groupUid, id, patchedGroupDiscussionCommentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param groupDiscussionCommentReactionRequest 
     */
    public groupsDiscussionsCommentsReactionCreate(discussionUid: string, groupUid: string, id: number, groupDiscussionCommentReactionRequest: GroupDiscussionCommentReactionRequest, _options?: Configuration): Observable<GroupDiscussionCommentReaction> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsReactionCreate(discussionUid, groupUid, id, groupDiscussionCommentReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsReactionCreate(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param discussion 
     */
    public groupsDiscussionsCommentsReactionsList(discussionUid: string, groupUid: string, id: number, discussion?: string, _options?: Configuration): Observable<Array<GroupDiscussionCommentReaction>> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsReactionsList(discussionUid, groupUid, id, discussion, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsReactionsList(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     */
    public groupsDiscussionsCommentsRetrieve(discussionUid: string, groupUid: string, id: number, _options?: Configuration): Observable<GroupDiscussionComment> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsRetrieve(discussionUid, groupUid, id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsRetrieve(rsp)));
            }));
    }

    /**
     * @param discussionUid 
     * @param groupUid 
     * @param id A unique integer value identifying this group discussion comment.
     * @param groupDiscussionCommentRequest 
     */
    public groupsDiscussionsCommentsUpdate(discussionUid: string, groupUid: string, id: number, groupDiscussionCommentRequest: GroupDiscussionCommentRequest, _options?: Configuration): Observable<GroupDiscussionComment> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCommentsUpdate(discussionUid, groupUid, id, groupDiscussionCommentRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCommentsUpdate(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param createGroupDiscussionRequest 
     */
    public groupsDiscussionsCreate(groupUid: string, createGroupDiscussionRequest: CreateGroupDiscussionRequest, _options?: Configuration): Observable<GroupDiscussion> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsCreate(groupUid, createGroupDiscussionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsCreate(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsDestroy(groupUid: string, uid: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsDestroy(groupUid, uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsDestroy(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param group 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public groupsDiscussionsList(groupUid: string, group?: string, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedGroupDiscussionList> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsList(groupUid, group, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsList(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param patchedGroupDiscussionRequest 
     */
    public groupsDiscussionsPartialUpdate(groupUid: string, uid: string, patchedGroupDiscussionRequest?: PatchedGroupDiscussionRequest, _options?: Configuration): Observable<GroupDiscussion> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsPartialUpdate(groupUid, uid, patchedGroupDiscussionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     * @param groupDiscussionPhotoRequest 
     */
    public groupsDiscussionsPhotosCreate(groupUid: string, uid: string, group?: string, groupDiscussionPhotoRequest?: GroupDiscussionPhotoRequest, _options?: Configuration): Observable<Array<GroupDiscussionPhoto>> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsPhotosCreate(groupUid, uid, group, groupDiscussionPhotoRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsPhotosCreate(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     */
    public groupsDiscussionsPhotosList(groupUid: string, uid: string, group?: string, _options?: Configuration): Observable<Array<GroupDiscussionPhoto>> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsPhotosList(groupUid, uid, group, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsPhotosList(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param groupDiscussionReactionRequest 
     */
    public groupsDiscussionsReactionCreate(groupUid: string, uid: string, groupDiscussionReactionRequest: GroupDiscussionReactionRequest, _options?: Configuration): Observable<GroupDiscussionReaction> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsReactionCreate(groupUid, uid, groupDiscussionReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsReactionCreate(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsReactionDestroy(groupUid: string, uid: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsReactionDestroy(groupUid, uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsReactionDestroy(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param group 
     */
    public groupsDiscussionsReactionsList(groupUid: string, uid: string, group?: string, _options?: Configuration): Observable<Array<GroupDiscussionReaction>> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsReactionsList(groupUid, uid, group, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsReactionsList(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     */
    public groupsDiscussionsRetrieve(groupUid: string, uid: string, _options?: Configuration): Observable<GroupDiscussion> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsRetrieve(groupUid, uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsRetrieve(rsp)));
            }));
    }

    /**
     * @param groupUid 
     * @param uid 
     * @param updateGroupDiscussionRequest 
     */
    public groupsDiscussionsUpdate(groupUid: string, uid: string, updateGroupDiscussionRequest?: UpdateGroupDiscussionRequest, _options?: Configuration): Observable<GroupDiscussion> {
        const requestContextPromise = this.requestFactory.groupsDiscussionsUpdate(groupUid, uid, updateGroupDiscussionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsDiscussionsUpdate(rsp)));
            }));
    }

    /**
     */
    public groupsList(_options?: Configuration): Observable<Array<Group>> {
        const requestContextPromise = this.requestFactory.groupsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param patchedGroupRequest 
     */
    public groupsPartialUpdate(uid: string, patchedGroupRequest?: PatchedGroupRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsPartialUpdate(uid, patchedGroupRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public groupsProfileRetrieve(uid: string, _options?: Configuration): Observable<GroupProfile> {
        const requestContextPromise = this.requestFactory.groupsProfileRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsProfileRetrieve(rsp)));
            }));
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsRemoveHorsesCreate(uid: string, faedingarnumerSerilizerRequest: Array<FaedingarnumerSerilizerRequest>, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsRemoveHorsesCreate(uid, faedingarnumerSerilizerRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsRemoveHorsesCreate(rsp)));
            }));
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param faedingarnumerSerilizerRequest 
     */
    public groupsRemoveMareCreate(uid: string, faedingarnumerSerilizerRequest: FaedingarnumerSerilizerRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsRemoveMareCreate(uid, faedingarnumerSerilizerRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsRemoveMareCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public groupsRetrieve(uid: string, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsRetrieve(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param groupSettingsRequest 
     */
    public groupsSettingsUpdate(uid: string, groupSettingsRequest?: GroupSettingsRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsSettingsUpdate(uid, groupSettingsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsSettingsUpdate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param groupThumbnailRequest 
     */
    public groupsThumbnailCreate(uid: string, groupThumbnailRequest?: GroupThumbnailRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsThumbnailCreate(uid, groupThumbnailRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsThumbnailCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param weeks 
     * @param year 
     */
    public groupsTrainingSummaryList(uid: string, weeks?: string, year?: number, _options?: Configuration): Observable<Array<WeeklyTrainingSummary>> {
        const requestContextPromise = this.requestFactory.groupsTrainingSummaryList(uid, weeks, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsTrainingSummaryList(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param updateGroupRequest 
     */
    public groupsUpdate(uid: string, updateGroupRequest?: UpdateGroupRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsUpdate(uid, updateGroupRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsUpdate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param updateGroupHorseRequest 
     */
    public groupsUpdateHorseUpdate(uid: string, updateGroupHorseRequest: UpdateGroupHorseRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsUpdateHorseUpdate(uid, updateGroupHorseRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsUpdateHorseUpdate(rsp)));
            }));
    }

    /**
     * if GroupType is STALLION, then add to obj.stallion_group.mares else add to obj.horses
     * @param uid 
     * @param updateGroupMareRequest 
     */
    public groupsUpdateMareCreate(uid: string, updateGroupMareRequest?: UpdateGroupMareRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsUpdateMareCreate(uid, updateGroupMareRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsUpdateMareCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     * @param groupUserRoleRequest 
     */
    public groupsUpdateRoleCreate(uid: string, groupUserRoleRequest: GroupUserRoleRequest, _options?: Configuration): Observable<Group> {
        const requestContextPromise = this.requestFactory.groupsUpdateRoleCreate(uid, groupUserRoleRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.groupsUpdateRoleCreate(rsp)));
            }));
    }

}

import { HorseAccessApiRequestFactory, HorseAccessApiResponseProcessor} from "../apis/HorseAccessApi";
export class ObservableHorseAccessApi {
    private requestFactory: HorseAccessApiRequestFactory;
    private responseProcessor: HorseAccessApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessApiRequestFactory,
        responseProcessor?: HorseAccessApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorseAccessApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorseAccessApiResponseProcessor();
    }

    /**
     * @param faedingarnumer 
     */
    public horseAccessAccessHistoryList(faedingarnumer?: string, _options?: Configuration): Observable<Array<HorseAccessHistory>> {
        const requestContextPromise = this.requestFactory.horseAccessAccessHistoryList(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessAccessHistoryList(rsp)));
            }));
    }

    /**
     * Change health status of horse
     * @param id A unique integer value identifying this horse access.
     * @param status 
     */
    public horseAccessChangeHealthStatusCreate(id: number, status?: string, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessChangeHealthStatusCreate(id, status, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessChangeHealthStatusCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param stable 
     */
    public horseAccessChangeStableStatusCreate(id: number, stable: boolean, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessChangeStableStatusCreate(id, stable, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessChangeStableStatusCreate(rsp)));
            }));
    }

    /**
     * @param createHorseAccessRequest 
     */
    public horseAccessCreate(createHorseAccessRequest: CreateHorseAccessRequest, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessCreate(createHorseAccessRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     */
    public horseAccessDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseAccessDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessDestroy(rsp)));
            }));
    }

    /**
     * @param horse 
     * @param user 
     */
    public horseAccessList(horse?: string, user?: number, _options?: Configuration): Observable<Array<HorseAccess>> {
        const requestContextPromise = this.requestFactory.horseAccessList(horse, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param patchedHorseAccessRequest 
     */
    public horseAccessPartialUpdate(id: number, patchedHorseAccessRequest?: PatchedHorseAccessRequest, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessPartialUpdate(id, patchedHorseAccessRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     */
    public horseAccessRetrieve(id: number, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer 
     */
    public horseAccessTeamInfoRetrieve(faedingarnumer: string, _options?: Configuration): Observable<TeamInfo> {
        const requestContextPromise = this.requestFactory.horseAccessTeamInfoRetrieve(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessTeamInfoRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param horseAccessRequest 
     */
    public horseAccessUpdate(id: number, horseAccessRequest: HorseAccessRequest, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessUpdate(id, horseAccessRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access.
     * @param role 
     */
    public horseAccessUpdateAccessCreate(id: number, role: number, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessUpdateAccessCreate(id, role, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessUpdateAccessCreate(rsp)));
            }));
    }

}

import { HorseAccessInvitesApiRequestFactory, HorseAccessInvitesApiResponseProcessor} from "../apis/HorseAccessInvitesApi";
export class ObservableHorseAccessInvitesApi {
    private requestFactory: HorseAccessInvitesApiRequestFactory;
    private responseProcessor: HorseAccessInvitesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessInvitesApiRequestFactory,
        responseProcessor?: HorseAccessInvitesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorseAccessInvitesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorseAccessInvitesApiResponseProcessor();
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesAcceptCreate(id: number, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesAcceptCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesAcceptCreate(rsp)));
            }));
    }

    /**
     * @param createHorseAccessInviteRequest 
     */
    public horseAccessInvitesCreate(createHorseAccessInviteRequest: CreateHorseAccessInviteRequest, _options?: Configuration): Observable<HorseAccessInvite> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesCreate(createHorseAccessInviteRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesDestroy(rsp)));
            }));
    }

    /**
     * @param dtProcessed 
     * @param dtRequested 
     * @param horse 
     * @param inviter 
     * @param status 
     * @param user 
     */
    public horseAccessInvitesList(dtProcessed?: Date, dtRequested?: Date, horse?: string, inviter?: number, status?: 'ACCEPTED' | 'PENDING' | 'REJECTED', user?: number, _options?: Configuration): Observable<Array<HorseAccessInvite>> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesList(dtProcessed, dtRequested, horse, inviter, status, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     * @param patchedHorseAccessInviteRequest 
     */
    public horseAccessInvitesPartialUpdate(id: number, patchedHorseAccessInviteRequest?: PatchedHorseAccessInviteRequest, _options?: Configuration): Observable<HorseAccessInvite> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesPartialUpdate(id, patchedHorseAccessInviteRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesRejectCreate(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesRejectCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesRejectCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     */
    public horseAccessInvitesRetrieve(id: number, _options?: Configuration): Observable<HorseAccessInvite> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access invite.
     * @param horseAccessInviteRequest 
     */
    public horseAccessInvitesUpdate(id: number, horseAccessInviteRequest: HorseAccessInviteRequest, _options?: Configuration): Observable<HorseAccessInvite> {
        const requestContextPromise = this.requestFactory.horseAccessInvitesUpdate(id, horseAccessInviteRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessInvitesUpdate(rsp)));
            }));
    }

}

import { HorseAccessRequestsApiRequestFactory, HorseAccessRequestsApiResponseProcessor} from "../apis/HorseAccessRequestsApi";
export class ObservableHorseAccessRequestsApi {
    private requestFactory: HorseAccessRequestsApiRequestFactory;
    private responseProcessor: HorseAccessRequestsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseAccessRequestsApiRequestFactory,
        responseProcessor?: HorseAccessRequestsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorseAccessRequestsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorseAccessRequestsApiResponseProcessor();
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsAcceptCreate(id: number, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsAcceptCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsAcceptCreate(rsp)));
            }));
    }

    /**
     * @param createHorseAccessReqRequest 
     */
    public horseAccessRequestsCreate(createHorseAccessReqRequest: CreateHorseAccessReqRequest, _options?: Configuration): Observable<HorseAccessReq> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsCreate(createHorseAccessReqRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsDestroy(rsp)));
            }));
    }

    /**
     * @param dtProcessed 
     * @param dtRequested 
     * @param horse 
     * @param status 
     * @param user 
     */
    public horseAccessRequestsList(dtProcessed?: Date, dtRequested?: Date, horse?: string, status?: 'ACCEPTED' | 'PENDING' | 'REJECTED', user?: number, _options?: Configuration): Observable<Array<HorseAccessReq>> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsList(dtProcessed, dtRequested, horse, status, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsRejectCreate(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsRejectCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsRejectCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse access request.
     */
    public horseAccessRequestsRetrieve(id: number, _options?: Configuration): Observable<HorseAccessReq> {
        const requestContextPromise = this.requestFactory.horseAccessRequestsRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseAccessRequestsRetrieve(rsp)));
            }));
    }

}

import { HorsePhotosApiRequestFactory, HorsePhotosApiResponseProcessor} from "../apis/HorsePhotosApi";
export class ObservableHorsePhotosApi {
    private requestFactory: HorsePhotosApiRequestFactory;
    private responseProcessor: HorsePhotosApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsePhotosApiRequestFactory,
        responseProcessor?: HorsePhotosApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorsePhotosApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorsePhotosApiResponseProcessor();
    }

    /**
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosCreate(horse: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Observable<HorsePhoto> {
        const requestContextPromise = this.requestFactory.horsePhotosCreate(horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horsePhotosDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosDestroy(rsp)));
            }));
    }

    /**
     * Fetches the profile photo for a horse identified by its FEIF number. If no profile photo is set, returns the first available photo. Returns null if no photos are available.
     * @param horse faedingarnumer
     */
    public horsePhotosGetProfilePhotoRetrieve(horse: string, _options?: Configuration): Observable<HorsePhoto> {
        const requestContextPromise = this.requestFactory.horsePhotosGetProfilePhotoRetrieve(horse, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosGetProfilePhotoRetrieve(rsp)));
            }));
    }

    /**
     * @param horse 
     * @param inProfile 
     * @param isHidden 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public horsePhotosList(horse?: string, inProfile?: boolean, isHidden?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-profile_order' | 'dt_created' | 'dt_modified' | 'profile_order'>, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedHorsePhotoList> {
        const requestContextPromise = this.requestFactory.horsePhotosList(horse, inProfile, isHidden, order, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosList(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param horse 
     * @param inProfile 
     * @param isHidden 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public horsePhotosPaginatedList(cursor?: string, horse?: string, inProfile?: boolean, isHidden?: boolean, order?: Array<'-dt_created' | '-dt_modified' | '-profile_order' | 'dt_created' | 'dt_modified' | 'profile_order'>, pageSize?: number, _options?: Configuration): Observable<PaginatedPaginatedHorsePhotosList> {
        const requestContextPromise = this.requestFactory.horsePhotosPaginatedList(cursor, horse, inProfile, isHidden, order, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosPaginatedList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosPartialUpdate(id: number, horse?: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Observable<HorsePhoto> {
        const requestContextPromise = this.requestFactory.horsePhotosPartialUpdate(id, horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosRetrieve(id: number, _options?: Configuration): Observable<HorsePhoto> {
        const requestContextPromise = this.requestFactory.horsePhotosRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     */
    public horsePhotosSetInProfileCreate(id: number, _options?: Configuration): Observable<Array<HorsePhoto>> {
        const requestContextPromise = this.requestFactory.horsePhotosSetInProfileCreate(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosSetInProfileCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse photo.
     * @param horse 
     * @param name 
     * @param image 
     * @param isHidden 
     * @param inProfile 
     * @param profileOrder 
     * @param user 
     * @param isSalePhoto 
     */
    public horsePhotosUpdate(id: number, horse: string, name?: string, image?: HttpFile, isHidden?: boolean, inProfile?: boolean, profileOrder?: number, user?: SimpleUserRequest, isSalePhoto?: boolean, _options?: Configuration): Observable<HorsePhoto> {
        const requestContextPromise = this.requestFactory.horsePhotosUpdate(id, horse, name, image, isHidden, inProfile, profileOrder, user, isSalePhoto, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsePhotosUpdate(rsp)));
            }));
    }

}

import { HorseSocialsApiRequestFactory, HorseSocialsApiResponseProcessor} from "../apis/HorseSocialsApi";
export class ObservableHorseSocialsApi {
    private requestFactory: HorseSocialsApiRequestFactory;
    private responseProcessor: HorseSocialsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseSocialsApiRequestFactory,
        responseProcessor?: HorseSocialsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorseSocialsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorseSocialsApiResponseProcessor();
    }

    /**
     * follow user
     * @param horseIdRequest 
     */
    public horseSocialsFollowUnfollowCreate(horseIdRequest: HorseIdRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.horseSocialsFollowUnfollowCreate(horseIdRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseSocialsFollowUnfollowCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer 
     */
    public horseSocialsFollowersList(faedingarnumer: string, _options?: Configuration): Observable<Array<SimpleUser>> {
        const requestContextPromise = this.requestFactory.horseSocialsFollowersList(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseSocialsFollowersList(rsp)));
            }));
    }

    /**
     * Get the horses that the user follows
     */
    public horseSocialsGetFollowedHorsesList(_options?: Configuration): Observable<Array<SimpleHorse>> {
        const requestContextPromise = this.requestFactory.horseSocialsGetFollowedHorsesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseSocialsGetFollowedHorsesList(rsp)));
            }));
    }

    /**
     */
    public horseSocialsGetMostFollowedHorsesList(_options?: Configuration): Observable<Array<SimpleHorse>> {
        const requestContextPromise = this.requestFactory.horseSocialsGetMostFollowedHorsesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseSocialsGetMostFollowedHorsesList(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public horseSocialsList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.horseSocialsList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, type, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseSocialsList(rsp)));
            }));
    }

}

import { HorseVideosApiRequestFactory, HorseVideosApiResponseProcessor} from "../apis/HorseVideosApi";
export class ObservableHorseVideosApi {
    private requestFactory: HorseVideosApiRequestFactory;
    private responseProcessor: HorseVideosApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorseVideosApiRequestFactory,
        responseProcessor?: HorseVideosApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorseVideosApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorseVideosApiResponseProcessor();
    }

    /**
     * @param horse 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosCreate(horse: string, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Observable<HorseVideo> {
        const requestContextPromise = this.requestFactory.horseVideosCreate(horse, name, video, transcoded, thumbnail, user, isSaleVideo, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse video.
     */
    public horseVideosDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horseVideosDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosDestroy(rsp)));
            }));
    }

    /**
     * @param horse 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public horseVideosList(horse?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedHorseVideoList> {
        const requestContextPromise = this.requestFactory.horseVideosList(horse, order, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosList(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param horse 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public horseVideosPaginatedList(cursor?: string, horse?: string, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, pageSize?: number, _options?: Configuration): Observable<PaginatedPaginatedHorseVideosList> {
        const requestContextPromise = this.requestFactory.horseVideosPaginatedList(cursor, horse, order, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosPaginatedList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param name 
     * @param horse 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosPartialUpdate(id: number, name?: string, horse?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Observable<HorseVideo> {
        const requestContextPromise = this.requestFactory.horseVideosPartialUpdate(id, name, horse, video, transcoded, thumbnail, user, isSaleVideo, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse video.
     */
    public horseVideosRetrieve(id: number, _options?: Configuration): Observable<HorseVideo> {
        const requestContextPromise = this.requestFactory.horseVideosRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param thumbnail 
     */
    public horseVideosThumbnailCreate(id: number, thumbnail?: HttpFile, _options?: Configuration): Observable<HorseVideo> {
        const requestContextPromise = this.requestFactory.horseVideosThumbnailCreate(id, thumbnail, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosThumbnailCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse video.
     * @param horse 
     * @param name 
     * @param video 
     * @param transcoded 
     * @param thumbnail 
     * @param user 
     * @param isSaleVideo 
     */
    public horseVideosUpdate(id: number, horse: string, name?: string, video?: HttpFile, transcoded?: TranscoderDataRequest, thumbnail?: HttpFile, user?: SimpleUserRequest, isSaleVideo?: boolean, _options?: Configuration): Observable<HorseVideo> {
        const requestContextPromise = this.requestFactory.horseVideosUpdate(id, horse, name, video, transcoded, thumbnail, user, isSaleVideo, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horseVideosUpdate(rsp)));
            }));
    }

}

import { HorsedayRankingApiRequestFactory, HorsedayRankingApiResponseProcessor} from "../apis/HorsedayRankingApi";
export class ObservableHorsedayRankingApi {
    private requestFactory: HorsedayRankingApiRequestFactory;
    private responseProcessor: HorsedayRankingApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsedayRankingApiRequestFactory,
        responseProcessor?: HorsedayRankingApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorsedayRankingApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorsedayRankingApiResponseProcessor();
    }

    /**
     */
    public horsedayRankingCountriesYearsList(_options?: Configuration): Observable<Array<CountryYears>> {
        const requestContextPromise = this.requestFactory.horsedayRankingCountriesYearsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsedayRankingCountriesYearsList(rsp)));
            }));
    }

    /**
     * @param land 
     * @param year 
     */
    public horsedayRankingFlokkurKeppnirList(land: string, year: number, _options?: Configuration): Observable<Array<FlokkurKeppni>> {
        const requestContextPromise = this.requestFactory.horsedayRankingFlokkurKeppnirList(land, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsedayRankingFlokkurKeppnirList(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param flokkur 
     * @param keppnisgrein 
     * @param land 
     * @param pageSize Number of results to return per page.
     * @param year 
     */
    public horsedayRankingList(cursor?: string, flokkur?: string, keppnisgrein?: string, land?: string, pageSize?: number, year?: number, _options?: Configuration): Observable<PaginatedHorseDayRankingList> {
        const requestContextPromise = this.requestFactory.horsedayRankingList(cursor, flokkur, keppnisgrein, land, pageSize, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsedayRankingList(rsp)));
            }));
    }

    /**
     * @param keppandiNumer A unique value identifying this horse day ranking.
     */
    public horsedayRankingRetrieve(keppandiNumer: number, _options?: Configuration): Observable<HorseDayRanking> {
        const requestContextPromise = this.requestFactory.horsedayRankingRetrieve(keppandiNumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsedayRankingRetrieve(rsp)));
            }));
    }

}

import { HorsedayWrappedApiRequestFactory, HorsedayWrappedApiResponseProcessor} from "../apis/HorsedayWrappedApi";
export class ObservableHorsedayWrappedApi {
    private requestFactory: HorsedayWrappedApiRequestFactory;
    private responseProcessor: HorsedayWrappedApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsedayWrappedApiRequestFactory,
        responseProcessor?: HorsedayWrappedApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorsedayWrappedApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorsedayWrappedApiResponseProcessor();
    }

    /**
     */
    public horsedayWrappedRetrieve(_options?: Configuration): Observable<WrappedSerialzer> {
        const requestContextPromise = this.requestFactory.horsedayWrappedRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsedayWrappedRetrieve(rsp)));
            }));
    }

}

import { HorsesApiRequestFactory, HorsesApiResponseProcessor} from "../apis/HorsesApi";
export class ObservableHorsesApi {
    private requestFactory: HorsesApiRequestFactory;
    private responseProcessor: HorsesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsesApiRequestFactory,
        responseProcessor?: HorsesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorsesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorsesApiResponseProcessor();
    }

    /**
     */
    public horsesAllMyHorsesList(_options?: Configuration): Observable<Array<Horse>> {
        const requestContextPromise = this.requestFactory.horsesAllMyHorsesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesAllMyHorsesList(rsp)));
            }));
    }

    /**
     */
    public horsesAllMySimpleHorsesList(_options?: Configuration): Observable<Array<SimpleHorse>> {
        const requestContextPromise = this.requestFactory.horsesAllMySimpleHorsesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesAllMySimpleHorsesList(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param dam 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesAssessmentOverviewList(faedingarnumer: string, dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Observable<Array<AssessmentOverview>> {
        const requestContextPromise = this.requestFactory.horsesAssessmentOverviewList(faedingarnumer, dam, order, sire, userRole, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesAssessmentOverviewList(rsp)));
            }));
    }

    /**
     * Change health status of horse
     * @param faedingarnumer A unique value identifying this horse.
     * @param status 
     */
    public horsesChangeHealthStatusCreate(faedingarnumer: string, status?: string, _options?: Configuration): Observable<SimpleHorse> {
        const requestContextPromise = this.requestFactory.horsesChangeHealthStatusCreate(faedingarnumer, status, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesChangeHealthStatusCreate(rsp)));
            }));
    }

    /**
     * @param createHorseRequest 
     */
    public horsesCreateHorseManualCreate(createHorseRequest: CreateHorseRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesCreateHorseManualCreate(createHorseRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesCreateHorseManualCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseVaccinationRequest 
     */
    public horsesDeleteVaccinationCreate(faedingarnumer: string, horseVaccinationRequest?: HorseVaccinationRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.horsesDeleteVaccinationCreate(faedingarnumer, horseVaccinationRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesDeleteVaccinationCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesDestroy(faedingarnumer: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.horsesDestroy(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesDestroy(rsp)));
            }));
    }

    /**
     * @param domurId 
     */
    public horsesDetailedAssessmentOverviewRetrieve(domurId: string, _options?: Configuration): Observable<DetailedAssessmentOverview> {
        const requestContextPromise = this.requestFactory.horsesDetailedAssessmentOverviewRetrieve(domurId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesDetailedAssessmentOverviewRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseDetailsRequest 
     */
    public horsesDetailsCreate(faedingarnumer: string, horseDetailsRequest?: HorseDetailsRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesDetailsCreate(faedingarnumer, horseDetailsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesDetailsCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseDetailsRequest 
     */
    public horsesDetailsPartialUpdate(faedingarnumer: string, patchedHorseDetailsRequest?: PatchedHorseDetailsRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesDetailsPartialUpdate(faedingarnumer, patchedHorseDetailsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesDetailsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesFollowCreate(faedingarnumer: string, _options?: Configuration): Observable<HorseAccess> {
        const requestContextPromise = this.requestFactory.horsesFollowCreate(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesFollowCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseForSaleRequest 
     */
    public horsesForSaleCreate(faedingarnumer: string, horseForSaleRequest: HorseForSaleRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesForSaleCreate(faedingarnumer, horseForSaleRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesForSaleCreate(rsp)));
            }));
    }

    /**
     * Get the feeding history of a horse
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesGetHorseFeedingHistoryList(faedingarnumer: string, _options?: Configuration): Observable<Array<Feeding>> {
        const requestContextPromise = this.requestFactory.horsesGetHorseFeedingHistoryList(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesGetHorseFeedingHistoryList(rsp)));
            }));
    }

    /**
     * listi af strengjum
     * @param faedingarnumers 
     */
    public horsesGetHorsesByFaedingarnumerList(faedingarnumers?: string, _options?: Configuration): Observable<Array<SimpleHorse>> {
        const requestContextPromise = this.requestFactory.horsesGetHorsesByFaedingarnumerList(faedingarnumers, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesGetHorsesByFaedingarnumerList(rsp)));
            }));
    }

    /**
     * @param sportfengurIds 
     */
    public horsesGetHorsesBySportfengurIdsList(sportfengurIds?: string, _options?: Configuration): Observable<Array<SimpleHorse>> {
        const requestContextPromise = this.requestFactory.horsesGetHorsesBySportfengurIdsList(sportfengurIds, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesGetHorsesBySportfengurIdsList(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesGetVaccinationsList(faedingarnumer: string, _options?: Configuration): Observable<Array<HorseVaccination>> {
        const requestContextPromise = this.requestFactory.horsesGetVaccinationsList(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesGetVaccinationsList(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesHorseInfoSummaryRetrieve(faedingarnumer: string, _options?: Configuration): Observable<InfoSummary> {
        const requestContextPromise = this.requestFactory.horsesHorseInfoSummaryRetrieve(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesHorseInfoSummaryRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param isMonthly 
     * @param month 
     * @param week 
     * @param year 
     */
    public horsesHorseTrainingSummaryRetrieve(faedingarnumer: string, isMonthly?: boolean, month?: number, week?: number, year?: number, _options?: Configuration): Observable<Summary> {
        const requestContextPromise = this.requestFactory.horsesHorseTrainingSummaryRetrieve(faedingarnumer, isMonthly, month, week, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesHorseTrainingSummaryRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer 
     * @param faedingarnumerContains 
     * @param name 
     * @param nameContains 
     */
    public horsesHorsedaySearchList(faedingarnumer?: string, faedingarnumerContains?: string, name?: string, nameContains?: string, _options?: Configuration): Observable<Array<SearchHorse>> {
        const requestContextPromise = this.requestFactory.horsesHorsedaySearchList(faedingarnumer, faedingarnumerContains, name, nameContains, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesHorsedaySearchList(rsp)));
            }));
    }

    /**
     * @param dam 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param sire 
     * @param userRole 
     */
    public horsesList(dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, page?: number, pageSize?: number, sire?: string, userRole?: string, _options?: Configuration): Observable<PaginatedHorseList> {
        const requestContextPromise = this.requestFactory.horsesList(dam, order, page, pageSize, sire, userRole, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesList(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param measurmentsRequest 
     */
    public horsesMeasurementsCreate(faedingarnumer: string, measurmentsRequest: MeasurmentsRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesMeasurementsCreate(faedingarnumer, measurmentsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesMeasurementsCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseMedicalDetailsRequest 
     */
    public horsesMedicalDetailsCreate(faedingarnumer: string, horseMedicalDetailsRequest?: HorseMedicalDetailsRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesMedicalDetailsCreate(faedingarnumer, horseMedicalDetailsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesMedicalDetailsCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseMedicalDetailsRequest 
     */
    public horsesMedicalDetailsPartialUpdate(faedingarnumer: string, patchedHorseMedicalDetailsRequest?: PatchedHorseMedicalDetailsRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesMedicalDetailsPartialUpdate(faedingarnumer, patchedHorseMedicalDetailsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesMedicalDetailsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param goalRequest 
     */
    public horsesMonthlyGoalCreate(faedingarnumer: string, goalRequest?: GoalRequest, _options?: Configuration): Observable<MonthlyTrainingSummary> {
        const requestContextPromise = this.requestFactory.horsesMonthlyGoalCreate(faedingarnumer, goalRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesMonthlyGoalCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param dam 
     * @param faedingarnumer2 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesOffspringList(faedingarnumer: string, dam?: string, faedingarnumer2?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Observable<Array<WFOffspring>> {
        const requestContextPromise = this.requestFactory.horsesOffspringList(faedingarnumer, dam, faedingarnumer2, order, sire, userRole, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesOffspringList(rsp)));
            }));
    }

    /**
     * @param birthDateGt age greater than
     * @param birthDateLt age less than
     * @param country Origin country, country code 2 letters
     * @param horse 
     * @param next 
     * @param onlyAlive 
     * @param orderBy 
     * @param pageSize 
     * @param place 
     * @param sexEnum Sex enum
     */
    public horsesPaginatedHorsedaySearchRetrieve(birthDateGt?: string, birthDateLt?: string, country?: string, horse?: string, next?: number, onlyAlive?: boolean, orderBy?: string, pageSize?: number, place?: string, sexEnum?: string, _options?: Configuration): Observable<PaginatedHorses> {
        const requestContextPromise = this.requestFactory.horsesPaginatedHorsedaySearchRetrieve(birthDateGt, birthDateLt, country, horse, next, onlyAlive, orderBy, pageSize, place, sexEnum, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesPaginatedHorsedaySearchRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseRequest 
     */
    public horsesPartialUpdate(faedingarnumer: string, patchedHorseRequest?: PatchedHorseRequest, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesPartialUpdate(faedingarnumer, patchedHorseRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesPartialUpdate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesRetrieve(faedingarnumer: string, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesRetrieve(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseSettingsRequest 
     */
    public horsesSettingsCreate(faedingarnumer: string, horseSettingsRequest?: HorseSettingsRequest, _options?: Configuration): Observable<HorseSettings> {
        const requestContextPromise = this.requestFactory.horsesSettingsCreate(faedingarnumer, horseSettingsRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesSettingsCreate(rsp)));
            }));
    }

    /**
     * Uses wf to search for horse.
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesSingleHorseRetrieve(faedingarnumer: string, _options?: Configuration): Observable<Horse> {
        const requestContextPromise = this.requestFactory.horsesSingleHorseRetrieve(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesSingleHorseRetrieve(rsp)));
            }));
    }

    /**
     */
    public horsesTopHorsesList(_options?: Configuration): Observable<Array<Horse>> {
        const requestContextPromise = this.requestFactory.horsesTopHorsesList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesTopHorsesList(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     */
    public horsesUnauthedHorseRetrieveRetrieve(faedingarnumer: string, _options?: Configuration): Observable<SearchHorse> {
        const requestContextPromise = this.requestFactory.horsesUnauthedHorseRetrieveRetrieve(faedingarnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesUnauthedHorseRetrieveRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseAccessRequest 
     */
    public horsesUpdateAccessCreate(faedingarnumer: string, horseAccessRequest: HorseAccessRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.horsesUpdateAccessCreate(faedingarnumer, horseAccessRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesUpdateAccessCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param horseVaccinationRequest 
     */
    public horsesVaccinationCreate(faedingarnumer: string, horseVaccinationRequest?: HorseVaccinationRequest, _options?: Configuration): Observable<HorseVaccination> {
        const requestContextPromise = this.requestFactory.horsesVaccinationCreate(faedingarnumer, horseVaccinationRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesVaccinationCreate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param patchedHorseVaccinationRequest 
     */
    public horsesVaccinationPartialUpdate(faedingarnumer: string, patchedHorseVaccinationRequest?: PatchedHorseVaccinationRequest, _options?: Configuration): Observable<HorseVaccination> {
        const requestContextPromise = this.requestFactory.horsesVaccinationPartialUpdate(faedingarnumer, patchedHorseVaccinationRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesVaccinationPartialUpdate(rsp)));
            }));
    }

    /**
     * @param faedingarnumer A unique value identifying this horse.
     * @param goalRequest 
     */
    public horsesWeeklyGoalCreate(faedingarnumer: string, goalRequest?: GoalRequest, _options?: Configuration): Observable<WeeklyTrainingSummary> {
        const requestContextPromise = this.requestFactory.horsesWeeklyGoalCreate(faedingarnumer, goalRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesWeeklyGoalCreate(rsp)));
            }));
    }

    /**
     * @param wFLoginRequest 
     */
    public horsesWfAccountSyncCreate(wFLoginRequest: WFLoginRequest, _options?: Configuration): Observable<Array<Horse>> {
        const requestContextPromise = this.requestFactory.horsesWfAccountSyncCreate(wFLoginRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesWfAccountSyncCreate(rsp)));
            }));
    }

    /**
     * @param dam 
     * @param faedingarnumer 
     * @param name 
     * @param order Ordering
     * @param origin 
     * @param owner 
     * @param rfid 
     * @param sire 
     * @param userRole 
     */
    public horsesWfSearchList(dam?: string, faedingarnumer?: string, name?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, origin?: string, owner?: string, rfid?: string, sire?: string, userRole?: string, _options?: Configuration): Observable<Array<SearchHorse>> {
        const requestContextPromise = this.requestFactory.horsesWfSearchList(dam, faedingarnumer, name, order, origin, owner, rfid, sire, userRole, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesWfSearchList(rsp)));
            }));
    }

    /**
     * @param dam 
     * @param order Ordering
     * @param sire 
     * @param userRole 
     */
    public horsesWorldChampionshipList(dam?: string, order?: Array<'-faedingarnumer' | '-name' | 'faedingarnumer' | 'name'>, sire?: string, userRole?: string, _options?: Configuration): Observable<Array<WorldCupHorse>> {
        const requestContextPromise = this.requestFactory.horsesWorldChampionshipList(dam, order, sire, userRole, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesWorldChampionshipList(rsp)));
            }));
    }

}

import { HorsesQueriesApiRequestFactory, HorsesQueriesApiResponseProcessor} from "../apis/HorsesQueriesApi";
export class ObservableHorsesQueriesApi {
    private requestFactory: HorsesQueriesApiRequestFactory;
    private responseProcessor: HorsesQueriesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: HorsesQueriesApiRequestFactory,
        responseProcessor?: HorsesQueriesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new HorsesQueriesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new HorsesQueriesApiResponseProcessor();
    }

    /**
     * @param adaleinkunn 
     * @param assessment 
     * @param availableForBreeding 
     * @param birthDate 
     * @param birthDateFiilterAfter 
     * @param birthDateFiilterBefore 
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param forSale 
     * @param haestaAdaleinkunn 
     * @param horseType 
     * @param name 
     * @param noPriceSet 
     * @param order Ordering
     * @param originCountry 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param priceCategory 
     * @param priceRangeGreaterThan 
     * @param priceRangeLessThan 
     * @param sexEnum 
     * @param ssName 
     * @param ssPlace 
     */
    public horsesQueriesList(adaleinkunn?: number, assessment?: number, availableForBreeding?: boolean, birthDate?: string, birthDateFiilterAfter?: string, birthDateFiilterBefore?: string, dateRangeAfter?: string, dateRangeBefore?: string, forSale?: boolean, haestaAdaleinkunn?: number, horseType?: Array<'COMPETITION' | 'FAMILY' | 'OTHER' | 'PACE' | 'RIDE' | 'UNTAMED'>, name?: string, noPriceSet?: boolean, order?: Array<'-adaleinkunn' | '-dt_set_for_sale' | '-for_sale__price' | '-haestaAdaleinkunn' | '-name' | 'adaleinkunn' | 'dt_set_for_sale' | 'for_sale__price' | 'haestaAdaleinkunn' | 'name'>, originCountry?: string, page?: number, pageSize?: number, priceCategory?: Array<'ONE' | 'THREE' | 'TWO' | 'UNDEFINED'>, priceRangeGreaterThan?: number, priceRangeLessThan?: number, sexEnum?: Array<'GELDING' | 'MARE' | 'STALLION'>, ssName?: string, ssPlace?: string, _options?: Configuration): Observable<PaginatedHorseList> {
        const requestContextPromise = this.requestFactory.horsesQueriesList(adaleinkunn, assessment, availableForBreeding, birthDate, birthDateFiilterAfter, birthDateFiilterBefore, dateRangeAfter, dateRangeBefore, forSale, haestaAdaleinkunn, horseType, name, noPriceSet, order, originCountry, page, pageSize, priceCategory, priceRangeGreaterThan, priceRangeLessThan, sexEnum, ssName, ssPlace, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesQueriesList(rsp)));
            }));
    }

    /**
     */
    public horsesQueriesMetadataRetrieve(_options?: Configuration): Observable<HorseQueryMetadata> {
        const requestContextPromise = this.requestFactory.horsesQueriesMetadataRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesQueriesMetadataRetrieve(rsp)));
            }));
    }

    /**
     * will this be quicker
     * @param number 
     */
    public horsesQueriesNewestForSaleList(number?: number, _options?: Configuration): Observable<Array<Horse>> {
        const requestContextPromise = this.requestFactory.horsesQueriesNewestForSaleList(number, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.horsesQueriesNewestForSaleList(rsp)));
            }));
    }

}

import { LeaderboardApiRequestFactory, LeaderboardApiResponseProcessor} from "../apis/LeaderboardApi";
export class ObservableLeaderboardApi {
    private requestFactory: LeaderboardApiRequestFactory;
    private responseProcessor: LeaderboardApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: LeaderboardApiRequestFactory,
        responseProcessor?: LeaderboardApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new LeaderboardApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new LeaderboardApiResponseProcessor();
    }

    /**
     * @param leaderboardRequest 
     */
    public leaderboardCreate(leaderboardRequest: LeaderboardRequest, _options?: Configuration): Observable<Leaderboard> {
        const requestContextPromise = this.requestFactory.leaderboardCreate(leaderboardRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this leader board.
     */
    public leaderboardDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.leaderboardDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardDestroy(rsp)));
            }));
    }

    /**
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardList(intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Observable<PaginatedLeaderboardList> {
        const requestContextPromise = this.requestFactory.leaderboardList(intervalType, intervalValue, metric, page, pageSize, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this leader board.
     * @param patchedLeaderboardRequest 
     */
    public leaderboardPartialUpdate(id: number, patchedLeaderboardRequest?: PatchedLeaderboardRequest, _options?: Configuration): Observable<Leaderboard> {
        const requestContextPromise = this.requestFactory.leaderboardPartialUpdate(id, patchedLeaderboardRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this leader board.
     */
    public leaderboardRetrieve(id: number, _options?: Configuration): Observable<Leaderboard> {
        const requestContextPromise = this.requestFactory.leaderboardRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardRetrieve(rsp)));
            }));
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetLeaderboardLast30DaysCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Observable<PaginatedLeaderboardList> {
        const requestContextPromise = this.requestFactory.leaderboardSetLeaderboardLast30DaysCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardSetLeaderboardLast30DaysCreate(rsp)));
            }));
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetMonthlyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Observable<PaginatedLeaderboardList> {
        const requestContextPromise = this.requestFactory.leaderboardSetMonthlyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardSetMonthlyLeaderboardCreate(rsp)));
            }));
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetWeeklyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Observable<PaginatedLeaderboardList> {
        const requestContextPromise = this.requestFactory.leaderboardSetWeeklyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardSetWeeklyLeaderboardCreate(rsp)));
            }));
    }

    /**
     * @param leaderboardRequest 
     * @param intervalType 
     * @param intervalValue 
     * @param metric 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param user 
     * @param year 
     */
    public leaderboardSetYearlyLeaderboardCreate(leaderboardRequest: LeaderboardRequest, intervalType?: Array<'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR'>, intervalValue?: number, metric?: Array<'DISTANCE' | 'SESSIONS' | 'TIME'>, page?: number, pageSize?: number, user?: number, year?: number, _options?: Configuration): Observable<PaginatedLeaderboardList> {
        const requestContextPromise = this.requestFactory.leaderboardSetYearlyLeaderboardCreate(leaderboardRequest, intervalType, intervalValue, metric, page, pageSize, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardSetYearlyLeaderboardCreate(rsp)));
            }));
    }

    /**
     * @param intervalType Type of the interval
     * @param intervalValue 
     * @param metric Metric type
     * @param user 
     * @param year 
     */
    public leaderboardUserLeaderboardList(intervalType?: 'DISTANCE' | 'SESSIONS' | 'TIME', intervalValue?: number, metric?: 'LAST_30_DAYS' | 'LAST_7_DAYS' | 'MONTH' | 'WEEK' | 'YEAR', user?: number, year?: number, _options?: Configuration): Observable<Array<Leaderboard>> {
        const requestContextPromise = this.requestFactory.leaderboardUserLeaderboardList(intervalType, intervalValue, metric, user, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.leaderboardUserLeaderboardList(rsp)));
            }));
    }

}

import { MessageTokensApiRequestFactory, MessageTokensApiResponseProcessor} from "../apis/MessageTokensApi";
export class ObservableMessageTokensApi {
    private requestFactory: MessageTokensApiRequestFactory;
    private responseProcessor: MessageTokensApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MessageTokensApiRequestFactory,
        responseProcessor?: MessageTokensApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MessageTokensApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MessageTokensApiResponseProcessor();
    }

    /**
     * @param tokenRequest 
     */
    public messageTokensCreate(tokenRequest: TokenRequest, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.messageTokensCreate(tokenRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.messageTokensCreate(rsp)));
            }));
    }

    /**
     * @param token A unique value identifying this messaging token.
     */
    public messageTokensDestroy(token: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.messageTokensDestroy(token, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.messageTokensDestroy(rsp)));
            }));
    }

}

import { MetaApiRequestFactory, MetaApiResponseProcessor} from "../apis/MetaApi";
export class ObservableMetaApi {
    private requestFactory: MetaApiRequestFactory;
    private responseProcessor: MetaApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MetaApiRequestFactory,
        responseProcessor?: MetaApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MetaApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MetaApiResponseProcessor();
    }

    /**
     */
    public metaAppMetaRetrieve(_options?: Configuration): Observable<AppMeta> {
        const requestContextPromise = this.requestFactory.metaAppMetaRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.metaAppMetaRetrieve(rsp)));
            }));
    }

    /**
     */
    public metaHorsedayMessageRetrieve(_options?: Configuration): Observable<HorseDayMessage> {
        const requestContextPromise = this.requestFactory.metaHorsedayMessageRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.metaHorsedayMessageRetrieve(rsp)));
            }));
    }

}

import { NotificationsApiRequestFactory, NotificationsApiResponseProcessor} from "../apis/NotificationsApi";
export class ObservableNotificationsApi {
    private requestFactory: NotificationsApiRequestFactory;
    private responseProcessor: NotificationsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: NotificationsApiRequestFactory,
        responseProcessor?: NotificationsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new NotificationsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new NotificationsApiResponseProcessor();
    }

    /**
     * @param id A unique value identifying this notification.
     */
    public notificationsDestroy(id: string, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.notificationsDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.notificationsDestroy(rsp)));
            }));
    }

    /**
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param receiver 
     * @param seen 
     */
    public notificationsList(cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, receiver?: number, seen?: boolean, _options?: Configuration): Observable<PaginatedNotificationList> {
        const requestContextPromise = this.requestFactory.notificationsList(cursor, dateRangeAfter, dateRangeBefore, order, pageSize, receiver, seen, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.notificationsList(rsp)));
            }));
    }

    /**
     */
    public notificationsNumberUnseenRetrieve(_options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.notificationsNumberUnseenRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.notificationsNumberUnseenRetrieve(rsp)));
            }));
    }

    /**
     */
    public notificationsUnseenList(_options?: Configuration): Observable<Array<Notification>> {
        const requestContextPromise = this.requestFactory.notificationsUnseenList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.notificationsUnseenList(rsp)));
            }));
    }

}

import { PromoApiRequestFactory, PromoApiResponseProcessor} from "../apis/PromoApi";
export class ObservablePromoApi {
    private requestFactory: PromoApiRequestFactory;
    private responseProcessor: PromoApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: PromoApiRequestFactory,
        responseProcessor?: PromoApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new PromoApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new PromoApiResponseProcessor();
    }

    /**
     * @param platform 
     */
    public promoCodeRetrieve(platform?: string, _options?: Configuration): Observable<PromoCode> {
        const requestContextPromise = this.requestFactory.promoCodeRetrieve(platform, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.promoCodeRetrieve(rsp)));
            }));
    }

    /**
     * @param promoCodeRequest 
     */
    public promoUseCodeCreate(promoCodeRequest?: PromoCodeRequest, _options?: Configuration): Observable<PromoCode> {
        const requestContextPromise = this.requestFactory.promoUseCodeCreate(promoCodeRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.promoUseCodeCreate(rsp)));
            }));
    }

}

import { RedeemCodeApiRequestFactory, RedeemCodeApiResponseProcessor} from "../apis/RedeemCodeApi";
export class ObservableRedeemCodeApi {
    private requestFactory: RedeemCodeApiRequestFactory;
    private responseProcessor: RedeemCodeApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: RedeemCodeApiRequestFactory,
        responseProcessor?: RedeemCodeApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new RedeemCodeApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new RedeemCodeApiResponseProcessor();
    }

    /**
     * @param code 
     */
    public redeemCodeCreate(code?: string, _options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.redeemCodeCreate(code, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.redeemCodeCreate(rsp)));
            }));
    }

}

import { SafetyTrackingApiRequestFactory, SafetyTrackingApiResponseProcessor} from "../apis/SafetyTrackingApi";
export class ObservableSafetyTrackingApi {
    private requestFactory: SafetyTrackingApiRequestFactory;
    private responseProcessor: SafetyTrackingApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SafetyTrackingApiRequestFactory,
        responseProcessor?: SafetyTrackingApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SafetyTrackingApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SafetyTrackingApiResponseProcessor();
    }

    /**
     * @param safetyTrackingTeamInviteCreateRequest 
     */
    public safetyTrackingAccessCreateInviteCreate(safetyTrackingTeamInviteCreateRequest: SafetyTrackingTeamInviteCreateRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessCreateInviteCreate(safetyTrackingTeamInviteCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessCreateInviteCreate(rsp)));
            }));
    }

    /**
     * @param idRequest 
     */
    public safetyTrackingAccessInviteDeleteCreate(idRequest: IdRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessInviteDeleteCreate(idRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessInviteDeleteCreate(rsp)));
            }));
    }

    /**
     * Teams user has been invited to
     */
    public safetyTrackingAccessInvitedList(_options?: Configuration): Observable<Array<SafetyTrackingTeamInvite>> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessInvitedList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessInvitedList(rsp)));
            }));
    }

    /**
     * All team user has invited others
     */
    public safetyTrackingAccessInviterList(_options?: Configuration): Observable<Array<SafetyTrackingTeamInvite>> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessInviterList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessInviterList(rsp)));
            }));
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingAccessRemoveCreate(userUidRequest: UserUidRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessRemoveCreate(userUidRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessRemoveCreate(rsp)));
            }));
    }

    /**
     * @param acceptRequest 
     */
    public safetyTrackingAccessRespondInviteCreate(acceptRequest: AcceptRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingAccessRespondInviteCreate(acceptRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAccessRespondInviteCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public safetyTrackingActiveTrackingRetrieve(uid: string, _options?: Configuration): Observable<HorseActivity | SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingActiveTrackingRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingActiveTrackingRetrieve(rsp)));
            }));
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingAddSafetyTeamViewerCreate(userUidRequest: UserUidRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingAddSafetyTeamViewerCreate(userUidRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingAddSafetyTeamViewerCreate(rsp)));
            }));
    }

    /**
     */
    public safetyTrackingDismissedSafetyTrackCreate(_options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingDismissedSafetyTrackCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingDismissedSafetyTrackCreate(rsp)));
            }));
    }

    /**
     * @param token 
     */
    public safetyTrackingGetTeamWithTokenRetrieve(token: string, _options?: Configuration): Observable<SafetyTrackingTeam> {
        const requestContextPromise = this.requestFactory.safetyTrackingGetTeamWithTokenRetrieve(token, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingGetTeamWithTokenRetrieve(rsp)));
            }));
    }

    /**
     */
    public safetyTrackingMyInfoRetrieve(_options?: Configuration): Observable<SafetyTrackingMyInfo> {
        const requestContextPromise = this.requestFactory.safetyTrackingMyInfoRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingMyInfoRetrieve(rsp)));
            }));
    }

    /**
     * @param userUidRequest 
     */
    public safetyTrackingRemoveSelfAsViewerCreate(userUidRequest: UserUidRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.safetyTrackingRemoveSelfAsViewerCreate(userUidRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingRemoveSelfAsViewerCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public safetyTrackingTeamRetrieve(uid?: string, _options?: Configuration): Observable<SafetyTrackingTeam> {
        const requestContextPromise = this.requestFactory.safetyTrackingTeamRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingTeamRetrieve(rsp)));
            }));
    }

    /**
     */
    public safetyTrackingTeamsWhereViewerList(_options?: Configuration): Observable<Array<SafetyTrackingTeam>> {
        const requestContextPromise = this.requestFactory.safetyTrackingTeamsWhereViewerList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingTeamsWhereViewerList(rsp)));
            }));
    }

    /**
     */
    public safetyTrackingToggleSafetyTrackingCreate(_options?: Configuration): Observable<SafetyTrackingTeam> {
        const requestContextPromise = this.requestFactory.safetyTrackingToggleSafetyTrackingCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingToggleSafetyTrackingCreate(rsp)));
            }));
    }

    /**
     * @param activityId 
     */
    public safetyTrackingTrackingPointsList(activityId: number, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.safetyTrackingTrackingPointsList(activityId, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingTrackingPointsList(rsp)));
            }));
    }

    /**
     * @param token 
     */
    public safetyTrackingTrackingPointsWithTokenList(token: string, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.safetyTrackingTrackingPointsWithTokenList(token, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.safetyTrackingTrackingPointsWithTokenList(rsp)));
            }));
    }

}

import { SchedulesApiRequestFactory, SchedulesApiResponseProcessor} from "../apis/SchedulesApi";
export class ObservableSchedulesApi {
    private requestFactory: SchedulesApiRequestFactory;
    private responseProcessor: SchedulesApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SchedulesApiRequestFactory,
        responseProcessor?: SchedulesApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SchedulesApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SchedulesApiResponseProcessor();
    }

    /**
     */
    public schedulesMonthlyTrainingSummaryRetrieve(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.schedulesMonthlyTrainingSummaryRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.schedulesMonthlyTrainingSummaryRetrieve(rsp)));
            }));
    }

    /**
     */
    public schedulesTrainingSessionValidationRetrieve(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.schedulesTrainingSessionValidationRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.schedulesTrainingSessionValidationRetrieve(rsp)));
            }));
    }

    /**
     * Virkur HDnotandi er skilgreindur sem notandi með einkvæmt númer hjá HD sem ekki er með persónulegan aðgang að WF tengdan og hefur framkvæmt meira en 5 leitir í WF frá því þeir hófu notkun á HD appinu.
     */
    public schedulesWorldFengurCountRetrieve(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.schedulesWorldFengurCountRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.schedulesWorldFengurCountRetrieve(rsp)));
            }));
    }

}

import { SchemaApiRequestFactory, SchemaApiResponseProcessor} from "../apis/SchemaApi";
export class ObservableSchemaApi {
    private requestFactory: SchemaApiRequestFactory;
    private responseProcessor: SchemaApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SchemaApiRequestFactory,
        responseProcessor?: SchemaApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SchemaApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SchemaApiResponseProcessor();
    }

    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param format 
     * @param lang 
     */
    public schemaRetrieve(format?: 'json' | 'yaml', lang?: 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'ug' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant', _options?: Configuration): Observable<{ [key: string]: any; }> {
        const requestContextPromise = this.requestFactory.schemaRetrieve(format, lang, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.schemaRetrieve(rsp)));
            }));
    }

}

import { SocialsApiRequestFactory, SocialsApiResponseProcessor} from "../apis/SocialsApi";
export class ObservableSocialsApi {
    private requestFactory: SocialsApiRequestFactory;
    private responseProcessor: SocialsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SocialsApiRequestFactory,
        responseProcessor?: SocialsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SocialsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SocialsApiResponseProcessor();
    }

    /**
     * follow user if response.number == 2 then it was a follow request if response.number == 1 then it was a follow
     * @param followRequest 
     */
    public socialsCancelFollowRequestCreate(followRequest: FollowRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.socialsCancelFollowRequestCreate(followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsCancelFollowRequestCreate(rsp)));
            }));
    }

    /**
     * follow user if response.number == 2 then it was a follow request if response.number == 1 then it was a follow
     * @param followRequest 
     */
    public socialsFollowCreate(followRequest: FollowRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.socialsFollowCreate(followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsFollowCreate(rsp)));
            }));
    }

    /**
     * @param userFollowRequestResponseRequest 
     */
    public socialsFollowRequestResponseCreate(userFollowRequestResponseRequest: UserFollowRequestResponseRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.socialsFollowRequestResponseCreate(userFollowRequestResponseRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsFollowRequestResponseCreate(rsp)));
            }));
    }

    /**
     * @param uid 
     */
    public socialsGetFollowRequestRetrieve(uid?: string, _options?: Configuration): Observable<UserFollowRequest | any> {
        const requestContextPromise = this.requestFactory.socialsGetFollowRequestRetrieve(uid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsGetFollowRequestRetrieve(rsp)));
            }));
    }

    /**
     * Hide user from suggested follow list
     * @param followRequest 
     */
    public socialsHideSuggestedCreate(followRequest: FollowRequest, _options?: Configuration): Observable<any> {
        const requestContextPromise = this.requestFactory.socialsHideSuggestedCreate(followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsHideSuggestedCreate(rsp)));
            }));
    }

    /**
     * @param activityId 
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     * @param type 
     */
    public socialsList(activityId?: number, cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_modified' | 'dt_modified'>, pageSize?: number, type?: 'CUSTOM_TAGGED_TRAINING' | 'CUSTOM_TRAINING_SESSION' | 'INVITATION_RESPONSE' | 'MEDICAL' | 'NOTE' | 'REQUEST_RESPONSE' | 'REST_DAY' | 'SHOEING' | 'TAGGED_MEDICAL' | 'TAGGED_SHOEING' | 'TAGGED_TRAINING' | 'TRAINING_SESSION', _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.socialsList(activityId, cursor, dateRangeAfter, dateRangeBefore, order, pageSize, type, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsList(rsp)));
            }));
    }

    /**
     */
    public socialsPendingFollowRequestsList(_options?: Configuration): Observable<Array<UserFollowRequest>> {
        const requestContextPromise = this.requestFactory.socialsPendingFollowRequestsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsPendingFollowRequestsList(rsp)));
            }));
    }

    /**
     * @param followRequest 
     */
    public socialsRemoveFromFollowersCreate(followRequest: FollowRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.socialsRemoveFromFollowersCreate(followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsRemoveFromFollowersCreate(rsp)));
            }));
    }

    /**
     * Get followers and following and requests of user_uid
     * @param userUid 
     */
    public socialsSocialInfoRetrieve(userUid: string, _options?: Configuration): Observable<FollowingFollowers> {
        const requestContextPromise = this.requestFactory.socialsSocialInfoRetrieve(userUid, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsSocialInfoRetrieve(rsp)));
            }));
    }

    /**
     * @param all 
     */
    public socialsSuggestedFollowRetrieve(all?: boolean, _options?: Configuration): Observable<Suggested> {
        const requestContextPromise = this.requestFactory.socialsSuggestedFollowRetrieve(all, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsSuggestedFollowRetrieve(rsp)));
            }));
    }

    /**
     * unfollow user
     * @param followRequest 
     */
    public socialsUnfollowCreate(followRequest: FollowRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.socialsUnfollowCreate(followRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.socialsUnfollowCreate(rsp)));
            }));
    }

}

import { SportfengurApiRequestFactory, SportfengurApiResponseProcessor} from "../apis/SportfengurApi";
export class ObservableSportfengurApi {
    private requestFactory: SportfengurApiRequestFactory;
    private responseProcessor: SportfengurApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SportfengurApiRequestFactory,
        responseProcessor?: SportfengurApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SportfengurApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SportfengurApiResponseProcessor();
    }

    /**
     * if not following, return 404 else return
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurEventFollowInfoRetrieve(motsnumer: string, _options?: Configuration): Observable<FollowEventFlokkur> {
        const requestContextPromise = this.requestFactory.sportfengurEventFollowInfoRetrieve(motsnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurEventFollowInfoRetrieve(rsp)));
            }));
    }

    /**
     * @param sportFengurEventFollowRequest 
     */
    public sportfengurFollowEventCreate(sportFengurEventFollowRequest: SportFengurEventFollowRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.sportfengurFollowEventCreate(sportFengurEventFollowRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurFollowEventCreate(rsp)));
            }));
    }

    /**
     *  flokkar_numer in category flokkar_numer is unique to flokkur/g if None then follow whole event.
     * @param followEventFlokkurRequest 
     */
    public sportfengurFollowEventFlokkurCreate(followEventFlokkurRequest: FollowEventFlokkurRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.sportfengurFollowEventFlokkurCreate(followEventFlokkurRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurFollowEventFlokkurCreate(rsp)));
            }));
    }

    /**
     * @param onlyFuture Only fetch future events
     * @param year Only fetch future events
     */
    public sportfengurFollowedEventsList(onlyFuture?: boolean, year?: number, _options?: Configuration): Observable<Array<SportFengurEvent>> {
        const requestContextPromise = this.requestFactory.sportfengurFollowedEventsList(onlyFuture, year, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurFollowedEventsList(rsp)));
            }));
    }

    /**
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurGetFollowersForEventList(motsnumer: string, _options?: Configuration): Observable<Array<SimpleUser>> {
        const requestContextPromise = this.requestFactory.sportfengurGetFollowersForEventList(motsnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurGetFollowersForEventList(rsp)));
            }));
    }

    /**
     * @param motsnumer A unique value identifying this sport fengur event.
     */
    public sportfengurRetrieve(motsnumer: string, _options?: Configuration): Observable<SportFengurEvent> {
        const requestContextPromise = this.requestFactory.sportfengurRetrieve(motsnumer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurRetrieve(rsp)));
            }));
    }

    /**
     * @param faedingarnumers 
     * @param n 
     * @param order 
     */
    public sportfengurTopHorsesList(faedingarnumers?: string, n?: number, order?: string, _options?: Configuration): Observable<Array<Horse>> {
        const requestContextPromise = this.requestFactory.sportfengurTopHorsesList(faedingarnumers, n, order, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurTopHorsesList(rsp)));
            }));
    }

    /**
     * number is flokkar numer if number is None, then unfollow whole event
     * @param motsnumer A unique value identifying this sport fengur event.
     * @param numberRequest 
     */
    public sportfengurUnfollowEventCreate(motsnumer: string, numberRequest?: NumberRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.sportfengurUnfollowEventCreate(motsnumer, numberRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurUnfollowEventCreate(rsp)));
            }));
    }

}

import { SportfengurWebhooksApiRequestFactory, SportfengurWebhooksApiResponseProcessor} from "../apis/SportfengurWebhooksApi";
export class ObservableSportfengurWebhooksApi {
    private requestFactory: SportfengurWebhooksApiRequestFactory;
    private responseProcessor: SportfengurWebhooksApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SportfengurWebhooksApiRequestFactory,
        responseProcessor?: SportfengurWebhooksApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SportfengurWebhooksApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SportfengurWebhooksApiResponseProcessor();
    }

    /**
     * When an event gets new results
     * @param webhookDomarRequest 
     */
    public sportfengurWebhooksDomarCreate(webhookDomarRequest: WebhookDomarRequest, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.sportfengurWebhooksDomarCreate(webhookDomarRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurWebhooksDomarCreate(rsp)));
            }));
    }

    /**
     * When an event details is updated or when an event is created
     * @param webhookEventRequest 
     */
    public sportfengurWebhooksEventCreate(webhookEventRequest: WebhookEventRequest, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.sportfengurWebhooksEventCreate(webhookEventRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurWebhooksEventCreate(rsp)));
            }));
    }

    /**
     * When event participants have been published
     * @param webhookEventParticipantRequest 
     */
    public sportfengurWebhooksParticipantsCreate(webhookEventParticipantRequest: WebhookEventParticipantRequest, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.sportfengurWebhooksParticipantsCreate(webhookEventParticipantRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurWebhooksParticipantsCreate(rsp)));
            }));
    }

    /**
     * When the event program have been published
     * @param webhookEventProgramRequest 
     */
    public sportfengurWebhooksProgramCreate(webhookEventProgramRequest: WebhookEventProgramRequest, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.sportfengurWebhooksProgramCreate(webhookEventProgramRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurWebhooksProgramCreate(rsp)));
            }));
    }

    /**
     * When the startinglinup of and event has been published
     * @param webhookRaslistiRequest 
     */
    public sportfengurWebhooksRaslistiCreate(webhookRaslistiRequest: WebhookRaslistiRequest, _options?: Configuration): Observable<string> {
        const requestContextPromise = this.requestFactory.sportfengurWebhooksRaslistiCreate(webhookRaslistiRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sportfengurWebhooksRaslistiCreate(rsp)));
            }));
    }

}

import { StripeApiRequestFactory, StripeApiResponseProcessor} from "../apis/StripeApi";
export class ObservableStripeApi {
    private requestFactory: StripeApiRequestFactory;
    private responseProcessor: StripeApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: StripeApiRequestFactory,
        responseProcessor?: StripeApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new StripeApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new StripeApiResponseProcessor();
    }

    /**
     * Two scenarios - buying for self - buying a giftcard
     * @param stripeCheckoutSessionCreateRequest 
     */
    public stripeCheckoutSessionCreate(stripeCheckoutSessionCreateRequest: StripeCheckoutSessionCreateRequest, _options?: Configuration): Observable<StripeCheckoutSession> {
        const requestContextPromise = this.requestFactory.stripeCheckoutSessionCreate(stripeCheckoutSessionCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeCheckoutSessionCreate(rsp)));
            }));
    }

    /**
     */
    public stripeGetRevenuecatSubscriptionRetrieve(_options?: Configuration): Observable<RevenucatSubscription> {
        const requestContextPromise = this.requestFactory.stripeGetRevenuecatSubscriptionRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeGetRevenuecatSubscriptionRetrieve(rsp)));
            }));
    }

    /**
     */
    public stripeMyBoughtGiftcardsList(_options?: Configuration): Observable<Array<StripeCoupon>> {
        const requestContextPromise = this.requestFactory.stripeMyBoughtGiftcardsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeMyBoughtGiftcardsList(rsp)));
            }));
    }

    /**
     * @param currency 
     */
    public stripePricesAndProductsRetrieve(currency?: string, _options?: Configuration): Observable<StripePricesAndProducts> {
        const requestContextPromise = this.requestFactory.stripePricesAndProductsRetrieve(currency, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripePricesAndProductsRetrieve(rsp)));
            }));
    }

    /**
     */
    public stripePricesRetrieve(_options?: Configuration): Observable<StripePrice> {
        const requestContextPromise = this.requestFactory.stripePricesRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripePricesRetrieve(rsp)));
            }));
    }

    /**
     */
    public stripeProductsRetrieve(_options?: Configuration): Observable<StripeProduct> {
        const requestContextPromise = this.requestFactory.stripeProductsRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeProductsRetrieve(rsp)));
            }));
    }

    /**
     * @param stripeCheckoutSessionCreateRequest 
     */
    public stripeRedeemGiftcardCreate(stripeCheckoutSessionCreateRequest: StripeCheckoutSessionCreateRequest, _options?: Configuration): Observable<StripeCheckoutSession> {
        const requestContextPromise = this.requestFactory.stripeRedeemGiftcardCreate(stripeCheckoutSessionCreateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeRedeemGiftcardCreate(rsp)));
            }));
    }

    /**
     */
    public stripeSentToMeGiftcardsList(_options?: Configuration): Observable<Array<StripeCoupon>> {
        const requestContextPromise = this.requestFactory.stripeSentToMeGiftcardsList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeSentToMeGiftcardsList(rsp)));
            }));
    }

    /**
     */
    public stripeUnsubscribeCreate(_options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.stripeUnsubscribeCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.stripeUnsubscribeCreate(rsp)));
            }));
    }

}

import { SurveyApiRequestFactory, SurveyApiResponseProcessor} from "../apis/SurveyApi";
export class ObservableSurveyApi {
    private requestFactory: SurveyApiRequestFactory;
    private responseProcessor: SurveyApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SurveyApiRequestFactory,
        responseProcessor?: SurveyApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SurveyApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SurveyApiResponseProcessor();
    }

    /**
     * @param id A unique integer value identifying this user survey.
     * @param answerUserSurveyRequest 
     * @param surveyFinished if last question
     */
    public surveyAnswerSurveyCreate(id: number, answerUserSurveyRequest: AnswerUserSurveyRequest, surveyFinished?: boolean, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.surveyAnswerSurveyCreate(id, answerUserSurveyRequest, surveyFinished, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.surveyAnswerSurveyCreate(rsp)));
            }));
    }

    /**
     */
    public surveyUnansweredSurveyRetrieve(_options?: Configuration): Observable<PartialUserSurvey> {
        const requestContextPromise = this.requestFactory.surveyUnansweredSurveyRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.surveyUnansweredSurveyRetrieve(rsp)));
            }));
    }

}

import { TracksApiRequestFactory, TracksApiResponseProcessor} from "../apis/TracksApi";
export class ObservableTracksApi {
    private requestFactory: TracksApiRequestFactory;
    private responseProcessor: TracksApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TracksApiRequestFactory,
        responseProcessor?: TracksApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TracksApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TracksApiResponseProcessor();
    }

    /**
     * @param userUid 
     * @param cursor The pagination cursor value.
     * @param dateRangeAfter 
     * @param dateRangeBefore 
     * @param order Ordering
     * @param pageSize Number of results to return per page.
     */
    public tracksList(userUid: string, cursor?: string, dateRangeAfter?: string, dateRangeBefore?: string, order?: Array<'-dt_performed' | 'dt_performed'>, pageSize?: number, _options?: Configuration): Observable<PaginatedHorseActivityList> {
        const requestContextPromise = this.requestFactory.tracksList(userUid, cursor, dateRangeAfter, dateRangeBefore, order, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.tracksList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this horse activity.
     */
    public tracksTrackingPointsList(id: number, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.tracksTrackingPointsList(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.tracksTrackingPointsList(rsp)));
            }));
    }

}

import { TrainingSessionsApiRequestFactory, TrainingSessionsApiResponseProcessor} from "../apis/TrainingSessionsApi";
export class ObservableTrainingSessionsApi {
    private requestFactory: TrainingSessionsApiRequestFactory;
    private responseProcessor: TrainingSessionsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: TrainingSessionsApiRequestFactory,
        responseProcessor?: TrainingSessionsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new TrainingSessionsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new TrainingSessionsApiResponseProcessor();
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trackingPointCorrectionRequest 
     */
    public trainingSessionsCorrectGaitCreate(activity: number, trackingPointCorrectionRequest: TrackingPointCorrectionRequest, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.trainingSessionsCorrectGaitCreate(activity, trackingPointCorrectionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsCorrectGaitCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitRatingRequest 
     */
    public trainingSessionsGaitRatingCreate(activity: number, gaitRatingRequest?: GaitRatingRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.trainingSessionsGaitRatingCreate(activity, gaitRatingRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsGaitRatingCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param gaitSensorRequest 
     */
    public trainingSessionsGaitSensorsCreate(activity: number, gaitSensorRequest: Array<GaitSensorRequest>, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.trainingSessionsGaitSensorsCreate(activity, gaitSensorRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsGaitSensorsCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsGetGaitRatingRetrieve(activity: number, _options?: Configuration): Observable<GaitRating> {
        const requestContextPromise = this.requestFactory.trainingSessionsGetGaitRatingRetrieve(activity, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsGetGaitRatingRetrieve(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsGetTrackingPointsList(activity: number, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.trainingSessionsGetTrackingPointsList(activity, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsGetTrackingPointsList(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activityHorse 
     * @param isIndoors 
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     */
    public trainingSessionsList(activityHorse?: string, isIndoors?: boolean, page?: number, pageSize?: number, _options?: Configuration): Observable<PaginatedTrainingSessionList> {
        const requestContextPromise = this.requestFactory.trainingSessionsList(activityHorse, isIndoors, page, pageSize, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsList(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param patchedTrainingSessionRequest 
     */
    public trainingSessionsPartialUpdate(activity: number, patchedTrainingSessionRequest?: PatchedTrainingSessionRequest, _options?: Configuration): Observable<TrainingSession> {
        const requestContextPromise = this.requestFactory.trainingSessionsPartialUpdate(activity, patchedTrainingSessionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsPartialUpdate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     */
    public trainingSessionsRetrieve(activity: number, _options?: Configuration): Observable<TrainingSession> {
        const requestContextPromise = this.requestFactory.trainingSessionsRetrieve(activity, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsRetrieve(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionTrackingPointRequest 
     * @param requestId 
     * @param totalNumberOfPoints 
     */
    public trainingSessionsSaveCurrentTrackingWithTaskCreate(activity: number, trainingSessionTrackingPointRequest: Array<TrainingSessionTrackingPointRequest>, requestId?: string, totalNumberOfPoints?: number, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.trainingSessionsSaveCurrentTrackingWithTaskCreate(activity, trainingSessionTrackingPointRequest, requestId, totalNumberOfPoints, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsSaveCurrentTrackingWithTaskCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param startEndRequest 
     */
    public trainingSessionsSetStartEndOfTrackingCreate(activity: number, startEndRequest: StartEndRequest, _options?: Configuration): Observable<HorseActivity> {
        const requestContextPromise = this.requestFactory.trainingSessionsSetStartEndOfTrackingCreate(activity, startEndRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsSetStartEndOfTrackingCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param faedingarnumerSerilizerRequest 
     */
    public trainingSessionsStartTrackingCreate(faedingarnumerSerilizerRequest: FaedingarnumerSerilizerRequest, _options?: Configuration): Observable<HorseActivity> {
        const requestContextPromise = this.requestFactory.trainingSessionsStartTrackingCreate(faedingarnumerSerilizerRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsStartTrackingCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param simpleBooleanRequest 
     */
    public trainingSessionsToggleSafetyTrackingCreate(simpleBooleanRequest: SimpleBooleanRequest, _options?: Configuration): Observable<SimpleMessage> {
        const requestContextPromise = this.requestFactory.trainingSessionsToggleSafetyTrackingCreate(simpleBooleanRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsToggleSafetyTrackingCreate(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param activityHorse 
     * @param isIndoors 
     */
    public trainingSessionsTrackingList(activity: number, activityHorse?: string, isIndoors?: boolean, _options?: Configuration): Observable<Array<TrainingSessionTrackingPoint>> {
        const requestContextPromise = this.requestFactory.trainingSessionsTrackingList(activity, activityHorse, isIndoors, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsTrackingList(rsp)));
            }));
    }

    /**
     * Avoid having to define the same `get_queryset` on multiple ViewSets.
     * @param activity 
     * @param trainingSessionRequest 
     */
    public trainingSessionsUpdate(activity: number, trainingSessionRequest?: TrainingSessionRequest, _options?: Configuration): Observable<TrainingSession> {
        const requestContextPromise = this.requestFactory.trainingSessionsUpdate(activity, trainingSessionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.trainingSessionsUpdate(rsp)));
            }));
    }

}

import { UserApiRequestFactory, UserApiResponseProcessor} from "../apis/UserApi";
export class ObservableUserApi {
    private requestFactory: UserApiRequestFactory;
    private responseProcessor: UserApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: UserApiRequestFactory,
        responseProcessor?: UserApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new UserApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new UserApiResponseProcessor();
    }

    /**
     */
    public userDestroy(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.userDestroy(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userDestroy(rsp)));
            }));
    }

    /**
     * GET or POST the locale of the logged in user --- response: - locale: 'is', 'en' or 'de'
     */
    public userDisconnectWfPartialUpdate(_options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.userDisconnectWfPartialUpdate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userDisconnectWfPartialUpdate(rsp)));
            }));
    }

    /**
     */
    public userMostLikelyStablePositionRetrieve(_options?: Configuration): Observable<Point> {
        const requestContextPromise = this.requestFactory.userMostLikelyStablePositionRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userMostLikelyStablePositionRetrieve(rsp)));
            }));
    }

    /**
     * @param patchedUserUpdateRequest 
     */
    public userPartialUpdate(patchedUserUpdateRequest?: PatchedUserUpdateRequest, _options?: Configuration): Observable<User> {
        const requestContextPromise = this.requestFactory.userPartialUpdate(patchedUserUpdateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userPartialUpdate(rsp)));
            }));
    }

    /**
     */
    public userRetrieve(_options?: Configuration): Observable<User | void> {
        const requestContextPromise = this.requestFactory.userRetrieve(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userRetrieve(rsp)));
            }));
    }

}

import { UserActivityReactionsApiRequestFactory, UserActivityReactionsApiResponseProcessor} from "../apis/UserActivityReactionsApi";
export class ObservableUserActivityReactionsApi {
    private requestFactory: UserActivityReactionsApiRequestFactory;
    private responseProcessor: UserActivityReactionsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: UserActivityReactionsApiRequestFactory,
        responseProcessor?: UserActivityReactionsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new UserActivityReactionsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new UserActivityReactionsApiResponseProcessor();
    }

    /**
     * @param activityReactionRequest 
     */
    public userActivityReactionsCreate(activityReactionRequest: ActivityReactionRequest, _options?: Configuration): Observable<ActivityReaction> {
        const requestContextPromise = this.requestFactory.userActivityReactionsCreate(activityReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsCreate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this reaction.
     */
    public userActivityReactionsDestroy(id: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.userActivityReactionsDestroy(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsDestroy(rsp)));
            }));
    }

    /**
     * @param activity 
     * @param order Ordering
     * @param page A page number within the paginated result set.
     * @param user 
     */
    public userActivityReactionsList(activity?: number, order?: Array<'-dt_created' | '-dt_modified' | 'dt_created' | 'dt_modified'>, page?: number, user?: number, _options?: Configuration): Observable<PaginatedActivityReactionList> {
        const requestContextPromise = this.requestFactory.userActivityReactionsList(activity, order, page, user, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsList(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this reaction.
     * @param patchedActivityReactionRequest 
     */
    public userActivityReactionsPartialUpdate(id: number, patchedActivityReactionRequest?: PatchedActivityReactionRequest, _options?: Configuration): Observable<ActivityReaction> {
        const requestContextPromise = this.requestFactory.userActivityReactionsPartialUpdate(id, patchedActivityReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this reaction.
     */
    public userActivityReactionsRetrieve(id: number, _options?: Configuration): Observable<ActivityReaction> {
        const requestContextPromise = this.requestFactory.userActivityReactionsRetrieve(id, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsRetrieve(rsp)));
            }));
    }

    /**
     * @param id A unique integer value identifying this reaction.
     * @param activityReactionRequest 
     */
    public userActivityReactionsUpdate(id: number, activityReactionRequest: ActivityReactionRequest, _options?: Configuration): Observable<ActivityReaction> {
        const requestContextPromise = this.requestFactory.userActivityReactionsUpdate(id, activityReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userActivityReactionsUpdate(rsp)));
            }));
    }

}

import { UserCommentReactionsApiRequestFactory, UserCommentReactionsApiResponseProcessor} from "../apis/UserCommentReactionsApi";
export class ObservableUserCommentReactionsApi {
    private requestFactory: UserCommentReactionsApiRequestFactory;
    private responseProcessor: UserCommentReactionsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: UserCommentReactionsApiRequestFactory,
        responseProcessor?: UserCommentReactionsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new UserCommentReactionsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new UserCommentReactionsApiResponseProcessor();
    }

    /**
     * @param commentReactionRequest 
     */
    public userCommentReactionsCreate(commentReactionRequest: CommentReactionRequest, _options?: Configuration): Observable<CommentReaction> {
        const requestContextPromise = this.requestFactory.userCommentReactionsCreate(commentReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsCreate(rsp)));
            }));
    }

    /**
     * @param comment 
     */
    public userCommentReactionsDestroy(comment: number, _options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.userCommentReactionsDestroy(comment, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsDestroy(rsp)));
            }));
    }

    /**
     * @param page A page number within the paginated result set.
     */
    public userCommentReactionsList(page?: number, _options?: Configuration): Observable<PaginatedCommentReactionList> {
        const requestContextPromise = this.requestFactory.userCommentReactionsList(page, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsList(rsp)));
            }));
    }

    /**
     * @param comment 
     * @param patchedCommentReactionRequest 
     */
    public userCommentReactionsPartialUpdate(comment: number, patchedCommentReactionRequest?: PatchedCommentReactionRequest, _options?: Configuration): Observable<CommentReaction> {
        const requestContextPromise = this.requestFactory.userCommentReactionsPartialUpdate(comment, patchedCommentReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsPartialUpdate(rsp)));
            }));
    }

    /**
     * @param comment 
     */
    public userCommentReactionsRetrieve(comment: number, _options?: Configuration): Observable<CommentReaction> {
        const requestContextPromise = this.requestFactory.userCommentReactionsRetrieve(comment, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsRetrieve(rsp)));
            }));
    }

    /**
     * @param comment 
     * @param commentReactionRequest 
     */
    public userCommentReactionsUpdate(comment: number, commentReactionRequest: CommentReactionRequest, _options?: Configuration): Observable<CommentReaction> {
        const requestContextPromise = this.requestFactory.userCommentReactionsUpdate(comment, commentReactionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.userCommentReactionsUpdate(rsp)));
            }));
    }

}

import { WebhookApiRequestFactory, WebhookApiResponseProcessor} from "../apis/WebhookApi";
export class ObservableWebhookApi {
    private requestFactory: WebhookApiRequestFactory;
    private responseProcessor: WebhookApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: WebhookApiRequestFactory,
        responseProcessor?: WebhookApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new WebhookApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new WebhookApiResponseProcessor();
    }

    /**
     */
    public webhookRevenuecatCreate(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.webhookRevenuecatCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.webhookRevenuecatCreate(rsp)));
            }));
    }

    /**
     */
    public webhookStripeCreate(_options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.webhookStripeCreate(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.webhookStripeCreate(rsp)));
            }));
    }

}

import { WfApiRequestFactory, WfApiResponseProcessor} from "../apis/WfApi";
export class ObservableWfApi {
    private requestFactory: WfApiRequestFactory;
    private responseProcessor: WfApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: WfApiRequestFactory,
        responseProcessor?: WfApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new WfApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new WfApiResponseProcessor();
    }

    /**
     */
    public wfLandsmotYfirlitList(_options?: Configuration): Observable<Array<LandsmotJudgeOverview>> {
        const requestContextPromise = this.requestFactory.wfLandsmotYfirlitList(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.wfLandsmotYfirlitList(rsp)));
            }));
    }

}

import { WorldfengurWebhooksApiRequestFactory, WorldfengurWebhooksApiResponseProcessor} from "../apis/WorldfengurWebhooksApi";
export class ObservableWorldfengurWebhooksApi {
    private requestFactory: WorldfengurWebhooksApiRequestFactory;
    private responseProcessor: WorldfengurWebhooksApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: WorldfengurWebhooksApiRequestFactory,
        responseProcessor?: WorldfengurWebhooksApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new WorldfengurWebhooksApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new WorldfengurWebhooksApiResponseProcessor();
    }

    /**
     * @param wFWebookHorseRemoveRequest 
     */
    public worldfengurWebhooksHorseRemoveCreate(wFWebookHorseRemoveRequest: WFWebookHorseRemoveRequest, _options?: Configuration): Observable<Array<SimpleMessage>> {
        const requestContextPromise = this.requestFactory.worldfengurWebhooksHorseRemoveCreate(wFWebookHorseRemoveRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.worldfengurWebhooksHorseRemoveCreate(rsp)));
            }));
    }

    /**
     * @param wFWebookHorseUpdateRequest 
     */
    public worldfengurWebhooksHorseUpdateCreate(wFWebookHorseUpdateRequest: WFWebookHorseUpdateRequest, _options?: Configuration): Observable<Array<SimpleMessage>> {
        const requestContextPromise = this.requestFactory.worldfengurWebhooksHorseUpdateCreate(wFWebookHorseUpdateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.worldfengurWebhooksHorseUpdateCreate(rsp)));
            }));
    }

    /**
     * @param wFWebookNewJudgementRequest 
     */
    public worldfengurWebhooksJudgementCreate(wFWebookNewJudgementRequest: WFWebookNewJudgementRequest, _options?: Configuration): Observable<Array<SimpleMessage>> {
        const requestContextPromise = this.requestFactory.worldfengurWebhooksJudgementCreate(wFWebookNewJudgementRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.worldfengurWebhooksJudgementCreate(rsp)));
            }));
    }

    /**
     * @param wFWebookOffspringUpdateRequest 
     */
    public worldfengurWebhooksOffspringUpdateCreate(wFWebookOffspringUpdateRequest: WFWebookOffspringUpdateRequest, _options?: Configuration): Observable<Array<SimpleMessage>> {
        const requestContextPromise = this.requestFactory.worldfengurWebhooksOffspringUpdateCreate(wFWebookOffspringUpdateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.worldfengurWebhooksOffspringUpdateCreate(rsp)));
            }));
    }

}
