/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ChatUser } from '../models/ChatUser';
import { SimpleHorse } from '../models/SimpleHorse';
import { HttpFile } from '../http/http';

export class ChatRoom {
    'id': number;
    'users': Array<ChatUser>;
    'dtLatestMessage'?: Date;
    'name'?: string;
    'latestMessage'?: string;
    'token'?: string;
    'dtTokenCreated'?: Date;
    'horse'?: SimpleHorse;
    'isSaleChat'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "users",
            "baseName": "users",
            "type": "Array<ChatUser>",
            "format": ""
        },
        {
            "name": "dtLatestMessage",
            "baseName": "dtLatestMessage",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "latestMessage",
            "baseName": "latestMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "token",
            "baseName": "token",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "dtTokenCreated",
            "baseName": "dtTokenCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "isSaleChat",
            "baseName": "isSaleChat",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ChatRoom.attributeTypeMap;
    }

    public constructor() {
    }
}

