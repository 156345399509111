/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseUserRoleEnum } from '../models/HorseUserRoleEnum';
import { RequestStatus } from '../models/RequestStatus';
import { SimpleHorse } from '../models/SimpleHorse';
import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class HorseAccessReq {
    'id': number;
    'horse': SimpleHorse;
    'user': SimpleUser;
    'role'?: HorseUserRoleEnum;
    'message'?: string;
    'isTrainer'?: boolean;
    'status': RequestStatus;
    'dtRequested': Date;
    'dtProcessed'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "SimpleHorse",
            "format": ""
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "HorseUserRoleEnum",
            "format": ""
        },
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "isTrainer",
            "baseName": "isTrainer",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "RequestStatus",
            "format": ""
        },
        {
            "name": "dtRequested",
            "baseName": "dtRequested",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtProcessed",
            "baseName": "dtProcessed",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return HorseAccessReq.attributeTypeMap;
    }

    public constructor() {
    }
}

