/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomTrainingSessionCreateRequest } from '../models/CustomTrainingSessionCreateRequest';
import { HorseActivityLocation } from '../models/HorseActivityLocation';
import { MedicalRequest } from '../models/MedicalRequest';
import { ShoeingRequest } from '../models/ShoeingRequest';
import { TrainingSessionCreateRequest } from '../models/TrainingSessionCreateRequest';
import { VisibilityEnum } from '../models/VisibilityEnum';
import { HttpFile } from '../http/http';

export class HorseActivityUpdateRequest {
    'dtPerformed'?: Date;
    'isDraft'?: boolean;
    'horse'?: string;
    'actor'?: number;
    'data'?: { [key: string]: any; };
    'location'?: HorseActivityLocation;
    'title'?: string;
    'text'?: string;
    'trainingSession'?: TrainingSessionCreateRequest;
    'customTrainingSession'?: CustomTrainingSessionCreateRequest;
    'shoeing'?: ShoeingRequest;
    'medical'?: MedicalRequest;
    'photos'?: Array<number>;
    'videos'?: Array<number>;
    'visibility'?: VisibilityEnum;
    'isPlanned'?: boolean;
    'hasFinishedPlanned'?: boolean;
    'plannedWithTime'?: boolean;
    'group'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "dtPerformed",
            "baseName": "dtPerformed",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isDraft",
            "baseName": "isDraft",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "string",
            "format": ""
        },
        {
            "name": "actor",
            "baseName": "actor",
            "type": "number",
            "format": ""
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "{ [key: string]: any; }",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseActivityLocation",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "text",
            "baseName": "text",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainingSession",
            "baseName": "trainingSession",
            "type": "TrainingSessionCreateRequest",
            "format": ""
        },
        {
            "name": "customTrainingSession",
            "baseName": "customTrainingSession",
            "type": "CustomTrainingSessionCreateRequest",
            "format": ""
        },
        {
            "name": "shoeing",
            "baseName": "shoeing",
            "type": "ShoeingRequest",
            "format": ""
        },
        {
            "name": "medical",
            "baseName": "medical",
            "type": "MedicalRequest",
            "format": ""
        },
        {
            "name": "photos",
            "baseName": "photos",
            "type": "Array<number>",
            "format": ""
        },
        {
            "name": "videos",
            "baseName": "videos",
            "type": "Array<number>",
            "format": ""
        },
        {
            "name": "visibility",
            "baseName": "visibility",
            "type": "VisibilityEnum",
            "format": ""
        },
        {
            "name": "isPlanned",
            "baseName": "isPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hasFinishedPlanned",
            "baseName": "hasFinishedPlanned",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "plannedWithTime",
            "baseName": "plannedWithTime",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "group",
            "baseName": "group",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseActivityUpdateRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



