/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { TranscoderDataRequest } from '../models/TranscoderDataRequest';
import { HttpFile } from '../http/http';

export class HorseVideoRequest {
    'name'?: string;
    'horse': string;
    'video'?: HttpFile;
    'transcoded'?: TranscoderDataRequest;
    'thumbnail'?: HttpFile;
    'user'?: SimpleUserRequest;
    'isSaleVideo'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "horse",
            "baseName": "horse",
            "type": "string",
            "format": ""
        },
        {
            "name": "video",
            "baseName": "video",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "transcoded",
            "baseName": "transcoded",
            "type": "TranscoderDataRequest",
            "format": ""
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUserRequest",
            "format": ""
        },
        {
            "name": "isSaleVideo",
            "baseName": "isSaleVideo",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseVideoRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

