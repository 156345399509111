/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class PatchedUserUpdateRequest {
    'avatar'?: HttpFile;
    'gender'?: string;
    'country'?: string;
    'phone'?: string;
    'phoneCountryCode'?: string;
    'description'?: string;
    'role'?: string;
    'birthdate'?: string;
    'name'?: string;
    'nationalIdNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "avatar",
            "baseName": "avatar",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "gender",
            "baseName": "gender",
            "type": "string",
            "format": ""
        },
        {
            "name": "country",
            "baseName": "country",
            "type": "string",
            "format": ""
        },
        {
            "name": "phone",
            "baseName": "phone",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneCountryCode",
            "baseName": "phoneCountryCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthdate",
            "baseName": "birthdate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "nationalIdNumber",
            "baseName": "nationalIdNumber",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PatchedUserUpdateRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

