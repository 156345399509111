/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PartialSurveyQuestion } from '../models/PartialSurveyQuestion';
import { HttpFile } from '../http/http';

export class PartialSurvey {
    'name': string;
    'surveyQuestions': Array<PartialSurveyQuestion>;
    'infoText'?: string;
    'showInFeed'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "surveyQuestions",
            "baseName": "surveyQuestions",
            "type": "Array<PartialSurveyQuestion>",
            "format": ""
        },
        {
            "name": "infoText",
            "baseName": "infoText",
            "type": "string",
            "format": ""
        },
        {
            "name": "showInFeed",
            "baseName": "showInFeed",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PartialSurvey.attributeTypeMap;
    }

    public constructor() {
    }
}

