/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Feeding } from '../models/Feeding';
import { SimpleHorseActivity } from '../models/SimpleHorseActivity';
import { SimpleNote } from '../models/SimpleNote';
import { HttpFile } from '../http/http';

export class InfoSummary {
    'lastShoeing'?: SimpleHorseActivity;
    'nextShoeing'?: SimpleHorseActivity;
    'lastMedical'?: SimpleHorseActivity;
    'nextMedical'?: SimpleHorseActivity;
    'lastNote'?: SimpleNote;
    'feedingPlan'?: Feeding;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "lastShoeing",
            "baseName": "lastShoeing",
            "type": "SimpleHorseActivity",
            "format": ""
        },
        {
            "name": "nextShoeing",
            "baseName": "nextShoeing",
            "type": "SimpleHorseActivity",
            "format": ""
        },
        {
            "name": "lastMedical",
            "baseName": "lastMedical",
            "type": "SimpleHorseActivity",
            "format": ""
        },
        {
            "name": "nextMedical",
            "baseName": "nextMedical",
            "type": "SimpleHorseActivity",
            "format": ""
        },
        {
            "name": "lastNote",
            "baseName": "lastNote",
            "type": "SimpleNote",
            "format": ""
        },
        {
            "name": "feedingPlan",
            "baseName": "feedingPlan",
            "type": "Feeding",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InfoSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

