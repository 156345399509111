/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseAssessment } from '../models/HorseAssessment';
import { HorseBreedEnum } from '../models/HorseBreedEnum';
import { HorseDetails } from '../models/HorseDetails';
import { HorseForSale } from '../models/HorseForSale';
import { HorseMedicalDetails } from '../models/HorseMedicalDetails';
import { HorsePhoto } from '../models/HorsePhoto';
import { HorseSettings } from '../models/HorseSettings';
import { HorseVideo } from '../models/HorseVideo';
import { Measurments } from '../models/Measurments';
import { Owner } from '../models/Owner';
import { SexEnumEnum } from '../models/SexEnumEnum';
import { HttpFile } from '../http/http';

export class Horse {
    'faedingarnumer': string;
    'name': string;
    'description'?: string;
    'colorCode'?: string;
    'colorName'?: string;
    'birthDate': string;
    'rfid'?: Array<string>;
    'sireFaedingarnumer'?: string;
    'sireName'?: string;
    'damFaedingarnumer'?: string;
    'damName'?: string;
    'sex'?: string;
    'sexEnum'?: SexEnumEnum;
    'isAlive': boolean;
    'originCountry'?: string;
    'owners': Array<Owner>;
    'photos': Array<HorsePhoto>;
    'dtSetForSale'?: Date;
    'videos': Array<HorseVideo>;
    'skopulag'?: number;
    'haefileikar'?: number;
    'adaleinkunn'?: number;
    'haefileikarAnSkeids'?: number;
    'adaleinkunnAnSkeids'?: number;
    'assessment'?: HorseAssessment;
    'measurements'?: Measurments;
    'details'?: HorseDetails;
    'medicalDetails'?: HorseMedicalDetails;
    'haestaAdaleinkunn'?: number;
    'noOffspring'?: number;
    'settings'?: HorseSettings;
    'numFollowers'?: number;
    'forSale'?: HorseForSale;
    'isHidden'?: boolean;
    'inStable'?: boolean;
    'isVerified': boolean;
    'horseBreed'?: HorseBreedEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "faedingarnumer",
            "baseName": "faedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorCode",
            "baseName": "colorCode",
            "type": "string",
            "format": ""
        },
        {
            "name": "colorName",
            "baseName": "colorName",
            "type": "string",
            "format": ""
        },
        {
            "name": "birthDate",
            "baseName": "birthDate",
            "type": "string",
            "format": "date"
        },
        {
            "name": "rfid",
            "baseName": "rfid",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "sireFaedingarnumer",
            "baseName": "sireFaedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "sireName",
            "baseName": "sireName",
            "type": "string",
            "format": ""
        },
        {
            "name": "damFaedingarnumer",
            "baseName": "damFaedingarnumer",
            "type": "string",
            "format": ""
        },
        {
            "name": "damName",
            "baseName": "damName",
            "type": "string",
            "format": ""
        },
        {
            "name": "sex",
            "baseName": "sex",
            "type": "string",
            "format": ""
        },
        {
            "name": "sexEnum",
            "baseName": "sexEnum",
            "type": "SexEnumEnum",
            "format": ""
        },
        {
            "name": "isAlive",
            "baseName": "isAlive",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "originCountry",
            "baseName": "originCountry",
            "type": "string",
            "format": ""
        },
        {
            "name": "owners",
            "baseName": "owners",
            "type": "Array<Owner>",
            "format": ""
        },
        {
            "name": "photos",
            "baseName": "photos",
            "type": "Array<HorsePhoto>",
            "format": ""
        },
        {
            "name": "dtSetForSale",
            "baseName": "dtSetForSale",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "videos",
            "baseName": "videos",
            "type": "Array<HorseVideo>",
            "format": ""
        },
        {
            "name": "skopulag",
            "baseName": "skopulag",
            "type": "number",
            "format": ""
        },
        {
            "name": "haefileikar",
            "baseName": "haefileikar",
            "type": "number",
            "format": ""
        },
        {
            "name": "adaleinkunn",
            "baseName": "adaleinkunn",
            "type": "number",
            "format": ""
        },
        {
            "name": "haefileikarAnSkeids",
            "baseName": "haefileikarAnSkeids",
            "type": "number",
            "format": ""
        },
        {
            "name": "adaleinkunnAnSkeids",
            "baseName": "adaleinkunnAnSkeids",
            "type": "number",
            "format": ""
        },
        {
            "name": "assessment",
            "baseName": "assessment",
            "type": "HorseAssessment",
            "format": ""
        },
        {
            "name": "measurements",
            "baseName": "measurements",
            "type": "Measurments",
            "format": ""
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "HorseDetails",
            "format": ""
        },
        {
            "name": "medicalDetails",
            "baseName": "medicalDetails",
            "type": "HorseMedicalDetails",
            "format": ""
        },
        {
            "name": "haestaAdaleinkunn",
            "baseName": "haestaAdaleinkunn",
            "type": "number",
            "format": "double"
        },
        {
            "name": "noOffspring",
            "baseName": "noOffspring",
            "type": "number",
            "format": ""
        },
        {
            "name": "settings",
            "baseName": "settings",
            "type": "HorseSettings",
            "format": ""
        },
        {
            "name": "numFollowers",
            "baseName": "numFollowers",
            "type": "number",
            "format": ""
        },
        {
            "name": "forSale",
            "baseName": "forSale",
            "type": "HorseForSale",
            "format": ""
        },
        {
            "name": "isHidden",
            "baseName": "isHidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "inStable",
            "baseName": "inStable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isVerified",
            "baseName": "isVerified",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "horseBreed",
            "baseName": "horseBreed",
            "type": "HorseBreedEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Horse.attributeTypeMap;
    }

    public constructor() {
    }
}



