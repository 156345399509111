/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GroupUserRoleEnum } from '../models/GroupUserRoleEnum';
import { SimpleUserRequest } from '../models/SimpleUserRequest';
import { HttpFile } from '../http/http';

export class SimpleGroupUserRequest {
    'user': SimpleUserRequest;
    'role'?: GroupUserRoleEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUserRequest",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "GroupUserRoleEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SimpleGroupUserRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



