/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class StripeRecurring {
    'interval': string;
    'intervalCount': number;
    'trialPeriodDays': number;
    'usageType': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "interval",
            "baseName": "interval",
            "type": "string",
            "format": ""
        },
        {
            "name": "intervalCount",
            "baseName": "intervalCount",
            "type": "number",
            "format": ""
        },
        {
            "name": "trialPeriodDays",
            "baseName": "trialPeriodDays",
            "type": "number",
            "format": ""
        },
        {
            "name": "usageType",
            "baseName": "usageType",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StripeRecurring.attributeTypeMap;
    }

    public constructor() {
    }
}

