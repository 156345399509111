import { captureException, devPrint } from "@/utils/devUtils";
import {
  accountsApi,
  activitiesApi,
  chatApi,
  groupsApi,
  horsesApi,
  horsesQueryApi,
  userActivityReactionsApi,
} from "@/utils/NetworkUtils";
import { getLocalUser, saveLocalUser } from "@/utils/sharedPrefrences";
import { loadTranslations, Locales, setLocale } from "@/utils/translation";
import {
  addToActivities,
  appendAllHorsesActivitySummary,
  appendHorsesActivitySummary,
  appendUserActivitySummary,
  setActivities,
  setFetchingAllHorsesActivitySummary,
  setNextActivities,
} from "./reducers/activityReducer";
import { setChatRooms } from "./reducers/chatReducer";
import { setFetchingGroups, setGroups } from "./reducers/groupsReducer";
import {
  setLoadingSimpleHorses,
  setSalesHorses,
  setSimpleHorses,
} from "./reducers/horsesReducer";
import { setUser, setUserReactions } from "./reducers/userReducer";
import store from "./store";
import { setTrackUser } from "@/utils/analyticsFunctions";
import * as Sentry from "@sentry/node";

export const fetchMyInfo = async (): Promise<boolean> => {
  try {
    const _user = await accountsApi.accountsUserRetrieve();
    const dispatch = store.dispatch;
    dispatch(setUser(_user));
    saveLocalUser(_user);
    setTrackUser(_user);
    setLocale(_user.locale as Locales);
    loadTranslations(_user.locale as Locales);
    // Sentry.configureScope((scope) => {
    //   scope.setUser({ id: _user.id, email: _user.email, name: _user.name });
    // });
    return true;
  } catch (e) {
    captureException("error fetching my info " + e);
    return false;
  }
};

export const fetchMySimpleHorses = async () => {
  try {
    const dispatch = store.dispatch;
    dispatch(setLoadingSimpleHorses(true));
    const _horses = await horsesApi.horsesAllMySimpleHorsesList();
    dispatch(setLoadingSimpleHorses(false));

    dispatch(setSimpleHorses(_horses));
  } catch (e) {
    captureException("error fetching simple horses " + e);
  }
};
let _isFetchingSales = false;

interface SalesFilter {
  originCountry: undefined | string;
  order:
    | undefined
    | Array<
        | "-adaleinkunn"
        | "-dt_set_for_sale"
        | "-for_sale__price"
        | "-haestaAdaleinkunn"
        | "-name"
        | "adaleinkunn"
        | "dt_set_for_sale"
        | "for_sale__price"
        | "haestaAdaleinkunn"
        | "name"
      >;
}

export const fetchBreedingHorses = async (filter: SalesFilter) => {
  const page = undefined;
  var res = await horsesQueryApi.horsesQueriesList(
    undefined,

    undefined,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    filter.order,
    filter.originCountry,
    page
    // next
  );
  return res;
};

export const fetchSalesHorses = async (
  scrolling: boolean,
  filter: SalesFilter
) => {
  if (_isFetchingSales) {
    return;
  }
  _isFetchingSales = true;
  try {
    const state = store.getState();
    const next = state.horsesState.salesHorses?.next;
    let page: undefined | number;

    if (next) {
      try {
        page = parseInt(next.split("page=")[1].split("&")[0]);
      } catch (e) {
        captureException(e);
      }
    }
    if (!page && scrolling) {
      return;
    }

    var res = await horsesQueryApi.horsesQueriesList(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      undefined,
      undefined,
      filter.order,
      filter.originCountry,
      page
      // next
    );
    if (res) {
      const dispatch = store.dispatch;
      dispatch(setSalesHorses(res));
    }
  } catch (e) {
    captureException(e);
  }
  _isFetchingSales = false;
};

export const initDoAllApiCalls = () => {
  fetchMySimpleHorses();
  fetchMyReactions();
};

const fetchMyReactions = async () => {
  try {
    const state = store.getState();
    const dispatch = store.dispatch;
    var res = await userActivityReactionsApi.userActivityReactionsList(
      undefined,
      undefined,
      undefined,
      state.userState.user?.id
    );
    if (res?.results) {
      dispatch(setUserReactions(res.results));
    }
  } catch (e) {
    captureException(e);
  }
};

let _isFetchingActs = false;
export const fetchActivities = async (next?: boolean) => {
  if (_isFetchingActs) {
    return;
  }

  _isFetchingActs = true;
  const dispatch = store.dispatch;
  try {
    let nextUrl;
    if (next) {
      nextUrl = store.getState().activityState.next;
    }
    var res = await activitiesApi.activitiesList(
      undefined,
      undefined,
      undefined,
      nextUrl
    );
    if (res?.results) {
      if (next) {
        dispatch(addToActivities(res.results));
      } else {
        dispatch(setActivities(res.results));
      }
    }
    if (res.next) {
      let n = res.next.split("cursor=")[1];
      n = n.replaceAll("%3D", "=");
      dispatch(setNextActivities(n));
    }
  } catch (e: any) {
    captureException(e);
  }
  _isFetchingActs = false;
};

export const getNextFromCursor = (
  cursor: string | undefined
): string | undefined => {
  if (!cursor) {
    return undefined;
  }
  let n = cursor.split("cursor=")[1];
  n = n.replaceAll("%3D", "=");
  return n;
};

const userSummariesGotten: string[] = [];
export const fetchUserActivitySummary = async (
  month: number,
  year: number,
  refetch?: boolean
) => {
  var s = year + " " + month;
  if (userSummariesGotten.includes(s) && !refetch) {
    return;
  }
  userSummariesGotten.push(s);

  try {
    const res = await accountsApi.accountsUserMonthlyActivitySummaryList(
      month + 1,
      year
    );
    const dispatch = store.dispatch;

    dispatch(appendUserActivitySummary(res));
  } catch (e) {
    captureException(e);
  }
};

export const fetchAllGroups = async () => {
  store.dispatch(setFetchingGroups(true));
  try {
    const res = await groupsApi.groupsList();
    store.dispatch(setGroups(res));
  } catch (e) {
    captureException(e);
  }
  store.dispatch(setFetchingGroups(false));
};

const allHorsesSummariesGotten: string[] = [];
export const fetchAllHorsesActivitySummary = async (
  month: number,
  year: number,
  refetch?: boolean
) => {
  var s = year + " " + month;
  if (allHorsesSummariesGotten.includes(s) && !refetch) {
    return;
  }
  allHorsesSummariesGotten.push(s);
  const dispatch = store.dispatch;
  dispatch(setFetchingAllHorsesActivitySummary(true));
  try {
    const res = await activitiesApi.activitiesSummaryAllHorsesList(
      month + 1,
      year
    );

    dispatch(setFetchingAllHorsesActivitySummary(false));

    dispatch(appendAllHorsesActivitySummary(res));
  } catch (e) {
    captureException(e);
  }
};

const horsesSummariesGotten: string[] = [];
export const fetchHorsesActivitySummary = async (
  month: number,
  year: number,
  faedingarnumer: string,
  refetch?: boolean
) => {
  var s = year + " " + month + " " + faedingarnumer;
  if (horsesSummariesGotten.includes(s) && !refetch) {
    return;
  }
  horsesSummariesGotten.push(s);

  try {
    const res = await activitiesApi.activitiesSummarySingleHorseRetrieve(
      faedingarnumer,

      month + 1,
      year
    );
    const dispatch = store.dispatch;

    dispatch(
      appendHorsesActivitySummary({
        activities: res.activities,
        faedingarnumer,
      })
    );
  } catch (e) {
    captureException(e);
  }
};

let _isFetchingChatRooms = false;
let _didFetch = false;

export const fetchChatRooms = async () => {
  if (_isFetchingChatRooms) {
    return;
  }
  const state = store.getState();
  const dispatch = store.dispatch;
  _isFetchingChatRooms = true;
  let next: undefined | string = undefined;

  if (state.chatState.chatRooms?.next) {
    next = state.chatState.chatRooms?.next.split("cursor=")[1];
  } else if (_didFetch) {
    return;
  }
  try {
    const res = await chatApi.chatList(next);
    _didFetch = true;
    if (res) {
      dispatch(setChatRooms(res));
    }
  } catch (e) {
    captureException(e);
  }
  _isFetchingChatRooms = false;
};
