/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { SimpleUser } from '../models/SimpleUser';
import { SimpleUserInfo } from '../models/SimpleUserInfo';
import { HttpFile } from '../http/http';

export class HorseDetails {
    'locationName'?: string;
    'location'?: HorseDetailsLocation;
    'isFiveGated'?: boolean;
    'description'?: string;
    'availableForBreeding'?: boolean;
    'dateInfoUpdated': string;
    'lastUpdatedBy': SimpleUser;
    'vet'?: string;
    'farrier'?: string;
    'trainer'?: string;
    'emergancyContact'?: SimpleUserInfo;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "locationName",
            "baseName": "locationName",
            "type": "string",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseDetailsLocation",
            "format": ""
        },
        {
            "name": "isFiveGated",
            "baseName": "isFiveGated",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "availableForBreeding",
            "baseName": "availableForBreeding",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dateInfoUpdated",
            "baseName": "dateInfoUpdated",
            "type": "string",
            "format": "date"
        },
        {
            "name": "lastUpdatedBy",
            "baseName": "lastUpdatedBy",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "vet",
            "baseName": "vet",
            "type": "string",
            "format": ""
        },
        {
            "name": "farrier",
            "baseName": "farrier",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainer",
            "baseName": "trainer",
            "type": "string",
            "format": ""
        },
        {
            "name": "emergancyContact",
            "baseName": "emergancyContact",
            "type": "SimpleUserInfo",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseDetails.attributeTypeMap;
    }

    public constructor() {
    }
}

