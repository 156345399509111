/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MessageTypeEnum } from '../models/MessageTypeEnum';
import { PlatformEnum } from '../models/PlatformEnum';
import { HttpFile } from '../http/http';

export class HorseDayMessage {
    'id': number;
    'title': string;
    'body': string;
    'dtCreated': Date;
    'thumbnail'?: string;
    'messageType'?: MessageTypeEnum;
    'platform'?: PlatformEnum;
    'deepLink'?: string;
    'seen'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "body",
            "baseName": "body",
            "type": "string",
            "format": ""
        },
        {
            "name": "dtCreated",
            "baseName": "dtCreated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "thumbnail",
            "baseName": "thumbnail",
            "type": "string",
            "format": ""
        },
        {
            "name": "messageType",
            "baseName": "messageType",
            "type": "MessageTypeEnum",
            "format": ""
        },
        {
            "name": "platform",
            "baseName": "platform",
            "type": "PlatformEnum",
            "format": ""
        },
        {
            "name": "deepLink",
            "baseName": "deepLink",
            "type": "string",
            "format": ""
        },
        {
            "name": "seen",
            "baseName": "seen",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseDayMessage.attributeTypeMap;
    }

    public constructor() {
    }
}



