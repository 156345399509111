/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HorseDetailsLocation } from '../models/HorseDetailsLocation';
import { SimpleUserInfoRequest } from '../models/SimpleUserInfoRequest';
import { HttpFile } from '../http/http';

export class HorseDetailsRequest {
    'locationName'?: string;
    'location'?: HorseDetailsLocation;
    'isFiveGated'?: boolean;
    'description'?: string;
    'availableForBreeding'?: boolean;
    'vet'?: string;
    'farrier'?: string;
    'trainer'?: string;
    'emergancyContact'?: SimpleUserInfoRequest;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "locationName",
            "baseName": "locationName",
            "type": "string",
            "format": ""
        },
        {
            "name": "location",
            "baseName": "location",
            "type": "HorseDetailsLocation",
            "format": ""
        },
        {
            "name": "isFiveGated",
            "baseName": "isFiveGated",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "availableForBreeding",
            "baseName": "availableForBreeding",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "vet",
            "baseName": "vet",
            "type": "string",
            "format": ""
        },
        {
            "name": "farrier",
            "baseName": "farrier",
            "type": "string",
            "format": ""
        },
        {
            "name": "trainer",
            "baseName": "trainer",
            "type": "string",
            "format": ""
        },
        {
            "name": "emergancyContact",
            "baseName": "emergancyContact",
            "type": "SimpleUserInfoRequest",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return HorseDetailsRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

