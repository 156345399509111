/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SafetyTrackingViewer } from '../models/SafetyTrackingViewer';
import { SimpleUser } from '../models/SimpleUser';
import { HttpFile } from '../http/http';

export class SafetyTrackingTeam {
    'user': SimpleUser;
    'safetyTrackingOn'?: boolean;
    'isCurrentlyTracking'?: boolean;
    'viewers': Array<SafetyTrackingViewer>;
    'dtLastMove'?: Date;
    'isMoving'?: boolean;
    'userDismissedSafety'?: boolean;
    'notifyTeamTime'?: number;
    'notifyUserTime'?: number;
    'didNotifyUser'?: boolean;
    'didNotifyViewers'?: boolean;
    'dtStartedTracking'?: Date;
    'dtLatestPoint'?: Date;
    'token'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "user",
            "baseName": "user",
            "type": "SimpleUser",
            "format": ""
        },
        {
            "name": "safetyTrackingOn",
            "baseName": "safetyTrackingOn",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "isCurrentlyTracking",
            "baseName": "isCurrentlyTracking",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "viewers",
            "baseName": "viewers",
            "type": "Array<SafetyTrackingViewer>",
            "format": ""
        },
        {
            "name": "dtLastMove",
            "baseName": "dtLastMove",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "isMoving",
            "baseName": "isMoving",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "userDismissedSafety",
            "baseName": "userDismissedSafety",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "notifyTeamTime",
            "baseName": "notifyTeamTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "notifyUserTime",
            "baseName": "notifyUserTime",
            "type": "number",
            "format": ""
        },
        {
            "name": "didNotifyUser",
            "baseName": "didNotifyUser",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "didNotifyViewers",
            "baseName": "didNotifyViewers",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "dtStartedTracking",
            "baseName": "dtStartedTracking",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dtLatestPoint",
            "baseName": "dtLatestPoint",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "token",
            "baseName": "token",
            "type": "string",
            "format": "uuid"
        }    ];

    static getAttributeTypeMap() {
        return SafetyTrackingTeam.attributeTypeMap;
    }

    public constructor() {
    }
}

