import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import {
  IActivityState,
  initialActivityState,
} from "./reducers/activityReducer";
import { IChatState, initialChatState } from "./reducers/chatReducer";
import { IGroupsState, initialGroupsState } from "./reducers/groupsReducer";
import { IHorsesState, initialHorsesState } from "./reducers/horsesReducer";
import {
  IHorsesSearchState,
  initialHorsesSearchState,
} from "./reducers/horsesSearchReducer";
import { initialUserState, IUserState } from "./reducers/userReducer";
import {
  initialUsersSearchState,
  IUsersSearchState,
} from "./reducers/usersSearchReducer";
import { IPaymentState, initialPaymentState } from "./reducers/paymentReducer";

export interface IStore {
  userState: IUserState;
  horsesState: IHorsesState;
  horsesSearchState: IHorsesSearchState;
  usersSearchState: IUsersSearchState;
  activityState: IActivityState;
  chatState: IChatState;
  groupState: IGroupsState;
  paymentState: IPaymentState;
}

const initialState: IStore = {
  userState: initialUserState,
  horsesState: initialHorsesState,
  horsesSearchState: initialHorsesSearchState,
  usersSearchState: initialUsersSearchState,
  activityState: initialActivityState,
  chatState: initialChatState,
  groupState: initialGroupsState,
  paymentState: initialPaymentState,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
