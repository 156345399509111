/**
 * HorseDay REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RevenucatEntitlement } from '../models/RevenucatEntitlement';
import { RevenuecatSubscriptions } from '../models/RevenuecatSubscriptions';
import { HttpFile } from '../http/http';

export class RevenucatSubscription {
    'entitlements': Array<RevenucatEntitlement>;
    'subscriptions': Array<RevenuecatSubscriptions>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "entitlements",
            "baseName": "entitlements",
            "type": "Array<RevenucatEntitlement>",
            "format": ""
        },
        {
            "name": "subscriptions",
            "baseName": "subscriptions",
            "type": "Array<RevenuecatSubscriptions>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return RevenucatSubscription.attributeTypeMap;
    }

    public constructor() {
    }
}

